import Index from "./Index.vue";
import { Router as JoinUsersRouter, Root as JoinUsersRoot } from "./JoinUsers";
import { Router as UsersRouter, Root as UsersRoot } from "./Users";

export default [
  {
    path: "",
    component: Index,
    name: "Invest24Index",
    meta: {
      title: "奥能登実態把調査ボランティア",
      subtitle: "VOLUNTEER",
      tree: [
        {
          title: "奥能登実態把調査ボランティア",
          name: "Invest24Index",
        },
      ],
    },
  },
  {
    path: "join_users",
    component: JoinUsersRoot,
    children: [...JoinUsersRouter],
  },
  {
    path: "users",
    component: UsersRoot,
    children: [...UsersRouter],
  },
];
