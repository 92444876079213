import { onlyHan, zen2han } from "/@/modules/string";

// フォームの入力を整形するためのモジュール

export function gPostcode(postcode: string) {
  let fPostcode = postcode;

  fPostcode = zen2han(fPostcode);
  fPostcode = fPostcode.replace(/[^0-9]/g, "");

  if (fPostcode.length > 7) {
    fPostcode = fPostcode.slice(0, 7);
  }

  return fPostcode;
}

export function gPhoneNumber(phoneNumber: string) {
  let fPhoneNumber = phoneNumber;

  fPhoneNumber = zen2han(fPhoneNumber);
  fPhoneNumber = fPhoneNumber.replace(/[^0-9]/g, "");

  if (fPhoneNumber.length > 11) {
    // 電話番号は11桁までらしい
    // https://qiita.com/tukiyo3/items/b994ffafb7f01e79fe34
    fPhoneNumber = fPhoneNumber.slice(0, 11);
  }

  return fPhoneNumber;
}

export function gKana(str: string) {
  const hira = str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

  // remove all characters except for hiragana
  return hira.replace(/[^ぁ-ん]/g, "");
}

export function gPassword(password: string) {
  return onlyHan(zen2han(password));
}

export function gRegistrationNumber(rnumber: string) {
  let fRnumber = rnumber;

  fRnumber = zen2han(fRnumber);

  return fRnumber.replace(/[^0-9]/g, "");
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("gPostcode", () => {
    expect(gPostcode("123-4567")).toBe("1234567");
    expect(gPostcode("123-45678")).toBe("1234567");
    expect(gPostcode("123-45678a")).toBe("1234567");
    expect(gPostcode("123-45678a90")).toBe("1234567");
    expect(gPostcode("１２３４５６７")).toBe("1234567");
    expect(gPostcode("ＡＢＣＤＥ８")).toBe("8");
  });

  it("gPhoneNumber", () => {
    expect(gPhoneNumber("090-1234-5678")).toBe("09012345678");
    expect(gPhoneNumber("090-1234-56789")).toBe("09012345678");
    expect(gPhoneNumber("090-1234-5678a")).toBe("09012345678");
    expect(gPhoneNumber("090-1234-5678a90")).toBe("09012345678");
    expect(gPhoneNumber("０９０１２３４５６７８")).toBe("09012345678");
    expect(gPhoneNumber("ＡＢＣＤＥ８")).toBe("8");
  });

  it("gKana", () => {
    expect(gKana("アイウエオ")).toBe("あいうえお");
    expect(gKana("あいうえお")).toBe("あいうえお");
    expect(gKana("アイウエオ123")).toBe("あいうえお");
  });

  it("gPassword", () => {
    expect(gPassword("abc123")).toBe("abc123");
    expect(gPassword("ａｂｃ１２３")).toBe("abc123");
  });
}
