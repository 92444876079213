import zod from "zod";
import { z } from "/@/modules/zodUtils";
import {
  UserLabelScheme,
  UserFormScheme,
  UserScheme,
  OrganizationMemberScheme,
  UserPositionScheme,
} from "/@/types/user.d";

export const ManageUserScheme = UserScheme.partial({
  birthday: true,
  phoneNumber: true,
});

export type ManageUser = zod.infer<typeof ManageUserScheme>;

export const ManageUserFormScheme = UserFormScheme.extend({
  userLabels: UserLabelScheme.partial({ id: true }).array(),
  userPositions: UserPositionScheme.partial({ id: true }).array(),
  organizationMembers: OrganizationMemberScheme.array(),
});

export type ManageUserForm = zod.infer<typeof ManageUserFormScheme>;

export const ManageUserCheckScheme = ManageUserScheme;
