<template>
  <div>
    <DataTable
      striped-rows
      paginator
      :rows="10"
      :value="dayInfos"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-click="$emit('select', $event.data.id)"
    >
      <Column
        field="targetDate"
        header="日付"
        :style="
          isMobile
            ? { width: '100px', 'min-width': '100px' }
            : { width: '100px', 'min-width': '100px' }
        "
      >
        <template #body="{ data }">
          <span :data-test-target-date="data.targetDate">{{
            basicFormatter(data.targetDate, "slashStyleOnlyDate")
          }}</span>
        </template>
      </Column>
      <Column
        field="area"
        header="場所"
        :style="isMobile ? { width: '100px' } : { width: '100px' }"
      >
        <template #body="{ data }">
          <span v-if="data.recruitmentType === 'invest24'">{{
            data.area
          }}</span>
          <span v-else>{{ data.workPlace }}</span>
        </template>
      </Column>
      <Column
        field="meetingAt"
        header="時間"
        :style="
          isMobile
            ? { width: '90px', 'min-width': '90px' }
            : { width: '90px', 'min-width': '90px' }
        "
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.meetingAt, "onlyTime") }}</span>
          <span v-if="data.finishAt"
            >〜{{ basicFormatter(data.finishAt, "onlyTime") }}</span
          >
        </template>
      </Column>
      <Column
        field="meetingPlace"
        header="集合場所"
        :style="isMobile ? {} : { width: '100px', 'min-width': '100px' }"
      >
        <template #body="{ data }">
          <span>{{ data.meetingPlace }}</span>
        </template>
      </Column>
      <Column v-if="!isMobile" field="details" header="詳細">
        <template #body="{ data }">
          <span>{{ data.details }}</span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils } from "/@/vue/composables";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

const { isMobile } = useMqUtils();

const props = defineProps<{
  invest24RecruitmentDayInfos: {
    id: number;
    targetDate: string;
    meetingPlace: string;
    meetingAt: string;
    details: string;
    area: string;
  }[];
}>();

defineEmits<{
  (e: "select", id: number): void;
}>();

const dayInfos = computed(() => {
  return props.invest24RecruitmentDayInfos || [];
});
</script>

<style scoped></style>
