import jsonpAdapter from "axios-jsonp";
import { axios } from "/@/modules/axios";
import { errorHandle } from "./error";

// post code

export function formatPostcode(n: string) {
  return "〒" + n.slice(0, 3) + "-" + n.slice(3);
}

// google map

export function createGmapURL(
  placeName: string | undefined,
  others?: string[]
) {
  if (!placeName) {
    return "";
  }

  const otherInfo = others?.filter((o) => o !== "").join("+") || "";
  const otherInfoStr = otherInfo ? `+${otherInfo}` : "";

  return `https://www.google.com/maps/search/?api=1&query=${placeName.trim()}${otherInfoStr}`;
}

export function openGmapInNewTab(url: string | null | undefined) {
  if (!url) {
    return;
  }

  window.open(url, "_blank");
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const n = "9201111";

  it("formatPostcode", () => {
    expect(formatPostcode(n)).toBe("〒920-1111");
  });

  it("createGmapURL", () => {
    expect(createGmapURL("福井県福井市大和田")).toBe(
      "https://www.google.com/maps/search/?api=1&query=福井県福井市大和田"
    );
    expect(createGmapURL("福井県福井市大和田", ["福井県", "福井市"])).toBe(
      "https://www.google.com/maps/search/?api=1&query=福井県福井市大和田+福井県+福井市"
    );
  });
}

// get address

type ZipAddress = {
  code: number;
  pref: string;
  address: string;
  city: string;
  town: string;
  fullAddress: string;
};

export async function getAddress(postcode?: string) {
  if (!postcode) return;

  try {
    const { data }: { data: ZipAddress } = await axios.get(
      `https://api.zipaddress.net/?zipcode=${postcode}`,
      { adapter: jsonpAdapter }
    );

    if (!data || data.code == 400 || data.code == 404) {
      return undefined;
    }
    return data.fullAddress;
  } catch (e) {
    errorHandle(e);
  }
}
