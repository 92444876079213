<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <img
      :src="logo"
      alt="石川県介護支援専門員協会"
      class="logo"
      role="button"
      :style="
        isMobile
          ? {
              width: '200px',
              height: '32px',
            }
          : {
              width: '300px',
              height: '50px',
            }
      "
      @click="goto({ name: 'Index' })"
    />
    <a v-if="showPhoneNumber" :href="`tel:0762241211`">
      <div
        class="d-flex align-items-center"
        style="color: #b0b8bf"
        :style="{ 'font-size': isMobile ? '0.6rem' : '1rem' }"
      >
        <i
          class="pi pi-phone me-2"
          :style="{ 'font-size': isMobile ? '0.6rem' : '1rem' }"
        ></i>
        <p>事務局：076-224-1211</p>
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
import { useMqUtils, useRouterUtil } from "/@/vue/composables";
import logo from "../../../../../public/img/logo.gif";

const { isMobile } = useMqUtils();
const { goto } = useRouterUtil();

defineProps<{
  showPhoneNumber?: boolean;
}>();
</script>

<style scoped></style>
