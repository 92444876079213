<template>
  <div>
    <div :class="`linkify-${randomId} linkify`">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

const randomId = Math.random().toString(32).substring(2);

onMounted(() => {
  const divs = document.querySelectorAll(".linkify-" + randomId);

  divs.forEach((div) => {
    const content = div.innerHTML;

    // TODO: primevue editor で埋め込んだリンクをスルーしたいために
    // "" で囲まれているようなリンクの文字列はスルーするようにしている。ただ、これですべてうまく行くか分からない
    const linkedContent = content.replace(
      /(?<!")https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+(?<!")/g,
      function (match) {
        return `<a href="${match}" target="_blank" class="linkified-url">${match}</a>`;
      }
    );
    div.innerHTML = linkedContent;
  });
});
</script>

<style>
.linkify {
  a {
    text-decoration: underline;
    color: var(--primary);
  }
}
</style>
