<template>
  <div>
    <div class="w-100 d-flex align-items-center justify-content-center">
      <router-link
        :to="{
          name: 'UsersRecruitmentsManagementsManagement',
          query: { recruitmentType: 'invest24' },
        }"
        :style="itemStyle"
        :class="itemClass"
        class="me-2"
      >
        奥能登実態調査
      </router-link>
      <router-link
        :to="{
          name: 'UsersRecruitmentsManagementsManagement',
          query: { recruitmentType: 'shelter24' },
        }"
        :style="itemStyle"
        :class="itemClass"
        class="ms-2"
      >
        1.5次避難所
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
const itemStyle = {
  border: "1px solid #000",
  padding: "1rem",
  width: "50%",
  maxWidth: "350px",
  borderRadius: "20px",
  height: "200px",
  fontSize: "2.5rem",
};

const itemClass = "d-flex align-items-center justify-content-center";
</script>

<style scoped></style>
