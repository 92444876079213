import Index from "./Index.vue";
import Edit from "./Edit.vue";
import New from "./New.vue";
import Show from "./Show.vue";
import ChangePassword from "./ChangePassword.vue";
import { Router as BcpRouter, Root as BcpRoot } from "./BCP";
import { Router as InquiriesRouter, Root as InquiriesRoot } from "./Inquiries";
import { Router as MagazinesRouter, Root as MagazinesRoot } from "./Magazines";
import { Root as NewsRoot, Router as NewsRouter } from "./News";
import {
  Root as OrganizationsRoot,
  Router as OrganizationsRouter,
} from "./Organizations";
import { Root as PasswordRoot, Router as PasswordRouter } from "./Password";
import { Root as RulesRoot, Router as RulesRouter } from "./Rules";
import { Root as Invest24Root, Router as Invest24Router } from "./Invest24";
import { Root as SponsorsRoot, Router as SponsorsRouter } from "./Sponsors";
import {
  Root as SystemAdmin,
  Router as SystemAdminRouter,
} from "./SystemAdmin";
import { Root as TrainingsRoot, Router as TrainingsRouter } from "./Trainings";

export default [
  {
    path: "",
    name: "UsersIndex",
    component: Index,
    meta: {
      title: "マイページ",
      subtitle: "MYPAGE",
      tree: [],
    },
  },
  {
    path: "edit",
    name: "UsersEdit",
    component: Edit,
    meta: {
      title: "編集",
      subtitle: "USER INFO",
      tree: [
        {
          name: "UsersShow",
          title: "会員ページ 会員情報",
        },
        {
          title: "編集",
        },
      ],
    },
  },
  {
    path: "new",
    name: "UsersNew",
    component: New,
    meta: {
      title: "会員代理追加",
      subtitle: "ADD USER",
      tree: [],
    },
  },
  {
    path: "change_password",
    name: "UsersChangePassword",
    component: ChangePassword,
    meta: {
      title: "パスワード変更",
      subtitle: "CHANGE PASSWORD",
      tree: [
        {
          name: "UsersShow",
          title: "会員ページ 会員情報",
        },
        {
          title: "パスワード変更",
        },
      ],
    },
  },
  {
    path: "show",
    name: "UsersShow",
    component: Show,
    meta: {
      title: "会員情報",
      subtitle: "MYPAGE",
      tree: [
        {
          title: "会員ページ 会員情報",
        },
      ],
    },
  },
  {
    path: "bcp",
    component: BcpRoot,
    children: BcpRouter,
  },
  {
    path: "inquiries",
    component: InquiriesRoot,
    children: InquiriesRouter,
  },
  {
    path: "magazines",
    component: MagazinesRoot,
    children: MagazinesRouter,
  },
  {
    path: "news",
    component: NewsRoot,
    children: NewsRouter,
  },
  {
    path: "organizations",
    component: OrganizationsRoot,
    children: OrganizationsRouter,
  },
  {
    path: "password",
    component: PasswordRoot,
    children: PasswordRouter,
  },
  {
    path: "rules",
    component: RulesRoot,
    children: RulesRouter,
  },
  {
    path: "sponsors",
    component: SponsorsRoot,
    children: SponsorsRouter,
  },
  {
    path: "system_admin",
    component: SystemAdmin,
    children: SystemAdminRouter,
  },
  {
    path: "invest24",
    component: Invest24Root,
    children: Invest24Router,
  },
  {
    path: "trainings",
    component: TrainingsRoot,
    children: TrainingsRouter,
  },
];
