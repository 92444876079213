import zod from "zod";
import { z } from "/@/modules/zodUtils";

const SponsorMemberScheme = z.object({
  name: z.string().trim().min(1),
  url: z.string().url().optional(),
  imageBlob: z.string().optional(),
});

export const SponsorScheme = z.object({
  id: z.number().optional(),
  members: SponsorMemberScheme.array(),
});
export type Sponsor = zod.infer<typeof SponsorScheme>;

export const SponsorFormScheme = SponsorScheme.partial().extend({
  members: SponsorMemberScheme.array(),
});
export type SponsorForm = zod.infer<typeof SponsorFormScheme>;

export const SponsorCheckScheme = SponsorScheme;

export const SponsorClientScheme = SponsorScheme.extend({
  id: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
});
export type SponsorClient = zod.infer<typeof SponsorClientScheme>;
