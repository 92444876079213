<template>
  <div>
    <div v-if="sponsors">
      <BasicButton
        label="追加"
        icon="pi pi-plus"
        :disabled="disabledAddMember"
        class="mb-3"
        @click="addMemberGroup"
      />

      <DataTable
        :value="form.members"
        :reorderable-columns="true"
        edit-mode="cell"
        class="mb-3"
        @row-reorder="(e) => onRowReorder(e)"
      >
        <Column
          row-reorder
          header-style="width: 3rem; min-width: 3rem"
          :reorderable-column="false"
        />
        <Column
          v-for="col of ['name', 'url']"
          :key="col"
          :field="col"
          :header="memberKeyToStr(col)"
          :style="{
            width: col === 'name' ? 'auto' : '400px',
            'min-width': '200px',
          }"
          :reorderable-column="false"
        >
          <template #body="{ field, index }">
            <FormItem
              :value="form.members[index][field]"
              form-type="text"
              :form-id="field"
              :errors="errors?.members?.[index]"
              :placeholder="memberKeyToStr(field)"
            />
          </template>
          <template #editor="{ field, index }">
            <FormItem
              v-model:value="form.members[index][field]"
              form-type="text"
              :form-id="field"
              :placeholder="memberKeyToStr(field)"
              :errors="errors?.members?.[index]"
            />
          </template>
        </Column>
        <Column
          field="image_blob"
          :header="memberKeyToStr('image_blob')"
          width="300px"
          style="min-width: 200px"
          :reorderable-column="false"
        >
          <template #body="{ index }">
            <ImageForm
              :model-value="[form.members[index].imageBlob]"
              :file-limit="1"
              :current-count="form.members[index].imageBlob ? 1 : 0"
              @update:model-value="form.members[index].imageBlob = $event[0]"
            />
          </template>
        </Column>
        <Column header-style="width: 80px; min-width: 80px">
          <template #body="{ index }">
            <BasicButton
              v-if="form.members.length > 1"
              icon="pi pi-trash"
              variant="danger"
              @click="removeInquiry(index)"
            />
          </template>
        </Column>
      </DataTable>

      <BasicButton
        v-if="form.members && form.members.length > 0"
        label="追加"
        icon="pi pi-plus"
        :disabled="disabledAddMember"
        @click="addMemberGroup"
      />

      <div class="d-flex justify-content-center my-4 py-4">
        <BasicButton
          label="キャンセル"
          variant="secondary"
          class="me-5"
          outlined
          @click="backto"
        />
        <BasicButton label="更新" button-type="submit" @click="submit" />
      </div>
    </div>
    <i v-else class="pi pi-spin pi-spinner"></i>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import { scrollToTop } from "/@/modules/ui";
import {
  useZodScheme,
  useSponsors,
  useRouterUtil,
  useUser,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { FormItem, ImageForm } from "/@/vue/components/Molecules";
import {
  SponsorForm as SponsorFormType,
  SponsorCheckScheme,
  SponsorClient,
} from "/@/types/sponsor.d";

import DataTable from "primevue/datatable";
import Column from "primevue/column";

const { getUser } = useUser();
const { data: user, mutate: getUserMutate } = getUser();

const organizations = computed(() => {
  if (!user.value) return [];

  return user.value.organizations;
});

const { getSponsors, updateSponsors } = useSponsors();
const { data: sponsors } = getSponsors();

const { backto, goto } = useRouterUtil();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<SponsorFormType>(SponsorCheckScheme);

function resetForm(sponsors?: SponsorClient) {
  form.id = sponsors?.id || undefined;
  form.members = sponsors?.members || [];
}

onMounted(async () => {
  if (!organizations.value) {
    await getUserMutate();
  }

  startValidation.value = true;
  if (sponsors.value) {
    resetForm(sponsors.value);
  }
  scrollToTop();
});

function memberKeyToStr(key: string) {
  switch (key) {
    case "name":
      return "名前";
    case "url":
      return "URL";
    case "image_blob":
      return "画像";
  }
}

function onRowReorder(e: any) {
  const newMembers = e.value;

  form.members = newMembers;
}

function removeInquiry(nidx: number) {
  form.members.splice(nidx, 1);
}

const disabledAddMember = computed(() => {
  return !!errors.value.members;
});

function defaultMember() {
  return {
    name: "",
  };
}

function addMemberGroup() {
  if (disabledAddMember.value) return;

  form.members = [...form.members, defaultMember()];
}

async function submit() {
  if (!form.id) return;

  try {
    startValidation.value = true;

    const args = SponsorCheckScheme.parse(form);

    if (await updateSponsors(form.id, args)) {
      alert("更新しました");
      goto({ name: "UsersSponsorsIndex" });
    } else {
      alert(
        "更新に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
