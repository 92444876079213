import Index from "./Index.vue";
import Show from "./Show.vue";

export default [
  {
    path: "",
    name: "UsersNewsUserNewsIndex",
    component: Index,
    meta: {
      title: "新着お知らせ",
      subtitle: "NEWS",
      tree: [
        {
          title: "会員メニュー 新着お知らせ",
        },
      ],
    },
  },
  {
    path: ":id",
    name: "UsersNewsUserNewsShow",
    component: Show,
    meta: {
      title: "詳細",
      tree: [
        {
          title: "新着お知らせ",
          name: "UsersNewsUserNewsIndex",
        },
        {
          title: "詳細",
        },
      ],
    },
  },
];
