<template>
  <div>
    <InputForm
      :value="value"
      :is-valid="isValid"
      :form-id="formId"
      :name="formName"
      :autocomplete="autocomplete"
      :form-type="showPassword ? 'text' : 'password'"
      @update:value="update"
    >
      <template #backButtonSlot>
        <button type="button" class="btn" @click="showPassword = !showPassword">
          <i v-if="showPassword" class="pi pi-eye"></i>
          <i v-else class="pi pi-eye-slash"></i>
        </button>
      </template>
    </InputForm>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { InputForm } from "./";

interface Props {
  value?: string;
  isValid?: boolean;
  isConfirmation?: boolean;
  formId?: string;
  formName?: string;
  autocomplete?: string;
}

withDefaults(defineProps<Props>(), {
  isValid: true,
  isConfirmation: false,
  formId: "password",
  formName: "password",
  autocomplete: "current-password",
});

const emit = defineEmits<{
  (e: "update:value", value: string | null): void;
}>();

function update(email: string | null) {
  emit("update:value", email);
}

const showPassword = ref(false);
</script>

<style lang="scss" scoped></style>
