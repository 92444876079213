import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { ManageUser } from "/@/types";
import { errorHandle } from "/@/modules/error";

export function useSystemAdmin() {
  // jpHoliday

  function getAllUsers() {
    return useSWRV("/api/v1/system_admins/users", fetcher);
  }

  async function updateUser(id: number, prms: ManageUser) {
    try {
      await axios.patch(`/api/v1/system_admins/users/${id}`, {
        users: prms,
      });
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  return {
    getAllUsers,

    updateUser,
  };
}
