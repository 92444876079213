import { Store } from "vuex";
import { createRouter, createWebHistory } from "vue-router";
import { version } from "/@/modules/version";
import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

import { RootState } from "../store";
import { routes, isNeedLogin, isNeedLabel } from "/@/vue/pages/routes";

export function creatingRouter({
  store,
  mock,
}: {
  store: Store<RootState>;
  mock?: boolean;
}) {
  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.name === from.name) {
        return savedPosition;
      }

      if (to.hash) {
        return {
          el: to.hash,
          // behavior: "smooth",
        };
      }

      return { top: 0, behavior: "instant" };
    },
  });

  router.beforeEach(async (to, from, next) => {
    if (mock) {
      next();
      return;
    }

    const currentVersion = version;

    const { data: serverVersion } = await axios
      .get("/api/v1/version")
      .catch((e) => errorHandle(e));

    if (currentVersion < serverVersion) {
      window.alert("アップデートがあるためページを更新します");
      window.location.reload();
      next({ path: from.fullPath });
    }

    let user = store.state.user.user;

    if (!user) {
      if (await store.dispatch("user/GET_USER")) {
        user = store.state.user.user;
      }
    }

    if (!to.name) {
      return;
    }

    if (
      (isNeedLabel(to.name as string, "system_admin") &&
        user?.userLabels.map((ul) => ul.labelType).includes("system_admin")) ||
      (isNeedLabel(to.name as string, "owner") &&
        user?.userLabels.map((ul) => ul.labelType).includes("owner"))
    ) {
      next();
    } else if (
      isNeedLabel(to.name as string, "system_admin") ||
      isNeedLabel(to.name as string, "owner")
    ) {
      next({ name: "Index", query: { reason: "not_allowed" } });
    }

    if (isNeedLogin(to.name as string) && !user) {
      next({ name: "SignIn" });
    } else if (
      isNeedLogin(to.name as string) &&
      to.name != "UsersChangePassword" &&
      user &&
      !user.changedPassword
    ) {
      next({ name: "UsersChangePassword" });
    } else if (to.name == "SignIn" && !!user) {
      next({ name: "Index" });
    } else {
      next();
    }
  });

  router.afterEach((to, from) => {
    if (["NotFound", "PrivacyPolicy"].includes(to.name)) {
      window.scrollTo({
        top: 0,
      });
    }
  });

  return router;
}
