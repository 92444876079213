<template>
  <div>
    <UserPageActions> </UserPageActions>

    <span v-if="error">読み込み中にエラーが発生しました</span>
    <i v-else-if="!organizations" class="pi pi-spin pi-spinner"></i>
    <span v-else-if="!organizations.length">管理可能な組織がありません</span>
    <OrganizationTable
      v-else
      :organizations="organizations"
      @select-organization="selectOrganization"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useUser, useRouterUtil } from "/@/vue/composables";
import { UserPageActions } from "/@/vue/components/Layouts";
import { Table as OrganizationTable } from "/@/vue/components/Organisms/Users/Organizations";

// api

const { getUser } = useUser();
const { data: user, error } = getUser();

const organizations = computed(() => {
  return user.value?.managedOrganizations;
});

const { goto } = useRouterUtil();

function selectOrganization(id: number) {
  goto({ name: "UsersOrganizationsShow", params: { id: id.toString() } });
}
</script>

<style lang="scss" scoped></style>
