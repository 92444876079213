<template>
  <div class="d-flex flex-column align-items-center">
    <div
      v-if="recruitment"
      class="form-wrapper"
      style="max-width: 1200px; width: 100%"
    >
      <template v-if="!confirming">
        <span class="text-danger">入力ミスにご注意下さい</span>

        <JoinForm
          v-model:target-date="form.targetDate"
          :area="recruitment.area"
          :recruitment="recruitment"
          :recruitment-type="recruitment.recruitmentType"
          :errors="errors"
          disabled
          class="mt-3"
        />
        <hr />
        <Invest24UserForm
          v-model:last-name="form.lastName"
          v-model:first-name="form.firstName"
          v-model:last-name-kana="form.lastNameKana"
          v-model:first-name-kana="form.firstNameKana"
          v-model:birthday="form.birthday"
          v-model:phone-number="form.phoneNumber"
          v-model:work-type="form.workType"
          v-model:email="form.email"
          v-model:email-check="emailCheck"
          :recruitment-type="recruitment.recruitmentType"
          :errors="errors"
        />

        <div class="mt-3">
          <BasicButton
            class="me-2"
            variant="secondary"
            outlined
            @click="backto"
          >
            キャンセル
          </BasicButton>
          <BasicButton class="ms-2" @click="confirm">確認</BasicButton>
        </div>
      </template>
      <template v-else>
        <JoinUserShow
          :join-form="form"
          :area="recruitment.area"
          :recruitment-type="recruitment.recruitmentType"
        />
        <div class="my-5">
          <BasicButton
            label="修正"
            variant="secondary"
            class="me-2"
            outlined
            @click="confirming = false"
          />
          <BasicButton
            button-type="submit"
            label="送信"
            :disabled="loading"
            class="ms-2"
            @click="submit"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { scrollToTop } from "/@/modules/ui";
import { gKana, gPhoneNumber } from "/@/modules/groomer";
import {
  useZodScheme,
  useRouterUtil,
  useRecruitment,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { JoinForm } from "/@/vue/components/Organisms/Users/Invest24Recruitments";
import { Form as Invest24UserForm } from "/@/vue/components/Organisms/Invest24/Invest24Users";
import { Show as JoinUserShow } from "/@/vue/components/Molecules/JoinUser";
import {
  Invest24JoinUserNewForm,
  Invest24JoinUserNewCheckScheme,
} from "/@/types";

const { backto, goto, currentRouteQuery } = useRouterUtil();

// query

const recruitmentId = computed<number>(() => {
  return Number(currentRouteQuery.value.id);
});

const targetDate = computed<string>(() => {
  return currentRouteQuery.value.targetDate;
});

// recruitment

const { getRecruitment, applyRecruitmentWithNewUser } = useRecruitment();
const { data: recruitment } = getRecruitment(recruitmentId);

// form

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<Invest24JoinUserNewForm>(Invest24JoinUserNewCheckScheme, {
    birthday: "1990-01-01",
    targetDate: targetDate.value,
    workType: "通勤",
  });

watch(form, (f) => {
  // form を watch する場合は、まとめて変更した時の処理を書けるが、
  // 無駄な比較処理が増えるのと過去の値へのアクセスができない問題がある。
  // そのため個別のプロパティでの変更があった時のような処理が書けない

  if (f.firstName && f.firstName.length > 50) {
    form.firstName = f.firstName.slice(0, 50);
  }

  if (f.lastName && f.lastName.length > 50) {
    form.lastName = f.lastName.slice(0, 50);
  }

  if (f.firstNameKana) {
    const kana = gKana(f.firstNameKana);
    form.firstNameKana = kana.slice(0, 50);
  }

  if (f.lastNameKana) {
    const kana = gKana(f.lastNameKana);
    form.lastNameKana = kana.slice(0, 50);
  }

  if (f.phoneNumber) {
    form.phoneNumber = gPhoneNumber(f.phoneNumber);
  }
});

const emailCheck = ref("");
/*
form.lastName = "試験";
form.firstName = "追加";
form.lastNameKana = "テスト";
form.firstNameKana = "ツイカ";
form.phoneNumber = "09012345678";
form.email = "s1200191@gmail.com";
emailCheck.value = "s1200191@gmail.com";
*/

startValidation.value = true;

const confirming = ref(false);
function confirm() {
  try {
    if (form.email !== emailCheck.value) {
      throw new Error("メールアドレスが一致しません。");
    }

    Invest24JoinUserNewCheckScheme.parse(form);
    scrollToTop();
    confirming.value = true;
  } catch (e) {
    console.error(e);
    window.alert("入力に問題があります。赤枠の項目を確認して下さい。");
  }
}

const loading = ref(false);

async function submit() {
  try {
    if (!recruitment.value) return;

    loading.value = true;

    if (form.email !== emailCheck.value) {
      throw new Error("メールアドレスが一致しません。");
    }

    const prms = Invest24JoinUserNewCheckScheme.parse(form);

    if (
      await applyRecruitmentWithNewUser(recruitmentId.value, prms, {
        isVManager: true,
      })
    ) {
      let txt = "申請が完了しました。";

      window.alert(txt);
      goto({
        name: "UsersRecruitmentsManagementsManagement",
        query: { recruitmentType: recruitment.value.recruitmentType },
      });
    } else {
      window.alert("申請に失敗しました。すでに申請済みか、人数が一杯です");
    }
    return;
  } catch (e) {
    console.error(e);
    window.alert("入力に問題があります。赤枠の項目を確認して下さい。");
  }

  loading.value = false;
}
</script>

<style scoped>
.form-wrapper {
  min-height: 100vh;
}
</style>
