import { PdfInfoScheme } from '/@/types';
<template>
  <div class="w-100">
    <div class="d-flex flex-column w-100 h-100">
      <div
        v-for="(pdfInfo, idx) in attachedPdfs"
        :key="pdfInfo.signedId"
        class="mb-3 h-100"
      >
        <div class="d-flex mb-3">
          <a
            class="d-flex align-items-center me-2"
            :href="pdfInfo.url"
            target="_blank"
          >
            <span class="me-1">添付書類{{ idx + 1 }}</span>
            <i class="pi pi-external-link ms-2"></i>
            <span>（別タブで開く）</span>
          </a>
          <BasicButton
            v-if="showDeleteButton"
            variant="danger"
            class="ms-2"
            icon="pi pi-trash"
            @click="$emit('remove', pdfInfo)"
          />
        </div>

        <div class="d-flex justify-content-center align-items-center w-100">
          <embed
            v-if="pdfInfo.contentType === 'application/pdf'"
            :src="pdfInfo.url"
            :type="pdfInfo.contentType"
            :style="pdfStyle"
          />
          <img
            v-if="
              pdfInfo.contentType === 'image/jpeg' ||
              pdfInfo.contentType === 'image/png'
            "
            :src="pdfInfo.url"
            :style="{
              height: '480px',
              width: 'auto',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PDF from "pdf-vue3";
import { BasicButton } from "/@/vue/components/Atom";
import { PdfInfo } from "/@/types";

defineProps<{
  attachedPdfs: PdfInfo[];
  showDeleteButton?: boolean;
}>();

defineEmits<{
  (e: "remove", pdf: PdfInfo): void;
}>();

const pdfStyle = {
  height: "100%",
  "min-height": "700px",
  "min-width": "800px",
};
</script>

<style scoped></style>
