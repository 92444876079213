<template>
  <div>
    <SelectButton
      v-model="recruitmentType"
      :options="['invest24', 'shelter24']"
      :option-label="recruitmentTypeTranslater"
      :allow-empty="false"
    />
    <RecruitmentCalendar
      :recruitments="recruitments || []"
      :jp-holidays="jpHolidays || {}"
      class="py-3"
      :loading="loading"
      :invest24-users="[]"
      :users="[]"
      :recruitment-type="recruitmentType"
      style="max-width: 1200px"
      @apply-recruitment="handleApplyRecruitment"
      @cancel-recruitment="handleCancelRecruitment"
      @destroy-recruitment="handleDestroyRecruitment"
      @detach-user="handleDetachUser"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { recruitmentTypeTranslater } from "/@/modules/invest24Recruitment";
import { useRouterUtil, useCalendar, useRecruitment } from "/@/vue/composables";
import { List as RecruitmentCalendar } from "/@/vue/components/Molecules/Invest24Recruitments";
import SelectButton from "primevue/selectbutton";
import { Invest24RecruitmentType } from "/@/types";
import { watch } from "vue";

const { goto, currentRouteQuery } = useRouterUtil();

// calendar

const { getJpHolidays } = useCalendar();
const { data: jpHolidays } = getJpHolidays();

// recruitment

const recruitmentType = ref<Invest24RecruitmentType>(
  (currentRouteQuery.value?.recruitmentType as Invest24RecruitmentType) ||
    "invest24"
);

watch(recruitmentType, (rt) => {
  goto({ query: { recruitmentType: rt } });
});

const {
  getRecruitments,
  cancelRecruitment,
  destroyRecruitment,
  allocateUserRecruitment,
  detachUserRecruitment,
} = useRecruitment();
const { data: recruitments, mutate: getRecruitmentsMutate } = getRecruitments({
  recruitmentType: recruitmentType,
});

const loading = ref(false);

async function handleApplyRecruitment(id: number, targetDate: string) {
  goto({ name: "UsersJoinUsersNew", query: { id, targetDate } });
}

async function handleCancelRecruitment(id: number, targetDate: string) {
  if (!window.confirm("申請を取り消してよろしいですか？")) return;

  loading.value = true;

  if (await cancelRecruitment(id, { targetDate })) {
    alert("申請を取り消しました");
    getRecruitmentsMutate();
  } else {
    alert("申請の取り消しに失敗しました");
  }

  loading.value = false;
}

async function handleDestroyRecruitment(id: number) {
  if (!window.confirm("募集の削除依頼を開発組織に依頼しますか？")) return;

  loading.value = true;

  if (await destroyRecruitment(id)) {
    alert("削除依頼を送信しました");
  } else {
    alert("削除依頼の送信に失敗しました");
  }
}

async function handleDetachUser(
  id: number,
  invest24UserId: number,
  targetDate: string
) {
  if (
    !window.confirm("ボランティアへの参加をキャンセルします。よろしいですか？")
  )
    return;

  loading.value = true;

  if (await detachUserRecruitment(id, { invest24UserId, targetDate })) {
    alert("キャンセルしました");
    getRecruitmentsMutate();
  } else {
    alert("キャンセルに失敗しました");
  }

  loading.value = false;
}
</script>

<style scoped></style>
