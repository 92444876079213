<template>
  <div>
    <ContentLabel v-if="label" :label="label" :item-id="formId" />
    <component
      :is="formComponent"
      v-if="!Array.isArray(value)"
      :value="value"
      :is-valid="!errors?.[formId]"
      :form-id="formId"
      :form-type="formType"
      :form-name="formId"
      :disabled="disabled"
      :readonly="readonly"
      :min-date="minDate"
      :max-date="maxDate"
      :start-date="startDate"
      :multi-dates="multiDates"
      :auto-apply="autoApply"
      :pattern="pattern"
      :rows="rows"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :allowed-dates="allowedDates"
      :disabled-dates="disabledDates"
      :items="items || []"
      @update:value="$emit('update:value', converter($event))"
    />
    <component
      :is="DateForm"
      v-else
      :value="value"
      :is-valid="!errors?.[formId]"
      :form-id="formId"
      :form-name="formId"
      :disabled="disabled"
      :readonly="readonly"
      :min-date="minDate"
      :max-date="maxDate"
      :multi-dates="multiDates"
      :auto-apply="autoApply"
      :allowed-dates="allowedDates"
      :disabled-dates="disabledDates"
      :start-date="startDate"
      @update:value="$emit('update:value', $event)"
    ></component>
    <MessageList
      v-if="showErrorMessage"
      :messages="errorMessage"
      status="error"
    />
  </div>
</template>

<script setup lang="ts">
import type { DateTime } from "luxon";
import { computed } from "vue";
import {
  Label as ContentLabel,
  DateForm,
  EmailForm,
  InputForm,
  MessageList,
  PasswordForm,
  TextareaForm,
  NumberForm,
} from "/@/vue/components/Atom";
import { ZodFormattedErrors } from "/@/types";

interface Props {
  value?: string | number | string[] | null;
  label?: string;
  formId: string;
  formType:
    | "email"
    | "password"
    | "color"
    | "text"
    | "number"
    | "tel"
    | "textarea"
    | "date"
    | "selectNumber";
  disabled?: boolean;
  readonly?: boolean;
  multiDates?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
  rows?: number;
  startDate?: DateTime;
  autoApply?: boolean;
  autocomplete?: string;
  pattern?: string;
  placeholder?: string;
  allowedDates?: string[];
  disabledDates?: string[];
  items?: any[];
  errors: ZodFormattedErrors;
  showErrorMessage?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  value: undefined,
  minDate: undefined,
  maxDate: undefined,
  startDate: undefined,
  rows: undefined,
  autoApply: true,
  pattern: undefined,
  disabledDates: undefined,
  autocomplete: "off",
});

defineEmits<{
  (e: "update:value", value: string | number | undefined): void;
  (e: "change:value", value: string | number | undefined): void;
}>();

const isNormalForm = computed<boolean>(() => {
  return ["text", "number", "tel"].includes(props.formType);
});

const isEmailForm = computed<boolean>(() => {
  return props.formType == "email";
});

const isTextareaForm = computed<boolean>(() => {
  return props.formType == "textarea";
});

const isDateForm = computed<boolean>(() => {
  return props.formType == "date";
});

const isSelectNumberForm = computed<boolean>(() => {
  return props.formType == "selectNumber";
});

const formComponent = computed(() => {
  if (isNormalForm.value) {
    return InputForm;
  } else if (isEmailForm.value) {
    return EmailForm;
  } else if (isTextareaForm.value) {
    return TextareaForm;
  } else if (isDateForm.value) {
    return DateForm;
  } else if (isSelectNumberForm.value) {
    return NumberForm;
  } else {
    return PasswordForm;
  }
});

const errorMessage = computed<string[]>(() => {
  const keyError = props.errors?.[props.formId];

  if (keyError?._errors) {
    return keyError._errors;
  } else {
    return [];
  }
});

function converter(input: string) {
  if (props.formType === "number") {
    return Number(input);
  } else {
    return input;
  }
}
</script>

<style scoped></style>
