<template>
  <div>
    <DataTable
      striped-rows
      paginator
      :rows="selectedPerPage"
      :value="joinUsers"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-click="$emit('select', $event.data)"
    >
      <template #header>
        <TableHeader
          v-model="selectedPerPage"
          :target-length="joinUsers.length"
          :current-page="currentPage"
        />
      </template>
      <Column
        sortable
        field="aria"
        header="エリア"
        :style="isMobile ? { width: '100%' } : {}"
      >
        <template #body="{ data }">
          <span>{{ data.recruitment.area }}</span>
        </template>
      </Column>
      <Column
        sortable
        field="targetDate"
        header="日にち"
        :style="
          isMobile
            ? { width: '150px', 'min-width': '150px' }
            : { width: '150px', 'min-width': '150px' }
        "
      >
        <template #body="{ data }">
          <span>{{
            basicFormatter(data.targetDate, "slashStyleOnlyDate")
          }}</span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { useMqUtils, useTableHeader } from "/@/vue/composables";
import { basicFormatter } from "/@/modules/luxon";
import { Header as TableHeader } from "/@/vue/components/Molecules/TableUtils";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { Invest24JoinUserClient, Invest24RecruitmentClient } from "/@/types";

const { isMobile } = useMqUtils();
const { selectedPerPage, currentPage, changePage } = useTableHeader();

defineProps<{
  joinUsers: (Invest24JoinUserClient & {
    recruitment: Invest24JoinUserClient;
  })[];
}>();

defineEmits<{
  (
    e: "select",
    joinUser: Invest24JoinUserClient & {
      recruitment: Invest24RecruitmentClient;
    }
  ): void;
}>();
</script>

<style scoped></style>
