<template>
  <div>
    <ShowFormItemWrapper title="申請内容">
      <div class="d-flex flex-column">
        <div>
          {{ basicFormatter(joinForm.targetDate) }}
        </div>
        <div>
          {{ recruitmentTypeStr }}
        </div>
        <div v-if="recruitmentType === 'invest24'">
          {{ area }}
        </div>
      </div>
    </ShowFormItemWrapper>
    <UserShow :user="joinForm" :recruitment-type="recruitmentType" />
  </div>
</template>

<script setup lang="ts">
import { basicFormatter } from "/@/modules/luxon";
import { recruitmentTypeTranslater } from "/@/modules/invest24Recruitment";
import { ShowFormItemWrapper } from "/@/vue/components/Molecules/Form";
import { Show as UserShow } from "/@/vue/components/Molecules/Users";
import { Invest24JoinUserNewForm, Invest24RecruitmentType } from "/@/types";
import { computed } from "vue";

const props = defineProps<{
  joinForm: Invest24JoinUserNewForm;
  area: string;
  recruitmentType: Invest24RecruitmentType;
}>();

const recruitmentTypeStr = computed(() => {
  return recruitmentTypeTranslater(props.recruitmentType);
});
</script>

<style scoped></style>
