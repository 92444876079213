<template>
  <div>
    <div class="d-flex flex-column align-items-center w-100">
      <div class="contents-960 w-100">
        <MagazinesList
          :magazines="allMagazines"
          show-filter
          show-page-info
          class="w-100"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useMagazines } from "/@/vue/composables";
import { List as MagazinesList } from "/@/vue/components/Molecules/Magazines";

const { getMagazines } = useMagazines();
const { data: magazines } = getMagazines();

const allMagazines = computed(() => {
  if (!magazines.value) return [];

  return magazines.value;
});
</script>

<style scoped></style>
