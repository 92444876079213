import useSWRV from "swrv";
import { Format, FormatType } from "/@/types/format";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { FormatClient } from "/@/types";
import { ComputedRef, Ref } from "vue";

export function useFormats() {
  function getFormatKey(id: Ref<number> | ComputedRef<number>) {
    if (!id || !id.value) return null;

    return `/api/v1/formats/${id.value}`;
  }

  function getFormat(id: Ref<number> | ComputedRef<number>) {
    return useSWRV<FormatClient>(getFormatKey(id), fetcher);
  }

  function getFormatByType(formatType: FormatType) {
    return useSWRV<FormatClient>(`/api/v1/formats/type/${formatType}`, fetcher);
  }

  function getFormats(options: { types: FormatType[] } = { types: [] }) {
    const { types } = options;

    let uri = "/api/v1/formats?";

    if (types.length) {
      uri += `types=${types.join(",")}`;
    }

    return useSWRV<FormatClient[]>(uri, fetcher);
  }

  async function updateFormat(id: number, format: Format) {
    try {
      await axios.patch(`/api/v1/formats/${id}`, {
        formats: format,
      });
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  async function createFormat(format: Format) {
    try {
      await axios.post(`/api/v1/formats`, {
        formats: format,
      });
      return true;
    } catch (error) {
      alert(errorHandle(error));
      return false;
    }
  }

  async function destroyFormat(id: number) {
    try {
      await axios.delete(`/api/v1/formats/${id}`);
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  return {
    getFormat,
    getFormats,
    getFormatByType,
    createFormat,
    updateFormat,
    destroyFormat,
  };
}
