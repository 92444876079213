<template>
  <Button
    :class="btnClass"
    :severity="severity"
    :disabled="disabledInComponent || disabled"
    :label="label"
    :name="name"
    :type="buttonType"
    icon="pi pi-angle-left"
    icon-pos="left"
    :size="size"
    :rounded="rounded"
    :text="text"
    :outlined="outlined"
    @click="onClick"
  >
    <slot></slot>
  </Button>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, ButtonHTMLAttributes } from "vue";
import { useRouterUtil } from "/@/vue/composables";
import Button from "primevue/button";
type ButtonTypes = ButtonHTMLAttributes["type"];

interface Props {
  name?: string;
  variant?: "green" | "primary" | "secondary" | "warning" | "gray" | "success";
  color?: "green" | "orange";
  colorStr?: string;
  label?: string;
  disabled?: boolean;
  circle?: boolean;
  large?: boolean;
  small?: boolean;
  middle?: boolean;
  slim?: boolean;
  auto?: boolean;
  icon?: string;
  iconPos?: "left" | "right";
  rounded?: boolean;
  withShadow?: boolean;
  buttonType?: ButtonTypes;
  outlined?: boolean;
  text?: boolean;
  w300?: boolean;
  stopDualClick?: boolean;
  clickCommand?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  name: undefined,
  variant: undefined,
  buttonType: "button",
  withShadow: false,
  icon: undefined,
  iconPos: "right",
  label: "戻る",
});

const emit = defineEmits<{
  (e: "click"): void;
}>();

const btnClass = computed(() => ({
  "with-opensite-shadow": true,
  "button-open-site-back": true,
  "drop-shadow": props.withShadow,
  "btn-circle": props.circle,
  "btn-slim": props.slim,
  "btn-auto": props.auto,
  "w-300": props.w300,
  "btn-green": props.color === "green",
  "btn-orange": props.color === "orange",
}));

const severity = computed(() => {
  return props.variant;
});

const size = computed(() => {
  if (props.small) {
    return "small";
  }

  if (props.large) {
    return "large";
  }

  return undefined;
});

const disabledInComponent = ref<boolean>(false);

const { backto } = useRouterUtil();

function onClick() {
  if (props.clickCommand) {
    props.clickCommand();
  } else {
    backto({ confirm: false });
  }
}
</script>

<style lang="scss">
.p-button.button-open-site-back {
  border-radius: 9999px;
  font-size: 12px;
  background-color: white;
  border-width: 2px;
  color: $main-brawn;
  width: 150px;
  padding: 0.375rem 0.675rem;

  .p-button-icon {
    text-align: center;
    color: #69bb01;
  }

  border-color: #69bb01;

  &:hover {
    background-color: #69bb01;
    color: white;

    .p-button-icon {
      color: white;
    }
  }
}
</style>
