import Index from "./Index.vue";
import Show from "./Show.vue";
import Edit from "./Edit.vue";

export default [
  {
    path: "",
    name: "UsersOrganizationsIndex",
    component: Index,
    meta: {
      title: "管理組織一覧",
      tree: [],
    },
  },
  {
    path: ":id",
    name: "UsersOrganizationsShow",
    component: Show,
    meta: {
      title: "組織詳細",
      tree: [
        {
          title: "管理組織一覧",
          name: "UsersOrganizationsIndex",
        },
      ],
    },
  },
  {
    path: ":id/edit",
    name: "UsersOrganizationsEdit",
    component: Edit,
    meta: {
      title: "編集",
      tree: [
        {
          title: "管理組織一覧",
          name: "UsersOrganizationsIndex",
        },
        {
          title: "組織詳細",
          name: "UsersOrganizationsShow",
        },
      ],
    },
  },
];
