import Edit from "./Edit.vue";
import Index from "./Index.vue";
import New from "./New.vue";
import Show from "./Show.vue";

export default [
  {
    path: "",
    name: "UsersMagazinesIndex",
    component: Index,
    meta: {
      title: "広報誌一覧",
      tree: [],
    },
  },
  {
    path: "new",
    name: "UsersMagazinesNew",
    component: New,
    meta: {
      title: "広報誌新規作成",
      tree: [{ title: "広報誌一覧", name: "UsersMagazinesIndex" }],
    },
  },
  {
    path: ":id",
    name: "UsersMagazinesShow",
    component: Show,
    meta: {
      title: "広報誌詳細",
      tree: [{ title: "広報誌一覧", name: "UsersMagazinesIndex" }],
    },
  },
  {
    path: ":id/edit",
    name: "UsersMagazinesEdit",
    component: Edit,
    meta: {
      title: "広報誌編集",
      tree: [{ title: "広報誌一覧", name: "UsersMagazinesIndex" }],
    },
  },
];
