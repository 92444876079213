<template>
  <div>
    <div class="d-flex justify-content-end align-items-center mb-3">
      <Dropdown
        v-if="isMobile"
        v-model="selectedOrganizationId"
        :options="organizations || []"
        option-label="name"
        option-value="id"
        input-id="organization-dropdown"
      />
    </div>
    <div class="d-flex mb-3 w-100">
      <div class="d-flex flex-column" style="flex-grow: 1">
        <div class="mb-3" :class="{ 'me-3': !isMobile }">
          <h3 v-if="selectedOrganization" class="mb-3">
            {{ selectedOrganization.name }}
          </h3>

          <div
            v-if="selectedOrganization && haveContactInfo"
            class="mb-3 d-flex flex-wrap align-items-top"
          >
            <div
              v-if="selectedOrganization?.constituentMembers.length"
              class="mb-3"
              style="width: 240px; min-width: 240px"
            >
              <h5 class="mb-3 pe-5">組織・役員</h5>
              <div>
                <div
                  v-for="g in selectedOrganization.constituentMembers"
                  :key="g.role"
                  class="d-flex align-items-top mb-1"
                >
                  <LabelChip
                    class="me-3 justify-content-center"
                    style="width: 100px"
                    :label="g.role"
                    reverse-color
                  />
                  <div class="d-flex flex-column">
                    <span v-for="m in g.members" :key="m.name">
                      {{ m.name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="" style="min-width: 250px">
              <h5 class="mb-3">お問い合わせ先</h5>
              <div v-if="selectedOrganization.postcode">
                〒{{ selectedOrganization.postcode.slice(0, 3) }}-{{
                  selectedOrganization.postcode.slice(3)
                }}
              </div>
              <div v-if="selectedOrganization.address">
                {{ selectedOrganization.address }}
              </div>
              <a
                v-if="selectedOrganization.phoneNumber"
                :href="`tel:${selectedOrganization.phoneNumber}`"
              >
                <i class="pi pi-phone me-1"></i
                >{{ formatNational(selectedOrganization.phoneNumber) }}
              </a>
            </div>
          </div>
          <div v-else>問い合わせ先の設定なし</div>
          <Line reverse-color />
        </div>

        <NewsList
          :news="news || []"
          with-content
          reverse-color
          class="w-100 mb-5"
          @select-news="handleSelectNews"
        />
      </div>
      <div v-if="!isMobile" class="d-flex flex-column" style="width: 240px">
        <PageLinks class="me-3">
          <ul class="list-group">
            <li
              v-for="o in organizations"
              :key="o.id"
              class="list-group-item"
              :class="{ active: o.id === selectedOrganizationId }"
              role="button"
              @click="selectedOrganizationId = o.id"
            >
              <span>{{ o.name }}</span>
            </li>
          </ul>
        </PageLinks>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from "vue";
import { formatNational } from "/@/modules/phonenumber";
import { getStorage, setStorage } from "/@/modules/localStorage";
import {
  useOrganizations,
  useNews,
  useRouterUtil,
  useMqUtils,
} from "/@/vue/composables";
import { Line, LabelChip } from "/@/vue/components/Atom";
import { PageLinks } from "/@/vue/components/Layouts";
import { List as NewsList } from "/@/vue/components/Molecules/News";
import Chip from "primevue/chip";
import Dropdown from "primevue/dropdown";

const { isMobile } = useMqUtils();
const { goto, currentRouteQuery } = useRouterUtil();

const queryOrganizationId = computed(() => {
  return currentRouteQuery.value.organizationId;
});

const { getOrganizations } = useOrganizations();
const { data: organizations } = getOrganizations({ branch: true });

const idKey = "BranchActivity.selectedOrganizationId";

const selectedOrganizationId = ref(getStorage(idKey) || undefined);
watch(selectedOrganizationId, (id) => {
  setStorage(idKey, id);
});
watch(queryOrganizationId, (id) => {
  selectedOrganizationId.value = id;
});

watch(organizations, (os) => {
  if (os && os.length && !selectedOrganizationId.value) {
    selectedOrganizationId.value = os[0].id;
  }
});

onMounted(() => {
  if (
    organizations.value &&
    organizations.value.length &&
    !selectedOrganizationId.value
  ) {
    selectedOrganizationId.value = organizations.value[0].id;
  }

  if (currentRouteQuery.value.organizationId) {
    selectedOrganizationId.value = Number(
      currentRouteQuery.value.organizationId
    );
  }
});

const selectedOrganization = computed(() => {
  if (!organizations.value || !selectedOrganizationId.value) return;

  return organizations.value.find((o) => o.id === selectedOrganizationId.value);
});

const haveContactInfo = computed(() => {
  if (!selectedOrganization.value) return false;

  return (
    selectedOrganization.value.address ||
    selectedOrganization.value.phoneNumber ||
    selectedOrganization.value.postcode
  );
});

// news

const { getNews } = useNews();
const { data: news } = getNews(selectedOrganizationId, { top: true });

function handleSelectNews(id: number) {
  goto({ name: "NewsShow", params: { id: id } });
}
</script>

<style scoped></style>
