<template>
  <BasicButton
    data-html2canvas-ignore="true"
    icon="pi pi-print"
    :label="btnLabel"
    @click="print"
  >
  </BasicButton>
</template>

<script setup lang="ts">
import { generatePDF } from "/@/modules/pdf";
import { BasicButton } from "/@/vue/components/Atom";
import { PdfGenOptions } from "/@/types";

const props = withDefaults(
  defineProps<{
    targetElementId: string;
    filename: string;
    btnLabel?: string;
    options?: PdfGenOptions;
  }>(),
  {
    btnLabel: "PDFで出力",
    options: () => ({
      unit: "in",
      format: "a4",
      orientation: "l",
      precision: 0,
    }),
  }
);

function print() {
  generatePDF(props.targetElementId, props.filename, props.options);
}
</script>

<style scoped></style>
