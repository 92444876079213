<template>
  <form @submit.prevent="signup">
    <template v-if="!confirming">
      <UserForm
        v-model:special="signUpForm.special"
        v-model:registration-number="signUpForm.registrationNumber"
        v-model:last-name="signUpForm.lastName"
        v-model:first-name="signUpForm.firstName"
        v-model:last-name-kana="signUpForm.lastNameKana"
        v-model:first-name-kana="signUpForm.firstNameKana"
        v-model:birthday="signUpForm.birthday"
        v-model:email="signUpForm.email"
        v-model:email-check="emailCheck"
        v-model:phone-number="signUpForm.phoneNumber"
        v-model:postcode="signUpForm.postcode"
        v-model:address="signUpForm.address"
        v-model:have-affiliation="signUpForm.haveAffiliation"
        v-model:affiliation="signUpForm.affiliation"
        v-model:affiliation-phone-number="signUpForm.affiliationPhoneNumber"
        v-model:affiliation-postcode="signUpForm.affiliationPostcode"
        v-model:affiliation-address="signUpForm.affiliationAddress"
        v-model:business="signUpForm.business"
        v-model:organization-members="signUpForm.organizationMembers"
        :organizations="organizations"
        :errors="errors"
        :is-substitute="isSubstitute"
        :with-organization="withOrganization"
        :need-organization="needOrganization"
      />

      <template v-if="withPassword">
        <FormItem
          v-model:value="signUpForm.password"
          :errors="errors"
          label="パスワード(半角英数字6文字以上)"
          form-id="password"
          form-type="password"
          autocomplete="new-password"
          class="mb-3"
          style="max-width: 300px"
        />
        <FormItem
          v-model:value="signUpForm.passwordConfirmation"
          :errors="errors"
          label="パスワード(確認)"
          form-id="passwordConfirmation"
          form-type="password"
          autocomplete="new-password"
          style="max-width: 300px"
        />
      </template>

      <div v-if="!isOpenSite" class="mt-3">
        <BasicButton class="me-1" variant="secondary" outlined @click="backto">
          キャンセル
        </BasicButton>
        <BasicButton class="ms-1" @click="confirm"> 確認 </BasicButton>
      </div>
      <div v-else class="w-100 d-flex justify-content-center my-5">
        <OpenSiteBackButton class="me-3" label="キャンセル" />
        <MoreReadButton
          class="ms-3"
          color="green"
          label="確認"
          :disabled="loading"
          @click="confirm"
        />
      </div>
    </template>
    <template v-else>
      <UserShow :user="confirmingUser" />

      <div v-if="!isOpenSite" class="mt-3 d-flex">
        <BasicButton class="me-1" outlined @click="confirming = false">
          修正
        </BasicButton>
        <BasicButton
          class="ms-1"
          button-type="submit"
          :disabled="loading"
          @click="signup"
        >
          アカウントの作成
        </BasicButton>
      </div>
      <div v-else class="w-100 d-flex justify-content-center my-5">
        <OpenSiteBackButton
          class="me-3"
          label="修正"
          :click-command="() => (confirming = false)"
        />
        <MoreReadButton
          class="ms-3"
          color="green"
          label="アカウントの作成"
          button-type="submit"
          :disabled="loading"
          @click="signup"
        />
      </div>
    </template>
  </form>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { scrollToTop } from "/@/modules/ui";
import { getAddress } from "/@/modules/address";
import {
  gPhoneNumber,
  gKana,
  gPassword,
  gPostcode,
  gRegistrationNumber,
} from "/@/modules/groomer";
import { useZodScheme, useRouterUtil } from "/@/vue/composables";
import {
  BasicButton,
  MoreReadButton,
  OpenSiteBackButton,
} from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";
import { Show as UserShow } from "/@/vue/components/Molecules/Users";
import { Form as UserForm } from "/@/vue/components/Organisms/Users";
import {
  OrganizationClient,
  User,
  UserSignUpCheckScheme,
  UserSignUpCheckSubstituteScheme,
  UserSignUpForm as UserSignUpFormType,
} from "/@/types";
import { computed } from "vue";

//

const props = defineProps<{
  organizations: OrganizationClient[];
  loading?: boolean;
  isSubstitute?: boolean;
  hideForm?: boolean;
  withOrganization?: boolean;
  needOrganization?: boolean;
  withPassword?: boolean;
  isOpenSite?: boolean;
}>();

interface Emits {
  (e: "signup", user: User): void;
}

const emit = defineEmits<Emits>();

const { backto } = useRouterUtil();

// form

onMounted(() => {
  /*
  signUpForm.lastName = "古河";
  signUpForm.lastNameKana = "フルカワ";
  signUpForm.firstName = "朋矢";
  signUpForm.firstNameKana = "トモヤ";
  signUpForm.birthday = "1993-11-17";
  signUpForm.email = "s1200191@gmail.com";
  signUpForm.phoneNumber = "09012345678";
  signUpForm.postcode = "9200966";
  signUpForm.address = "石川県金沢市";
  signUpForm.password = "password";
  signUpForm.passwordConfirmation = "password";

  signUpForm.registrationNumber = "12345678";

  //signUpForm.affiliation = "株式会社〇〇";
  //signUpForm.affiliationPhoneNumber = "0761234567";
  //signUpForm.affiliationPostcode = "9200966";
  //signUpForm.affiliationAddress = "石川県金沢市";

  emailCheck.value = "s1200191@gmail.com";
  */
});

const checkScheme = computed(() => {
  return props.isSubstitute
    ? UserSignUpCheckSubstituteScheme
    : UserSignUpCheckScheme;
});

const { useFormAndErrors } = useZodScheme();
const {
  form: signUpForm,
  errors,
  startValidation,
} = useFormAndErrors<UserSignUpFormType>(checkScheme.value, {
  special: false,
  registrationNumber: "",
  haveAffiliation: true,
  organizationMembers: props.needOrganization
    ? [{ organizationId: undefined }]
    : [],
});

const emailCheck = ref("");

startValidation.value = true;

watch(
  () => signUpForm.postcode,
  async (p, old) => {
    if (p !== old) {
      signUpForm.address = await getAddress(p);
    }
  }
);

watch(
  () => signUpForm.affiliationPostcode,
  async (p, old) => {
    if (p !== old) {
      signUpForm.affiliationAddress = await getAddress(p);
    }
  }
);

watch(signUpForm, (f) => {
  // form を watch する場合は、まとめて変更した時の処理を書けるが、
  // 無駄な比較処理が増えるのと過去の値へのアクセスができない問題がある。
  // そのため個別のプロパティでの変更があった時のような処理が書けない

  if (f.firstName && f.firstName.length > 50) {
    signUpForm.firstName = f.firstName.slice(0, 50);
  }

  if (f.lastName && f.lastName.length > 50) {
    signUpForm.lastName = f.lastName.slice(0, 50);
  }

  if (f.firstNameKana) {
    const kana = gKana(f.firstNameKana);
    signUpForm.firstNameKana = kana.slice(0, 50);
  }

  if (f.lastNameKana) {
    const kana = gKana(f.lastNameKana);
    signUpForm.lastNameKana = kana.slice(0, 50);
  }

  if (f.phoneNumber) {
    signUpForm.phoneNumber = gPhoneNumber(f.phoneNumber);
  }

  if (f.postcode) {
    signUpForm.postcode = gPostcode(f.postcode);
  }

  if (f.address && f.address.length > 150) {
    signUpForm.address = f.address.slice(0, 150);
  }

  if (f.affiliation && f.affiliation.length > 50) {
    signUpForm.affiliation = f.affiliation.slice(0, 50);
  }

  if (f.affiliationPostcode) {
    signUpForm.affiliationPostcode = gPostcode(f.affiliationPostcode);
  }

  if (f.affiliationAddress && f.affiliationAddress.length > 150) {
    signUpForm.affiliationAddress = f.affiliationAddress.slice(0, 150);
  }

  if (f.affiliationPhoneNumber) {
    signUpForm.affiliationPhoneNumber = gPhoneNumber(f.affiliationPhoneNumber);
  }

  if (f.registrationNumber) {
    signUpForm.registrationNumber = gRegistrationNumber(f.registrationNumber);
  }

  if (f.business && f.business.length > 50) {
    signUpForm.business = f.business.slice(0, 50);
  }

  if (f.password) {
    signUpForm.password = gPassword(f.password);
  }
});

// confirm

const confirmingUser = computed(() => {
  return {
    ...signUpForm,
    joinOrganizations: signUpForm.organizationMembers
      ?.filter((om) => om.organizationId)
      .map((om) => ({
        name: props.organizations.find((o) => o.id == om.organizationId)?.name,
      })),
  };
});

const confirming = ref(false);

function confirm() {
  try {
    if (signUpForm.email !== emailCheck.value) {
      throw new Error("メールアドレスが一致しません。");
    }

    if (
      props.needOrganization &&
      (!signUpForm.organizationMembers ||
        signUpForm.organizationMembers.length === 0)
    ) {
      throw new Error("所属団体を選択して下さい。");
    }

    checkScheme.value.parse(signUpForm);
    scrollToTop();
    confirming.value = true;
  } catch (e) {
    alert("入力内容に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}

// submit

function signup() {
  try {
    if (signUpForm.email !== emailCheck.value) {
      throw new Error("メールアドレスが一致しません。");
    }

    if (
      props.needOrganization &&
      (!signUpForm.organizationMembers ||
        signUpForm.organizationMembers.length === 0)
    ) {
      throw new Error("所属団体を選択して下さい。");
    }

    const user = checkScheme.value.parse(signUpForm);

    emit("signup", user);
  } catch (e) {
    alert("入力内容に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
