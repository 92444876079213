<template>
  <div>
    <div v-if="!hideSpecial" class="mb-4">
      <FormLabel
        label="会員種別 ※介護支援専門員の登録を受けている場合は正会員を選択して下さい"
      />
      <SelectButton
        :model-value="special"
        :options="[
          { label: '正会員', value: false },
          { label: '特別会員', value: true },
        ]"
        option-label="label"
        option-value="value"
        :allow-empty="false"
        @update:modelValue="$emit('update:special', $event)"
      />
    </div>
    <div class="d-flex mb-3">
      <FormItem
        :value="lastName"
        :errors="errors"
        label="姓(必須)"
        form-id="lastName"
        form-type="text"
        class="me-1 w-50"
        style="max-width: 300px"
        :disabled="disabled"
        @update:value="$emit('update:lastName', $event as string | undefined)"
      />
      <FormItem
        :value="firstName"
        :errors="errors"
        label="名(必須)"
        form-id="firstName"
        form-type="text"
        class="ms-1 w-50"
        style="max-width: 300px"
        :disabled="disabled"
        @update:value="$emit('update:firstName', $event as string | undefined)"
      />
    </div>

    <div class="d-flex mb-3">
      <FormItem
        :value="lastNameKana"
        :errors="errors"
        label="せい(必須)"
        form-id="lastNameKana"
        form-type="text"
        class="me-1 w-50"
        style="max-width: 300px"
        :disabled="disabled"
        @update:value="
          $emit('update:lastNameKana', $event as string | undefined)
        "
      />
      <FormItem
        :value="firstNameKana"
        :errors="errors"
        label="めい(必須)"
        form-id="firstNameKana"
        form-type="text"
        class="ms-1 w-50"
        style="max-width: 300px"
        :disabled="disabled"
        @update:value="
          $emit('update:firstNameKana', $event as string | undefined)
        "
      />
    </div>

    <div class="mb-3">
      <FormLabel label="生年月日(必須)" />
      <FormItem
        :value="birthday"
        :errors="errors"
        form-id="birthday"
        form-type="date"
        :start-date="fromISO('1990-01-01')"
        style="max-width: 210px"
        :disabled="disabled"
        @update:value="$emit('update:birthday', $event as string | undefined)"
      />
    </div>

    <FormItem
      :value="email"
      :errors="errors"
      label="メール(必須)"
      form-id="email"
      form-type="email"
      class="mb-3 w-100"
      style="max-width: 300px"
      :disabled="disabled"
      @update:value="$emit('update:email', $event as string | undefined)"
    />

    <FormItem
      v-if="!hideEmailCheck"
      :value="emailCheck"
      :errors="emailCheckErrors"
      label="メール(確認)"
      form-id="emailCheck"
      form-type="email"
      class="mb-3 w-100"
      style="max-width: 300px"
      :disabled="disabled"
      @update:value="$emit('update:emailCheck', $event as string | undefined)"
    />

    <FormItem
      :value="phoneNumber"
      :errors="errors"
      label="携帯電話番号(必須)"
      form-id="phoneNumber"
      form-type="text"
      class="mb-3 w-100"
      style="max-width: 300px"
      :disabled="disabled"
      @update:value="$emit('update:phoneNumber', $event as string | undefined)"
    />

    <div class="mb-4 w-100">
      <FormLabel
        label="郵便番号(必須) ※数字7桁（住所は書類の輸送のため必要です）"
      />
      <FormItem
        :value="postcode"
        :errors="errors"
        form-id="postcode"
        form-type="text"
        style="max-width: 250px"
        @update:value="$emit('update:postcode', $event as string | undefined)"
      />
    </div>

    <div class="mb-4">
      <FormLabel
        label="住所(必須) ※50文字以内 郵便番号を入力しても自動的に入力されない場合は手動で入力して下さい"
      />
      <FormItem
        :value="address"
        :errors="errors"
        form-id="address"
        form-type="text"
        @update:value="$emit('update:address', $event as string)"
      />
    </div>

    <!--
    <div v-if="false" class="d-flex align-items-center mb-3">
      <div class="me-2">
        <FormLabel label="都道府県(選択)" />
        <Dropdown
          :model-value="area"
          :options="prefectures"
          option-label="label"
          option-value="value"
          :errors="errors"
          filter
          no-empty-label="都道府県を選択してください。"
          label="都道府県"
          form-id="area"
          :class="{
            'p-invalid': errors.area,
          }"
          @update:model-value="$emit('update:area', $event)"
        />
      </div>

      <div>
        <FormLabel label="市町村(選択)" />
        <Dropdown
          :model-value="city"
          :options="cities"
          option-label="name"
          option-value="name"
          :errors="errors"
          filter
          no-empty-label="市町村を選択してください。"
          label="市町村"
          form-id="city"
          :class="{
            'p-invalid': errors.city,
          }"
          @update:model-value="$emit('update:city', $event)"
        />
      </div>
    </div>
    -->

    <div
      v-if="!props.hideRegistrationNumber && !props.special"
      class="d-flex flex-wrap align-items-center mb-4"
    >
      <div v-if="false" class="me-2">
        <FormLabel label="職種(選択)" />
        <Dropdown
          :model-value="business"
          :options="businesses"
          :errors="errors"
          placeholder="(未選択)"
          show-clear
          no-empty-label="職種を選択してください。"
          form-id="business"
          :class="{
            'p-invalid': errors.business,
          }"
          @update:model-value="updateBusiness"
        />
      </div>
      <FormItem
        :value="registrationNumber"
        :errors="errors"
        label="登録番号(必須)"
        form-id="registrationNumber"
        form-type="text"
        style="min-width: 200px; max-width: 300px"
        @update:value="
          $emit('update:registrationNumber', $event as string | undefined)
        "
      />
    </div>

    <div class="mb-4">
      <FormLabel label="所属事業所(選択必須)" />
      <Dropdown
        :model-value="haveAffiliation"
        :options="[
          { value: true, label: '所属あり' },
          { value: false, label: '所属なし' },
        ]"
        option-value="value"
        option-label="label"
        :errors="errors"
        no-empty-label="選択してください。"
        form-id="haveAffiliation"
        :class="{
          'p-invalid': errors.haveAffiliation,
        }"
        @update:model-value="$emit('update:haveAffiliation', $event)"
      />
    </div>

    <template v-if="haveAffiliation">
      <FormItem
        :value="affiliation"
        :errors="errors"
        label="所属事業所名(必須)"
        form-id="affiliation"
        form-type="text"
        placeholder="（例）株式会社〇〇"
        class="mb-4"
        style="max-width: 400px"
        @update:value="
          $emit('update:affiliation', $event as string | undefined)
        "
      />
      <FormItem
        :value="affiliationPhoneNumber"
        :errors="errors"
        label="事業所電話番号(必須)"
        form-id="affiliationPhoneNumber"
        form-type="text"
        class="mb-4 w-100"
        style="max-width: 300px"
        :disabled="disabled"
        @update:value="
          $emit('update:affiliationPhoneNumber', $event as string | undefined)
        "
      />

      <div class="mb-4 w-100">
        <FormLabel label="事業所郵便番号(必須) ※数字7桁" />
        <FormItem
          :value="affiliationPostcode"
          :errors="errors"
          form-id="affiliationPostcode"
          form-type="text"
          style="max-width: 250px"
          @update:value="
            $emit('update:affiliationPostcode', $event as string | undefined)
          "
        />
      </div>

      <div class="mb-4">
        <FormLabel
          label="事業所住所(必須) ※50文字以内 郵便番号を入力しても自動的に入力されない場合は手動で入力して下さい"
        />
        <FormItem
          :value="affiliationAddress"
          :errors="errors"
          form-id="affiliationAddress"
          form-type="text"
          @update:value="$emit('update:affiliationAddress', $event as string)"
        />
      </div>
    </template>

    <div
      v-if="withOrganization"
      :style="{
        border:
          needOrganization && !hasValidOrganizationMembers
            ? '1px solid red'
            : 'none',
        padding:
          needOrganization && !hasValidOrganizationMembers ? '1rem' : '0',
      }"
      :class="{
        'mb-3': needOrganization && !hasValidOrganizationMembers,
      }"
    >
      <div class="d-flex align-items-center mb-3 flex-wrap">
        <FormLabel label="所属支部" class="me-2" without-padding />
        <BasicButton
          icon="pi pi-plus"
          class="me-2"
          :disabled="disabledAddOrganizationMember"
          @click="addOrganizationMember"
        />
        <span
          v-if="!needOrganization"
          class="text-green"
          style="{
          fontSize: isMobile ? '0.8rem' : '1rem',
        }"
        >
          県協会の所属支部がある場合は追加して下さい
        </span>
      </div>
      <div
        v-for="(om, idx) in organizationMembers"
        :key="om.organizationId"
        class="card card-body mb-3"
      >
        <div class="mb-3">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <FormLabel label="組織選択" without-padding />
            <BasicButton
              icon="pi pi-trash"
              variant="danger"
              @click="deleteOrganizationMember(idx)"
            />
          </div>

          <Dropdown
            :model-value="om.organizationId"
            :options="organizations"
            option-label="name"
            option-value="id"
            class="w-100"
            :class="{
              'p-invalid': needOrganization && !om.organizationId,
            }"
            @update:model-value="updateOrganizationMember(idx, $event)"
          />
        </div>
      </div>
      <BasicButton
        v-if="organizationMembers.length > 2"
        icon="pi pi-plus"
        :disabled="disabledAddOrganizationMember"
        @click="addOrganizationMember"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useMqUtils } from "/@/vue/composables";
import { FormItem } from "/@/vue/components/Molecules";
import { BasicButton, Label as FormLabel } from "/@/vue/components/Atom";
import {
  ZodFormattedErrors,
  OrganizationMember,
  OrganizationClient,
} from "/@/types";
import { businesses } from "/@/modules/constants";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import { fromISO } from "/@/modules/luxon";
import { useMq } from "vue3-mq";

const props = defineProps<{
  special?: boolean;
  registrationNumber?: string;
  lastName?: string;
  firstName?: string;
  lastNameKana?: string;
  firstNameKana?: string;
  birthday?: string;
  phoneNumber?: string;
  email?: string;
  emailCheck?: string;
  postcode?: string;
  address?: string;
  haveAffiliation?: boolean;
  affiliation?: string;
  affiliationPhoneNumber?: string;
  affiliationPostcode?: string;
  affiliationAddress?: string;
  business?: string;
  organizationMembers: OrganizationMember[];
  organizations: OrganizationClient[];
  errors: ZodFormattedErrors;
  hideEmailCheck?: boolean;
  hideSpecial?: boolean;
  hideRegistrationNumber?: boolean;
  isSubstitute?: boolean;
  withOrganization?: boolean;
  needOrganization?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:special", value: boolean): void;
  (e: "update:registrationNumber", value?: string): void;
  (e: "update:lastName", value?: string): void;
  (e: "update:firstName", value?: string): void;
  (e: "update:lastNameKana", value?: string): void;
  (e: "update:firstNameKana", value?: string): void;
  (e: "update:birthday", value?: string): void;
  (e: "update:phoneNumber", value?: string): void;
  (e: "update:email", value?: string): void;
  (e: "update:emailCheck", value?: string): void;
  (e: "update:postcode", value?: string): void;
  (e: "update:address", value?: string): void;
  (e: "update:haveAffiliation", value: boolean): void;
  (e: "update:affiliation", value?: string): void;
  (e: "update:affiliationPhoneNumber", value?: string): void;
  (e: "update:affiliationPostcode", value?: string): void;
  (e: "update:affiliationAddress", value?: string): void;
  (e: "update:business", value?: string): void;
  (e: "update:organizationMembers", value: OrganizationMember[]): void;
}>();

const { isMobile } = useMqUtils();

function updateBusiness(value: string) {
  emit("update:business", value === "(未選択)" ? undefined : value);
}

const emailCheckErrors = computed(() => {
  if (
    !props.emailCheck ||
    (props.email && props.emailCheck && props.email !== props.emailCheck)
  ) {
    return {
      emailCheck: {
        _errors: ["メールアドレスが一致しません。"],
      },
    };
  }
  return {};
});

const disabledAddOrganizationMember = computed(() => {
  return props.organizationMembers.some((om) => !om.organizationId);
});

function addOrganizationMember() {
  emit("update:organizationMembers", [
    ...props.organizationMembers,
    {
      id: undefined,
      organizationId: undefined,
    },
  ]);
}

function deleteOrganizationMember(idx: number) {
  const organizationMembers = [...props.organizationMembers];
  organizationMembers.splice(idx, 1);

  emit("update:organizationMembers", organizationMembers);
}

function updateOrganizationMember(idx: number, organizationId: number) {
  const organizationMembers = [...props.organizationMembers];

  const targetOrganization = props.organizations.find(
    (o) => o.id === organizationId
  );

  if (!targetOrganization) {
    return;
  }

  organizationMembers[idx].organizationId = targetOrganization.id;
  organizationMembers[idx].organizationName = targetOrganization.name;

  emit("update:organizationMembers", organizationMembers);
}

const hasValidOrganizationMembers = computed(() => {
  return props.organizationMembers.some((om) => !!om.organizationId);
});
</script>

<style scoped></style>
