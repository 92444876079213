import Axios from "axios";
import { ZodError } from "zod";

export function errorHandle(e: any) {
  console.error(e);

  if (e.errors) {
    console.error(e.errors);
  }

  if (Axios.isAxiosError(e) && e.response) {
    if (e.response.status === 426) {
      window.alert("アップデートがあるためページを更新します");
      window.location.reload();
      return;
    }

    if (e.response.data && e.response.data.errors) {
      return e.response.data.errors;
    }
  }

  if (Axios.isAxiosError(e)) {
    return;
  }

  if (e instanceof ZodError) {
    return e.format();
  }

  alert("予期せぬエラーが発生しました。");

  return [];
}
