import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { DateTime } from "luxon";

export const DateScheme = z.string().regex(/^\d{4}-\d{2}-\d{2}$/);
export const DateTimeScheme = z
  .string()
  .datetime({ offset: true })
  .or(DateScheme);

export const DateMapScheme = z.object({
  year: z.number(),
  month: z.number().min(1).max(12),
  day: DateTimeScheme.optional(),
});

export type DateMap = zod.infer<typeof DateMapScheme>;

export const DateForCalendarScheme = z.object({
  dateKey: DateScheme,
  luxonDate: z.custom((value) => {
    if (value instanceof DateTime) {
      return value;
    }
    throw new Error("Invalid luxon date object");
  }),
  weekday: z.number().min(1).max(7),
});

export type DateForCalendar = {
  dateKey: string;
  luxonDate: DateTime;
  weekday: number;
};
