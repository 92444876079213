<template>
  <div>
    <div class="mb-4">
      <FormLabel label="名前 ※30文字以内" />
      <FormItem
        :value="name"
        :errors="errors"
        form-id="name"
        form-type="text"
        @update:value="$emit('update:name', $event as string | undefined)"
      />
    </div>

    <div class="mb-4 w-10" style="max-width: 350px">
      <FormLabel label="電話番号 ※数字10桁または11桁" />
      <FormItem
        :value="phoneNumber"
        :errors="errors"
        form-id="phoneNumber"
        form-type="text"
        @update:value="
          $emit('update:phoneNumber', $event as string | undefined)
        "
      />
    </div>

    <div class="mb-4 w-100" style="max-width: 250px">
      <FormLabel label="郵便番号 ※数字7桁" />
      <FormItem
        :value="postcode"
        :errors="errors"
        form-id="postcode"
        form-type="text"
        @update:value="$emit('update:postcode', $event as string | undefined)"
      />
    </div>

    <div class="mb-4">
      <FormLabel
        label="住所 ※50文字以内 郵便番号を入力しても自動的に入力されない場合は手動で入力して下さい"
      />
      <FormItem
        :value="address"
        :errors="errors"
        form-id="address"
        form-type="text"
        @update:value="$emit('update:address', $event as string)"
      />
    </div>

    <div class="mb-4">
      <div class="d-flex align-items-center mb-3">
        <FormLabel label="組織・役員" class="me-2" without-padding />
        <BasicButton
          label="役職追加"
          :disabled="disabledAddMemberGroup"
          @click="addMemberGroup"
        />
      </div>

      <Card
        v-for="(g, idx) in constituentMembers"
        :key="`members-${idx}-role`"
        class="mb-3 w-100 transition-all"
      >
        <template #content>
          <div class="d-flex justify-content-between align-items-center w-100">
            <FormItem
              v-model:value="g.role"
              label="役職名 ※20文字以内"
              form-id="role"
              form-type="text"
              :errors="errors.constituentMembers?.[idx]"
              class="mb-3"
              style="width: 200px"
            />
            <div
              v-if="idx != 0"
              class="d-flex justify-content-center"
              style="width: 200px"
            >
              <BasicButton
                icon="pi pi-angle-up"
                @click="changeOrder(idx, -1)"
              />
            </div>
            <div class="d-flex justify-content-end" style="width: 200px">
              <FormLabel label="" without-padding />
              <BasicButton
                icon="pi pi-trash"
                variant="danger"
                @click="removeMemberGroup(idx, g.role)"
              />
            </div>
          </div>

          <div class="mb-3 d-flex align-items-center">
            <FormLabel
              label="メンバー ※名前30文字、備考50文字以内"
              without-padding
              class="me-2"
            />
            <BasicButton
              label="メンバー追加"
              :disabled="disabledAddMember[idx]"
              @click="addMember(idx)"
            />
            <span></span>
          </div>

          <DataTable
            :value="g.members"
            :reorderable-columns="true"
            table-style="min-width: 50rem"
            edit-mode="cell"
            class="mb-3"
            @row-reorder="(e) => onRowReorder(e, idx)"
          >
            <Column
              row-reorder
              header-style="width: 3rem"
              :reorderable-column="false"
            />
            <Column
              v-for="col of showRemarks ? ['name', 'remarks'] : ['name']"
              :key="col"
              :field="col"
              :header="memberKeyToStr(col)"
              :reorderable-column="false"
              :style="{
                width: col === 'name' && showRemarks ? '160px' : 'auto',
              }"
            >
              <template #body="{ field, index }">
                <FormItem
                  :value="g.members[index][field]"
                  form-type="text"
                  :form-id="field"
                  :placeholder="memberKeyToStr(field)"
                  :errors="errors.constituentMembers?.[idx]?.members?.[index]"
                  @update:value="g.members[index][field] = $event"
                />
              </template>
              <template #editor="{ field, index }">
                <FormItem
                  :value="g.members[index][field]"
                  form-type="text"
                  :form-id="field"
                  :placeholder="memberKeyToStr(field)"
                  :errors="errors.constituentMembers?.[idx]?.members?.[index]"
                  @update:value="g.members[index][field] = $event"
                />
              </template>
            </Column>
            <Column header-style="width: 80px">
              <template #body="{ index }">
                <BasicButton
                  v-if="g.members.length > 1"
                  icon="pi pi-trash"
                  variant="danger"
                  @click="removeMember(idx, index)"
                />
              </template>
            </Column>
          </DataTable>

          <BasicButton
            v-if="g.members.length > 2"
            label="メンバー追加"
            :disabled="disabledAddMember[idx]"
            class="mb-2"
            @click="addMember(idx)"
          />

          <div
            v-if="idx != constituentMembers.length - 1"
            class="d-flex justify-content-center"
          >
            <BasicButton icon="pi pi-angle-down" @click="changeOrder(idx, 1)" />
          </div>
        </template>
      </Card>
      <BasicButton
        v-if="constituentMembers.length > 0"
        label="役職追加"
        :disabled="disabledAddMemberGroup"
        @click="addMemberGroup"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Label as FormLabel, BasicButton } from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";
import { ZodFormattedErrors, ConstituentMember } from "/@/types";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

const props = defineProps<{
  name?: string;
  postcode?: string;
  address?: string;
  phoneNumber?: string;
  constituentMembers: ConstituentMember[];
  showRemarks?: boolean;
  errors: ZodFormattedErrors;
}>();

const emit = defineEmits<{
  (e: "update:name", name: string | undefined): void;
  (e: "update:postcode", postcode: string | undefined): void;
  (e: "update:address", address: string | undefined): void;
  (e: "update:phoneNumber", phoneNumber: string | undefined): void;
  (e: "update:constituentMembers", members: ConstituentMember[]): void;
}>();

// member

function memberKeyToStr(memberKey: string) {
  switch (memberKey) {
    case "name":
      return "名前";
    case "remarks":
      return "備考";
    default:
      return "";
  }
}

function defaultMember(): ConstituentMember {
  return {
    role: "",
    members: [
      {
        name: "",
        remarks: undefined,
      },
    ],
  };
}

function addMemberGroup() {
  if (disabledAddMemberGroup.value) return;

  emit("update:constituentMembers", [
    ...props.constituentMembers,
    defaultMember(),
  ]);
}

const disabledAddMemberGroup = computed(() => {
  return !!props.errors.constituentMembers;
});

function addMember(idx: number) {
  if (disabledAddMember.value[idx]) return;

  emit("update:constituentMembers", [
    ...props.constituentMembers.slice(0, idx),
    {
      ...props.constituentMembers[idx],
      members: [...props.constituentMembers[idx].members, { name: "" }],
    },
    ...props.constituentMembers.slice(idx + 1),
  ]);
}

const disabledAddMember = computed(() => {
  let m: Record<number, boolean> = {};

  if (!props.errors.constituentMembers) return {};

  props.constituentMembers.forEach((g, idx) => {
    m[idx] = !!props.errors.constituentMembers?.[idx]?.members;
  });

  return m;
});

function removeMemberGroup(idx: number, role: string) {
  if (!window.confirm(`${role}を削除します。よろしいですか？`)) return;

  emit("update:constituentMembers", [
    ...props.constituentMembers.slice(0, idx),
    ...props.constituentMembers.slice(idx + 1),
  ]);
}

function removeMember(idx: number, nidx: number) {
  emit("update:constituentMembers", [
    ...props.constituentMembers.slice(0, idx),
    {
      ...props.constituentMembers[idx],
      members: [
        ...props.constituentMembers[idx].members.slice(0, nidx),
        ...props.constituentMembers[idx].members.slice(nidx + 1),
      ],
    },
    ...props.constituentMembers.slice(idx + 1),
  ]);
}

function onRowReorder(e: any, idx: number) {
  const newMembers = e.value;

  emit("update:constituentMembers", [
    ...props.constituentMembers.slice(0, idx),
    {
      ...props.constituentMembers[idx],
      members: newMembers,
    },
    ...props.constituentMembers.slice(idx + 1),
  ]);
}

function changeOrder(idx: number, diff: number) {
  if (idx + diff < 0 || idx + diff >= props.constituentMembers.length) return;

  const newMembers = [...props.constituentMembers];

  const tmp = newMembers[idx];
  newMembers[idx] = newMembers[idx + diff];
  newMembers[idx + diff] = tmp;

  emit("update:constituentMembers", newMembers);
}
</script>

<style scoped></style>
