import useSWRV from "swrv";
import { SponsorClient, SponsorForm } from "/@/types";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

export function useSponsors() {
  function getSponsors() {
    return useSWRV<SponsorClient>("/api/v1/sponsors", fetcher);
  }

  function updateSponsors(id: number, prms: SponsorForm) {
    try {
      return axios.patch(`/api/v1/sponsors/${id}`, {
        sponsor: prms,
      });
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getSponsors,
    updateSponsors,
  };
}
