<template>
  <div>
    <UserPageActions> </UserPageActions>

    <template v-if="format">
      <FormatCoreForm
        v-model:title="form.title"
        v-model:format-type="form.formatType"
        v-model:new-attached-pdfs="form.newAttachedPdfs"
        :attached-pdfs="format.attachedPdfs"
        :errors="errors"
        hide-select-format-type
        title-as-class
      />
    </template>

    <div class="d-flex justify-content-center my-4">
      <BasicButton
        label="キャンセル"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto"
      />
      <BasicButton label="更新" button-type="submit" @click="submit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch } from "vue";
import { pdfViewerStyles } from "/@/modules/pdf";
import { useRouterUtil, useFormats, useZodScheme } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { UserPageActions } from "/@/vue/components/Layouts";
import {
  PublicationFormat,
  Form as FormatCoreForm,
} from "/@/vue/components/Organisms/Users/Formats";
import { FormatForm, FormatCheckScheme, FormatClient } from "/@/types";

const { id, backto, goto } = useRouterUtil();

const pdfElementId = "pdf-contents";

const { getFormat, updateFormat } = useFormats();
const { data: format, mutate: getFormatMutate } = getFormat(id);

function resetForm(f?: FormatClient) {
  if (f) {
    form.id = f.id;
    form.title = f.title;
    form.formatType = f.formatType;
    form.data = f.data;
    form.newAttachedPdfs = f.attachedPdfs.map((pdf) => pdf.signedId);
  } else {
    form.id = undefined;
    form.title = undefined;
    form.data = {};
    form.formatType = "bcp";
    form.newAttachedPdfs = [];
  }
}

watch(
  () => format.value,
  (f) => {
    if (!form.id) {
      resetForm(f);
    }
  }
);

onMounted(() => {
  resetForm(format.value);
});

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<FormatForm>(
  FormatCheckScheme,
  format.value && {
    ...format.value,
    newAttachedPdfs: format.value.attachedPdfs.map((pdf) => pdf.signedId),
  }
);

watch(form, (f) => {
  if (f.title && f.title.length > 30) {
    form.title = f.title.slice(0, 30);
  }
});

startValidation.value = true;

async function submit() {
  try {
    const args = FormatCheckScheme.parse(form);

    if (await updateFormat(id.value, args)) {
      alert("更新しました");
      resetForm();
      getFormatMutate();
      goto({ name: "UsersSystemAdminRulesIndex" });
    } else {
      alert(
        "更新に失敗しました。添付されているPDFが大きすぎます。PDFが添付されていない、またはサイズに問題がないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
