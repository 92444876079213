<template>
  <div>
    <NewsForm
      v-model:for-members="form.forMembers"
      v-model:organization-id="form.organizationId"
      v-model:label="form.label"
      v-model:title="form.title"
      v-model:published-at="form.publishedAt"
      v-model:held-at="form.heldAt"
      v-model:content="form.content"
      v-model:place="form.place"
      v-model:lecturer="form.lecturer"
      v-model:cost="form.cost"
      v-model:application-method="form.applicationMethod"
      v-model:application-form-link="form.applicationFormLink"
      v-model:show-top="form.showTop"
      v-model:pinned-to="form.pinnedTo"
      v-model:new-attached-pdfs="form.newAttachedPdfs"
      :attached-pdfs="[]"
      :organizations="managedOrganizations"
      :errors="errors"
    />

    <div class="d-flex justify-content-center my-5">
      <BasicButton
        label="キャンセル"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto"
      />
      <BasicButton button-type="submit" label="作成" @click="submit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch, computed } from "vue";
import {
  useZodScheme,
  useNews,
  useRouterUtil,
  useUser,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as NewsForm } from "/@/vue/components/Organisms/Users/News";
import { NewsCheckScheme, NewsForm as NewsFormType } from "/@/types/news.d";

const { goto, backto, currentRouteQuery } = useRouterUtil();

const { getUser } = useUser();
const { data: user } = getUser();

const managedOrganizations = computed(() => {
  if (!user.value) return [];

  return user.value.managedOrganizations;
});

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<NewsFormType>(
  NewsCheckScheme,
  {
    forMembers: false,
    label: "announce",
    showTop: true,
    newAttachedPdfs: [],
  }
);

watch(form, (f) => {
  if (f.title && f.title.length > 50) {
    form.title = f.title.slice(0, 50);
  }
});

onMounted(() => {
  startValidation.value = true;

  form.organizationId = Number(currentRouteQuery.value.organizationId);
});

const { createNews } = useNews();

async function submit() {
  try {
    startValidation.value = true;

    const args = NewsCheckScheme.parse(form);

    if (await createNews(args)) {
      alert("作成に成功しました");
      goto({ name: "UsersNewsIndex" });
    } else {
      alert(
        "作成に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい");
    console.error(e);
  }
}
</script>

<style scoped></style>
