<template>
  <div>
    <div
      class="link-wrapper"
      style="background-color: white !important"
      :style="{
        padding: indexMode ? '0.35rem 1.25rem' : '1rem 1.25rem',
        'border-radius': indexMode ? '0.25rem' : '0',
        'border-top': indexMode || idx != 0 ? 'none' : '1px solid #e1dbc7',
        'border-bottom': indexMode ? 'none' : '1px solid #e1dbc7',
      }"
      :class="{
        'mb-2': indexMode && idx !== newsLength - 1,
      }"
    >
      <div class="text-green" @click="$emit('select', news.id)">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex w-100 text-ellipsis">
            <div
              class="d-flex align-items-center w-100 text-ellipsis"
              :class="{
                'flex-wrap': isLessThanMd,
              }"
            >
              <span class="me-4">{{
                isoFormat(news.publishedAt, "yyyy.MM.dd")
              }}</span>
              <NewsLabelChip
                :label="labelTranslater(news.label)"
                :reverseColor="reverseColor"
                class="me-4"
              />
              <span
                style="
                  min-width: 150px;
                  color: #333;
                  font-weight: 100;
                  max-width: 450px;
                "
                class="text-ellipsis"
                :class="{
                  'w-100': isLessThanMd,
                  'mt-2': isLessThanMd,
                }"
                >{{ news.title }}</span
              >
            </div>
            <div v-if="withContent" class="mt-2 with-list-padding">
              <span v-html="news.content"></span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <i
              v-if="news.nowPinned"
              class="pi pi-thumbtack text-danger me-2"
            ></i>
            <i
              v-if="news.unread"
              class="pi pi-circle-fill text-danger me-2"
            ></i>
            <i class="pi pi-angle-right icon-md"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMqUtils } from "/@/vue/composables";
import { isoFormat } from "/@/modules/luxon";
import { labelTranslater } from "/@/modules/news";
import { NewsLabelChip } from "/@/vue/components/Atom";
import { NewsClient } from "/@/types";

defineProps<{
  news: NewsClient;
  newsLength: number;
  idx: number;
  indexMode?: boolean;
  reverseColor?: boolean;
  withContent?: boolean;
}>();

defineEmits<{
  (e: "select", id: number): void;
}>();

const { isLessThanMd } = useMqUtils();
</script>

<style scoped></style>
