import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { PdfInfoScheme } from "/@/types/pdf.d";

export const CompletationFormatDataScheme = z.object({
  title: z.string(), // 修了証(研修受講証明書)
  to: z.string(), // 石川県健康福祉部長寿社会課長
  numberTitle: z.string(), // 介護支援専門員登録番号
});

export type CompletationFormatData = zod.infer<
  typeof CompletationFormatDataScheme
>;

export const formatTypes = ["bcp", "bylaws", "application"] as const;
export const FormatTypeScheme = z.enum(formatTypes);
export type FormatType = zod.infer<typeof FormatTypeScheme>;

export const FormatScheme = z.object({
  id: zod.number().optional(),
  title: z.string(),
  formatType: FormatTypeScheme,
  data: CompletationFormatDataScheme.partial().optional(), // || OtherFormatDataScheme when other format types are added
  newAttachedPdfs: z.string().array().min(1).max(1),
});

export type Format = zod.infer<typeof FormatScheme>;

export const FormatCheckScheme = FormatScheme;

export const FormatFormScheme = FormatScheme.partial({
  id: true,
  title: true,
  formatClass: true,
});
export type FormatForm = zod.infer<typeof FormatFormScheme>;

export const FormatClientScheme = FormatScheme.extend({
  createdAt: z.string(),
  updatedAt: z.string(),
  attachedPdfs: PdfInfoScheme.array(),
});
export type FormatClient = zod.infer<typeof FormatClientScheme>;
