<template>
  <div class="user-news-index">
    <UserPageActions>
      <div class="d-flex align-items-center justify-content-between w-100">
        <Dropdown
          v-model="selectedOrganizationId"
          :options="organizations"
          option-value="id"
          option-label="name"
          class="me-2"
        />

        <BasicButton
          label="投稿一覧へ"
          class="add-btn"
          variant="secondary"
          outlined
          @click="goto({ name: 'UsersNewsIndex' })"
        />
      </div>
    </UserPageActions>

    <span v-if="news && !news.length">ゴミ箱は空です</span>
    <NewsTable
      v-else-if="news"
      :news="news"
      trashed
      @select-news="selectNews"
      @trash-news="handleDestroyNews"
      @restore-news="handleRestoreNews"
    />
    <i v-else class="pi pi-spin pi-spinner"></i>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { getStorage, setStorage } from "/@/modules/localStorage";
import { useRouterUtil, useUser } from "/@/vue/composables";
import { useNews } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Table as NewsTable } from "/@/vue/components/Organisms/Users/News";
import { UserPageActions } from "/@/vue/components/Layouts";
import Dropdown from "primevue/dropdown";

const { goto } = useRouterUtil();

function selectNews(id: number) {
  goto({ name: "UsersNewsShow", params: { id: id } });
}

// api

const { getUser } = useUser();
const { data: user } = getUser();

const organizations = computed(() => {
  if (!user.value) return [];

  return user.value.organizations;
});

const selectedOrganizationId = ref(
  getStorage("selectedOrganizationId") || undefined
);

watch(selectedOrganizationId, (id) => {
  if (!id) return;
  setStorage("selectedOrganizationId", id);
});

watch(user, (u) => {
  if (!u || !!selectedOrganizationId.value) return;

  selectedOrganizationId.value = u.organizations[0]?.id;
});

const { getTrashedNews, destroyNews, restoreNews } = useNews();
const { data: news, mutate: getNewsMutate } = getTrashedNews(
  selectedOrganizationId
);

async function handleDestroyNews(id: number | number[]) {
  if (!window.confirm("完全に削除しますか？この操作は取り消せません。")) {
    return;
  }

  if (await destroyNews(id)) {
    alert("削除しました");
    getNewsMutate();
  } else {
    alert("削除に失敗しました");
  }
}

async function handleRestoreNews(id: number | number[]) {
  if (!window.confirm("復元しますか？")) {
    return;
  }

  if (await restoreNews(id)) {
    alert("復元しました");
    getNewsMutate();
  } else {
    alert(
      "復元に失敗しました。時間をおいて再度お試しください。解決しない場合は開発会社にお問い合わせください。"
    );
  }
}
</script>

<style lang="scss" scoped></style>
