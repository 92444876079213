<template>
  <div class="d-flex flex-column align-items-center">
    <div style="max-width: 1200px; width: 100%">
      <div class="mb-3">
        申請時に入力した項目をもう一度入力することで、過去の申請の一覧を確認できます
      </div>
      <Invest24UserForm
        v-model:last-name="form.lastName"
        v-model:first-name="form.firstName"
        v-model:birthday="form.birthday"
        v-model:phone-number="form.phoneNumber"
        v-model:email="form.email"
        :errors="errors"
        :recruitment-type="'invest24'"
        for-login
      />

      <div class="mt-3">
        <BasicButton class="me-1" variant="secondary" outlined @click="backto">
          キャンセル
        </BasicButton>
        <BasicButton class="ms-1" @click="submit">ログイン</BasicButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { gPhoneNumber } from "/@/modules/groomer";
import {
  useZodScheme,
  useRouterUtil,
  useInvest24User,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as Invest24UserForm } from "/@/vue/components/Organisms/Invest24/Invest24Users";
import { Invest24LoginForm, Invest24LoginCheckScheme } from "/@/types";

const { backto, goto } = useRouterUtil();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<Invest24LoginForm>(
  Invest24LoginCheckScheme,
  {
    birthday: "1990-01-01",
  }
);

watch(form, (f) => {
  // form を watch する場合は、まとめて変更した時の処理を書けるが、
  // 無駄な比較処理が増えるのと過去の値へのアクセスができない問題がある。
  // そのため個別のプロパティでの変更があった時のような処理が書けない

  if (f.firstName && f.firstName.length > 50) {
    form.firstName = f.firstName.slice(0, 50);
  }

  if (f.lastName && f.lastName.length > 50) {
    form.lastName = f.lastName.slice(0, 50);
  }

  if (f.phoneNumber) {
    form.phoneNumber = gPhoneNumber(f.phoneNumber);
  }
});

startValidation.value = true;

const { login } = useInvest24User();

const loading = ref<boolean>(false);

async function submit() {
  try {
    loading.value = true;

    const prms = Invest24LoginCheckScheme.parse(form);

    const uuid = await login(prms);

    if (uuid) {
      goto({ name: "Invest24UsersShow", query: { uuid } });
    } else {
      // error show in `await login`
      // window.alert("ログインに失敗しました");
    }
    return;
  } catch (e) {
    console.error(e);
    window.alert("入力に問題があります。赤枠の項目を確認して下さい。");
  }

  loading.value = false;
}
</script>

<style scoped></style>
