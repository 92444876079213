<template>
  <div>
    <form @submit.prevent="login">
      <FormItem
        v-model:value="loginForm.email"
        :errors="errors"
        label="メール"
        form-id="email"
        form-type="email"
        class="mb-3"
      />
      <FormItem
        v-model:value="loginForm.password"
        :errors="errors"
        label="パスワード"
        form-id="password"
        form-type="password"
      />
      <div class="mb-3"></div>
      <slot></slot>
      <div
        v-if="!loading"
        class="w-100 d-flex align-items-center justify-content-between"
      >
        <MoreReadButton
          color="green"
          label="ログイン"
          button-type="submit"
          :disabled="loading"
          @click="login"
        />
        <router-link
          :to="{ name: 'PasswordReset' }"
          style="color: rgba(var(--bs-link-color-rgb))"
          :style="{
            'font-size': isMobile ? '0.8rem' : '1rem',
          }"
        >
          パスワードを忘れた方はこちら
        </router-link>
      </div>
      <i v-else class="pi pi-spin pi-spinner"></i>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useZodScheme, useMqUtils } from "/@/vue/composables";
import { BasicButton, MoreReadButton } from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";
import { User, LoginForm, LoginScheme } from "/@/types";

interface Props {
  loading?: boolean;
}

interface Emits {
  (e: "login", user: User): void;
}

defineProps<Props>();
const emit = defineEmits<Emits>();

// form

const { isMobile } = useMqUtils();

const { useFormAndErrors } = useZodScheme();
const {
  form: loginForm,
  errors,
  startValidation,
} = useFormAndErrors<LoginForm>(LoginScheme);

function login() {
  try {
    startValidation.value = true;
    const user = LoginScheme.parse(loginForm);
    emit("login", user);
  } catch (e) {
    console.error("");
  }
}
</script>

<style scoped></style>
