<template>
  <div class="pb-3">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div
        v-if="currentRouteName == 'Index'"
        class="organization-container d-flex w-100"
        :class="{
          'flex-wrap': isMobile,
          'justify-content-center': isMobile,
        }"
        style="
          background-color: white;
          border-radius: 1.5rem;
          min-height: 250px;
          overflow-x: scroll;
        "
        :style="{
          padding: isMobile ? '18px 36px' : '36px 18px',
        }"
      >
        <div
          v-for="(o, idx) in organizations"
          class="organization-item d-flex flex-column px-2"
          :class="{
            'py-3': isMobile,
            'm-2': isMobile,
          }"
          :key="o.name"
          :style="{
            'border-right':
              !isMobile && idx !== organizations.length - 1
                ? '2px dashed #c2ba9f'
                : '',
            'border-bottom': isMobile ? '1px dashed #c2ba9f' : '',
            'border-top': isMobile ? '1px dashed #c2ba9f' : '',
            width: isMobile ? '250px' : '100%',
          }"
        >
          <div class="mb-3 d-flex justify-content-center">
            <span
              :style="{
                color: o.color,
              }"
              >{{ o.name }}</span
            >
          </div>

          <div
            :style="{
              height: isMobile ? '100%' : '120px',
            }"
            class="d-flex flex-column"
            :class="{
              'align-items-center': isMobile,
            }"
          >
            <div class="mb-2">
              <span class="text-brawn" style="font-size: 0.8rem">{{
                o.postcode ? formatPostcode(o.postcode) : ""
              }}</span>
            </div>
            <div
              v-if="o.address"
              class="d-flex flex-column text-brawn"
              style="font-size: 0.8rem"
            >
              <span v-for="add in o.address.split(' ')" :key="add">
                {{ add }}
              </span>
            </div>
          </div>

          <div
            v-if="false"
            class="d-flex justify-content-center w-100"
            :class="{ 'mt-3': isMobile }"
          >
            <a v-if="o.phoneNumber" :href="'tel:' + o.phoneNumber">
              <PhoneButton
                v-if="o.phoneNumber"
                :color-str="o.color"
                :tel="formatNational(o.phoneNumber)"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <router-link
          :to="{ name: 'Index' }"
          style="font-size: 100%"
          :class="{
            'd-flex justify-content-center': isMobile,
          }"
        >
          <b>石川県介護支援専門員協会</b>
        </router-link>
      </div>

      <div
        class="d-flex align-items-top flex-wrap"
        style="font-size: 75%"
        :class="{ 'justify-content-center': isMobile }"
      >
        <p class="text-nowrap">【事務局】</p>
        <span class="text-nowrap" style="width: 80px"> 〒920-8557 </span>
        <span class="text-nowrap" v-if="isMobile"
          >石川県金沢市本多町3-1-10</span
        >

        <div
          class="d-flex flex-column"
          :class="{ 'justify-contet-center': isMobile }"
        >
          <span v-if="!isMobile">石川県金沢市本多町3-1-10</span>
          <span>社会福祉法人 石川県社会福祉協議会・施設振興課</span>
        </div>
      </div>
      <div
        class="d-flex align-items-center flex-wrap"
        style="font-size: 75%"
        :class="{ 'justify-content-center mb-3': isMobile }"
      >
        <a href="tel:0762241211" class="me-2">TEL：076-224-1211</a>
        <span>FAX：076-208-5760</span>
      </div>
    </div>

    <div
      class="d-flex flex-column align-items-center justify-content-center my-2"
      style="font-size: 75%"
    >
      <div
        style="
          font-size: 75%;
          border-left: solid 1px black;
          border-right: solid 1px black;
        "
        class="px-2 mb-2"
      >
        <router-link :to="{ name: 'PrivacyPolicy' }">
          プライバシーポリシー
        </router-link>
      </div>

      <div
        style="font-size: 75%"
        class="d-flex flex-wrap align-items-center"
        :class="{ 'justify-content-center': isMobile }"
      >
        <span class="text-nowrap me-2"
          >Copyright ©︎{{ now.year }}, Ishikawa Care Manager Association.</span
        >
        <span class="text-nowrap">All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMqUtils, useRouterUtil } from "/@/vue/composables";
import { luxonNow } from "/@/modules/luxon";
import { formatPostcode } from "/@/modules/address";
import { PhoneButton } from "/@/vue/components/Atom/Buttons";
import { OrganizationClient } from "/@/types";
import { formatNational } from "/@/modules/phonenumber";
import { onMounted } from "vue";
import { onUpdated } from "vue";

defineProps<{
  organizations: OrganizationClient[];
}>();

const { isMobile } = useMqUtils();
const { currentRouteName } = useRouterUtil();

const now = luxonNow();

function markLastRowItems() {
  const container = document.querySelector(".organization-container");
  if (!container) return;

  const items = container.querySelectorAll(".organization-item");

  let currentRowTop = items[0].offsetTop;
  let lastRowTop = currentRowTop;

  items.forEach((item) => {
    const itemTop = item.offsetTop;
    if (itemTop > lastRowTop) {
      lastRowTop = itemTop;
    }
  });

  items.forEach((item) => {
    const itemTop = item.offsetTop;
    if (itemTop === lastRowTop) {
      item.classList.add("last-row");
    } else {
      item.classList.remove("last-row");
    }
  });
}

onUpdated(() => {
  markLastRowItems();
});
</script>

<style scoped></style>
