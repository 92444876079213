import Index from "./Index.vue";
import New from "./New.vue";
import Show from "./Show.vue";
import Edit from "./Edit.vue";
import Trash from "./Trash.vue";

import {
  Root as UsersNewsRoot,
  Router as UsersNewsRouter,
} from "./UsersNews/index";

export default [
  {
    path: "",
    name: "UsersNewsIndex",
    component: Index,
    meta: {
      title: "お知らせ",
      tree: [],
    },
  },
  {
    path: "new",
    name: "UsersNewsNew",
    component: New,
    meta: {
      title: "お知らせ作成",
      tree: [{ title: "お知らせ", name: "UsersNewsIndex" }],
    },
  },
  {
    path: ":id",
    name: "UsersNewsShow",
    component: Show,
    meta: {
      title: "詳細",
      tree: [{ title: "お知らせ", name: "UsersNewsIndex" }],
    },
  },
  {
    path: ":id/edit",
    name: "UsersNewsEdit",
    component: Edit,
    meta: {
      title: "編集",
      tree: [
        { title: "お知らせ", name: "UsersNewsIndex" },
        { title: "詳細", name: "UsersNewsShow" },
      ],
    },
  },
  {
    path: "trash",
    name: "UsersNewsTrashIndex",
    component: Trash,
    meta: {
      title: "ゴミ箱",
      tree: [{ title: "お知らせ", name: "UsersNewsIndex" }],
    },
  },
  {
    path: "users_news",
    component: UsersNewsRoot,
    children: UsersNewsRouter,
  },
];
