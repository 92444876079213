<template>
  <div class="grid text-center w-100" style="max-width: 1000px">
    <template v-for="item in menuItems" :key="item.label">
      <router-link
        v-if="item.to"
        style="
          border-radius: 17px 17px 22px 22px;
          box-shadow: 2px 2px 2px #0003;
        "
        :style="{
          height: isMobile ? '75px' : '125px',
        }"
        :to="item.to"
        class="d-flex flex-column align-items-center justify-content-center bg-white"
        :class="{
          'g-col-3': isMobile,
          'g-col-2': !isMobile,
        }"
      >
        <i
          :class="{
            [item.icon]: true,
            'mb-1': true,
            'icon-md': isMobile,
            'icon-lg': !isMobile,
          }"
        ></i>
        <span
          :style="{
            'font-size': isMobile ? '0.75rem' : '0.85rem',
          }"
          >{{ item.label }}</span
        >
      </router-link>
      <div
        v-if="item.command"
        style="
          border-radius: 17px 17px 22px 22px;
          box-shadow: 2px 2px 2px #0003;
        "
        :style="{
          height: isMobile ? '75px' : '125px',
        }"
        class="d-flex flex-column align-items-center justify-content-center bg-white"
        :class="{
          'g-col-3': isMobile,
          'g-col-2': !isMobile,
        }"
        role="button"
        @click="item.command"
      >
        <i
          :class="{
            [item.icon]: true,
            'mb-1': true,
            'icon-md': isMobile,
            'icon-lg': !isMobile,
          }"
        ></i>
        <span
          :style="{
            'font-size': isMobile ? '0.75rem' : '0.85rem',
          }"
          >{{ item.label }}</span
        >
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { MenuItem } from "primevue/menuitem";
import { useMqUtils } from "/@/vue/composables";

defineProps<{
  menuItems: MenuItem[];
}>();

const { isMobile } = useMqUtils();
</script>
