<template>
  <div>
    <div class="mb-3">研修 開発中</div>

    <div class="mb-3">
      <ul class="list-group">
        <router-link
          v-for="li in menuItems"
          :key="li.label"
          class="list-group-item"
          :to="li.to"
        >
          {{ li.label }}
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
const menuItems = [
  { to: { name: "UsersTrainingsPublication" }, label: "証明書の発行" },
];
</script>

<style scoped></style>
