<template>
  <div
    id="invest24-index"
    class="d-flex flex-column align-items-center justify-content-center"
  >
    <div
      class="d-flex justify-content-end flex-wrap w-100"
      :style="{
        fontSize: isMobile ? '0.8rem' : '1rem',
      }"
    >
      <span>申請済情報の確認はメールもしくは</span>
      <router-link
        id="to-invest24-users-login"
        :to="{ name: 'Invest24UsersLogin' }"
        class="link-text"
        >こちら</router-link
      >
      <span>から</span>
    </div>
    <RecruitmentCalendar
      :recruitments="recruitments || []"
      :jp-holidays="jpHolidays || {}"
      class="py-3"
      :loading="loading"
      :invest24-users="[]"
      :users="[]"
      :recruitment-type="recruitmentType"
      style="max-width: 1200px"
      is-open-site
      @apply-recruitment="handleApplyRecruitment"
      @cancel-recruitment="handleCancelRecruitment"
      @destroy-recruitment="handleDestroyRecruitment"
      @allocate-user="handleAllocateUser"
      @detach-user="handleDetachUser"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import {
  useMqUtils,
  useRouterUtil,
  useRecruitment,
  useCalendar,
} from "/@/vue/composables";
import { List as RecruitmentCalendar } from "/@/vue/components/Molecules/Invest24Recruitments";
import { Invest24RecruitmentType } from "/@/types";

const { isMobile } = useMqUtils();
const { goto } = useRouterUtil();

// recruitment type

const recruitmentType = ref<Invest24RecruitmentType>("invest24");

const { getJpHolidays } = useCalendar();
const { data: jpHolidays } = getJpHolidays();

// recruitments

const {
  getRecruitments,
  cancelRecruitment,
  destroyRecruitment,
  allocateUserRecruitment,
  detachUserRecruitment,
} = useRecruitment();
const { data: recruitments, mutate: getRecruitmentsMutate } = getRecruitments({
  recruitmentType: recruitmentType,
});

const loading = ref(false);

async function handleApplyRecruitment(id: number, targetDate: string) {
  goto({
    name: "Invest24JoinUsersNew",
    query: { id, targetDate, recruitmentType: "invest24" },
  });
}

async function handleCancelRecruitment(id: number, targetDate: string) {
  if (!window.confirm("申請を取り消してよろしいですか？")) return;

  loading.value = true;

  if (await cancelRecruitment(id, { targetDate })) {
    alert("申請を取り消しました");
    getRecruitmentsMutate();
  } else {
    alert("申請の取り消しに失敗しました");
  }

  loading.value = false;
}

async function handleDestroyRecruitment(id: number) {
  if (!window.confirm("募集の削除依頼を開発組織に依頼しますか？")) return;

  loading.value = true;

  if (await destroyRecruitment(id)) {
    alert("削除依頼を送信しました");
  } else {
    alert("削除依頼の送信に失敗しました");
  }
}

async function handleDetachUser(
  id: number,
  invest24UserId: number,
  targetDate: string
) {
  if (
    !window.confirm("ボランティアへの参加をキャンセルします。よろしいですか？")
  )
    return;

  loading.value = true;

  if (await detachUserRecruitment(id, { invest24UserId, targetDate })) {
    alert("キャンセルしました");
    getRecruitmentsMutate();
  } else {
    alert("キャンセルに失敗しました");
  }

  loading.value = false;
}

async function handleAllocateUser(
  id: number,
  targetDate: string,
  invest24UserId?: number,
  userId?: number
) {
  if (!window.confirm("ボランティアを追加しますか？")) return;

  loading.value = true;

  if (
    await allocateUserRecruitment(id, { targetDate, invest24UserId, userId })
  ) {
    alert("追加しました");
    getRecruitmentsMutate();
  } else {
    alert("追加に失敗しました");
  }

  loading.value = false;
}
</script>

<style lang="scss" scoped>
#invest24-index {
  .p-carousel-next,
  .p-carousel-prev {
    display: none;
  }

  .link-text {
    color: #007bff;
  }
}
</style>
