<template>
  <div>
    <template v-if="disabled">
      <div class="mb-3">
        <FormLabel label="申請日" />
        <span>{{ basicFormatter(targetDate) }}</span>
      </div>
      <div v-if="recruitmentType === 'invest24'" class="mb-3">
        <FormLabel label="エリア" />
        <span>{{ area }}</span>
      </div>
      <div v-if="recruitmentType" class="mb-3">
        <FormLabel label="種別" />
        <span>{{ recruitmentTypeStr }}</span>
      </div>
    </template>
    <template v-else>
      <FormItem
        :value="targetDate"
        :errors="errors"
        label="申請日"
        form-id="targetDate"
        form-type="date"
        class="mb-3"
        disabled
        :min-date="range.minDate"
        :max-date="range.maxDate"
        style="max-width: 300px"
        @update:value="$emit('update:targetDate', $event as string | undefined)"
      />
      <div class="mb-3">
        <FormLabel label="エリア" />
        <Dropdown
          :model-value="area"
          :options="areas"
          class="w-100"
          disabled
          style="max-width: 300px"
          @update:model-value="$emit('update:area', $event)"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { areas } from "/@/modules/constants";
import { basicFormatter } from "/@/modules/luxon";
import { recruitmentTypeTranslater } from "/@/modules/invest24Recruitment";
import { Label as FormLabel } from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";
import {
  Invest24RecruitmentClient,
  Invest24RecruitmentType,
  Invest24WorkType,
  ZodFormattedErrors,
} from "/@/types";
import Dropdown from "primevue/dropdown";
import { luxonNow, fromISO } from "/@/modules/luxon";
import { computed } from "vue";

const today = luxonNow();

const props = defineProps<{
  recruitment: Invest24RecruitmentClient;
  targetDate?: string;
  workType?: Invest24WorkType;
  area?: string;
  recruitmentType: Invest24RecruitmentType;
  disabled?: boolean;
  errors: ZodFormattedErrors;
}>();

defineEmits<{
  (e: "update:targetDate", value: string | undefined): void;
  (e: "update:area", value: string | undefined): void;
  (e: "update:workType", value: Invest24WorkType | undefined): void;
}>();

const range = computed(() => {
  const startOn = fromISO(props.recruitment.startOn);
  const finishOn = fromISO(props.recruitment.finishOn);

  return {
    minDate: startOn > today ? startOn : today,
    maxDate: finishOn,
  };
});

const recruitmentTypeStr = computed(() => {
  return recruitmentTypeTranslater(props.recruitmentType);
});
</script>

<style scoped></style>
