import { ref, onMounted } from "vue";

export function useScroll() {
  const scrollPos = ref(0);

  function updateScrollPos() {
    if (window.scrollY < 5) {
      scrollPos.value = 0;
      return;
    }

    if (
      window.scrollY - scrollPos.value > 50 ||
      window.scrollY - scrollPos.value < -50
    ) {
      scrollPos.value = window.scrollY;
    }
  }

  onMounted(() => {
    window.addEventListener("scroll", updateScrollPos, { passive: true });
  });

  return {
    scrollPos,
  };
}
