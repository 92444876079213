<template>
  <div>
    <RecruitmentUpdateForm
      v-model:start-on="form.startOn"
      v-model:finish-on="form.finishOn"
      v-model:area="form.area"
      v-model:need-count="form.needCount"
      v-model:work-place="form.workPlace"
      v-model:meeting-at="form.meetingAt"
      v-model:finish-at="form.finishAt"
      v-model:meeting-place="form.meetingPlace"
      v-model:belongings="form.belongings"
      v-model:rewards="form.rewards"
      v-model:details="form.details"
      v-model:invest24-recruitment-day-infos="form.invest24RecruitmentDayInfos"
      v-model:selected-target-dates="selectedTargetDates"
      :recruitment="recruitment"
      :errors="errors"
      @cancel="backto"
      @submit-area-term="submit('area-term')"
      @submit-need-count="submit('need-count')"
      @submit-info="submit('info')"
      @submit="submit"
    />

    <div v-if="!targetDate" class="d-flex justify-content-center mt-5">
      <BasicButton
        label="キャンセル"
        variant="secondary"
        class="me-2"
        outlined
        @click="backto"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed, onMounted } from "vue";
import {
  useRouterUtil,
  useZodScheme,
  useRecruitment,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { UpdateForm as RecruitmentUpdateForm } from "/@/vue/components/Organisms/Users/Invest24Recruitments";
import {
  Invest24RecruitmentForm,
  Invest24RecruitmentCheckUpdateScheme,
  Invest24RecruitmentClient,
} from "/@/types/invest24Recruitment.d";
import { recruitmentTypeTranslater } from "/@/modules/invest24Recruitment";

const { backto, goto, id, currentRouteQuery } = useRouterUtil();
const targetDate = computed(() => currentRouteQuery.value.targetDate as string);

const { getRecruitment } = useRecruitment();
const { data: recruitment } = getRecruitment(id);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<Invest24RecruitmentForm>(
    Invest24RecruitmentCheckUpdateScheme,
    {
      invest24RecruitmentDayInfos: [],
    }
  );

watch(form, (f) => {});

function resetForm(recruitment?: Invest24RecruitmentClient) {
  if (recruitment) {
    form.id = recruitment.id;
    form.area = recruitment.area;
    form.details = recruitment.details;
    form.startOn = recruitment.startOn;
    form.finishOn = recruitment.finishOn;
    form.needCount = recruitment.needCount;
    form.invest24RecruitmentDayInfos = recruitment.invest24RecruitmentDayInfos;
  }
}

watch(recruitment, (n) => {
  if (!form.id && n) {
    resetForm(n);
  }
});

onMounted(() => {
  if (targetDate.value) {
    resetForm(recruitment.value);
  }
});

const selectedTargetDates = ref<string[]>([]);

// startValidation.value = true;

const { updateRecruitment } = useRecruitment();

async function submit(target: "area-term" | "need-count" | "info" | undefined) {
  try {
    let checkScheme;

    if (target === "area-term") {
      checkScheme = Invest24RecruitmentCheckUpdateScheme.pick({
        area: true,
        startOn: true,
        finishOn: true,
        invest24RecruitmentDayInfos: true,
      });
    } else if (target === "need-count") {
      checkScheme = Invest24RecruitmentCheckUpdateScheme.pick({
        needCount: true,
        invest24RecruitmentDayInfos: true,
      });
    } else if (target === "info") {
      checkScheme = Invest24RecruitmentCheckUpdateScheme.pick({
        workPlace: true,
        meetingAt: true,
        finishAt: true,
        meetingPlace: true,
        belongings: true,
        rewards: true,
        details: true,
        invest24RecruitmentDayInfos: true,
      }).partial();
    } else {
      checkScheme = Invest24RecruitmentCheckUpdateScheme.partial();
    }

    const prms = checkScheme.parse(form);

    if (
      await updateRecruitment(
        id.value,
        prms,
        ["need-count", "info"].includes(target)
          ? selectedTargetDates.value
          : undefined
      )
    ) {
      window.alert("募集を更新しました");
      goto({
        name: "UsersRecruitmentsManagementsManagement",
        query: { recruitmentType: recruitment.value?.recruitmentType },
      });
    } else {
      window.alert("募集の更新に失敗しました");
    }
    return;
  } catch (e) {
    console.error(e);
    window.alert("入力に問題があります。赤枠の項目を確認して下さい。");
  }
}
</script>

<style scoped></style>
