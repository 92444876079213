<template>
  <div>
    <div class="d-flex mb-3">
      <FormItem
        :value="lastName"
        :errors="errors"
        label="姓(必須)"
        form-id="lastName"
        form-type="text"
        class="me-1 w-50"
        style="max-width: 300px"
        autocomplete="family-name"
        @update:value="$emit('update:lastName', $event as string | undefined)"
      />
      <FormItem
        :value="firstName"
        :errors="errors"
        label="名(必須)"
        form-id="firstName"
        form-type="text"
        class="ms-1 w-50"
        style="max-width: 300px"
        autocomplete="given-name"
        @update:value="$emit('update:firstName', $event as string | undefined)"
      />
    </div>

    <div v-if="!forLogin" class="d-flex mb-3">
      <FormItem
        :value="lastNameKana"
        :errors="errors"
        label="せい(必須)"
        form-id="lastNameKana"
        form-type="text"
        class="me-1 w-50"
        style="max-width: 300px"
        autocomplete="off"
        @update:value="
          $emit('update:lastNameKana', $event as string | undefined)
        "
      />
      <FormItem
        :value="firstNameKana"
        :errors="errors"
        label="めい(必須)"
        form-id="firstNameKana"
        form-type="text"
        class="ms-1 w-50"
        style="max-width: 300px"
        autocomplete="off"
        @update:value="
          $emit('update:firstNameKana', $event as string | undefined)
        "
      />
    </div>

    <div v-if="!forLogin && recruitmentType === 'invest24'" class="mb-3">
      <FormLabel label="勤務種別(選択必須)" />
      <Dropdown
        :model-value="workType"
        :options="invest24WorkTypes"
        :errors="errors"
        no-empty-label="勤務種別を選択してください。"
        form-id="workType"
        :class="{
          'p-invalid': errors.workType,
        }"
        @update:model-value="$emit('update:workType', $event)"
      />
    </div>

    <div class="mb-3">
      <FormLabel label="生年月日(必須)" />
      <FormItem
        :value="birthday"
        :errors="errors"
        form-id="birthday"
        form-type="date"
        :start-date="fromISO('1990-01-01')"
        style="max-width: 210px"
        autocomplete="bday"
        @update:value="$emit('update:birthday', $event as string | undefined)"
      />
    </div>

    <FormItem
      :value="phoneNumber"
      :errors="errors"
      label="携帯電話番号(必須)"
      form-id="phoneNumber"
      form-type="text"
      class="mb-3 w-100"
      autocomplete="tel"
      style="max-width: 300px"
      @update:value="$emit('update:phoneNumber', $event as string | undefined)"
    />

    <FormItem
      :value="email"
      :errors="errors"
      label="メール(必須)"
      form-id="email"
      form-type="email"
      class="mb-3 w-100"
      autocomplete="current-email"
      style="max-width: 300px"
      @update:value="$emit('update:email', $event as string | undefined)"
    />

    <FormItem
      v-if="!forLogin"
      :value="emailCheck"
      :errors="emailCheckErrors"
      label="メール(確認)"
      form-id="emailCheck"
      form-type="email"
      autocomplete="current-email"
      class="mb-3 w-100"
      style="max-width: 300px"
      @update:value="$emit('update:emailCheck', $event as string | undefined)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { FormItem } from "/@/vue/components/Molecules";
import { Label as FormLabel } from "/@/vue/components/Atom";
import Dropdown from "primevue/dropdown";
import {
  Invest24WorkType,
  invest24WorkTypes,
  Invest24RecruitmentType,
  ZodFormattedErrors,
} from "/@/types";

import { fromISO } from "/@/modules/luxon";

const props = defineProps<{
  lastName?: string;
  firstName?: string;
  lastNameKana?: string;
  firstNameKana?: string;
  workType?: Invest24WorkType;
  birthday?: string;
  phoneNumber?: string;
  email?: string;
  emailCheck?: string;
  area?: string;
  forLogin?: boolean;
  errors: ZodFormattedErrors;
  recruitmentType: Invest24RecruitmentType;
}>();

defineEmits<{
  (e: "update:lastName", value?: string): void;
  (e: "update:firstName", value?: string): void;
  (e: "update:lastNameKana", value?: string): void;
  (e: "update:firstNameKana", value?: string): void;
  (e: "update:workType", value?: Invest24WorkType): void;
  (e: "update:birthday", value?: string): void;
  (e: "update:phoneNumber", value?: string): void;
  (e: "update:email", value?: string): void;
  (e: "update:emailCheck", value?: string): void;
}>();

const emailCheckErrors = computed(() => {
  if (
    !props.emailCheck ||
    (props.email && props.emailCheck && props.email !== props.emailCheck)
  ) {
    return {
      emailCheck: {
        _errors: ["メールアドレスが一致しません。"],
      },
    };
  }
  return {};
});
</script>

<style scoped></style>
