<template>
  <div id="user-index" class="d-flex align-items-center justify-content-center">
    <div v-if="user" class="contents-960 w-100">
      <ShowFormItemWrapper title="名前">
        <span>{{ user.lastName }} {{ user.firstName }}</span
        ><span v-if="user.lastNameKana && user.firstNameKana"
          >({{ user.lastNameKana }} {{ user.firstNameKana }})</span
        >
      </ShowFormItemWrapper>
      <ShowFormItemEmail :email="user.email" />
      <ShowFormItemTel v-if="user.phoneNumber" :tel="user.phoneNumber" />
      <ShowFormItemWrapper v-if="user.postcode" title="住所">
        <span>{{ formatPostcode(user.postcode) }} {{ user.address }}</span>
      </ShowFormItemWrapper>
      <ShowFormItemWrapper v-if="user.affiliation" title="所属事業所">
        <div class="d-flex flex-column">
          <span class="me-2">{{ user.affiliation }}</span>
          <a
            v-if="user.affiliationPhoneNumber"
            :href="`tel:` + user.affiliationPhoneNumber"
          >
            {{ formatNational(user.affiliationPhoneNumber) }}
          </a>
          <div v-if="user.affiliationPostcode || user.affiliationAddress">
            {{ formatPostcode(user.affiliationPostcode) }}
            {{ user.affiliationAddress }}
          </div>
        </div>
      </ShowFormItemWrapper>
      <ShowFormItemWrapper title="業種">
        <span>{{ user.business }}</span>
        <span v-if="user.registrationNumber">
          （登録番号：{{ user.registrationNumber }}）
        </span>
      </ShowFormItemWrapper>
      <ShowFormItemWrapper v-if="false" title="管理対象組織">
        <span v-if="!user?.managedOrganizations.length"> 通常の会員です </span>
        <template v-else>
          <ul class="list-group">
            <li
              v-for="organization in user?.managedOrganizations || []"
              :key="organization.id"
              class="list-group-item"
              role="button"
              @click="
                goto({
                  name: 'UsersOrganizationsShow',
                  params: { id: organization.id },
                })
              "
            >
              {{ organization.name }}
            </li>
          </ul>
        </template>
      </ShowFormItemWrapper>
      <ShowFormItemWrapper title="所属組織">
        <div v-if="user.organizations.length" class="w-100 d-flex flex-column">
          <span v-for="o in user.organizations" :key="o.id">
            <span>{{ o.name }}</span>
          </span>
        </div>
      </ShowFormItemWrapper>
      <ShowFormItemWrapper title="アカウントメニュー" without-divider>
        <div class="d-flex flex-column w-100">
          <template v-for="(li, idx) in menuItems" :key="li.label">
            <router-link
              v-if="li.visible"
              :to="li.to"
              :class="{ 'mb-2': idx !== menuItems.length - 1 }"
            >
              <MoreReadButton color="green" :label="li.label" />
            </router-link>
          </template>
        </div>
      </ShowFormItemWrapper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatNational } from "/@/modules/phonenumber";
import { formatPostcode } from "/@/modules/address";
import { useUser, useRouterUtil } from "/@/vue/composables";
import { MoreReadButton } from "/@/vue/components/Atom";
import {
  ShowFormItemEmail,
  ShowFormItemTel,
  ShowFormItemWrapper,
} from "/@/vue/components/Molecules/Form";
import { List as NewsList } from "/@/vue/components/Molecules/News";

const { goto } = useRouterUtil();

const { getUser } = useUser();
const { data: user } = getUser({ withMemberAnnounces: true });

const menuItems = [
  { to: { name: "UsersEdit" }, label: "アカウント情報変更", visible: true },
  {
    to: { name: "UsersChangePassword" },
    label: "パスワード変更",
    visible: true,
  },
  { to: { name: "UsersTrainingsIndex" }, label: "研修", visible: false },
];
</script>

<style scoped lang="scss"></style>
