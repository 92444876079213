<template>
  <div>
    <UserPageActions>
      <div
        class="d-flex align-items-center flex-wrap justify-content-between w-100"
      >
        <div class="d-flex flex-column">
          <div class="mb-3" style="width: 300px">
            <FormLabel label="名前検索" />
            <InputForm
              v-model:value="searchName"
              class="w-100"
              is-valid
              placeholder="名前を入力"
            />
          </div>
          <div class="d-flex flex-wrap align-items-center">
            <div class="me-2">
              <FormLabel label="支部" />
              <SelectButton
                v-model="selectedBranchIds"
                :options="branchOrganizations"
                option-value="id"
                option-label="name"
                multiple
              />
            </div>

            <div class="me-2">
              <FormLabel label="所属" />
              <SelectButton
                v-model="selectedCommitteeIds"
                :options="committeeOrganizations"
                option-value="id"
                option-label="name"
                multiple
              />
            </div>
          </div>
        </div>

        <BasicButton
          v-if="isOffice"
          label="追加"
          variant="secondary"
          outlined
          @click="
            goto({
              name: 'UsersNew',
            })
          "
        />
      </div>
    </UserPageActions>
    <DataTable
      striped-rows
      paginator
      :rows="30"
      :value="filteredUsers"
      table-style="width: 100%"
      selection-mode="single"
      @row-click="selectUser($event.data)"
    >
      <Column
        sortable
        :field="(user: UserClient) => user.lastName + user.firstName"
        header="名前"
        :style="{ width: '250px' }"
      >
        <template #body="slotProps">
          <span>{{ slotProps.data.lastName + slotProps.data.firstName }}</span>
          <span
            v-if="slotProps.data.confirmedInWeek"
            class="text-danger ms-1 rotate-45"
          >
            NEW
          </span>
          <span
            v-if="isOwner && slotProps.data.updatedInWeek"
            class="text-green ms-1 rotate-45"
          >
            UPDATE
          </span>
        </template>
      </Column>
      <Column
        v-if="false"
        sortable
        field="beforeCheckManager"
        header="承認"
        style="width: 100px"
      >
        <template #body="slotProps">
          <i
            v-if="slotProps.data.beforeCheckManager"
            class="pi pi-minus text-danger"
          ></i>
          <i v-else class="pi pi-check text-success"></i>
        </template>
      </Column>
      <Column sortable field="special" header="特別会員" style="width: 120px">
        <template #body="slotProps">
          <i v-if="slotProps.data.special" class="pi pi-check text-success"></i>
        </template>
      </Column>
      <Column
        v-if="isOffice"
        sortable
        field="deposited"
        header="口振"
        style="width: 100px"
      >
        <template #body="slotProps">
          <i
            v-if="
              slotProps.data.beforeCheckManager && !slotProps.data.deposited
            "
            class="pi pi-minus text-danger"
          ></i>
          <i v-else class="pi pi-check text-success"></i>
        </template>
      </Column>
      <Column
        sortable
        field="email"
        header="メール"
        :style="{ width: '300px' }"
      />
      <Column
        sortable
        :field="
          (user) =>
            user.organizations.filter((o) => o.branch).length
              ? user.organizations
                  .filter((o) => o.branch)
                  .map((o) => o.name)
                  .join(',')
              : '-'
        "
        header="支部"
        :style="{ width: '200px' }"
      />
      <Column
        sortable
        :field="
          (user) =>
            user.organizations.filter((o) => o.name !== '県協会' && !o.branch)
              .length
              ? user.organizations
                  .filter((o) => o.name !== '県協会' && !o.branch)
                  .map((o) => o.name)
                  .join(',')
              : '-'
        "
        header="所属"
        :style="{ width: '200px' }"
      />
      <Column
        sortable
        :field="
          (user: UserClient) =>
            user.userPositions.length
              ? user.userPositions
                  .map((up) => userPositionTranslater(up.positionType))
                  .join(',')
              : '-'
        "
        header="役職"
        :style="{ width: '200px' }"
      />
      <Column
        sortable
        :field="
          (user) =>
            user.userLabels
              .map(
                (ul) =>
                  `${
                    ul.dependancyName ? `${ul.dependancyName} ` : ''
                  }${userLabelTranslater(ul.labelType)}`
              )
              .join(',')
        "
        header="ラベル"
        style="width: 200px"
      />
      <Column
        v-if="isOffice"
        sortable
        field="confirmedAt"
        header="登録日"
        :style="{ width: '160px', 'min-width': '160px' }"
      >
        <template #body="{ data }">
          <div v-if="data.confirmedAt" class="d-flex align-items-center">
            <span class="text-end">{{
              basicFormatter(data.confirmedAt, "slashStyleOnlyDateWithZero")
            }}</span>
          </div>
          <span v-else class="text-danger">メール未認証</span>
        </template>
      </Column>
    </DataTable>

    <Dialog
      v-model:visible="visible"
      modal
      :header="
        selectedUser ? selectedUser.lastName + selectedUser.firstName : ''
      "
      dismissable-mask
      block-scroll
      style="width: 90%; max-width: 500px"
      @after-hide="closeDialog"
    >
      <div v-if="selectedUser" class="p-2">
        <template v-if="selectedUser.beforeCheckManager">
          <div class="mb-3 text-danger">
            <span v-if="selectedUser.beforeCheckManager">承認前の会員です</span>
          </div>
          <UserShow :user="selectedUser" />
        </template>
        <template v-else-if="isUserManager || isOffice">
          <FormLabel label="会員情報" class="me-2" />
          <UserForm
            v-model:special="form.special"
            v-model:last-name="form.lastName"
            v-model:first-name="form.firstName"
            v-model:last-name-kana="form.lastNameKana"
            v-model:first-name-kana="form.firstNameKana"
            v-model:email="form.email"
            v-model:birthday="form.birthday"
            v-model:phone-number="form.phoneNumber"
            v-model:postcode="form.postcode"
            v-model:address="form.address"
            v-model:have-affiliation="form.haveAffiliation"
            v-model:affiliation="form.affiliation"
            v-model:affiliation-phone-number="form.affiliationPhoneNumber"
            v-model:affiliation-postcode="form.affiliationPostcode"
            v-model:affiliation-address="form.affiliationAddress"
            v-model:business="form.business"
            v-model:registration-number="form.registrationNumber"
            :email-check="form.email"
            :errors="errors"
            :organizations="[]"
            :organization-members="[]"
            hide-email-check
          />
        </template>
        <template v-else-if="isOffice">
          <div class="mb-3 text-danger">
            <span v-if="selectedUser.beforeCheckManager">認証前の会員です</span>
            <span v-else>認証済みです</span>
          </div>

          <FormLabel label="会員情報" class="me-2" />
          <UserShow :user="selectedUser" />
        </template>

        <template v-if="!selectedUser.beforeCheckManager && isAllLManager">
          <div class="d-flex align-items-center mb-3">
            <FormLabel label="所属組織" class="me-2" without-padding />
            <BasicButton
              icon="pi pi-plus"
              :disabled="disabledAddOrganizationMember"
              @click="addOrganizationMember"
            />
          </div>
          <div
            v-for="(om, idx) in form.organizationMembers"
            :key="om.organizationId"
            class="card card-body mb-3"
          >
            <div class="mb-3">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <FormLabel label="組織選択" without-padding />
                <BasicButton
                  icon="pi pi-trash"
                  variant="danger"
                  @click="deleteOrganizationMember(idx)"
                />
              </div>

              <Dropdown
                v-model="om.organizationId"
                :options="organizations"
                option-label="name"
                option-value="id"
                class="w-100"
              />
            </div>
          </div>
          <BasicButton
            v-if="form.organizationMembers.length > 2"
            icon="pi pi-plus"
            :disabled="disabledAddOrganizationMember"
            @click="addOrganizationMember"
          />
        </template>

        <template
          v-if="
            !selectedUser.beforeCheckManager && (isAllLManager || isABCManager)
          "
        >
          <div class="d-flex align-items-center mb-3">
            <FormLabel label="役職" class="me-2" without-padding />
            <BasicButton
              icon="pi pi-plus"
              :disabled="disabledAddPosition"
              @click="addPosition"
            />
          </div>
          <div
            v-for="(up, idx) in form.userPositions"
            :key="up.positionType"
            class="card card-body mb-3"
          >
            <div class="mb-3">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <FormLabel label="役職選択" without-padding />
                <BasicButton
                  icon="pi pi-trash"
                  variant="danger"
                  @click="deletePosition(idx)"
                />
              </div>

              <Dropdown
                v-model="up.positionType"
                :options="selectablePositionTypes(selectedUser)"
                :option-label="userPositionTranslater"
                class="w-100"
              />
            </div>
          </div>
          <BasicButton
            v-if="form.userPositions.length > 2"
            icon="pi pi-plus"
            :disabled="disabledAddPosition"
            @click="addPosition"
          />

          <div class="d-flex align-items-center mb-3">
            <FormLabel label="ラベル" class="me-2" without-padding />
            <BasicButton
              icon="pi pi-plus"
              :disabled="disabledAddRole"
              @click="addRole"
            />
          </div>
          <div
            v-for="(ul, idx) in form.userLabels"
            :key="ul.labelType"
            class="card card-body mb-3"
          >
            <div class="mb-3">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <FormLabel label="ラベル選択" without-padding />
                <BasicButton
                  icon="pi pi-trash"
                  variant="danger"
                  @click="deleteRole(idx)"
                />
              </div>

              <Dropdown
                v-model="ul.labelType"
                :options="userLabelTypes"
                :option-label="userLabelTranslater"
                class="w-100"
                @update:model-value="updateLabelType(ul, $event)"
              />
            </div>
            <div v-if="needDepsLabels.includes(ul.labelType)" class="mb-3">
              <FormLabel label="組織選択" />
              <Dropdown
                v-model="ul.dependancyId"
                :options="
                  branchLabels.includes(ul.labelType)
                    ? branchOrganizations
                    : committeeOrganizations
                "
                option-label="name"
                option-value="id"
                class="w-100"
                @update:model-value="ul.dependancyType = 'Organization'"
              />
            </div>
          </div>
          <BasicButton
            v-if="form.userLabels.length > 2"
            icon="pi pi-plus"
            :disabled="disabledAddRole"
            @click="addRole"
          />
        </template>

        <div class="my-5">
          <div
            v-if="selectedUser.beforeCheckManager"
            class="mb-2 d-flex flex-column"
          >
            <div class="mb-3">
              <span>
                口座振替を確認次第承認して下さい。会員を承認しますか？
              </span>
            </div>
            <div>
              <BasicButton
                label="キャンセル"
                variant="secondary"
                class="me-2"
                outlined
                @click="closeDialog"
              />
              <BasicButton
                button-type="submit"
                label="承認"
                class="ms-2"
                @click="accept"
              />
            </div>
          </div>
          <template v-else>
            <div v-if="hasSame" class="text-danger mb-2">
              同様のラベルか組織が存在します
            </div>
            <div v-if="isUserManager || isAllLManager || isABCManager">
              <BasicButton
                label="キャンセル"
                variant="secondary"
                class="me-2"
                outlined
                @click="closeDialog"
              />
              <BasicButton
                button-type="submit"
                label="送信"
                class="ms-2"
                :disabled="hasSame"
                @click="submit"
              />
            </div>
          </template>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import {
  gKana,
  gPhoneNumber,
  gPostcode,
  gRegistrationNumber,
} from "/@/modules/groomer";
import { userLabelTranslater, userPositionTranslater } from "/@/modules/user";
import {
  useRouterUtil,
  useUser,
  useSystemAdmin,
  useOrganizations,
  useZodScheme,
} from "/@/vue/composables";
import {
  Label as FormLabel,
  BasicButton,
  InputForm,
} from "/@/vue/components/Atom";
import { UserPageActions } from "/@/vue/components/Layouts";
import { Show as UserShow } from "/@/vue/components/Molecules/Users";
import { Form as UserForm } from "/@/vue/components/Organisms/Users";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Badge from "primevue/badge";
import SelectButton from "primevue/selectbutton";
import {
  UserClient,
  ManageUserCheckScheme,
  ManageUserForm,
  userLabelTypes,
  userPositionTypes,
  UserLabel,
  OrganizationMember,
  OrganizationClient,
} from "/@/types";

const { goto } = useRouterUtil();

const {
  getUser,
  isOwner,
  isOffice,
  isUserManager,
  isAllLManager,
  isAManager,
  isBManager,
  isCManager,
  isABCManager,
} = useUser();
const { data: user } = getUser();

const { getAllUsers, updateUser } = useSystemAdmin();
const { data: users, mutate: getAllUsersMutate } = getAllUsers();

const visible = ref(false);
const selectedUser = ref<UserClient | null>();

function selectUser(user: UserClient) {
  visible.value = true;
  selectedUser.value = user;

  form.userLabels = [...user.userLabels];
}

function closeDialog() {
  visible.value = false;
  selectedUser.value = null;
}

// position

function selectablePositionTypes(user: UserClient) {
  if (isAllLManager.value) {
    return userPositionTypes;
  }

  if (isAManager && user.organizations.some((o) => !o.branch && !o.committee)) {
    return [
      "senior_advisor",
      "chairman",
      "vice_chairman",
      "director",
      "advisor",
    ];
  }

  if (isBManager && user.organizations.some((o) => o.branch)) {
    return ["committee_chairman", "committee_vice_chairman"];
  }

  if (isCManager && user.organizations.some((o) => o.committee)) {
    return [
      "branch_manager",
      "branch_submanager",
      "secretary_general",
      "secretariat",
      "accountant",
    ];
  }

  return [];
}

// organizations

const { getOrganizations } = useOrganizations();
const { data: organizations } = getOrganizations();

const associationOrganizationId = computed(() => {
  if (!organizations.value) {
    return undefined;
  }

  return organizations.value.find((o) => !o.branch)?.id;
});

const branchOrganizations = computed(() => {
  if (!organizations.value) {
    return [];
  }

  return organizations.value.filter((o) => !!o.branch);
});

const committeeOrganizations = computed(() => {
  if (!organizations.value) {
    return [];
  }

  return organizations.value.filter((o) => o.committee);
});

// filter

const searchName = ref("");
const selectedBranchIds = ref<number[]>([]);
const selectedCommitteeIds = ref<number[]>([]);

const filteredUsers = computed(() => {
  if (!users.value) {
    return [];
  }

  return users.value.filter((u: UserClient) => {
    return (
      (!searchName.value ||
        (u.lastName + u.firstName).includes(searchName.value) ||
        (u.lastNameKana + u.firstNameKana).includes(searchName.value)) &&
      (selectedBranchIds.value.length === 0 ||
        u.joinOrganizations.some((o: OrganizationClient) =>
          selectedBranchIds.value.includes(o.id)
        )) &&
      (selectedCommitteeIds.value.length === 0 ||
        u.joinOrganizations.some((o: OrganizationClient) =>
          selectedCommitteeIds.value.includes(o.id)
        ))
    );
  });
});

// label form

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<ManageUserForm>(
  ManageUserCheckScheme,
  {
    userLabels: [],
    userPositions: [],
    organizationMembers: [],
  }
);

const branchLabels = ["c_manager", "c_poster"];
const committeeLabels = ["b_manager", "b_poster"];
const needDepsLabels = [...branchLabels, ...committeeLabels];
const associationDepsLabels = ["a_manager"];

function hasSameLabels(uls: UserLabel[]) {
  // return true when uls has same label
  // same means labelType and dependancyType are same but ignore same index

  return uls.some((ul, idx) => {
    const same = uls.find(
      (u, i) =>
        i !== idx &&
        u.labelType === ul.labelType &&
        u.dependancyType === ul.dependancyType &&
        u.dependancyId === ul.dependancyId
    );

    return !!same;
  });
}

function hasSameOrganizationMembers(oms: OrganizationMember[]) {
  // return true when oms has same organization
  // same means organizationId are same but ignore same index

  return oms.some((om, idx) => {
    const same = oms.find(
      (o, i) => i !== idx && o.organizationId === om.organizationId
    );

    return !!same;
  });
}

const hasSame = computed(() => {
  if (form.userLabels.length === 0 && form.organizationMembers.length === 0) {
    return false;
  }

  return (
    hasSameLabels(form.userLabels) ||
    hasSameOrganizationMembers(form.organizationMembers)
  );
});

watch(selectedUser, (u) => {
  if (!u) {
    return;
  }

  form.special = u.special;
  form.firstName = u.firstName;
  form.lastName = u.lastName;
  form.firstNameKana = u.firstNameKana;
  form.lastNameKana = u.lastNameKana;
  form.email = u.email;
  form.birthday = u.birthday;
  form.phoneNumber = u.phoneNumber;
  form.postcode = u.postcode;
  form.address = u.address;
  form.haveAffiliation = !!u.affiliation;
  form.affiliation = u.affiliation;
  form.affiliationPhoneNumber = u.affiliationPhoneNumber;
  form.affiliationPostcode = u.affiliationPostcode;
  form.affiliationAddress = u.affiliationAddress;
  form.business = u.business;
  form.registrationNumber = u.registrationNumber;
  form.userLabels = [...u.userLabels];
  form.userPositions = [...u.userPositions];
  form.organizationMembers = u.organizationMembers.map((om) => ({
    id: om.id,
    organizationId: om.organizationId,
  }));
});

watch(form, (f) => {
  // form を watch する場合は、まとめて変更した時の処理を書けるが、
  // 無駄な比較処理が増えるのと過去の値へのアクセスができない問題がある。
  // そのため個別のプロパティでの変更があった時のような処理が書けない

  if (f.firstName && f.firstName.length > 50) {
    form.firstName = f.firstName.slice(0, 50);
  }

  if (f.lastName && f.lastName.length > 50) {
    form.lastName = f.lastName.slice(0, 50);
  }

  if (f.firstName && f.firstName.length > 50) {
    form.firstName = f.firstName.slice(0, 50);
  }

  if (f.firstNameKana && f.firstNameKana.length > 50) {
    form.firstNameKana = gKana(f.firstNameKana.slice(0, 50));
  }

  if (f.lastNameKana && f.lastNameKana.length > 50) {
    form.lastNameKana = gKana(f.lastNameKana.slice(0, 50));
  }

  if (f.postcode) {
    form.postcode = gPostcode(f.postcode);
  }

  if (f.address && f.address.length > 150) {
    form.address = f.address.slice(0, 150);
  }

  if (f.phoneNumber) {
    form.phoneNumber = gPhoneNumber(f.phoneNumber);
  }

  if (f.affiliation && f.affiliation.length > 50) {
    form.affiliation = f.affiliation.slice(0, 50);
  }

  if (f.affiliationPostcode) {
    form.affiliationPostcode = gPostcode(f.affiliationPostcode);
  }

  if (f.affiliationAddress && f.affiliationAddress.length > 150) {
    form.affiliationAddress = f.affiliationAddress.slice(0, 150);
  }

  if (f.affiliationPhoneNumber) {
    form.affiliationPhoneNumber = gPhoneNumber(f.affiliationPhoneNumber);
  }

  if (f.registrationNumber) {
    form.registrationNumber = gRegistrationNumber(f.registrationNumber);
  }

  if (f.business && f.business.length > 50) {
    form.business = f.business.slice(0, 50);
  }
});

startValidation.value = true;

// up

function addPosition() {
  form.userPositions.push({
    positionType: undefined,
  });
}

const disabledAddPosition = computed(() => {
  return form.userPositions.some((up) => up.positionType === undefined);
});

function deletePosition(idx: number) {
  form.userPositions.splice(idx, 1);
}

// ul

function addRole() {
  form.userLabels.push({
    labelType: "c_manager",
    dependancyType: undefined,
    dependancyName: undefined,
  });
}

const disabledAddRole = computed(() => {
  return form.userLabels.some(
    (ul) =>
      !ul.dependancyName &&
      ul.labelType === "c_manager" &&
      ul.dependancyType === undefined
  );
});

function deleteRole(idx: number) {
  form.userLabels.splice(idx, 1);
}

function updateLabelType(ul: UserLabel, value: string) {
  if (needDepsLabels.includes(value)) {
    ul.dependancyType = undefined;
  } else if (associationDepsLabels.includes(value)) {
    ul.dependancyType = "Organization";
    ul.dependancyId = associationOrganizationId.value;
  } else {
    ul.dependancyType = undefined;
    ul.dependancyId = undefined;
  }
}

// om

function addOrganizationMember() {
  form.organizationMembers.push({
    organizationId: undefined,
  });
}

const disabledAddOrganizationMember = computed(() => {
  return form.organizationMembers.some((om) => !om.organizationId);
});

function deleteOrganizationMember(idx: number) {
  form.organizationMembers.splice(idx, 1);
}

async function accept() {
  if (!selectedUser.value) {
    return;
  }

  if (await updateUser(selectedUser.value.id, { beforeCheckManager: false })) {
    window.alert("承認に成功しました。");
    getAllUsersMutate();
    closeDialog();
  } else {
    window.alert("承認に失敗しました。");
  }
}

async function submit() {
  if (!selectedUser.value) {
    return;
  }

  form.userLabels = form.userLabels.filter((ul) => ul.labelType);
  form.userPositions = form.userPositions.filter((up) => up.positionType);
  form.organizationMembers = form.organizationMembers.filter(
    (om) => om.organizationId
  );

  try {
    const prms = ManageUserCheckScheme.parse(form);

    if (await updateUser(selectedUser.value.id, prms)) {
      window.alert("更新に成功しました。");
      getAllUsersMutate();
      closeDialog();
    } else {
      window.alert("更新に失敗しました。");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
