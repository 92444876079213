<template>
  <div>
    <template v-if="error">
      <span>ページがみつかりません</span>
    </template>
    <template v-else>
      <UserPageActions>
        <div class="d-flex justify-content-end w-100">
          <BasicButton
            label="編集"
            @click="goto({ name: 'UsersOrganizationsEdit' })"
          />
        </div>
      </UserPageActions>
      <i v-if="!organization" class="pi pi-spinner spinner"></i>
      <template v-else-if="organization">
        <ShowFormItemWrapper title="名前">
          <span>{{ organization.name }}</span>
        </ShowFormItemWrapper>
        <ShowFormItemWrapper title="問い合わせ先住所">
          <div>
            <p v-if="!organization.postcode && !organization.address">未設定</p>
            <template v-else>
              <p>
                {{
                  organization.postcode
                    ? `〒${organization.postcode}`
                    : "未設定"
                }}
              </p>
              <p>{{ organization.address || "未" }}</p>
            </template>
          </div>
        </ShowFormItemWrapper>
        <ShowFormItemWrapper title="問い合わせ先電話番号">
          <span>{{ organization.phoneNumber || "未設定" }}</span>
        </ShowFormItemWrapper>
        <ShowFormItemWrapper title="組織・役員">
          <div v-if="!organization.constituentMembers.length">未設定</div>
          <template v-else>
            <div class="d-flex flex-column w-100">
              <template
                v-for="(group, gidx) in organization.constituentMembers"
                :key="gidx"
              >
                <div class="mb-3">
                  <Chip :label="group.role" />
                  <div class="d-flex flex-column">
                    <template
                      v-for="(member, midx) in group.members"
                      :key="midx"
                    >
                      <span>{{ member.name }}</span>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </ShowFormItemWrapper>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouterUtil, useOrganizations } from "/@/vue/composables";
import { ShowFormItemWrapper } from "/@/vue/components/Molecules/Form";
import { UserPageActions } from "/@/vue/components/Layouts";
import { BasicButton } from "/@/vue/components/Atom";
import Chip from "primevue/chip";

const { currentRouteParams, goto } = useRouterUtil();
const id = computed(() => {
  return currentRouteParams.value?.id;
});

const { getOrganization } = useOrganizations();
const { data: organization, error } = getOrganization(id);
</script>

<style scoped></style>
