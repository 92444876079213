import Index from "./Index.vue";
import Show from "./Show.vue";

export default [
  {
    path: "",
    name: "NewsIndex",
    component: Index,
    meta: {
      title: "お知らせ",
      subtitle: "NEWS",
      tree: [
        {
          title: "お知らせ",
        },
      ],
    },
  },
  {
    path: ":id",
    name: "NewsShow",
    component: Show,
    meta: {
      title: "詳細",
      subtitle: "NEWS",
      tree: [
        {
          title: "お知らせ",
          name: "NewsIndex",
        },
        {
          title: "詳細",
        },
      ],
    },
  },
];
