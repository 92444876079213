<template>
  <div>
    <DataTable
      ref="dt"
      :value="users"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 40rem'"
      selection-mode="single"
      style="font-size: 0.8rem"
      @row-select="$emit('select', $event.data)"
    >
      <Column
        field="targetDate"
        header="勤務開始日"
        :style="
          isMobile
            ? { width: '80px', 'min-width': '80px' }
            : { width: '80px', 'min-width': '80px' }
        "
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.targetDate, "withoutWdayYear") }}</span>
        </template>
      </Column>
      <Column
        field="targetDate"
        header="勤務終了日"
        :style="
          isMobile
            ? { width: '80px', 'min-width': '80px' }
            : { width: '80px', 'min-width': '80px' }
        "
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.targetDate, "withoutWdayYear") }}</span>
        </template>
      </Column>
      <Column
        :field="(data) => data.lastName + data.firtsName"
        header="氏名"
        :style="isMobile ? { width: '80px' } : { width: '80px' }"
      >
        <template #body="{ data }">
          <span>{{ data.lastName }}{{ data.firstName }}</span>
        </template>
      </Column>
      <Column
        field="area"
        header="調査エリア"
        :style="isMobile ? { width: '80px' } : { width: '80px' }"
      >
        <template #body="{ data }">
          <span>{{ data.area }}</span>
        </template>
      </Column>
      <Column
        field="workType"
        header="勤務種別"
        :style="isMobile ? { width: '80px' } : { width: '80px' }"
      >
        <template #body="{ data }">
          <span>{{ data.workType }}</span>
        </template>
      </Column>
      <Column
        field="birthday"
        header="生年月日"
        :style="isMobile ? { width: '100px' } : { width: '100px' }"
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.birthday, "withoutWday") }}</span>
        </template>
      </Column>
      <Column field="email" header="メールアドレス">
        <template #body="{ data }">
          <span>{{ data.email }}</span>
        </template>
      </Column>
      <Column
        field="phoneNumber"
        header="電話番号"
        :style="isMobile ? { width: '80px' } : { width: '80px' }"
      >
        <template #body="{ data }">
          <span>{{ data.phoneNumber }}</span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils } from "/@/vue/composables";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { Invest24UserAttachedInDayInfo } from "/@/types";

const { isMobile } = useMqUtils();

defineProps<{
  area: string;
  users: (Invest24UserAttachedInDayInfo & { targetDate: string })[];
}>();

defineEmits<{
  (e: "select", user: Invest24UserAttachedInDayInfo): void;
  (e: "detach", invest24JoinUserId: number): void;
}>();

const dt = ref<DataTable>();

function exportCSV() {
  dt.value?.exportCSV();
}

defineExpose({ exportCSV });
</script>

<style scoped></style>
