<template>
  <div>
    <div
      v-if="!isOpenSite && !hideSelectArea && recruitmentType === 'invest24'"
      class="mb-3"
    >
      <FormLabel label="エリア選択(半透明のものは募集がありません)" />
      <SelectButton
        v-model="selectedArea"
        :options="areasWithDisabled"
        option-label="label"
        option-value="value"
        option-disabled="disabled"
        :allow-empty="false"
      />
    </div>
    <div
      v-else-if="!hideSelectArea && recruitmentType === 'invest24'"
      class="d-flex justify-content-center"
    >
      <AreaFilter v-model="selectedArea" :areas="areasWithDisabled" />
    </div>

    <BasicCalendar
      :events="events"
      :jp-holidays="jpHolidays || []"
      :exists-dates="existsDates"
      @event-click="eventClick"
      @date-click="dateClick"
      @update:selected-date="updateSelectedDate"
    />

    <RecruitmentModal
      v-model:visible="visibleRecruitmentModal"
      v-model:target-date="targetDate"
      :loading="loading"
      :recruitment="selectedRecruitment"
      :invest24-users="invest24Users"
      :users="users"
      :is-v-manager="isVManager"
      @apply-recruitment="handleApplyRecruitment"
      @cancel-recruitment="handleCancelRecruitment"
      @destroy-recruitment="handleDestroyRecruitment"
      @allocate-user="handleAllocateUser"
      @allocate-new-user="$emit('allocateNewUser', $event)"
      @detach-user="handleDetachUser"
      @after-hide="clear"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import {
  isJoined,
  recruitmentFilter,
  getAreasWithDisabled,
} from "/@/modules/invest24Recruitment";
import { EventApi, EventClickArg, DateSelectArg } from "@fullcalendar/core";
import { luxonNow, isAfterToday, fromISO } from "/@/modules/luxon";
import { Label as FormLabel } from "/@/vue/components/Atom";
import { BasicCalendar } from "/@/vue/components/Molecules";
import { AreaFilter } from "/@/vue/components/Molecules/Invest24Recruitments";
import { Modal as RecruitmentModal } from "/@/vue/components/Molecules/Invest24Recruitments";
import SelectButton from "primevue/selectbutton";
import {
  DateMap,
  Invest24RecruitmentClient,
  Invest24RecruitmentDayInfoClient,
  Invest24RecruitmentType,
  Invest24UserClient,
  Invest24WorkType,
  UserClient,
} from "/@/types";
import { isContainInDateMap } from "/@/modules/datemap";
import DayInfoUserTable from "../../Organisms/Users/Invest24RecruitmentDayInfos/DayInfoUserTable.vue";

const props = defineProps<{
  recruitments: Invest24RecruitmentClient[];
  jpHolidays: Record<string, string>;
  invest24Users: Invest24UserClient[];
  users: UserClient[];
  loading?: boolean;
  hideSelectArea?: boolean;
  isVManager?: boolean;
  recruitmentType?: Invest24RecruitmentType;
  isOpenSite?: boolean;
}>();

const emit = defineEmits<{
  (e: "applyRecruitment", id: number, targetDate: string): void;
  (e: "cancelRecruitment", id: number, targetDate: string): void;
  (e: "destroyRecruitment", id: number): void;
  (
    e: "detachUser",
    id: number,
    invest24UserId: number,
    targetDate: string
  ): void;
  (
    e: "allocateUser",
    id: number,
    prms: {
      targetDate: string;
      workType: Invest24WorkType;
      invest24UserId?: number;
      userId?: number;
    }
  ): void;
  (e: "allocateNewUser", prms: { id: number; targetDate: string }): void;
  (e: "updateSelectedDate", dateMap: DateMap): void;
}>();

//

const today = luxonNow().startOf("day");

const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
  day: today.toISO(),
});

function updateSelectedDate(dateMap: DateMap) {
  selectedDate.year = dateMap.year;
  selectedDate.month = dateMap.month;
  selectedDate.day = dateMap.day;

  emit("updateSelectedDate", selectedDate);
}

const existsDates = computed<string[]>(() => {
  if (!props.recruitments) return [];

  return props.recruitments
    .map((r) => {
      return r.invest24RecruitmentDayInfos.map((dayInfo) => dayInfo.targetDate);
    })
    .flat();
});

watch(existsDates, (eds) => {
  if (!eds.some((ed) => isContainInDateMap(ed, selectedDate))) {
    const firstDate = eds.at(0);
    if (!firstDate) return;

    const targetDate = fromISO(firstDate);

    if (!targetDate) return;

    selectedDate.year = targetDate.year;
    selectedDate.month = targetDate.month;
  }
});

const selectedRecruitmentId = ref<number>();
const selectedRecruitment = computed(() => {
  if (!props.recruitments) return;

  return props.recruitments.find((r) => r.id === selectedRecruitmentId.value);
});
const targetDate = ref<string>();

// area

const selectedArea = ref<string>(
  props.recruitmentType === "invest24" ? "能登町" : "その他"
);

watch(
  () => props.recruitmentType,
  (rt) => {
    selectedArea.value = rt === "invest24" ? "能登町" : "その他";
  }
);

// modal

const visibleRecruitmentModal = ref(false);

// events

const areasWithDisabled = computed(() => {
  return getAreasWithDisabled(props.recruitments).map((awd) => {
    return {
      ...awd,
      disabled: ["七尾"].includes(awd.value) ? true : awd.disabled,
    };
  });
});

const filteredRecruitments = computed(() => {
  const recruitments = props.recruitments;

  if (!recruitments) return [];

  return recruitments.filter((r) => {
    return recruitmentFilter(
      r,
      props.hideSelectArea ? undefined : selectedArea.value,
      selectedDate
    );
  });
});

function eventColor(
  recruitment: Invest24RecruitmentClient,
  dayInfo: Invest24RecruitmentDayInfoClient
) {
  if (dayInfo.currentInvest24JoinUsersCount >= dayInfo.needCount) {
    return "lightgray";
  }

  if (isAfterToday(dayInfo.targetDate)) {
    return "inherit";
  }

  return "lightgray";
}

function eventTitle(
  recruitment: Invest24RecruitmentClient,
  dayInfo: Invest24RecruitmentDayInfoClient,
  user?: Invest24UserClient
) {
  if (props.isVManager) {
    return `${dayInfo.currentInvest24JoinUsersCount}/${dayInfo.needCount}`;
  }

  const restCount = dayInfo.needCount - dayInfo.currentInvest24JoinUsersCount;

  return isJoined(user, recruitment, dayInfo.targetDate)
    ? "済"
    : restCount === 0
    ? "満員"
    : `残${restCount}`;
}

const events = computed(() => {
  return filteredRecruitments.value
    .map((r) => {
      // trim after T
      return r.invest24RecruitmentDayInfos.map((dayInfo) => {
        if (!props.isVManager) {
          /*
          if (dayInfo.currentInvest24JoinUsersCount >= dayInfo.needCount) {
            return;
          }
          */

          if (!isAfterToday(dayInfo.targetDate)) {
            return;
          }
        }

        return {
          id: `${r.id}-${dayInfo.targetDate}`,
          title: eventTitle(r, dayInfo),
          backgroundColor: eventColor(r, dayInfo),
          textColor: "black",
          borderColor: "rgba(0,0,0,0)",
          start: dayInfo.targetDate,
          allDay: true,
          extendedProps: {
            eventType: "Recruitment",
            ...r,
          },
        };
      });
    })
    .flat()
    .filter((b) => b);
});

function eventClick(event: EventApi, eventClickInfo: EventClickArg) {
  if (event.extendedProps.eventType === "Recruitment") {
    if (!isAfterToday(event.startStr)) return;

    selectedRecruitmentId.value = event.extendedProps.id;
    targetDate.value = event.startStr;
    visibleRecruitmentModal.value = true;
  }
}

function dateClick(startStr: string, dateSelectArg: DateSelectArg) {
  // @ts-ignore
  const td = dateSelectArg.dateStr;

  if (!isAfterToday(td)) return;

  const recruitment = filteredRecruitments.value.find((r) => {
    return r.invest24RecruitmentDayInfos.some(
      (dayInfo) => dayInfo.targetDate === td
    );
  });

  if (!recruitment) return;

  selectedRecruitmentId.value = recruitment?.id;
  targetDate.value = td;
  visibleRecruitmentModal.value = true;
}

function clear() {
  selectedRecruitmentId.value = undefined;
  targetDate.value = undefined;
}

// actions

async function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function handleApplyRecruitment() {
  if (!selectedRecruitmentId.value || !targetDate.value) return;

  emit("applyRecruitment", selectedRecruitmentId.value, targetDate.value);
  await wait(3000);
  visibleRecruitmentModal.value = false;
}

async function handleCancelRecruitment() {
  if (!selectedRecruitmentId.value || !targetDate.value) return;

  emit("cancelRecruitment", selectedRecruitmentId.value, targetDate.value);
  await wait(3000);
  visibleRecruitmentModal.value = false;
}

async function handleDestroyRecruitment() {
  if (!selectedRecruitmentId.value) return;

  emit("destroyRecruitment", selectedRecruitmentId.value);
  await wait(3000);
  visibleRecruitmentModal.value = false;
}

async function handleDetachUser(invest24UserId: number) {
  if (!selectedRecruitmentId.value || !targetDate.value) return;

  emit(
    "detachUser",
    selectedRecruitmentId.value,
    invest24UserId,
    targetDate.value
  );
}

async function handleAllocateUser(
  prms:
    | {
        invest24UserId: number;
        workType: Invest24WorkType;
      }
    | { userId: number; workType: Invest24WorkType }
) {
  if (!selectedRecruitmentId.value || !targetDate.value) return;

  if (prms.invest24UserId && prms.workType) {
    emit("allocateUser", selectedRecruitmentId.value, {
      invest24UserId: prms.invest24UserId,
      workType: prms.workType,
      targetDate: targetDate.value,
    });
  } else if (prms.userId && prms.workType) {
    emit("allocateUser", selectedRecruitmentId.value, {
      userId: prms.userId,
      workType: prms.workType,
      targetDate: targetDate.value,
    });
  }
}
</script>

<style lang="scss">
.fc-event-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-h-event .fc-event-title {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 0.8rem;
  // padding-top: 0.3rem;
}
</style>
