<template>
  <div id="index" class="w-100">
    <div class="d-flex flex-column">
      <div
        class="d-flex flex-column justify-content-center align-items-center p-3 flex-glow-1"
        :style="{
          margin: isMobile ? '-1rem 0 0' : '-1rem -3rem 0',
          'min-height': 'calc(100vh - 144px)',
        }"
      >
        <template v-if="false">
          <IndexMenuItem :menu-items="filteredMenuItems" />
          <hr />
          <IndexMenuItem :menu-items="userMenuItems" />
        </template>
        <div
          class="h-100 d-flex align-items-center justify-content-center with-grass flex-grow-1"
          :class="{
            'flex-column': isMobile,
            'is-mobile': isMobile,
            'p-3': isMobile,
          }"
          style="background-color: #f8f4ee; border-radius: 3rem 3rem 0 0"
          :style="{
            width: isMobile ? '100%' : '90%',
          }"
        >
          <div
            class="d-flex flex-column"
            style="z-index: 100"
            :style="{
              order: isMobile ? '2' : '1',
              'margin-top': isMobile ? '20px' : '0',
              'padding-bottom': isMobile ? '65px' : '0',
            }"
          >
            <div>
              <h1
                class="pb-2"
                style="
                  border-bottom: 2px dashed #c2ba9f;
                  z-index: 101;
                  display: inline-block;
                "
                :style="{
                  'font-size': isMobile ? '' : '400%',
                }"
              >
                石川を支える
              </h1>
            </div>

            <h1
              class="py-2"
              :class="{
                'ms-5': !isMobile,
                'mb-5': isMobile,
              }"
              style="border-bottom: 2px dashed #c2ba9f; z-index: 101"
              :style="{
                'max-width': isMobile ? '100%' : '700px',
                'font-size': isMobile ? '' : '400%',
              }"
            >
              介護支援専門員の連携
            </h1>

            <div
              v-if="!isMobile && false"
              class="w-100 d-flex align-items-center justify-content-center"
            >
              <img
                id="map"
                :src="topMap01"
                class="d-flex align-items-center justify-content-center mt-5"
                :class="{
                  'mt-5': !isMobile,
                  'mt-3': isMobile,
                  'mb-3': isMobile,
                }"
                style="height: 300px; width: auto"
              />
            </div>
          </div>

          <div
            class="d-flex align-items-center justify-content-center"
            :class="{
              'w-100': isMobile,
              'w-50': !isMobile,
            }"
            style="position: relative"
            :style="{
              height: '600px',
              order: isMobile ? '1' : '2',
              'max-height': isMobile ? '300px' : '600px',
              'max-width': isMobile ? '300px' : '600px',
              'margin-left': isMobile ? '0' : '-200px',
            }"
          >
            <template v-for="(img, idx) in topImages" :key="`topimages` + idx">
              <img
                :src="img"
                :alt="`top_image` + (idx + 1)"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                  object-fit: cover;
                  object-position: 0%;
                  mask-image: radial-gradient(
                    rgb(255, 255, 255) 50%,
                    rgba(255, 255, 255, 0) 70%
                  );
                  opacity: 0;
                  animation-name: fade;
                  animation-duration: 12s;
                  animation-iteration-count: infinite;
                  -webkit-animation-name: fade;
                  -webkit-animation-duration: 12s;
                  -webkit-animation-iteration-count: infinite;
                "
                :style="{
                  'clip-path': isMobile ? 'circle(50%)' : 'circle(50%)',
                  height: '100%',
                  width: '100%',
                  'animation-delay': `${idx * 4}s`,
                  '-webkit-animation-delay': `${idx * 4}s`,
                }"
              />
            </template>
          </div>

          <div
            style="
              position: absolute;
              left: -30px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              z-index: 100;
            "
            :style="{
              'background-image': 'url(/img/top_deco03.png)',
              bottom: isMobile ? '-35px' : '10px',
              width: isMobile ? '180px' : '250px',
              height: isMobile ? '180px' : '250px',
            }"
          ></div>
        </div>
      </div>
    </div>

    <div
      class="d-flex flex-column align-items-center bg-grass-green"
      :style="{
        margin: isMobile ? '0 -1rem' : '0 -3rem',
        padding: '40px 0 0',
      }"
    >
      <div
        class="d-flex flex-wrap alig-items-center justify-content-center w-100"
        :class="{
          'p-3': isMobile,
        }"
        style="flex-grow: 1; color: white; margin-bottom: 100px"
      >
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style="min-height: 100%"
          :style="{
            'margin-right': isMobile ? '0' : '160px',
            'margin-bottom': isMobile ? '50px' : '0',
          }"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center"
            :class="{
              'w-100': isMobile,
            }"
          >
            <div
              style="font-size: 1rem"
              class="d-flex align-items-center"
              :class="{ 'flex-column': isMobile }"
            >
              <Triangles />

              <span>NEWS</span>
            </div>
            <span style="font-size: 1.75rem">お知らせ</span>
          </div>

          <div v-if="!isMobile" class="mt-4">
            <router-link :to="{ name: 'NewsIndex' }">
              <i class="pi pi-bars me-1"></i>
              <span>一覧を見る</span>
            </router-link>
          </div>
        </div>

        <div class="d-flex flex-column w-100" style="max-width: 800px">
          <div v-if="pinnedNews.length" class="mb-4">
            <NewsList
              :news="pinnedNews"
              :organizations="organizations || []"
              @select-news="handleSelectNews"
              reverse-color
              index-mode
              :show-filter="false"
            />
          </div>

          <NewsList
            :news="latestNews"
            :organizations="organizations || []"
            @select-news="handleSelectNews"
            reverse-color
            index-mode
            :show-filter="false"
          />
        </div>
      </div>
      <div
        style="background-color: white"
        :style="{
          'margin-left': isMobile ? '0' : '150px',
          width: isMobile ? '100%' : 'calc(100% - 150px)',
          'border-radius': isMobile ? '0' : '100px 0 0 0',
        }"
      >
        <div
          id="information"
          style="margin-top: -5rem; margin-bottom: 5rem"
        ></div>
        <div
          class="d-flex flex-column w-100 justify-content-center align-items-center with-grass"
          style="
            background-position: center calc(100% + 50px);
            position: relative;
          "
          :style="{
            padding: isMobile ? '3rem 1rem 10rem' : '100px 150px 150px 0',
          }"
        >
          <div class="d-flex flex-column align-items-center w-100">
            <Triangles class="text-green" />
            <span class="text-green">INFORMATION</span>

            <div
              class="d-flex flex-column justify-content-center align-items-center mt-3"
            >
              <div
                class="d-flex flex-wrap align-items-center justify-content-center"
              >
                <h2 class="mb-3">介護が必要な方と</h2>
                <h2 class="mb-3">その家族を支えるため</h2>
              </div>

              <h2>日々活動しています</h2>
            </div>

            <div
              class="d-flex align-items-center justify-content-center flex-wrap w-100 pt-5"
            >
              <div
                class="d-flex flex-column w-100"
                :class="{
                  'p-5': !isMobile,
                }"
                style="max-width: 600px"
                :style="{
                  order: isMobile ? '2' : '1',
                }"
              >
                <span> 石川県介護支援専門員協会は </span>
                <div class="d-flex flex-wrap align-items-center">
                  <span> 石川県において介護支援専門員の連携、 </span>
                  <span> 知識・技術の研鑽 </span>
                </div>

                <span>
                  及び人格資質の向上に努め、介護支援事業の発展を図り、
                </span>
                <span> すべての県民が安心して老後を過ごすことのできる </span>
                <span> 社会の実現に寄与することを目的としています。 </span>
                <div class="d-flex align-items-center justify-content-end mt-4">
                  <MoreReadButton
                    label="もっと見る"
                    color="green"
                    @click="goto({ name: 'About' })"
                  />
                </div>
              </div>
              <div
                class="w-100 p-5"
                style="
                  height: 100%;
                  min-height: 300px;
                  max-width: calc(500px + 48px * 2);
                  background-image: url(/img/bg_top_catch.jpg);
                  background-repeat: no-repeat;
                "
                :style="{
                  order: isMobile ? '1' : '2',
                }"
              ></div>
              <div
                style="
                  position: absolute;
                  bottom: 0;
                  right: 30px;
                  width: 180px;
                  height: 180px;
                  background-image: url(/img/top_deco04.png);
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                  z-index: 100;
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column align-items-center justify-content-center"
      style="background-position: center 115%; background-color: #f2eee9"
      :style="{
        padding: isMobile ? '50px 0 75px' : '100px 0 150px',
        margin: isMobile ? '0 -1rem' : '0 -3rem',
      }"
    >
      <div
        class="d-flex flex-column align-items-center justify-content-center with-opensite-shadow"
        style="background-color: white; padding: 32px; border-radius: 20px"
        :style="{
          width: isMobile ? '90%' : 'auto',
        }"
      >
        <Triangles class="text-green" />
        <span class="text-green">BRANCH NEWS</span>

        <h2 class="mt-3">各支部からのお知らせ</h2>
        <h4 v-if="false" class="mt-4 text-orange">
          <i class="pi pi-wifi"></i> 私たちの活動を発信
          <i class="pi pi-wifi"></i>
        </h4>
      </div>

      <div
        class="d-flex justify-content-center flex-wrap mt-5 w-100 contents-1200"
      >
        <div
          v-for="b in branchs"
          :key="b.id"
          class="d-flex align-items-center justify-content-center"
          :class="{
            'm-4': !isMobile,
            'w-100': isLessThanMd,
            'm-2': isMobile,
          }"
        >
          <div
            class="d-flex flex-column w-100 with-opensite-shadow w-100"
            style="
              height: 350px;
              padding: 32px;
              border-radius: 20px;
              background-color: white;
            "
            :style="{
              'max-width': isLessThanMd ? 'calc(90% + 1rem)' : '350px',
            }"
          >
            <div style="flex-grow: 1">
              <h4 class="mb-3" :style="{ color: b.color }">{{ b.name }}</h4>
              <router-link
                v-if="b.news && b.news.length"
                :to="{
                  name: 'NewsShow',
                  params: { id: b.news[0].id },
                  query: { organizationId: b.id },
                }"
                class="h-100"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <span class="text-green" style="font-size: 0.8rem">{{
                    basicFormatter(b.news[0].publishedAt)
                  }}</span>
                  <NewsLabelChip
                    :label="labelTranslater(b.news[0].label)"
                    reverse-color
                    class="me-2"
                  />
                </div>

                <h5
                  class="text-green mt-3 pb-3"
                  style="border-bottom: 2px dashed #e1dbc7"
                >
                  {{
                    b.news[0].title.length > 20
                      ? b.news[0].title.slice(0, 20) + "..."
                      : b.news[0].title
                  }}
                </h5>
                <div class="mt-2">
                  {{
                    stripHtml(b.news[0].content).length > 40
                      ? stripHtml(b.news[0].content).slice(0, 40) + "..."
                      : stripHtml(b.news[0].content)
                  }}
                </div>
              </router-link>
              <span v-else> 新着記事はありません </span>
            </div>

            <div class="d-flex align-items-center justify-content-end">
              <MoreReadButton
                @click="
                  goto({
                    name: 'NewsIndex',
                    query: { organizationId: b.id },
                  })
                "
                label="もっと見る"
                :color-str="b.color"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center bg-grass-green text-white"
      :class="{
        'flex-column': isMobile,
      }"
      style="
        background-image: url(/img/top_image3.jpeg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
      "
      :style="{
        padding: isMobile ? '1rem 1rem 200px' : '0 100px 200px',
        margin: isMobile ? '0 -1rem' : '0 -3rem',
      }"
      id="recruit"
    >
      <div
        class="w-100"
        style="z-index: 1; backdrop-filter: blur(2px); padding: 1rem"
        :style="{
          margin: isMobile ? '100px 0 0' : '200px 0 0',
        }"
      >
        <div class="d-flex align-items-center w-100">
          <Triangles />
          <span>RECRUIT INFO</span>
        </div>

        <div class="mt-3 mb-5 w-100">
          <h2>県協会への入会</h2>
        </div>

        <div class="mb-3">
          <p class="mb-2">
            　当協会は平成12年の5月、全国でもいち早く介護支援専門員のための組織として発足しました。
          </p>
          <p class="mb-2">
            　平成18年11月には、日本介護支援専門員協会が発足、全国的な活動へと広がり、今日では、富山・福井県と共に北陸ブロックとしての活動も始まっています。
          </p>
          <p class="mb-2">
            　私たち介護支援専門員は、介護が必要な方を支えるということだけでなく、その家族を含めた多くの方々の生活を支えています。そして、その役割は今後ますます重要になることが予測されることから、その期待に応えるべく常にケアマネジメントの確立と専門性の向上を目指していきたいと考えています。
          </p>
          <p class="mb-2">
            　当協会の特徴は、様々な職種が活動に参加しているという点であり、活動を通して、多くの刺激を受けることができます。今後も会員の皆さんのために、研修の充実はもちろん必要な情報の提供、会員同士や他職種との交流などを積極的に進めていきたいと考えています。
          </p>
          <p class="mb-2">　是非、皆さんの活動への参加をお願いいたします。</p>
        </div>

        <div class="d-flex align-items-center justify-content-start mt-5">
          <MoreReadButton
            @click="goto({ name: 'Join' })"
            label="入会案内"
            color="orange"
          />
        </div>
      </div>
      <div
        v-if="!isMobile && false"
        class="w-100"
        style="
          z-index: 0;
          height: 400px;
          background-image: url(/img/top_image3.jpeg);
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          mask-image: radial-gradient(
            rgb(255, 255, 255) 50%,
            rgba(255, 255, 255, 0) 75%
          );
        "
        :style="{
          'margin-left': isMobile ? '0' : '-100px',
          'margin-top': isMobile ? '-200px' : '300px',
        }"
      ></div>
    </div>
    <div
      style="
        height: 300px;
        background-image: linear-gradient(
          180deg,
          rgba(166, 197, 101, 0),
          rgba(255, 255, 255, 1)
        );
      "
      :style="{
        margin: isMobile ? '-300px -1rem 0' : '-300px -3rem 0',
      }"
    ></div>

    <div
      class="d-flex flex-column align-items-center justify-content-center"
      style="padding-top: 100px"
      :style="{
        margin: isMobile ? '-200px -1rem 0' : '-200px -3rem 0',
      }"
    >
      <h3 class="">賛助会員様</h3>

      <div
        v-if="sponsors"
        style="
          margin-top: 50px;
          margin-bottom: 100px;
          width: 100%;
          overflow-x: hidden;
        "
        class="p-2"
      >
        <div
          class="d-flex align-items-center"
          style="
            -webkit-animation: loop-slide 30s infinite linear 1s both;
            animation: loop-slide 30s infinite linear 1s both;
            max-height: 200px;
          "
        >
          <a
            v-for="(m, idx) in [
              ...sponsors.members,
              ...sponsors.members,
              ...sponsors.members,
            ]"
            style="height: auto"
            :href="m.url"
            target="_blank"
            class="p-3 sponsor-item"
            :class="{ 'with-margin': idx % 2 === 1 }"
          >
            <img
              :src="m.imageBlob || m.imageUrl"
              :style="{
                width: m.name === '花王株式会社' ? '130px' : '230px',
                'max-width': '200px',
              }"
              :alt="m.name"
            />
          </a>
        </div>

        <div
          class="d-flex justify-content-center mb-3"
          style="margin-top: 150px"
        >
          <MoreReadButton
            label="賛助会員様一覧"
            color="green"
            @click="goto({ name: 'Sponsor' })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from "vue";
import {
  useNews,
  useRouterUtil,
  useMqUtils,
  useUser,
  useAuthorize,
  useOrganizations,
  useSponsors,
} from "/@/vue/composables";
import { useToast } from "primevue/usetoast";
import { basicFormatter } from "/@/modules/luxon";
import { labelTranslater } from "/@/modules/news";
import { stripHtml } from "/@/modules/string";
import { MoreReadButton } from "/@/vue/components/Atom/Buttons";
import { NewsLabelChip } from "/@/vue/components/Atom/Chips";
import { List as NewsList } from "/@/vue/components/Molecules/News";
import {
  MenuItems as IndexMenuItem,
  Triangles,
} from "/@/vue/components/Organisms/Index";

import topSlide01 from "../../../../public/img/top_slide01.webp";
import topImage01 from "../../../../public/img/top_image1.jpeg";
import topImage02 from "../../../../public/img/ishikawa_img_2.jpeg";
import topImage03 from "../../../../public/img/ishikawa_img_4.jpeg";
import topMap01 from "../../../../public/img/top_map.png";

const { isMobile, isLessThanMd } = useMqUtils();
const { goto, reload, currentRouteQuery, dropQuery } = useRouterUtil();

// toast

const toast = useToast();

function showConfirmSuccsess() {
  toast.add({
    severity: "success",
    summary: "メールの認証に成功しました",
    group: "br",
  });
}

function showConfirmPending() {
  toast.add({
    severity: "info",
    summary:
      "メールの認証が完了し、口座振替手続きに関するメールを送信しました。",
    detail:
      "入会金・年会費のための口座振替手続きに関する内容になるため必ずご確認下さい。",
    group: "br",
  });
}

function showRedirectedReason() {
  switch (currentRouteQuery.value.reason) {
    case "not_allowed":
      toast.add({
        severity: "error",
        summary: "アクセス権限がありません",
        detail: "ページにアクセスする権限が無いためリダイレクトされました",
        group: "br",
      });
      return;
    default:
      toast.add({
        severity: "error",
        summary: "リダイレクトされました",
        group: "br",
      });
  }
}

// top slide

const topImages = [topImage01, topImage02, topImage03];

// mounted

onMounted(() => {
  if (currentRouteQuery.value.reason) {
    showRedirectedReason();
  }

  if (currentRouteQuery.value.confirmation === "success") {
    showConfirmSuccsess();
  }

  if (currentRouteQuery.value.confirmation === "pending") {
    showConfirmPending();
  }

  setTimeout(() => {
    // dropQuery();
  }, 4000);
});

// user

const { getUser } = useUser();
const { data: user } = getUser();

const selectedOrganizationId = ref();

// menu

const tabMenuItems = [
  {
    label: "トップ",
    class: "w-100 text-center",
    tabKey: "top",
  },
  {
    label: "調べる",
    class: "w-100 text-center",
    tabKey: "search",
  },
  {
    label: "手続き",
    class: "w-100 text-center",
    tabKey: "application",
  },
  {
    label: "お問い合わせ",
    class: "w-100 text-center",
    tabKey: "inquiry",
  },
];

const selectedTabIdx = ref(0);

const selectedTabKey = computed(
  () => tabMenuItems[selectedTabIdx.value].tabKey
);

function createMenuItems(count: number, tabKey: string) {
  return Array.from({ length: count }, (_, i) => ({
    title: `メニュー${i + 1}`,
    icon: "pi pi-box",
    to: { name: "Index" },
    tabKey: tabKey,
  }));
}

const menuItems = [
  {
    title: "実態調査",
    icon: "pi pi-sun",
    to: { name: "Invest24Index" },
    tabKey: "top",
  },
  {
    title: "避難所",
    icon: "pi pi-shield",
    to: { name: "Shelter24Index" },
    tabKey: "top",
  },
  {
    title: "協会概要",
    icon: "pi pi-sitemap",
    to: { name: "About" },
    tabKey: "top",
  },
  {
    title: "県協会活動",
    icon: "pi pi-star",
    to: { name: "Activity" },
    tabKey: "top",
  },
  {
    title: "支部概要",
    icon: "pi pi-star-fill",
    to: { name: "BranchActivity" },
    tabKey: "top",
  },
  {
    title: "入会案内",
    icon: "pi pi-sign-in",
    to: { name: "Join" },
    tabKey: "top",
  },
  {
    title: "賛助会員様一覧",
    icon: "pi pi-users",
    to: { name: "Sponsor" },
    tabKey: "top",
  },
  {
    title: "新着お知らせ",
    icon: "pi pi-bell",
    to: { name: "NewsIndex" },
    tabKey: "top",
  },
  {
    title: "広報誌『わ』管理",
    icon: "pi pi-book",
    to: { name: "MagazinesIndex" },
    tabKey: "top",
  },
  {
    title: "お問い合わせ",
    icon: "pi pi-info-circle",
    to: { name: "Inquiry" },
    tabKey: "top",
  },
  //...createMenuItems(13, "top"),
  //...createMenuItems(18, "search"),
  //...createMenuItems(8, "application"),
  //...createMenuItems(4, "inquiry"),
];

const { logout } = useAuthorize();

const userMenuItems = computed(() => {
  if (user.value) {
    return [
      {
        title: "マイページ",
        icon: "pi pi-user",
        to: { name: "UsersIndex", params: { id: user.value.id } },
        tabKey: "top",
      },
      {
        title: "ログアウト",
        icon: "pi pi-sign-out",
        tabKey: "top",
        command: async () => {
          await logout();
          reload();
        },
      },
    ];
  } else {
    return [
      {
        title: "会員の方",
        icon: "pi pi-sign-in",
        to: { name: "SignIn" },
        tabKey: "top",
      },
    ];
  }
});

const filteredMenuItems = computed(() =>
  menuItems.filter((item) => item.tabKey === selectedTabKey.value)
);

// news

const { getNews } = useNews();
const { data: news } = getNews(selectedOrganizationId, { top: true });

const pinnedNews = computed(() => news.value?.filter((n) => n.nowPinned) || []);

const latestNews = computed(
  () =>
    news.value
      ?.filter((n) => !n.pinnedTo)
      .slice(0, 5 - pinnedNews.value.length) || []
);

function handleSelectNews(id: number) {
  goto({ name: "NewsShow", params: { id: id } });
}

// organizations

const { getOrganizations } = useOrganizations();
const { data: organizations } = getOrganizations({
  branch: true,
  withLatestNews: true,
});

const branchs = computed(() =>
  organizations.value?.filter((org) => !!org.branch)
);

// sponsors

const { getSponsors } = useSponsors();
const { data: sponsors } = getSponsors();

/*
const sponsors = ref({
  members: [
    {
      imgUrl: "https://sanesu-care.com/images/logo.png",
      name: "株式会社ケア・サンエス",
      url: "https://sanesu-care.com/company.php",
    },
    {
      imgUrl: "https://www.ishikawa.med.or.jp/images/head_logo.jpg",
      name: "石川県医師会",
      url: "https://www.ishikawa.med.or.jp/",
    },
    {
      imgUrl: "https://www.centralmedical.co.jp/medipec/img/medipec.png",
      name: "株式会社メディペック",
      url: "https://www.centralmedical.co.jp/medipec/",
    },
    {
      imgUrl: "http://www.tomiki-lc.co.jp/assets/img/logo.png",
      name: "株式会社トミキライフケア",
      url: "http://www.tomiki-lc.co.jp/",
    },
    {
      imgUrl: "https://www.toyoumo.co.jp/cms/img/usr/cp/header/logo.gif",
      name: "東洋羽毛北信越販売株式会社",
      url: "https://www.toyoumo.co.jp/",
    },
    {
      imgUrl: "https://www.pmc-pmc.com/img_r/logo.png",
      name: "株式会社PMC",
      url: "https://www.pmc-pmc.com/index.html",
    },
    {
      imgUrl:
        "https://www.kao.com/content/dam/sites/kao/inc-common/kao_global_header_logo.png",
      name: "花王株式会社",
      url: "https://www.kao.com/jp/",
      width: "130px",
    },
    {
      imgUrl:
        "https://www.transparence.jp/sakura/assets_c/2015/01/sakurahead-1-thumb-330x75-383.jpg",
      name: "在宅鍼灸マッサージさくら",
      url: "https://transparence.jp/sakura/",
    },
    {
      imgUrl:
        "https://www.daiichihoki.co.jp/common/images/share/header/logo.png",
      name: "第一法規株式会社",
      url: "https://www.daiichihoki.co.jp/",
    },
    {
      imgUrl: "http://www.rurikou.jp/img/base/Logo.jpg",
      name: "株式会社瑠璃光",
      url: "http://www.rurikou.jp/",
    },
    {
      imgUrl: "https://www.nakakita.co.jp/img/logo.gif",
      name: "中北薬品株式会社",
      url: "https://www.nakakita.co.jp/",
    },
    {
      imgUrl: "https://www.finese.co.jp/themes/site/images/logo.jpg",
      name: "株式会社ファイネス",
      url: "https://www.finese.co.jp/",
    },
    {
      imgUrl:
        "https://nekonote335.com/853zwpg3kabc/wp-content/uploads/2021/10/logo4-300x67.png",
      name: "株式会社ねこの手",
      url: "https://nekonote335.com/",
    },
  ],
});
*/
</script>

<style lang="scss">
@keyframes fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    z-index: 0;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    z-index: 0;
  }
}

@keyframes loop-slide {
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(-252%);
  }
}

@-webkit-keyframes loop-slide {
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(-252%);
  }
}

#index {
  .sponsor-item {
    margin-right: 20px;
    margin-left: -80px;

    &.with-margin {
      margin-top: 200px;
    }
  }

  .with-grass {
    position: relative;

    &:before {
      z-index: 300;
      position: absolute;
      bottom: -50px;
      content: "";
      width: 100vw;
      height: 100px;
      background-image: url(/img/bg_grass.svg);
      background-repeat: no-repeat;
      background-position: top;
      background-size: 120%;
    }

    &.is-mobile {
      &:before {
        bottom: -80px;
      }
    }
  }
}

.bg-grass {
  background-image: url(/img/bg_grass.svg);
  background-repeat: no-repeat;
  background-position: center 120%;
  background-size: 120%;
}
</style>
