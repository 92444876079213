<template>
  <div>
    <div
      class="d-flex flex-column align-items-center justify-content-center my-5 w-100"
    >
      <Triangles class="text-green" />
      <h3 class="text-green w-100 text-center">県協会とは</h3>
    </div>

    <div class="d-flex justify-content-center">
      <div class="contents-960 w-100">
        石川県介護支援専門員協会は石川県において介護支援専門員の連携、知識・技術の研鑽及び人格資質の向上に努め、介護支援事業の発展を図り、すべての県民が安心して老後を過ごすことのできる社会の実現に寄与することを目的としています。
      </div>
    </div>

    <div
      class="py-5 mt-3 d-flex flex-column justify-content-center align-items-center"
      :style="{
        margin: isMobile ? '200px -1rem 0' : '200px -3rem 0',
      }"
      style="background-color: #f2eee9"
    >
      <div class="mb-5">
        <h3 class="text-green w-100 text-center">支部構成</h3>
      </div>

      <div
        class="contents-1200 flex-column"
        :class="{ grid: !isMobile, 'd-flex': isMobile }"
      >
        <template v-for="b in branchs" :key="b.id">
          <div
            class="d-flex justify-content-center align-items-center"
            :class="{
              'g-col-6': !isMobile,
              'mb-3': isMobile,
            }"
          >
            <div
              class="d-flex flex-column align-items-center bg-white"
              style="
                width: 95%;
                border-radius: 20px;
                min-height: 270px;
                height: 100%;
                padding: 1rem;
              "
            >
              <div class="my-3">
                <h5 :style="{ color: b.color }">{{ b.name }}</h5>
              </div>
              <div
                v-if="b.constituentMembers.length"
                class="d-flex justify-content-center w-100"
                :class="{
                  'flex-row': !isMobile,
                  'flex-column': isMobile,
                }"
                style="min-width: 200px"
              >
                <div
                  :class="{
                    'w-100': isMobile,
                    'mb-3': isMobile,
                    'me-3': !isMobile,
                    'pe-3': !isMobile,
                  }"
                  style="min-width: 230px"
                  :style="{
                    'border-right': isMobile ? 'none' : '1px dashed #c2ba9f',
                  }"
                >
                  <div class="mb-3 pe-5">
                    <span>組織・役員</span>
                  </div>

                  <div
                    v-for="g in b.constituentMembers"
                    :key="g.role"
                    class="d-flex align-items-top mb-1"
                  >
                    <LabelChip
                      class="me-2 justify-content-center"
                      style="width: 90px"
                      :label="g.role"
                      reverse-color
                    />
                    <div class="d-flex flex-column text-nowrap">
                      <span v-for="m in g.members" :key="m.name">
                        {{ m.name }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="" style="min-width: 200px">
                  <div class="mb-3">
                    <span>お問い合わせ先</span>
                  </div>

                  <div v-if="b.postcode">
                    〒{{ b.postcode.slice(0, 3) }}-{{ b.postcode.slice(3) }}
                  </div>
                  <div v-if="b.address">
                    {{ b.address }}
                  </div>
                  <div v-if="false" class="mt-3">
                    <a v-if="b.phoneNumber" :href="'tel:' + b.phoneNumber">
                      <PhoneButton
                        :color-str="b.color"
                        :tel="formatNational(b.phoneNumber)"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="mb-5" style="margin-top: 200px">
      <h3 class="text-green w-100 text-center">役員</h3>
    </div>

    <div
      class="w-100 d-flex flex-column align-items-center"
      style="overflow-x: scroll"
    >
      <table
        class="table table-sm mb-3 table-bordered contents-960"
        style="width: 100%"
        :style="{
          'font-size': isMobile ? '0.8rem' : '1rem',
        }"
      >
        <thead>
          <tr>
            <th
              class="cell-role"
              :class="{
                'is-mobile': isMobile,
              }"
            >
              役職
            </th>
            <th
              class="cell-name"
              :class="{
                'is-mobile': isMobile,
              }"
            >
              氏名
            </th>
            <th class="cell-remarks">備考</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="g in memberGroups" :key="g.role">
            <template v-for="(m, idx) in g.members" :key="m.name">
              <tr>
                <td
                  v-if="idx === 0"
                  class="cell-role align-middle"
                  :class="{
                    'is-mobile': isMobile,
                  }"
                  :rowspan="g.members.length"
                >
                  {{ g.role }}
                </td>
                <td
                  class="cell-name"
                  :class="{
                    'is-mobile': isMobile,
                  }"
                >
                  {{ m.name }}
                </td>
                <td class="cell-remarks">{{ m.remarks }}</td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>

    <div class="mb-5" style="margin-top: 200px">
      <h3 class="text-green w-100 text-center">組織図</h3>
    </div>

    <div class="w-100 d-flex justify-content-center mb-5">
      <Image
        :src="outline"
        :image-style="{
          'max-width': '100%',
        }"
        class="contents-960"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useOrganizations, useMqUtils } from "/@/vue/composables";
import { LabelChip } from "/@/vue/components/Atom";
import { OrganizationClient } from "/@/types";
import Image from "primevue/image";
import { Triangles } from "/@/vue/components/Organisms/Index";
import { PhoneButton } from "/@/vue/components/Atom/Buttons";
import outline from "../../../../public/img/outline_organization.gif";
import AboutImage from "../../../../public/img/ishikawa_img_2.jpeg";
import { formatNational } from "/@/modules/phonenumber";

const { isMobile } = useMqUtils();

const selectedOrganizationId = ref();

const { getOrganizations } = useOrganizations();
const { data: organizations } = getOrganizations({
  association: true,
  branch: true,
});

const branchs = computed(() => {
  if (!organizations.value) return [];

  return organizations.value.filter((o) => o.branch);
});

function setAssociationOrganization(os: OrganizationClient[]) {
  if (!os) return;

  selectedOrganizationId.value = os.find((o) => !o.branch)?.id;
}

watch(organizations, (os) => {
  setAssociationOrganization(os || []);
});

onMounted(() => {
  setAssociationOrganization(organizations.value || []);
});

const memberGroups = computed(() => {
  if (!organizations.value || !selectedOrganizationId.value) return [];

  const association = organizations.value.find(
    (o) => o.id === selectedOrganizationId.value
  );

  if (!association) return [];

  return association.constituentMembers;
});
</script>

<style scoped lang="scss">
table.table th {
  background-color: #a6c565;
}

.cell-role {
  width: 150px;
  text-align: center;

  &.is-mobile {
    width: 70px;
  }
}

.cell-name {
  width: 300px;
  text-align: center;

  &.is-mobile {
    width: 100px;
  }
}
</style>
