<template>
  <div>
    <div
      v-if="showFilter"
      class="d-flex align-items-center justify-content-end my-3 mb-3"
    >
      <div class="mt-3">
        <FormLabel label="絞り込み" />
        <Dropdown
          v-model="selectedYear"
          :options="['すべて', ...existsYears]"
          :option-label="(m) => (m === 'すべて' ? m : `${m}年`)"
          style="min-width: 150px"
          class="radius"
        />
      </div>
    </div>
    <div v-if="showPageInfo" class="d-flex justify-content-end mb-3">
      <PageInfo
        :per-page="perPage"
        :total="formats.length"
        :first="currentPage"
        style="font-size: 0.75rem"
        class="me-3"
      />
    </div>
    <span v-if="!currentPageformats.length" class="text-danger"
      >書類がありません</span
    >
    <div
      v-for="(n, idx) in currentPageformats"
      :key="n.id"
      :style="{
        padding: '1rem 1.25rem',
        'border-radius': '0',
        'border-top': idx != 0 ? 'none' : '1px solid #e1dbc7',
        'border-bottom': '1px solid #e1dbc7',
      }"
    >
      <a :href="n.attachedPdfs[0].url" target="_blank" class="w-100">
        <div class="d-flex flex-column py-3 w-100">
          <div
            class="d-flex align-items-center justify-content-between flex-wrap"
          >
            <div class="d-flex align-items-center flex-wrap">
              <span class="me-4 text-green">{{
                isoFormat(n.updatedAt, "yyyy.MM.dd")
              }}</span>
              <h5>{{ n.title }}</h5>
            </div>
            <span>開く</span>
          </div>
        </div>
      </a>
    </div>

    <Paginator
      v-if="showPageInfo"
      v-model:first="currentPage"
      :rows="perPage"
      :total-records="formats.length"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { isoFormat } from "/@/modules/luxon";
import { Label as FormLabel } from "/@/vue/components/Atom";
import { PageInfo } from "/@/vue/components/Molecules/UI";
import { FormatClient } from "/@/types";
import Dropdown from "primevue/dropdown";
import Paginator from "primevue/paginator";

const props = defineProps<{
  formats: FormatClient[];
  showFilter?: boolean;
  showPageInfo?: boolean;
}>();

const selectedYear = ref("すべて");

const existsYears = computed(() => {
  return [...new Set(props.formats.map((m) => isoFormat(m.updatedAt, "yyyy")))];
});

const perPage = 10;
const currentPage = ref(0);

const filteredformats = computed(() => {
  if (selectedYear.value === "すべて") return props.formats;

  return props.formats.filter(
    (m) => isoFormat(m.updatedAt, "yyyy") === selectedYear.value
  );
});

const currentPageformats = computed(() => {
  return filteredformats.value.slice(
    currentPage.value,
    currentPage.value + perPage
  );
});
</script>

<style scoped></style>
