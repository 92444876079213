import axios from "axios";
import { errorHandle } from "/@/modules/error";

export function useInvest24JoinUser() {
  async function destroyJoinUser(
    id: number,
    prms: { uuid?: string } = { uuid: undefined }
  ) {
    // Cancel invest24Recruitment
    try {
      let uri = `/api/v1/invest24_join_users/${id}`;

      if (prms.uuid) {
        uri += `?uuid=${prms.uuid}`;
      }

      await axios.delete(uri);
      return true;
    } catch (e) {
      //alert(errorHandle(e));
      return false;
    }
  }

  async function detachJoinUser(id: number) {
    // Cancel invest24Recruitment
    try {
      await axios.delete(`/api/v1/invest24_join_users/${id}/detach`);
      return true;
    } catch (e) {
      alert(errorHandle(e));
      return false;
    }
  }

  return {
    destroyJoinUser,
    detachJoinUser,
  };
}
