<template>
  <div>
    <LabelChip
      :style="{
        'min-width': isMobile ? '70px' : '100px',
      }"
      :label="label"
      :reverseColor="reverseColor"
      :active="active"
    />
  </div>
</template>

<script setup lang="ts">
import { LabelChip } from ".";
import { useMqUtils } from "/@/vue/composables";

defineProps<{
  label: string;
  active?: boolean;
  reverseColor?: boolean;
}>();

const { isMobile } = useMqUtils();
</script>

<style scoped></style>
