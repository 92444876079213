<template>
  <div>
    <FormItem
      v-model:value="name"
      :errors="errors"
      label="お名前(必須)"
      form-id="name"
      form-type="text"
      class="mb-3"
      style="max-width: 300px"
      autocomplete="family-name"
    />

    <FormItem
      v-model:value="email"
      :errors="errors"
      label="メールアドレス(必須)"
      form-id="email"
      form-type="email"
      class="mb-3"
      style="max-width: 300px"
      autocomplete="email"
    />

    <FormItem
      v-model:value="emailCheck"
      :errors="emailCheckErrors"
      label="メール(確認)"
      form-id="emailCheck"
      form-type="email"
      autocomplete="current-email"
      class="mb-3"
      style="max-width: 300px"
    />

    <FormItem
      v-model:value="phoneNumber"
      :errors="errors"
      label="電話番号"
      form-id="phoneNumber"
      form-type="tel"
      class="mb-3"
      style="max-width: 300px"
      autocomplete="tel"
    />

    <div class="mb-3">
      <FormLabel label="お問い合わせ種別(選択必須)" />
      <Dropdown
        v-model="inquiryType"
        :options="inquiryTypes"
        :errors="errors"
        no-empty-label="選択してください。"
        form-id="inquiryType"
        :class="{
          'p-invalid': errors.inquiryType,
        }"
      />
    </div>

    <FormItem
      v-model:value="contents"
      :errors="errors"
      label="お問い合わせ内容(必須)"
      form-id="contents"
      form-type="textarea"
      class="mb-3"
      :rows="5"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Label as FormLabel } from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";
import Dropdown from "primevue/dropdown";
import { ZodFormattedErrors, inquiryTypes } from "/@/types";

defineProps<{
  errors: ZodFormattedErrors;
}>();

const name = defineModel<string>("name");
const email = defineModel<string>("email");
const emailCheck = defineModel<string>("emailCheck");
const phoneNumber = defineModel<string>("phoneNumber");
const inquiryType = defineModel<string>("inquiryType");
const contents = defineModel<string>("contents");

const emailCheckErrors = computed(() => {
  if (
    !emailCheck.value ||
    (email.value && emailCheck.value && email.value !== emailCheck.value)
  ) {
    return {
      emailCheck: {
        _errors: ["メールアドレスが一致しません。"],
      },
    };
  }
  return {};
});
</script>

<style scoped></style>
