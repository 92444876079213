<template>
  <div>
    <BasicButton
      v-if="!allocateUserMode"
      icon="pi pi-plus"
      label="ボランティア追加"
      class="mb-3"
      @click="allocateUserMode = true"
    />
    <div v-else class="mb-3 card card-body">
      <div class="mb-3">
        <FormLabel label="ボランティア追加" />
        <Dropdown
          :model-value="selectedUserMap"
          :options="groupedUsers"
          :option-label="(u) => u.lastName + u.firstName"
          :option-value="(u) => ({ id: u.id, userType: u.userType })"
          :option-group-label="
            (g) =>
              `${g.label}${
                g.items.length ? `(${g.items.length})` : ` 候補無し`
              }`
          "
          option-group-children="items"
          filter
          class="w-100"
          :class="{
            'p-invalid': !selectedUserMap,
          }"
          @update:model-value="
            selectedUserMap = { id: $event.id, userType: $event.userType }
          "
        />
      </div>

      <div v-if="recruitment.recruitmentType === 'invest24'">
        <FormLabel label="業務種別" />
        <Dropdown
          v-model="selectedWorkType"
          :options="invest24WorkTypes"
          class="w-100 mb-3"
        />
      </div>

      <span style="font-size: 0.85rem" class="mb-3 text-danger"
        >追加時にメールが送信されます</span
      >
      <div class="d-flex align-items-center justify-content-between w-100">
        <div>
          <BasicButton
            label="キャンセル"
            variant="secondary"
            outlined
            class="me-2"
            @click="allocateUserMode = false"
          />
          <BasicButton
            label="追加"
            :disabled="!selectedUserMap"
            button-type="submit"
            @click="allocate"
          />
        </div>
        <div>
          <BasicButton
            label="新規追加"
            variant="primary"
            @click="$emit('allocateNewUser')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { Label as FormLabel, BasicButton } from "/@/vue/components/Atom";
import {
  Invest24UserClient,
  Invest24RecruitmentClient,
  Invest24WorkType,
  invest24WorkTypes,
  UserClient,
} from "/@/types";
import Dropdown from "primevue/dropdown";

const props = defineProps<{
  recruitment: Invest24RecruitmentClient;
  invest24Users: Invest24UserClient[];
  users: UserClient[];
  targetDate: string;
}>();

const emit = defineEmits<{
  (
    e: "allocateUser",
    prms:
      | { invest24UserId: number; workType: Invest24WorkType }
      | { userId: number; workType: Invest24WorkType }
  ): void;
  (e: "allocateNewUser"): void;
}>();

const allocateUserMode = ref(false);
const selectedUserMap = ref<{
  id: number;
  userType: "invest24Users" | "users";
}>();

const groupedUsers = computed(() => {
  const recruitment = props.recruitment;
  const invest24Users = props.invest24Users;
  const users = props.users;

  let groups = [
    {
      label: "ボランティア",
      items: [] as (Invest24UserClient & { userType: "invest24Users" })[],
    },
    {
      label: "会員",
      items: [] as (UserClient & { userType: "users" })[],
    },
  ];

  if (!recruitment) return groups;

  const targetDateInfo = recruitment.invest24RecruitmentDayInfos.find(
    (r) => r.targetDate === props.targetDate
  );

  if (!targetDateInfo || !targetDateInfo.invest24JoinUserInfos) return groups;

  invest24Users.forEach((iu) => {
    if (targetDateInfo.invest24JoinUserInfos?.find((ju) => ju.id === iu.id))
      return;

    groups[0].items.push({ ...iu, userType: "invest24Users" });
  });

  users.forEach((u) => {
    if (targetDateInfo.invest24JoinUserInfos?.find((ju) => ju.userId === u.id))
      return;

    groups[1].items.push({ ...u, userType: "users" });
  });

  return groups;
});

const selectedWorkType = ref<Invest24WorkType>(invest24WorkTypes[0]);

function allocate() {
  if (!selectedUserMap.value) return;

  if (selectedUserMap.value.userType === "invest24Users") {
    emit("allocateUser", {
      invest24UserId: selectedUserMap.value.id,
      workType: selectedWorkType.value,
    });
  } else {
    emit("allocateUser", {
      userId: selectedUserMap.value.id,
      workType: selectedWorkType.value,
    });
  }

  selectedUserMap.value = undefined;

  allocateUserMode.value = false;
}
</script>

<style scoped></style>
