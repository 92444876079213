<template>
  <div>
    <InputForm
      :value="value"
      :is-valid="isValid"
      :form-id="formId"
      :disabled="disabled"
      form-type="email"
      autuocomplete="email"
      @update:value="update"
    />
  </div>
</template>

<script setup lang="ts">
import { InputForm } from "./";

interface Props {
  value?: string;
  formId: string;
  isValid?: boolean;
  disabled?: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
  (e: "update:value", value: string | null): void;
}>();

function update(email: string | null) {
  emit("update:value", email);
}
</script>

<style lang="scss" scoped></style>
