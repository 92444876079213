<template>
  <div>
    <div class="d-flex w-100">
      <NewsList
        :news="news || []"
        :organizations="organizations || []"
        reverse-color
        show-title
        show-filter
        show-page-info
        show-pagenation
        class="w-100"
        @select-news="handleSelectNews"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useNews, useOrganizations, useRouterUtil } from "/@/vue/composables";
import {
  List as NewsList,
  Filter as LabelChipFilter,
} from "/@/vue/components/Molecules/News";
import NewsImage from "../../../../../public/img/ishikawa_img_4.jpeg";

const { goto } = useRouterUtil();

const selectedOrganizationId = ref();

// news

const { getNews } = useNews();
const { data: news } = getNews(selectedOrganizationId);

function handleSelectNews(id: number) {
  goto({ name: "NewsShow", params: { id: id } });
}

// organizations

const { getOrganizations } = useOrganizations();
const { data: organizations } = getOrganizations({
  association: true,
  branch: true,
});
</script>

<style scoped></style>
