<template>
  <div class="d-flex flex-column align-items-center">
    <div class="contents-960 w-100 pt-5">
      <template v-if="!confirming">
        <InquiryForm
          v-model:name="form.name"
          v-model:email="form.email"
          v-model:email-check="emailCheck"
          v-model:phoneNumber="form.phoneNumber"
          v-model:inquiry-type="form.inquiryType"
          v-model:contents="form.contents"
          :errors="errors"
          class="contents-960"
        />

        <div
          class="my-5 w-100 d-flex align-items-center justify-content-center"
        >
          <OpenSiteBackButton class="me-3" />
          <MoreReadButton
            class="ms-3"
            color="green"
            label="確認"
            @click="confirm"
          />
        </div>
      </template>
      <template v-else>
        <InquiryShow :inquiry="form" />

        <div
          class="my-5 w-100 d-flex align-items-center justify-content-center"
        >
          <OpenSiteBackButton
            class="me-3"
            label="修正"
            :click-command="() => (confirming = false)"
          />
          <MoreReadButton
            class="ms-3"
            color="green"
            label="送信"
            button-type="submit"
            :disabled="loading"
            @click="submit"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { gPhoneNumber } from "/@/modules/groomer";
import {
  useInquiries,
  useZodScheme,
  useRouterUtil,
  useUser,
} from "/@/vue/composables";
import {
  BasicButton,
  MoreReadButton,
  OpenSiteBackButton,
} from "/@/vue/components/Atom";
import { Show as InquiryShow } from "/@/vue/components/Molecules/Inquiries";
import { InquiryForm } from "/@/vue/components/Organisms";
import {
  InquiryForm as InquiryFormType,
  InquiryFormCheckScheme,
  inquiryTypes,
  UserClient,
} from "/@/types";
import { scrollToTop } from "/@/modules/ui";

const { backto, goto } = useRouterUtil();

const { getUser } = useUser();
const { data: user } = getUser();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<InquiryFormType>(
  InquiryFormCheckScheme,
  {
    inquiryType: inquiryTypes[0],
  }
);

function initForm(user: UserClient) {
  form.name = user.lastName + user.firstName;
  form.email = user.email;
  emailCheck.value = user.email;
  form.phoneNumber = user.phoneNumber;
}

onMounted(() => {
  if (user.value) {
    initForm(user.value);
  }
});

watch(user, (u) => {
  if (u) {
    initForm(u);
  }
});

watch(form, (f) => {
  // form を watch する場合は、まとめて変更した時の処理を書けるが、
  // 無駄な比較処理が増えるのと過去の値へのアクセスができない問題がある。
  // そのため個別のプロパティでの変更があった時のような処理が書けない

  if (f.name && f.name.length > 50) {
    form.name = f.name.slice(0, 50);
  }

  if (f.phoneNumber) {
    form.phoneNumber = gPhoneNumber(f.phoneNumber);
  }
});

const emailCheck = ref("");

/*
form.name = "お名前";
form.email = "s1200191@gmail.com";
emailCheck.value = "s1200191@gmail.com";
form.phoneNumber = "09012345678";
form.contents = "お問い合わせ内容";
*/

startValidation.value = true;

const confirming = ref(false);

function confirm() {
  try {
    if (form.email !== emailCheck.value) {
      throw new Error("メールアドレスが一致しません。");
    }

    InquiryFormCheckScheme.parse(form);
    scrollToTop();
    confirming.value = true;
  } catch (e) {
    console.error(e);
    window.alert("入力に問題があります。赤枠の項目を確認して下さい。");
  }
}

const loading = ref(false);

const { submitInquiry } = useInquiries();

async function submit() {
  try {
    loading.value = true;

    if (form.email !== emailCheck.value) {
      throw new Error("メールアドレスが一致しません。");
    }

    const prms = InquiryFormCheckScheme.parse(form);

    if (await submitInquiry(prms)) {
      window.alert("送信が完了しました。");
      goto({ name: "Index" });
    } else {
      window.alert("送信に失敗しまし");
    }
    return;
  } catch (e) {
    console.error(e);
    window.alert("入力に問題があります。赤枠の項目を確認して下さい。");
  }

  loading.value = false;
}
</script>
