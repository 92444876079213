<template>
  <div>
    <Dialog
      :visible="visible"
      modal
      header="詳細情報"
      dismissable-mask
      block-scroll
      style="width: 90%; max-width: 500px"
      @after-hide="$emit('afterHide')"
      @update:visible="$emit('update:visible', $event)"
    >
      <div v-if="dayInfo" :key="dayInfo.id">
        <DayInfoShow :target-date-info="dayInfo" />

        <div class="d-flex justify-content-between w-100">
          <div v-if="!cannotCancel" class="d-flex flex-column">
            <div>
              <BasicButton
                label="申請の取り消し"
                variant="danger"
                :disabled="loading"
                button-type="submit"
                @click="$emit('cancel', dayInfo.invest24JoinUserId)"
              />
            </div>
          </div>

          <span v-else class="text-danger"
            >前日15時以降はシステム上でのキャンセルができません。県協会に電話でのご相談をお願いします</span
          >
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { luxonNow, fromISO } from "/@/modules/luxon";
import { BasicButton } from "/@/vue/components/Atom";
import { Show as DayInfoShow } from "/@/vue/components/Molecules/Invest24RecruitmentDayInfos";
import Dialog from "primevue/dialog";
import { Invest24UserAttachedDayInfo } from "/@/types";

const props = defineProps<{
  dayInfo?: Invest24UserAttachedDayInfo;
  visible?: boolean;
  loading?: boolean;
}>();

defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "cancel", id: number): void;
  (e: "afterHide"): void;
}>();

const today = luxonNow();

const cannotCancel = computed(() => {
  if (!props.dayInfo) return;

  const targetDateLuxon = fromISO(props.dayInfo.targetDate);

  return (
    (targetDateLuxon.hasSame(today.plus({ days: 1 }), "day") &&
      today.hour >= 15) ||
    targetDateLuxon <= today
  );
});
</script>

<style scoped></style>
