export const areas = [
  "七尾",
  "穴水",
  "能登町",
  "輪島",
  "珠洲",
  "志賀",
  "その他",
];

export const businesses = [
  "(未選択)",
  "ケアマネージャー",
  "地域包括支援センター",
  "医療ソーシャルワーカー",
  "相談員（高齢者分野）",
  "支援員（障害分野）",
  "介護職",
  "看護師",
  "医師",
  "その他",
] as const;

export const prefectures = [
  { label: "北海道", value: "北海道" },
  { label: "青森県", value: "青森県" },
  { label: "岩手県", value: "岩手県" },
  { label: "宮城県", value: "宮城県" },
  { label: "秋田県", value: "秋田県" },
  { label: "山形県", value: "山形県" },
  { label: "福島県", value: "福島県" },
  { label: "茨城県", value: "茨城県" },
  { label: "栃木県", value: "栃木県" },
  { label: "群馬県", value: "群馬県" },
  { label: "埼玉県", value: "埼玉県" },
  { label: "千葉県", value: "千葉県" },
  { label: "東京都", value: "東京都" },
  { label: "神奈川県", value: "神奈川県" },
  { label: "新潟県", value: "新潟県" },
  { label: "富山県", value: "富山県" },
  { label: "石川県", value: "石川県" },
  { label: "福井県", value: "福井県" },
  { label: "山梨県", value: "山梨県" },
  { label: "長野県", value: "長野県" },
  { label: "岐阜県", value: "岐阜県" },
  { label: "静岡県", value: "静岡県" },
  { label: "愛知県", value: "愛知県" },
  { label: "三重県", value: "三重県" },
  { label: "滋賀県", value: "滋賀県" },
  { label: "京都府", value: "京都府" },
  { label: "大阪府", value: "大阪府" },
  { label: "兵庫県", value: "兵庫県" },
  { label: "奈良県", value: "奈良県" },
  { label: "和歌山県", value: "和歌山県" },
  { label: "鳥取県", value: "鳥取県" },
  { label: "島根県", value: "島根県" },
  { label: "岡山県", value: "岡山県" },
  { label: "広島県", value: "広島県" },
  { label: "山口県", value: "山口県" },
  { label: "徳島県", value: "徳島県" },
  { label: "香川県", value: "香川県" },
  { label: "愛媛県", value: "愛媛県" },
  { label: "高知県", value: "高知県" },
  { label: "福岡県", value: "福岡県" },
  { label: "佐賀県", value: "佐賀県" },
  { label: "長崎県", value: "長崎県" },
  { label: "熊本県", value: "熊本県" },
  { label: "大分県", value: "大分県" },
  { label: "宮崎県", value: "宮崎県" },
  { label: "鹿児島県", value: "鹿児島県" },
  { label: "沖縄県", value: "沖縄県" },
];

export const prefecturesWithCities = [
  {
    id: "01",
    name: "北海道",
    short: "北海道",
    kana: "ホッカイドウ",
    en: "hokkaido",
    cities: [
      {
        id: "01100",
        name: "札幌市",
        words: [
          { id: "01101", name: "中央区" },
          { id: "01102", name: "北区" },
          { id: "01103", name: "東区" },
          { id: "01104", name: "白石区" },
          { id: "01105", name: "豊平区" },
          { id: "01106", name: "南区" },
          { id: "01107", name: "西区" },
          { id: "01108", name: "厚別区" },
          { id: "01109", name: "手稲区" },
          { id: "01110", name: "清田区" },
        ],
      },
      { id: "01202", name: "函館市" },
      { id: "01203", name: "小樽市" },
      { id: "01204", name: "旭川市" },
      { id: "01205", name: "室蘭市" },
      { id: "01206", name: "釧路市" },
      { id: "01207", name: "帯広市" },
      { id: "01208", name: "北見市" },
      { id: "01209", name: "夕張市" },
      { id: "01210", name: "岩見沢市" },
      { id: "01211", name: "網走市" },
      { id: "01212", name: "留萌市" },
      { id: "01213", name: "苫小牧市" },
      { id: "01214", name: "稚内市" },
      { id: "01215", name: "美唄市" },
      { id: "01216", name: "芦別市" },
      { id: "01217", name: "江別市" },
      { id: "01218", name: "赤平市" },
      { id: "01219", name: "紋別市" },
      { id: "01220", name: "士別市" },
      { id: "01221", name: "名寄市" },
      { id: "01222", name: "三笠市" },
      { id: "01223", name: "根室市" },
      { id: "01224", name: "千歳市" },
      { id: "01225", name: "滝川市" },
      { id: "01226", name: "砂川市" },
      { id: "01227", name: "歌志内市" },
      { id: "01228", name: "深川市" },
      { id: "01229", name: "富良野市" },
      { id: "01230", name: "登別市" },
      { id: "01231", name: "恵庭市" },
      { id: "01233", name: "伊達市" },
      { id: "01234", name: "北広島市" },
      { id: "01235", name: "石狩市" },
      { id: "01236", name: "北斗市" },
      { id: "01303", name: "当別町" },
      { id: "01304", name: "新篠津村" },
      { id: "01331", name: "松前町" },
      { id: "01332", name: "福島町" },
      { id: "01333", name: "知内町" },
      { id: "01334", name: "木古内町" },
      { id: "01337", name: "七飯町" },
      { id: "01343", name: "鹿部町" },
      { id: "01345", name: "森町" },
      { id: "01346", name: "八雲町" },
      { id: "01347", name: "長万部町" },
      { id: "01361", name: "江差町" },
      { id: "01362", name: "上ノ国町" },
      { id: "01363", name: "厚沢部町" },
      { id: "01364", name: "乙部町" },
      { id: "01367", name: "奥尻町" },
      { id: "01370", name: "今金町" },
      { id: "01371", name: "せたな町" },
      { id: "01391", name: "島牧村" },
      { id: "01392", name: "寿都町" },
      { id: "01393", name: "黒松内町" },
      { id: "01394", name: "蘭越町" },
      { id: "01395", name: "ニセコ町" },
      { id: "01396", name: "真狩村" },
      { id: "01397", name: "留寿都村" },
      { id: "01398", name: "喜茂別町" },
      { id: "01399", name: "京極町" },
      { id: "01400", name: "倶知安町" },
      { id: "01401", name: "共和町" },
      { id: "01402", name: "岩内町" },
      { id: "01403", name: "泊村" },
      { id: "01404", name: "神恵内村" },
      { id: "01405", name: "積丹町" },
      { id: "01406", name: "古平町" },
      { id: "01407", name: "仁木町" },
      { id: "01408", name: "余市町" },
      { id: "01409", name: "赤井川村" },
      { id: "01423", name: "南幌町" },
      { id: "01424", name: "奈井江町" },
      { id: "01425", name: "上砂川町" },
      { id: "01427", name: "由仁町" },
      { id: "01428", name: "長沼町" },
      { id: "01429", name: "栗山町" },
      { id: "01430", name: "月形町" },
      { id: "01431", name: "浦臼町" },
      { id: "01432", name: "新十津川町" },
      { id: "01433", name: "妹背牛町" },
      { id: "01434", name: "秩父別町" },
      { id: "01436", name: "雨竜町" },
      { id: "01437", name: "北竜町" },
      { id: "01438", name: "沼田町" },
      { id: "01452", name: "鷹栖町" },
      { id: "01453", name: "東神楽町" },
      { id: "01454", name: "当麻町" },
      { id: "01455", name: "比布町" },
      { id: "01456", name: "愛別町" },
      { id: "01457", name: "上川町" },
      { id: "01458", name: "東川町" },
      { id: "01459", name: "美瑛町" },
      { id: "01460", name: "上富良野町" },
      { id: "01461", name: "中富良野町" },
      { id: "01462", name: "南富良野町" },
      { id: "01463", name: "占冠村" },
      { id: "01464", name: "和寒町" },
      { id: "01465", name: "剣淵町" },
      { id: "01468", name: "下川町" },
      { id: "01469", name: "美深町" },
      { id: "01470", name: "音威子府村" },
      { id: "01471", name: "中川町" },
      { id: "01472", name: "幌加内町" },
      { id: "01481", name: "増毛町" },
      { id: "01482", name: "小平町" },
      { id: "01483", name: "苫前町" },
      { id: "01484", name: "羽幌町" },
      { id: "01485", name: "初山別村" },
      { id: "01486", name: "遠別町" },
      { id: "01487", name: "天塩町" },
      { id: "01511", name: "猿払村" },
      { id: "01512", name: "浜頓別町" },
      { id: "01513", name: "中頓別町" },
      { id: "01514", name: "枝幸町" },
      { id: "01516", name: "豊富町" },
      { id: "01517", name: "礼文町" },
      { id: "01518", name: "利尻町" },
      { id: "01519", name: "利尻富士町" },
      { id: "01520", name: "幌延町" },
      { id: "01543", name: "美幌町" },
      { id: "01544", name: "津別町" },
      { id: "01545", name: "斜里町" },
      { id: "01546", name: "清里町" },
      { id: "01547", name: "小清水町" },
      { id: "01549", name: "訓子府町" },
      { id: "01550", name: "置戸町" },
      { id: "01552", name: "佐呂間町" },
      { id: "01555", name: "遠軽町" },
      { id: "01559", name: "湧別町" },
      { id: "01560", name: "滝上町" },
      { id: "01561", name: "興部町" },
      { id: "01562", name: "西興部村" },
      { id: "01563", name: "雄武町" },
      { id: "01564", name: "大空町" },
      { id: "01571", name: "豊浦町" },
      { id: "01575", name: "壮瞥町" },
      { id: "01578", name: "白老町" },
      { id: "01581", name: "厚真町" },
      { id: "01584", name: "洞爺湖町" },
      { id: "01585", name: "安平町" },
      { id: "01586", name: "むかわ町" },
      { id: "01601", name: "日高町" },
      { id: "01602", name: "平取町" },
      { id: "01604", name: "新冠町" },
      { id: "01607", name: "浦河町" },
      { id: "01608", name: "様似町" },
      { id: "01609", name: "えりも町" },
      { id: "01610", name: "新ひだか町" },
      { id: "01631", name: "音更町" },
      { id: "01632", name: "士幌町" },
      { id: "01633", name: "上士幌町" },
      { id: "01634", name: "鹿追町" },
      { id: "01635", name: "新得町" },
      { id: "01636", name: "清水町" },
      { id: "01637", name: "芽室町" },
      { id: "01638", name: "中札内村" },
      { id: "01639", name: "更別村" },
      { id: "01641", name: "大樹町" },
      { id: "01642", name: "広尾町" },
      { id: "01643", name: "幕別町" },
      { id: "01644", name: "池田町" },
      { id: "01645", name: "豊頃町" },
      { id: "01646", name: "本別町" },
      { id: "01647", name: "足寄町" },
      { id: "01648", name: "陸別町" },
      { id: "01649", name: "浦幌町" },
      { id: "01661", name: "釧路町" },
      { id: "01662", name: "厚岸町" },
      { id: "01663", name: "浜中町" },
      { id: "01664", name: "標茶町" },
      { id: "01665", name: "弟子屈町" },
      { id: "01667", name: "鶴居村" },
      { id: "01668", name: "白糠町" },
      { id: "01691", name: "別海町" },
      { id: "01692", name: "中標津町" },
      { id: "01693", name: "標津町" },
      { id: "01694", name: "羅臼町" },
      { id: "01695", name: "色丹村" },
      { id: "01696", name: "泊村" },
      { id: "01697", name: "留夜別村" },
      { id: "01698", name: "留別村" },
      { id: "01699", name: "紗那村" },
      { id: "01700", name: "蘂取村" },
    ],
  },
  {
    id: "02",
    name: "青森県",
    short: "青森",
    kana: "アオモリケン",
    en: "aomori",
    cities: [
      { id: "02201", name: "青森市" },
      { id: "02202", name: "弘前市" },
      { id: "02203", name: "八戸市" },
      { id: "02204", name: "黒石市" },
      { id: "02205", name: "五所川原市" },
      { id: "02206", name: "十和田市" },
      { id: "02207", name: "三沢市" },
      { id: "02208", name: "むつ市" },
      { id: "02209", name: "つがる市" },
      { id: "02210", name: "平川市" },
      { id: "02301", name: "平内町" },
      { id: "02303", name: "今別町" },
      { id: "02304", name: "蓬田村" },
      { id: "02307", name: "外ヶ浜町" },
      { id: "02321", name: "鰺ヶ沢町" },
      { id: "02323", name: "深浦町" },
      { id: "02343", name: "西目屋村" },
      { id: "02361", name: "藤崎町" },
      { id: "02362", name: "大鰐町" },
      { id: "02367", name: "田舎館村" },
      { id: "02381", name: "板柳町" },
      { id: "02384", name: "鶴田町" },
      { id: "02387", name: "中泊町" },
      { id: "02401", name: "野辺地町" },
      { id: "02402", name: "七戸町" },
      { id: "02405", name: "六戸町" },
      { id: "02406", name: "横浜町" },
      { id: "02408", name: "東北町" },
      { id: "02411", name: "六ヶ所村" },
      { id: "02412", name: "おいらせ町" },
      { id: "02423", name: "大間町" },
      { id: "02424", name: "東通村" },
      { id: "02425", name: "風間浦村" },
      { id: "02426", name: "佐井村" },
      { id: "02441", name: "三戸町" },
      { id: "02442", name: "五戸町" },
      { id: "02443", name: "田子町" },
      { id: "02445", name: "南部町" },
      { id: "02446", name: "階上町" },
      { id: "02450", name: "新郷村" },
    ],
  },
  {
    id: "03",
    name: "岩手県",
    short: "岩手",
    kana: "イワテケン",
    en: "iwate",
    cities: [
      { id: "03201", name: "盛岡市" },
      { id: "03202", name: "宮古市" },
      { id: "03203", name: "大船渡市" },
      { id: "03205", name: "花巻市" },
      { id: "03206", name: "北上市" },
      { id: "03207", name: "久慈市" },
      { id: "03208", name: "遠野市" },
      { id: "03209", name: "一関市" },
      { id: "03210", name: "陸前高田市" },
      { id: "03211", name: "釜石市" },
      { id: "03213", name: "二戸市" },
      { id: "03214", name: "八幡平市" },
      { id: "03215", name: "奥州市" },
      { id: "03216", name: "滝沢市" },
      { id: "03301", name: "雫石町" },
      { id: "03302", name: "葛巻町" },
      { id: "03303", name: "岩手町" },
      { id: "03321", name: "紫波町" },
      { id: "03322", name: "矢巾町" },
      { id: "03366", name: "西和賀町" },
      { id: "03381", name: "金ケ崎町" },
      { id: "03402", name: "平泉町" },
      { id: "03441", name: "住田町" },
      { id: "03461", name: "大槌町" },
      { id: "03482", name: "山田町" },
      { id: "03483", name: "岩泉町" },
      { id: "03484", name: "田野畑村" },
      { id: "03485", name: "普代村" },
      { id: "03501", name: "軽米町" },
      { id: "03503", name: "野田村" },
      { id: "03506", name: "九戸村" },
      { id: "03507", name: "洋野町" },
      { id: "03524", name: "一戸町" },
    ],
  },
  {
    id: "04",
    name: "宮城県",
    short: "宮城",
    kana: "ミヤギケン",
    en: "miyagi",
    cities: [
      {
        id: "04100",
        name: "仙台市",
        words: [
          { id: "04101", name: "青葉区" },
          { id: "04102", name: "宮城野区" },
          { id: "04103", name: "若林区" },
          { id: "04104", name: "太白区" },
          { id: "04105", name: "泉区" },
        ],
      },
      { id: "04202", name: "石巻市" },
      { id: "04203", name: "塩竈市" },
      { id: "04205", name: "気仙沼市" },
      { id: "04206", name: "白石市" },
      { id: "04207", name: "名取市" },
      { id: "04208", name: "角田市" },
      { id: "04209", name: "多賀城市" },
      { id: "04211", name: "岩沼市" },
      { id: "04212", name: "登米市" },
      { id: "04213", name: "栗原市" },
      { id: "04214", name: "東松島市" },
      { id: "04215", name: "大崎市" },
      { id: "04216", name: "富谷市" },
      { id: "04301", name: "蔵王町" },
      { id: "04302", name: "七ヶ宿町" },
      { id: "04321", name: "大河原町" },
      { id: "04322", name: "村田町" },
      { id: "04323", name: "柴田町" },
      { id: "04324", name: "川崎町" },
      { id: "04341", name: "丸森町" },
      { id: "04361", name: "亘理町" },
      { id: "04362", name: "山元町" },
      { id: "04401", name: "松島町" },
      { id: "04404", name: "七ヶ浜町" },
      { id: "04406", name: "利府町" },
      { id: "04421", name: "大和町" },
      { id: "04422", name: "大郷町" },
      { id: "04424", name: "大衡村" },
      { id: "04444", name: "色麻町" },
      { id: "04445", name: "加美町" },
      { id: "04501", name: "涌谷町" },
      { id: "04505", name: "美里町" },
      { id: "04581", name: "女川町" },
      { id: "04606", name: "南三陸町" },
    ],
  },
  {
    id: "05",
    name: "秋田県",
    short: "秋田",
    kana: "アキタケン",
    en: "akita",
    cities: [
      { id: "05201", name: "秋田市" },
      { id: "05202", name: "能代市" },
      { id: "05203", name: "横手市" },
      { id: "05204", name: "大館市" },
      { id: "05206", name: "男鹿市" },
      { id: "05207", name: "湯沢市" },
      { id: "05209", name: "鹿角市" },
      { id: "05210", name: "由利本荘市" },
      { id: "05211", name: "潟上市" },
      { id: "05212", name: "大仙市" },
      { id: "05213", name: "北秋田市" },
      { id: "05214", name: "にかほ市" },
      { id: "05215", name: "仙北市" },
      { id: "05303", name: "小坂町" },
      { id: "05327", name: "上小阿仁村" },
      { id: "05346", name: "藤里町" },
      { id: "05348", name: "三種町" },
      { id: "05349", name: "八峰町" },
      { id: "05361", name: "五城目町" },
      { id: "05363", name: "八郎潟町" },
      { id: "05366", name: "井川町" },
      { id: "05368", name: "大潟村" },
      { id: "05434", name: "美郷町" },
      { id: "05463", name: "羽後町" },
      { id: "05464", name: "東成瀬村" },
    ],
  },
  {
    id: "06",
    name: "山形県",
    short: "山形",
    kana: "ヤマガタケン",
    en: "yamagata",
    cities: [
      { id: "06201", name: "山形市" },
      { id: "06202", name: "米沢市" },
      { id: "06203", name: "鶴岡市" },
      { id: "06204", name: "酒田市" },
      { id: "06205", name: "新庄市" },
      { id: "06206", name: "寒河江市" },
      { id: "06207", name: "上山市" },
      { id: "06208", name: "村山市" },
      { id: "06209", name: "長井市" },
      { id: "06210", name: "天童市" },
      { id: "06211", name: "東根市" },
      { id: "06212", name: "尾花沢市" },
      { id: "06213", name: "南陽市" },
      { id: "06301", name: "山辺町" },
      { id: "06302", name: "中山町" },
      { id: "06321", name: "河北町" },
      { id: "06322", name: "西川町" },
      { id: "06323", name: "朝日町" },
      { id: "06324", name: "大江町" },
      { id: "06341", name: "大石田町" },
      { id: "06361", name: "金山町" },
      { id: "06362", name: "最上町" },
      { id: "06363", name: "舟形町" },
      { id: "06364", name: "真室川町" },
      { id: "06365", name: "大蔵村" },
      { id: "06366", name: "鮭川村" },
      { id: "06367", name: "戸沢村" },
      { id: "06381", name: "高畠町" },
      { id: "06382", name: "川西町" },
      { id: "06401", name: "小国町" },
      { id: "06402", name: "白鷹町" },
      { id: "06403", name: "飯豊町" },
      { id: "06426", name: "三川町" },
      { id: "06428", name: "庄内町" },
      { id: "06461", name: "遊佐町" },
    ],
  },
  {
    id: "07",
    name: "福島県",
    short: "福島",
    kana: "フクシマケン",
    en: "fukushima",
    cities: [
      { id: "07201", name: "福島市" },
      { id: "07202", name: "会津若松市" },
      { id: "07203", name: "郡山市" },
      { id: "07204", name: "いわき市" },
      { id: "07205", name: "白河市" },
      { id: "07207", name: "須賀川市" },
      { id: "07208", name: "喜多方市" },
      { id: "07209", name: "相馬市" },
      { id: "07210", name: "二本松市" },
      { id: "07211", name: "田村市" },
      { id: "07212", name: "南相馬市" },
      { id: "07213", name: "伊達市" },
      { id: "07214", name: "本宮市" },
      { id: "07301", name: "桑折町" },
      { id: "07303", name: "国見町" },
      { id: "07308", name: "川俣町" },
      { id: "07322", name: "大玉村" },
      { id: "07342", name: "鏡石町" },
      { id: "07344", name: "天栄村" },
      { id: "07362", name: "下郷町" },
      { id: "07364", name: "檜枝岐村" },
      { id: "07367", name: "只見町" },
      { id: "07368", name: "南会津町" },
      { id: "07402", name: "北塩原村" },
      { id: "07405", name: "西会津町" },
      { id: "07407", name: "磐梯町" },
      { id: "07408", name: "猪苗代町" },
      { id: "07421", name: "会津坂下町" },
      { id: "07422", name: "湯川村" },
      { id: "07423", name: "柳津町" },
      { id: "07444", name: "三島町" },
      { id: "07445", name: "金山町" },
      { id: "07446", name: "昭和村" },
      { id: "07447", name: "会津美里町" },
      { id: "07461", name: "西郷村" },
      { id: "07464", name: "泉崎村" },
      { id: "07465", name: "中島村" },
      { id: "07466", name: "矢吹町" },
      { id: "07481", name: "棚倉町" },
      { id: "07482", name: "矢祭町" },
      { id: "07483", name: "塙町" },
      { id: "07484", name: "鮫川村" },
      { id: "07501", name: "石川町" },
      { id: "07502", name: "玉川村" },
      { id: "07503", name: "平田村" },
      { id: "07504", name: "浅川町" },
      { id: "07505", name: "古殿町" },
      { id: "07521", name: "三春町" },
      { id: "07522", name: "小野町" },
      { id: "07541", name: "広野町" },
      { id: "07542", name: "楢葉町" },
      { id: "07543", name: "富岡町" },
      { id: "07544", name: "川内村" },
      { id: "07545", name: "大熊町" },
      { id: "07546", name: "双葉町" },
      { id: "07547", name: "浪江町" },
      { id: "07548", name: "葛尾村" },
      { id: "07561", name: "新地町" },
      { id: "07564", name: "飯舘村" },
    ],
  },
  {
    id: "08",
    name: "茨城県",
    short: "茨城",
    kana: "イバラキケン",
    en: "ibaraki",
    cities: [
      { id: "08201", name: "水戸市" },
      { id: "08202", name: "日立市" },
      { id: "08203", name: "土浦市" },
      { id: "08204", name: "古河市" },
      { id: "08205", name: "石岡市" },
      { id: "08207", name: "結城市" },
      { id: "08208", name: "龍ケ崎市" },
      { id: "08210", name: "下妻市" },
      { id: "08211", name: "常総市" },
      { id: "08212", name: "常陸太田市" },
      { id: "08214", name: "高萩市" },
      { id: "08215", name: "北茨城市" },
      { id: "08216", name: "笠間市" },
      { id: "08217", name: "取手市" },
      { id: "08219", name: "牛久市" },
      { id: "08220", name: "つくば市" },
      { id: "08221", name: "ひたちなか市" },
      { id: "08222", name: "鹿嶋市" },
      { id: "08223", name: "潮来市" },
      { id: "08224", name: "守谷市" },
      { id: "08225", name: "常陸大宮市" },
      { id: "08226", name: "那珂市" },
      { id: "08227", name: "筑西市" },
      { id: "08228", name: "坂東市" },
      { id: "08229", name: "稲敷市" },
      { id: "08230", name: "かすみがうら市" },
      { id: "08231", name: "桜川市" },
      { id: "08232", name: "神栖市" },
      { id: "08233", name: "行方市" },
      { id: "08234", name: "鉾田市" },
      { id: "08235", name: "つくばみらい市" },
      { id: "08236", name: "小美玉市" },
      { id: "08302", name: "茨城町" },
      { id: "08309", name: "大洗町" },
      { id: "08310", name: "城里町" },
      { id: "08341", name: "東海村" },
      { id: "08364", name: "大子町" },
      { id: "08442", name: "美浦村" },
      { id: "08443", name: "阿見町" },
      { id: "08447", name: "河内町" },
      { id: "08521", name: "八千代町" },
      { id: "08542", name: "五霞町" },
      { id: "08546", name: "境町" },
      { id: "08564", name: "利根町" },
    ],
  },
  {
    id: "09",
    name: "栃木県",
    short: "栃木",
    kana: "トチギケン",
    en: "tochigi",
    cities: [
      { id: "09201", name: "宇都宮市" },
      { id: "09202", name: "足利市" },
      { id: "09203", name: "栃木市" },
      { id: "09204", name: "佐野市" },
      { id: "09205", name: "鹿沼市" },
      { id: "09206", name: "日光市" },
      { id: "09208", name: "小山市" },
      { id: "09209", name: "真岡市" },
      { id: "09210", name: "大田原市" },
      { id: "09211", name: "矢板市" },
      { id: "09213", name: "那須塩原市" },
      { id: "09214", name: "さくら市" },
      { id: "09215", name: "那須烏山市" },
      { id: "09216", name: "下野市" },
      { id: "09301", name: "上三川町" },
      { id: "09342", name: "益子町" },
      { id: "09343", name: "茂木町" },
      { id: "09344", name: "市貝町" },
      { id: "09345", name: "芳賀町" },
      { id: "09361", name: "壬生町" },
      { id: "09364", name: "野木町" },
      { id: "09384", name: "塩谷町" },
      { id: "09386", name: "高根沢町" },
      { id: "09407", name: "那須町" },
      { id: "09411", name: "那珂川町" },
    ],
  },
  {
    id: "10",
    name: "群馬県",
    short: "群馬",
    kana: "グンマケン",
    en: "gunma",
    cities: [
      { id: "10201", name: "前橋市" },
      { id: "10202", name: "高崎市" },
      { id: "10203", name: "桐生市" },
      { id: "10204", name: "伊勢崎市" },
      { id: "10205", name: "太田市" },
      { id: "10206", name: "沼田市" },
      { id: "10207", name: "館林市" },
      { id: "10208", name: "渋川市" },
      { id: "10209", name: "藤岡市" },
      { id: "10210", name: "富岡市" },
      { id: "10211", name: "安中市" },
      { id: "10212", name: "みどり市" },
      { id: "10344", name: "榛東村" },
      { id: "10345", name: "吉岡町" },
      { id: "10366", name: "上野村" },
      { id: "10367", name: "神流町" },
      { id: "10382", name: "下仁田町" },
      { id: "10383", name: "南牧村" },
      { id: "10384", name: "甘楽町" },
      { id: "10421", name: "中之条町" },
      { id: "10424", name: "長野原町" },
      { id: "10425", name: "嬬恋村" },
      { id: "10426", name: "草津町" },
      { id: "10428", name: "高山村" },
      { id: "10429", name: "東吾妻町" },
      { id: "10443", name: "片品村" },
      { id: "10444", name: "川場村" },
      { id: "10448", name: "昭和村" },
      { id: "10449", name: "みなかみ町" },
      { id: "10464", name: "玉村町" },
      { id: "10521", name: "板倉町" },
      { id: "10522", name: "明和町" },
      { id: "10523", name: "千代田町" },
      { id: "10524", name: "大泉町" },
      { id: "10525", name: "邑楽町" },
    ],
  },
  {
    id: "11",
    name: "埼玉県",
    short: "埼玉",
    kana: "サイタマケン",
    en: "saitama",
    cities: [
      {
        id: "11100",
        name: "さいたま市",
        words: [
          { id: "11101", name: "西区" },
          { id: "11102", name: "北区" },
          { id: "11103", name: "大宮区" },
          { id: "11104", name: "見沼区" },
          { id: "11105", name: "中央区" },
          { id: "11106", name: "桜区" },
          { id: "11107", name: "浦和区" },
          { id: "11108", name: "南区" },
          { id: "11109", name: "緑区" },
          { id: "11110", name: "岩槻区" },
        ],
      },
      { id: "11201", name: "川越市" },
      { id: "11202", name: "熊谷市" },
      { id: "11203", name: "川口市" },
      { id: "11206", name: "行田市" },
      { id: "11207", name: "秩父市" },
      { id: "11208", name: "所沢市" },
      { id: "11209", name: "飯能市" },
      { id: "11210", name: "加須市" },
      { id: "11211", name: "本庄市" },
      { id: "11212", name: "東松山市" },
      { id: "11214", name: "春日部市" },
      { id: "11215", name: "狭山市" },
      { id: "11216", name: "羽生市" },
      { id: "11217", name: "鴻巣市" },
      { id: "11218", name: "深谷市" },
      { id: "11219", name: "上尾市" },
      { id: "11221", name: "草加市" },
      { id: "11222", name: "越谷市" },
      { id: "11223", name: "蕨市" },
      { id: "11224", name: "戸田市" },
      { id: "11225", name: "入間市" },
      { id: "11227", name: "朝霞市" },
      { id: "11228", name: "志木市" },
      { id: "11229", name: "和光市" },
      { id: "11230", name: "新座市" },
      { id: "11231", name: "桶川市" },
      { id: "11232", name: "久喜市" },
      { id: "11233", name: "北本市" },
      { id: "11234", name: "八潮市" },
      { id: "11235", name: "富士見市" },
      { id: "11237", name: "三郷市" },
      { id: "11238", name: "蓮田市" },
      { id: "11239", name: "坂戸市" },
      { id: "11240", name: "幸手市" },
      { id: "11241", name: "鶴ケ島市" },
      { id: "11242", name: "日高市" },
      { id: "11243", name: "吉川市" },
      { id: "11245", name: "ふじみ野市" },
      { id: "11246", name: "白岡市" },
      { id: "11301", name: "伊奈町" },
      { id: "11324", name: "三芳町" },
      { id: "11326", name: "毛呂山町" },
      { id: "11327", name: "越生町" },
      { id: "11341", name: "滑川町" },
      { id: "11342", name: "嵐山町" },
      { id: "11343", name: "小川町" },
      { id: "11346", name: "川島町" },
      { id: "11347", name: "吉見町" },
      { id: "11348", name: "鳩山町" },
      { id: "11349", name: "ときがわ町" },
      { id: "11361", name: "横瀬町" },
      { id: "11362", name: "皆野町" },
      { id: "11363", name: "長瀞町" },
      { id: "11365", name: "小鹿野町" },
      { id: "11369", name: "東秩父村" },
      { id: "11381", name: "美里町" },
      { id: "11383", name: "神川町" },
      { id: "11385", name: "上里町" },
      { id: "11408", name: "寄居町" },
      { id: "11442", name: "宮代町" },
      { id: "11464", name: "杉戸町" },
      { id: "11465", name: "松伏町" },
    ],
  },
  {
    id: "12",
    name: "千葉県",
    short: "千葉",
    kana: "チバケン",
    en: "chiba",
    cities: [
      {
        id: "12100",
        name: "千葉市",
        words: [
          { id: "12101", name: "中央区" },
          { id: "12102", name: "花見川区" },
          { id: "12103", name: "稲毛区" },
          { id: "12104", name: "若葉区" },
          { id: "12105", name: "緑区" },
          { id: "12106", name: "美浜区" },
        ],
      },
      { id: "12202", name: "銚子市" },
      { id: "12203", name: "市川市" },
      { id: "12204", name: "船橋市" },
      { id: "12205", name: "館山市" },
      { id: "12206", name: "木更津市" },
      { id: "12207", name: "松戸市" },
      { id: "12208", name: "野田市" },
      { id: "12210", name: "茂原市" },
      { id: "12211", name: "成田市" },
      { id: "12212", name: "佐倉市" },
      { id: "12213", name: "東金市" },
      { id: "12215", name: "旭市" },
      { id: "12216", name: "習志野市" },
      { id: "12217", name: "柏市" },
      { id: "12218", name: "勝浦市" },
      { id: "12219", name: "市原市" },
      { id: "12220", name: "流山市" },
      { id: "12221", name: "八千代市" },
      { id: "12222", name: "我孫子市" },
      { id: "12223", name: "鴨川市" },
      { id: "12224", name: "鎌ケ谷市" },
      { id: "12225", name: "君津市" },
      { id: "12226", name: "富津市" },
      { id: "12227", name: "浦安市" },
      { id: "12228", name: "四街道市" },
      { id: "12229", name: "袖ケ浦市" },
      { id: "12230", name: "八街市" },
      { id: "12231", name: "印西市" },
      { id: "12232", name: "白井市" },
      { id: "12233", name: "富里市" },
      { id: "12234", name: "南房総市" },
      { id: "12235", name: "匝瑳市" },
      { id: "12236", name: "香取市" },
      { id: "12237", name: "山武市" },
      { id: "12238", name: "いすみ市" },
      { id: "12239", name: "大網白里市" },
      { id: "12322", name: "酒々井町" },
      { id: "12329", name: "栄町" },
      { id: "12342", name: "神崎町" },
      { id: "12347", name: "多古町" },
      { id: "12349", name: "東庄町" },
      { id: "12403", name: "九十九里町" },
      { id: "12409", name: "芝山町" },
      { id: "12410", name: "横芝光町" },
      { id: "12421", name: "一宮町" },
      { id: "12422", name: "睦沢町" },
      { id: "12423", name: "長生村" },
      { id: "12424", name: "白子町" },
      { id: "12426", name: "長柄町" },
      { id: "12427", name: "長南町" },
      { id: "12441", name: "大多喜町" },
      { id: "12443", name: "御宿町" },
      { id: "12463", name: "鋸南町" },
    ],
  },
  {
    id: "13",
    name: "東京都",
    short: "東京",
    kana: "トウキョウト",
    en: "tokyo",
    cities: [
      { id: "13101", name: "千代田区" },
      { id: "13102", name: "中央区" },
      { id: "13103", name: "港区" },
      { id: "13104", name: "新宿区" },
      { id: "13105", name: "文京区" },
      { id: "13106", name: "台東区" },
      { id: "13107", name: "墨田区" },
      { id: "13108", name: "江東区" },
      { id: "13109", name: "品川区" },
      { id: "13110", name: "目黒区" },
      { id: "13111", name: "大田区" },
      { id: "13112", name: "世田谷区" },
      { id: "13113", name: "渋谷区" },
      { id: "13114", name: "中野区" },
      { id: "13115", name: "杉並区" },
      { id: "13116", name: "豊島区" },
      { id: "13117", name: "北区" },
      { id: "13118", name: "荒川区" },
      { id: "13119", name: "板橋区" },
      { id: "13120", name: "練馬区" },
      { id: "13121", name: "足立区" },
      { id: "13122", name: "葛飾区" },
      { id: "13123", name: "江戸川区" },
      { id: "13201", name: "八王子市" },
      { id: "13202", name: "立川市" },
      { id: "13203", name: "武蔵野市" },
      { id: "13204", name: "三鷹市" },
      { id: "13205", name: "青梅市" },
      { id: "13206", name: "府中市" },
      { id: "13207", name: "昭島市" },
      { id: "13208", name: "調布市" },
      { id: "13209", name: "町田市" },
      { id: "13210", name: "小金井市" },
      { id: "13211", name: "小平市" },
      { id: "13212", name: "日野市" },
      { id: "13213", name: "東村山市" },
      { id: "13214", name: "国分寺市" },
      { id: "13215", name: "国立市" },
      { id: "13218", name: "福生市" },
      { id: "13219", name: "狛江市" },
      { id: "13220", name: "東大和市" },
      { id: "13221", name: "清瀬市" },
      { id: "13222", name: "東久留米市" },
      { id: "13223", name: "武蔵村山市" },
      { id: "13224", name: "多摩市" },
      { id: "13225", name: "稲城市" },
      { id: "13227", name: "羽村市" },
      { id: "13228", name: "あきる野市" },
      { id: "13229", name: "西東京市" },
      { id: "13303", name: "瑞穂町" },
      { id: "13305", name: "日の出町" },
      { id: "13307", name: "檜原村" },
      { id: "13308", name: "奥多摩町" },
      { id: "13361", name: "大島町" },
      { id: "13362", name: "利島村" },
      { id: "13363", name: "新島村" },
      { id: "13364", name: "神津島村" },
      { id: "13381", name: "三宅村" },
      { id: "13382", name: "御蔵島村" },
      { id: "13401", name: "八丈町" },
      { id: "13402", name: "青ヶ島村" },
      { id: "13421", name: "小笠原村" },
    ],
  },
  {
    id: "14",
    name: "神奈川県",
    short: "神奈川",
    kana: "カナガワケン",
    en: "kanagawa",
    cities: [
      {
        id: "14100",
        name: "横浜市",
        words: [
          { id: "14101", name: "鶴見区" },
          { id: "14102", name: "神奈川区" },
          { id: "14103", name: "西区" },
          { id: "14104", name: "中区" },
          { id: "14105", name: "南区" },
          { id: "14106", name: "保土ケ谷区" },
          { id: "14107", name: "磯子区" },
          { id: "14108", name: "金沢区" },
          { id: "14109", name: "港北区" },
          { id: "14110", name: "戸塚区" },
          { id: "14111", name: "港南区" },
          { id: "14112", name: "旭区" },
          { id: "14113", name: "緑区" },
          { id: "14114", name: "瀬谷区" },
          { id: "14115", name: "栄区" },
          { id: "14116", name: "泉区" },
          { id: "14117", name: "青葉区" },
          { id: "14118", name: "都筑区" },
        ],
      },
      {
        id: "14130",
        name: "川崎市",
        words: [
          { id: "14131", name: "川崎区" },
          { id: "14132", name: "幸区" },
          { id: "14133", name: "中原区" },
          { id: "14134", name: "高津区" },
          { id: "14135", name: "多摩区" },
          { id: "14136", name: "宮前区" },
          { id: "14137", name: "麻生区" },
        ],
      },
      {
        id: "14150",
        name: "相模原市",
        words: [
          { id: "14151", name: "緑区" },
          { id: "14152", name: "中央区" },
          { id: "14153", name: "南区" },
        ],
      },
      { id: "14201", name: "横須賀市" },
      { id: "14203", name: "平塚市" },
      { id: "14204", name: "鎌倉市" },
      { id: "14205", name: "藤沢市" },
      { id: "14206", name: "小田原市" },
      { id: "14207", name: "茅ヶ崎市" },
      { id: "14208", name: "逗子市" },
      { id: "14210", name: "三浦市" },
      { id: "14211", name: "秦野市" },
      { id: "14212", name: "厚木市" },
      { id: "14213", name: "大和市" },
      { id: "14214", name: "伊勢原市" },
      { id: "14215", name: "海老名市" },
      { id: "14216", name: "座間市" },
      { id: "14217", name: "南足柄市" },
      { id: "14218", name: "綾瀬市" },
      { id: "14301", name: "葉山町" },
      { id: "14321", name: "寒川町" },
      { id: "14341", name: "大磯町" },
      { id: "14342", name: "二宮町" },
      { id: "14361", name: "中井町" },
      { id: "14362", name: "大井町" },
      { id: "14363", name: "松田町" },
      { id: "14364", name: "山北町" },
      { id: "14366", name: "開成町" },
      { id: "14382", name: "箱根町" },
      { id: "14383", name: "真鶴町" },
      { id: "14384", name: "湯河原町" },
      { id: "14401", name: "愛川町" },
      { id: "14402", name: "清川村" },
    ],
  },
  {
    id: "15",
    name: "新潟県",
    short: "新潟",
    kana: "ニイガタケン",
    en: "niigata",
    cities: [
      {
        id: "15100",
        name: "新潟市",
        words: [
          { id: "15101", name: "北区" },
          { id: "15102", name: "東区" },
          { id: "15103", name: "中央区" },
          { id: "15104", name: "江南区" },
          { id: "15105", name: "秋葉区" },
          { id: "15106", name: "南区" },
          { id: "15107", name: "西区" },
          { id: "15108", name: "西蒲区" },
        ],
      },
      { id: "15202", name: "長岡市" },
      { id: "15204", name: "三条市" },
      { id: "15205", name: "柏崎市" },
      { id: "15206", name: "新発田市" },
      { id: "15208", name: "小千谷市" },
      { id: "15209", name: "加茂市" },
      { id: "15210", name: "十日町市" },
      { id: "15211", name: "見附市" },
      { id: "15212", name: "村上市" },
      { id: "15213", name: "燕市" },
      { id: "15216", name: "糸魚川市" },
      { id: "15217", name: "妙高市" },
      { id: "15218", name: "五泉市" },
      { id: "15222", name: "上越市" },
      { id: "15223", name: "阿賀野市" },
      { id: "15224", name: "佐渡市" },
      { id: "15225", name: "魚沼市" },
      { id: "15226", name: "南魚沼市" },
      { id: "15227", name: "胎内市" },
      { id: "15307", name: "聖籠町" },
      { id: "15342", name: "弥彦村" },
      { id: "15361", name: "田上町" },
      { id: "15385", name: "阿賀町" },
      { id: "15405", name: "出雲崎町" },
      { id: "15461", name: "湯沢町" },
      { id: "15482", name: "津南町" },
      { id: "15504", name: "刈羽村" },
      { id: "15581", name: "関川村" },
      { id: "15586", name: "粟島浦村" },
    ],
  },
  {
    id: "16",
    name: "富山県",
    short: "富山",
    kana: "トヤマケン",
    en: "toyama",
    cities: [
      { id: "16201", name: "富山市" },
      { id: "16202", name: "高岡市" },
      { id: "16204", name: "魚津市" },
      { id: "16205", name: "氷見市" },
      { id: "16206", name: "滑川市" },
      { id: "16207", name: "黒部市" },
      { id: "16208", name: "砺波市" },
      { id: "16209", name: "小矢部市" },
      { id: "16210", name: "南砺市" },
      { id: "16211", name: "射水市" },
      { id: "16321", name: "舟橋村" },
      { id: "16322", name: "上市町" },
      { id: "16323", name: "立山町" },
      { id: "16342", name: "入善町" },
      { id: "16343", name: "朝日町" },
    ],
  },
  {
    id: "17",
    name: "石川県",
    short: "石川",
    kana: "イシカワケン",
    en: "ishikawa",
    cities: [
      { id: "17201", name: "金沢市" },
      { id: "17202", name: "七尾市" },
      { id: "17203", name: "小松市" },
      { id: "17204", name: "輪島市" },
      { id: "17205", name: "珠洲市" },
      { id: "17206", name: "加賀市" },
      { id: "17207", name: "羽咋市" },
      { id: "17209", name: "かほく市" },
      { id: "17210", name: "白山市" },
      { id: "17211", name: "能美市" },
      { id: "17212", name: "野々市市" },
      { id: "17324", name: "川北町" },
      { id: "17361", name: "津幡町" },
      { id: "17365", name: "内灘町" },
      { id: "17384", name: "志賀町" },
      { id: "17386", name: "宝達志水町" },
      { id: "17407", name: "中能登町" },
      { id: "17461", name: "穴水町" },
      { id: "17463", name: "能登町" },
    ],
  },
  {
    id: "18",
    name: "福井県",
    short: "福井",
    kana: "フクイケン",
    en: "fukui",
    cities: [
      { id: "18201", name: "福井市" },
      { id: "18202", name: "敦賀市" },
      { id: "18204", name: "小浜市" },
      { id: "18205", name: "大野市" },
      { id: "18206", name: "勝山市" },
      { id: "18207", name: "鯖江市" },
      { id: "18208", name: "あわら市" },
      { id: "18209", name: "越前市" },
      { id: "18210", name: "坂井市" },
      { id: "18322", name: "永平寺町" },
      { id: "18382", name: "池田町" },
      { id: "18404", name: "南越前町" },
      { id: "18423", name: "越前町" },
      { id: "18442", name: "美浜町" },
      { id: "18481", name: "高浜町" },
      { id: "18483", name: "おおい町" },
      { id: "18501", name: "若狭町" },
    ],
  },
  {
    id: "19",
    name: "山梨県",
    short: "山梨",
    kana: "ヤマナシケン",
    en: "yamanashi",
    cities: [
      { id: "19201", name: "甲府市" },
      { id: "19202", name: "富士吉田市" },
      { id: "19204", name: "都留市" },
      { id: "19205", name: "山梨市" },
      { id: "19206", name: "大月市" },
      { id: "19207", name: "韮崎市" },
      { id: "19208", name: "南アルプス市" },
      { id: "19209", name: "北杜市" },
      { id: "19210", name: "甲斐市" },
      { id: "19211", name: "笛吹市" },
      { id: "19212", name: "上野原市" },
      { id: "19213", name: "甲州市" },
      { id: "19214", name: "中央市" },
      { id: "19346", name: "市川三郷町" },
      { id: "19364", name: "早川町" },
      { id: "19365", name: "身延町" },
      { id: "19366", name: "南部町" },
      { id: "19368", name: "富士川町" },
      { id: "19384", name: "昭和町" },
      { id: "19422", name: "道志村" },
      { id: "19423", name: "西桂町" },
      { id: "19424", name: "忍野村" },
      { id: "19425", name: "山中湖村" },
      { id: "19429", name: "鳴沢村" },
      { id: "19430", name: "富士河口湖町" },
      { id: "19442", name: "小菅村" },
      { id: "19443", name: "丹波山村" },
    ],
  },
  {
    id: "20",
    name: "長野県",
    short: "長野",
    kana: "ナガノケン",
    en: "nagano",
    cities: [
      { id: "20201", name: "長野市" },
      { id: "20202", name: "松本市" },
      { id: "20203", name: "上田市" },
      { id: "20204", name: "岡谷市" },
      { id: "20205", name: "飯田市" },
      { id: "20206", name: "諏訪市" },
      { id: "20207", name: "須坂市" },
      { id: "20208", name: "小諸市" },
      { id: "20209", name: "伊那市" },
      { id: "20210", name: "駒ヶ根市" },
      { id: "20211", name: "中野市" },
      { id: "20212", name: "大町市" },
      { id: "20213", name: "飯山市" },
      { id: "20214", name: "茅野市" },
      { id: "20215", name: "塩尻市" },
      { id: "20217", name: "佐久市" },
      { id: "20218", name: "千曲市" },
      { id: "20219", name: "東御市" },
      { id: "20220", name: "安曇野市" },
      { id: "20303", name: "小海町" },
      { id: "20304", name: "川上村" },
      { id: "20305", name: "南牧村" },
      { id: "20306", name: "南相木村" },
      { id: "20307", name: "北相木村" },
      { id: "20309", name: "佐久穂町" },
      { id: "20321", name: "軽井沢町" },
      { id: "20323", name: "御代田町" },
      { id: "20324", name: "立科町" },
      { id: "20349", name: "青木村" },
      { id: "20350", name: "長和町" },
      { id: "20361", name: "下諏訪町" },
      { id: "20362", name: "富士見町" },
      { id: "20363", name: "原村" },
      { id: "20382", name: "辰野町" },
      { id: "20383", name: "箕輪町" },
      { id: "20384", name: "飯島町" },
      { id: "20385", name: "南箕輪村" },
      { id: "20386", name: "中川村" },
      { id: "20388", name: "宮田村" },
      { id: "20402", name: "松川町" },
      { id: "20403", name: "高森町" },
      { id: "20404", name: "阿南町" },
      { id: "20407", name: "阿智村" },
      { id: "20409", name: "平谷村" },
      { id: "20410", name: "根羽村" },
      { id: "20411", name: "下條村" },
      { id: "20412", name: "売木村" },
      { id: "20413", name: "天龍村" },
      { id: "20414", name: "泰阜村" },
      { id: "20415", name: "喬木村" },
      { id: "20416", name: "豊丘村" },
      { id: "20417", name: "大鹿村" },
      { id: "20422", name: "上松町" },
      { id: "20423", name: "南木曽町" },
      { id: "20425", name: "木祖村" },
      { id: "20429", name: "王滝村" },
      { id: "20430", name: "大桑村" },
      { id: "20432", name: "木曽町" },
      { id: "20446", name: "麻績村" },
      { id: "20448", name: "生坂村" },
      { id: "20450", name: "山形村" },
      { id: "20451", name: "朝日村" },
      { id: "20452", name: "筑北村" },
      { id: "20481", name: "池田町" },
      { id: "20482", name: "松川村" },
      { id: "20485", name: "白馬村" },
      { id: "20486", name: "小谷村" },
      { id: "20521", name: "坂城町" },
      { id: "20541", name: "小布施町" },
      { id: "20543", name: "高山村" },
      { id: "20561", name: "山ノ内町" },
      { id: "20562", name: "木島平村" },
      { id: "20563", name: "野沢温泉村" },
      { id: "20583", name: "信濃町" },
      { id: "20588", name: "小川村" },
      { id: "20590", name: "飯綱町" },
      { id: "20602", name: "栄村" },
    ],
  },
  {
    id: "21",
    name: "岐阜県",
    short: "岐阜",
    kana: "ギフケン",
    en: "gifu",
    cities: [
      { id: "21201", name: "岐阜市" },
      { id: "21202", name: "大垣市" },
      { id: "21203", name: "高山市" },
      { id: "21204", name: "多治見市" },
      { id: "21205", name: "関市" },
      { id: "21206", name: "中津川市" },
      { id: "21207", name: "美濃市" },
      { id: "21208", name: "瑞浪市" },
      { id: "21209", name: "羽島市" },
      { id: "21210", name: "恵那市" },
      { id: "21211", name: "美濃加茂市" },
      { id: "21212", name: "土岐市" },
      { id: "21213", name: "各務原市" },
      { id: "21214", name: "可児市" },
      { id: "21215", name: "山県市" },
      { id: "21216", name: "瑞穂市" },
      { id: "21217", name: "飛騨市" },
      { id: "21218", name: "本巣市" },
      { id: "21219", name: "郡上市" },
      { id: "21220", name: "下呂市" },
      { id: "21221", name: "海津市" },
      { id: "21302", name: "岐南町" },
      { id: "21303", name: "笠松町" },
      { id: "21341", name: "養老町" },
      { id: "21361", name: "垂井町" },
      { id: "21362", name: "関ケ原町" },
      { id: "21381", name: "神戸町" },
      { id: "21382", name: "輪之内町" },
      { id: "21383", name: "安八町" },
      { id: "21401", name: "揖斐川町" },
      { id: "21403", name: "大野町" },
      { id: "21404", name: "池田町" },
      { id: "21421", name: "北方町" },
      { id: "21501", name: "坂祝町" },
      { id: "21502", name: "富加町" },
      { id: "21503", name: "川辺町" },
      { id: "21504", name: "七宗町" },
      { id: "21505", name: "八百津町" },
      { id: "21506", name: "白川町" },
      { id: "21507", name: "東白川村" },
      { id: "21521", name: "御嵩町" },
      { id: "21604", name: "白川村" },
    ],
  },
  {
    id: "22",
    name: "静岡県",
    short: "静岡",
    kana: "シズオカケン",
    en: "shizuoka",
    cities: [
      {
        id: "22100",
        name: "静岡市",
        words: [
          { id: "22101", name: "葵区" },
          { id: "22102", name: "駿河区" },
          { id: "22103", name: "清水区" },
        ],
      },
      {
        id: "22130",
        name: "浜松市",
        words: [
          { id: "22131", name: "中区" },
          { id: "22132", name: "東区" },
          { id: "22133", name: "西区" },
          { id: "22134", name: "南区" },
          { id: "22135", name: "北区" },
          { id: "22136", name: "浜北区" },
          { id: "22137", name: "天竜区" },
        ],
      },
      { id: "22203", name: "沼津市" },
      { id: "22205", name: "熱海市" },
      { id: "22206", name: "三島市" },
      { id: "22207", name: "富士宮市" },
      { id: "22208", name: "伊東市" },
      { id: "22209", name: "島田市" },
      { id: "22210", name: "富士市" },
      { id: "22211", name: "磐田市" },
      { id: "22212", name: "焼津市" },
      { id: "22213", name: "掛川市" },
      { id: "22214", name: "藤枝市" },
      { id: "22215", name: "御殿場市" },
      { id: "22216", name: "袋井市" },
      { id: "22219", name: "下田市" },
      { id: "22220", name: "裾野市" },
      { id: "22221", name: "湖西市" },
      { id: "22222", name: "伊豆市" },
      { id: "22223", name: "御前崎市" },
      { id: "22224", name: "菊川市" },
      { id: "22225", name: "伊豆の国市" },
      { id: "22226", name: "牧之原市" },
      { id: "22301", name: "東伊豆町" },
      { id: "22302", name: "河津町" },
      { id: "22304", name: "南伊豆町" },
      { id: "22305", name: "松崎町" },
      { id: "22306", name: "西伊豆町" },
      { id: "22325", name: "函南町" },
      { id: "22341", name: "清水町" },
      { id: "22342", name: "長泉町" },
      { id: "22344", name: "小山町" },
      { id: "22424", name: "吉田町" },
      { id: "22429", name: "川根本町" },
      { id: "22461", name: "森町" },
    ],
  },
  {
    id: "23",
    name: "愛知県",
    short: "愛知",
    kana: "アイチケン",
    en: "aichi",
    cities: [
      {
        id: "23100",
        name: "名古屋市",
        words: [
          { id: "23101", name: "千種区" },
          { id: "23102", name: "東区" },
          { id: "23103", name: "北区" },
          { id: "23104", name: "西区" },
          { id: "23105", name: "中村区" },
          { id: "23106", name: "中区" },
          { id: "23107", name: "昭和区" },
          { id: "23108", name: "瑞穂区" },
          { id: "23109", name: "熱田区" },
          { id: "23110", name: "中川区" },
          { id: "23111", name: "港区" },
          { id: "23112", name: "南区" },
          { id: "23113", name: "守山区" },
          { id: "23114", name: "緑区" },
          { id: "23115", name: "名東区" },
          { id: "23116", name: "天白区" },
        ],
      },
      { id: "23201", name: "豊橋市" },
      { id: "23202", name: "岡崎市" },
      { id: "23203", name: "一宮市" },
      { id: "23204", name: "瀬戸市" },
      { id: "23205", name: "半田市" },
      { id: "23206", name: "春日井市" },
      { id: "23207", name: "豊川市" },
      { id: "23208", name: "津島市" },
      { id: "23209", name: "碧南市" },
      { id: "23210", name: "刈谷市" },
      { id: "23211", name: "豊田市" },
      { id: "23212", name: "安城市" },
      { id: "23213", name: "西尾市" },
      { id: "23214", name: "蒲郡市" },
      { id: "23215", name: "犬山市" },
      { id: "23216", name: "常滑市" },
      { id: "23217", name: "江南市" },
      { id: "23219", name: "小牧市" },
      { id: "23220", name: "稲沢市" },
      { id: "23221", name: "新城市" },
      { id: "23222", name: "東海市" },
      { id: "23223", name: "大府市" },
      { id: "23224", name: "知多市" },
      { id: "23225", name: "知立市" },
      { id: "23226", name: "尾張旭市" },
      { id: "23227", name: "高浜市" },
      { id: "23228", name: "岩倉市" },
      { id: "23229", name: "豊明市" },
      { id: "23230", name: "日進市" },
      { id: "23231", name: "田原市" },
      { id: "23232", name: "愛西市" },
      { id: "23233", name: "清須市" },
      { id: "23234", name: "北名古屋市" },
      { id: "23235", name: "弥富市" },
      { id: "23236", name: "みよし市" },
      { id: "23237", name: "あま市" },
      { id: "23238", name: "長久手市" },
      { id: "23302", name: "東郷町" },
      { id: "23342", name: "豊山町" },
      { id: "23361", name: "大口町" },
      { id: "23362", name: "扶桑町" },
      { id: "23424", name: "大治町" },
      { id: "23425", name: "蟹江町" },
      { id: "23427", name: "飛島村" },
      { id: "23441", name: "阿久比町" },
      { id: "23442", name: "東浦町" },
      { id: "23445", name: "南知多町" },
      { id: "23446", name: "美浜町" },
      { id: "23447", name: "武豊町" },
      { id: "23501", name: "幸田町" },
      { id: "23561", name: "設楽町" },
      { id: "23562", name: "東栄町" },
      { id: "23563", name: "豊根村" },
    ],
  },
  {
    id: "24",
    name: "三重県",
    short: "三重",
    kana: "ミエケン",
    en: "mie",
    cities: [
      { id: "24201", name: "津市" },
      { id: "24202", name: "四日市市" },
      { id: "24203", name: "伊勢市" },
      { id: "24204", name: "松阪市" },
      { id: "24205", name: "桑名市" },
      { id: "24207", name: "鈴鹿市" },
      { id: "24208", name: "名張市" },
      { id: "24209", name: "尾鷲市" },
      { id: "24210", name: "亀山市" },
      { id: "24211", name: "鳥羽市" },
      { id: "24212", name: "熊野市" },
      { id: "24214", name: "いなべ市" },
      { id: "24215", name: "志摩市" },
      { id: "24216", name: "伊賀市" },
      { id: "24303", name: "木曽岬町" },
      { id: "24324", name: "東員町" },
      { id: "24341", name: "菰野町" },
      { id: "24343", name: "朝日町" },
      { id: "24344", name: "川越町" },
      { id: "24441", name: "多気町" },
      { id: "24442", name: "明和町" },
      { id: "24443", name: "大台町" },
      { id: "24461", name: "玉城町" },
      { id: "24470", name: "度会町" },
      { id: "24471", name: "大紀町" },
      { id: "24472", name: "南伊勢町" },
      { id: "24543", name: "紀北町" },
      { id: "24561", name: "御浜町" },
      { id: "24562", name: "紀宝町" },
    ],
  },
  {
    id: "25",
    name: "滋賀県",
    short: "滋賀",
    kana: "シガケン",
    en: "shiga",
    cities: [
      { id: "25201", name: "大津市" },
      { id: "25202", name: "彦根市" },
      { id: "25203", name: "長浜市" },
      { id: "25204", name: "近江八幡市" },
      { id: "25206", name: "草津市" },
      { id: "25207", name: "守山市" },
      { id: "25208", name: "栗東市" },
      { id: "25209", name: "甲賀市" },
      { id: "25210", name: "野洲市" },
      { id: "25211", name: "湖南市" },
      { id: "25212", name: "高島市" },
      { id: "25213", name: "東近江市" },
      { id: "25214", name: "米原市" },
      { id: "25383", name: "日野町" },
      { id: "25384", name: "竜王町" },
      { id: "25425", name: "愛荘町" },
      { id: "25441", name: "豊郷町" },
      { id: "25442", name: "甲良町" },
      { id: "25443", name: "多賀町" },
    ],
  },
  {
    id: "26",
    name: "京都府",
    short: "京都",
    kana: "キョウトフ",
    en: "kyoto",
    cities: [
      {
        id: "26100",
        name: "京都市",
        words: [
          { id: "26101", name: "北区" },
          { id: "26102", name: "上京区" },
          { id: "26103", name: "左京区" },
          { id: "26104", name: "中京区" },
          { id: "26105", name: "東山区" },
          { id: "26106", name: "下京区" },
          { id: "26107", name: "南区" },
          { id: "26108", name: "右京区" },
          { id: "26109", name: "伏見区" },
          { id: "26110", name: "山科区" },
          { id: "26111", name: "西京区" },
        ],
      },
      { id: "26201", name: "福知山市" },
      { id: "26202", name: "舞鶴市" },
      { id: "26203", name: "綾部市" },
      { id: "26204", name: "宇治市" },
      { id: "26205", name: "宮津市" },
      { id: "26206", name: "亀岡市" },
      { id: "26207", name: "城陽市" },
      { id: "26208", name: "向日市" },
      { id: "26209", name: "長岡京市" },
      { id: "26210", name: "八幡市" },
      { id: "26211", name: "京田辺市" },
      { id: "26212", name: "京丹後市" },
      { id: "26213", name: "南丹市" },
      { id: "26214", name: "木津川市" },
      { id: "26303", name: "大山崎町" },
      { id: "26322", name: "久御山町" },
      { id: "26343", name: "井手町" },
      { id: "26344", name: "宇治田原町" },
      { id: "26364", name: "笠置町" },
      { id: "26365", name: "和束町" },
      { id: "26366", name: "精華町" },
      { id: "26367", name: "南山城村" },
      { id: "26407", name: "京丹波町" },
      { id: "26463", name: "伊根町" },
      { id: "26465", name: "与謝野町" },
    ],
  },
  {
    id: "27",
    name: "大阪府",
    short: "大阪",
    kana: "オオサカフ",
    en: "osaka",
    cities: [
      {
        id: "27100",
        name: "大阪市",
        words: [
          { id: "27102", name: "都島区" },
          { id: "27103", name: "福島区" },
          { id: "27104", name: "此花区" },
          { id: "27106", name: "西区" },
          { id: "27107", name: "港区" },
          { id: "27108", name: "大正区" },
          { id: "27109", name: "天王寺区" },
          { id: "27111", name: "浪速区" },
          { id: "27113", name: "西淀川区" },
          { id: "27114", name: "東淀川区" },
          { id: "27115", name: "東成区" },
          { id: "27116", name: "生野区" },
          { id: "27117", name: "旭区" },
          { id: "27118", name: "城東区" },
          { id: "27119", name: "阿倍野区" },
          { id: "27120", name: "住吉区" },
          { id: "27121", name: "東住吉区" },
          { id: "27122", name: "西成区" },
          { id: "27123", name: "淀川区" },
          { id: "27124", name: "鶴見区" },
          { id: "27125", name: "住之江区" },
          { id: "27126", name: "平野区" },
          { id: "27127", name: "北区" },
          { id: "27128", name: "中央区" },
          { id: "27140", name: "堺市" },
          { id: "27141", name: "堺区" },
          { id: "27142", name: "中区" },
          { id: "27143", name: "東区" },
          { id: "27144", name: "西区" },
          { id: "27145", name: "南区" },
          { id: "27146", name: "北区" },
          { id: "27147", name: "美原区" },
        ],
      },
      { id: "27202", name: "岸和田市" },
      { id: "27203", name: "豊中市" },
      { id: "27204", name: "池田市" },
      { id: "27205", name: "吹田市" },
      { id: "27206", name: "泉大津市" },
      { id: "27207", name: "高槻市" },
      { id: "27208", name: "貝塚市" },
      { id: "27209", name: "守口市" },
      { id: "27210", name: "枚方市" },
      { id: "27211", name: "茨木市" },
      { id: "27212", name: "八尾市" },
      { id: "27213", name: "泉佐野市" },
      { id: "27214", name: "富田林市" },
      { id: "27215", name: "寝屋川市" },
      { id: "27216", name: "河内長野市" },
      { id: "27217", name: "松原市" },
      { id: "27218", name: "大東市" },
      { id: "27219", name: "和泉市" },
      { id: "27220", name: "箕面市" },
      { id: "27221", name: "柏原市" },
      { id: "27222", name: "羽曳野市" },
      { id: "27223", name: "門真市" },
      { id: "27224", name: "摂津市" },
      { id: "27225", name: "高石市" },
      { id: "27226", name: "藤井寺市" },
      { id: "27227", name: "東大阪市" },
      { id: "27228", name: "泉南市" },
      { id: "27229", name: "四條畷市" },
      { id: "27230", name: "交野市" },
      { id: "27231", name: "大阪狭山市" },
      { id: "27232", name: "阪南市" },
      { id: "27301", name: "島本町" },
      { id: "27321", name: "豊能町" },
      { id: "27322", name: "能勢町" },
      { id: "27341", name: "忠岡町" },
      { id: "27361", name: "熊取町" },
      { id: "27362", name: "田尻町" },
      { id: "27366", name: "岬町" },
      { id: "27381", name: "太子町" },
      { id: "27382", name: "河南町" },
      { id: "27383", name: "千早赤阪村" },
    ],
  },
  {
    id: "28",
    name: "兵庫県",
    short: "兵庫",
    kana: "ヒョウゴケン",
    en: "hyogo",
    cities: [
      {
        id: "28100",
        name: "神戸市",
        words: [
          { id: "28101", name: "東灘区" },
          { id: "28102", name: "灘区" },
          { id: "28105", name: "兵庫区" },
          { id: "28106", name: "長田区" },
          { id: "28107", name: "須磨区" },
          { id: "28108", name: "垂水区" },
          { id: "28109", name: "北区" },
          { id: "28110", name: "中央区" },
          { id: "28111", name: "西区" },
        ],
      },
      { id: "28201", name: "姫路市" },
      { id: "28202", name: "尼崎市" },
      { id: "28203", name: "明石市" },
      { id: "28204", name: "西宮市" },
      { id: "28205", name: "洲本市" },
      { id: "28206", name: "芦屋市" },
      { id: "28207", name: "伊丹市" },
      { id: "28208", name: "相生市" },
      { id: "28209", name: "豊岡市" },
      { id: "28210", name: "加古川市" },
      { id: "28212", name: "赤穂市" },
      { id: "28213", name: "西脇市" },
      { id: "28214", name: "宝塚市" },
      { id: "28215", name: "三木市" },
      { id: "28216", name: "高砂市" },
      { id: "28217", name: "川西市" },
      { id: "28218", name: "小野市" },
      { id: "28219", name: "三田市" },
      { id: "28220", name: "加西市" },
      { id: "28221", name: "丹波篠山市" },
      { id: "28222", name: "養父市" },
      { id: "28223", name: "丹波市" },
      { id: "28224", name: "南あわじ市" },
      { id: "28225", name: "朝来市" },
      { id: "28226", name: "淡路市" },
      { id: "28227", name: "宍粟市" },
      { id: "28228", name: "加東市" },
      { id: "28229", name: "たつの市" },
      { id: "28301", name: "猪名川町" },
      { id: "28365", name: "多可町" },
      { id: "28381", name: "稲美町" },
      { id: "28382", name: "播磨町" },
      { id: "28442", name: "市川町" },
      { id: "28443", name: "福崎町" },
      { id: "28446", name: "神河町" },
      { id: "28464", name: "太子町" },
      { id: "28481", name: "上郡町" },
      { id: "28501", name: "佐用町" },
      { id: "28585", name: "香美町" },
      { id: "28586", name: "新温泉町" },
    ],
  },
  {
    id: "29",
    name: "奈良県",
    short: "奈良",
    kana: "ナラケン",
    en: "nara",
    cities: [
      { id: "29201", name: "奈良市" },
      { id: "29202", name: "大和高田市" },
      { id: "29203", name: "大和郡山市" },
      { id: "29204", name: "天理市" },
      { id: "29205", name: "橿原市" },
      { id: "29206", name: "桜井市" },
      { id: "29207", name: "五條市" },
      { id: "29208", name: "御所市" },
      { id: "29209", name: "生駒市" },
      { id: "29210", name: "香芝市" },
      { id: "29211", name: "葛城市" },
      { id: "29212", name: "宇陀市" },
      { id: "29322", name: "山添村" },
      { id: "29342", name: "平群町" },
      { id: "29343", name: "三郷町" },
      { id: "29344", name: "斑鳩町" },
      { id: "29345", name: "安堵町" },
      { id: "29361", name: "川西町" },
      { id: "29362", name: "三宅町" },
      { id: "29363", name: "田原本町" },
      { id: "29385", name: "曽爾村" },
      { id: "29386", name: "御杖村" },
      { id: "29401", name: "高取町" },
      { id: "29402", name: "明日香村" },
      { id: "29424", name: "上牧町" },
      { id: "29425", name: "王寺町" },
      { id: "29426", name: "広陵町" },
      { id: "29427", name: "河合町" },
      { id: "29441", name: "吉野町" },
      { id: "29442", name: "大淀町" },
      { id: "29443", name: "下市町" },
      { id: "29444", name: "黒滝村" },
      { id: "29446", name: "天川村" },
      { id: "29447", name: "野迫川村" },
      { id: "29449", name: "十津川村" },
      { id: "29450", name: "下北山村" },
      { id: "29451", name: "上北山村" },
      { id: "29452", name: "川上村" },
      { id: "29453", name: "東吉野村" },
    ],
  },
  {
    id: "30",
    name: "和歌山県",
    short: "和歌山",
    kana: "ワカヤマケン",
    en: "wakayama",
    cities: [
      { id: "30201", name: "和歌山市" },
      { id: "30202", name: "海南市" },
      { id: "30203", name: "橋本市" },
      { id: "30204", name: "有田市" },
      { id: "30205", name: "御坊市" },
      { id: "30206", name: "田辺市" },
      { id: "30207", name: "新宮市" },
      { id: "30208", name: "紀の川市" },
      { id: "30209", name: "岩出市" },
      { id: "30304", name: "紀美野町" },
      { id: "30341", name: "かつらぎ町" },
      { id: "30343", name: "九度山町" },
      { id: "30344", name: "高野町" },
      { id: "30361", name: "湯浅町" },
      { id: "30362", name: "広川町" },
      { id: "30366", name: "有田川町" },
      { id: "30381", name: "美浜町" },
      { id: "30382", name: "日高町" },
      { id: "30383", name: "由良町" },
      { id: "30390", name: "印南町" },
      { id: "30391", name: "みなべ町" },
      { id: "30392", name: "日高川町" },
      { id: "30401", name: "白浜町" },
      { id: "30404", name: "上富田町" },
      { id: "30406", name: "すさみ町" },
      { id: "30421", name: "那智勝浦町" },
      { id: "30422", name: "太地町" },
      { id: "30424", name: "古座川町" },
      { id: "30427", name: "北山村" },
      { id: "30428", name: "串本町" },
    ],
  },
  {
    id: "31",
    name: "鳥取県",
    short: "鳥取",
    kana: "トットリケン",
    en: "tottori",
    cities: [
      { id: "31201", name: "鳥取市" },
      { id: "31202", name: "米子市" },
      { id: "31203", name: "倉吉市" },
      { id: "31204", name: "境港市" },
      { id: "31302", name: "岩美町" },
      { id: "31325", name: "若桜町" },
      { id: "31328", name: "智頭町" },
      { id: "31329", name: "八頭町" },
      { id: "31364", name: "三朝町" },
      { id: "31370", name: "湯梨浜町" },
      { id: "31371", name: "琴浦町" },
      { id: "31372", name: "北栄町" },
      { id: "31384", name: "日吉津村" },
      { id: "31386", name: "大山町" },
      { id: "31389", name: "南部町" },
      { id: "31390", name: "伯耆町" },
      { id: "31401", name: "日南町" },
      { id: "31402", name: "日野町" },
      { id: "31403", name: "江府町" },
    ],
  },
  {
    id: "32",
    name: "島根県",
    short: "島根",
    kana: "シマネケン",
    en: "shimane",
    cities: [
      { id: "32201", name: "松江市" },
      { id: "32202", name: "浜田市" },
      { id: "32203", name: "出雲市" },
      { id: "32204", name: "益田市" },
      { id: "32205", name: "大田市" },
      { id: "32206", name: "安来市" },
      { id: "32207", name: "江津市" },
      { id: "32209", name: "雲南市" },
      { id: "32343", name: "奥出雲町" },
      { id: "32386", name: "飯南町" },
      { id: "32441", name: "川本町" },
      { id: "32448", name: "美郷町" },
      { id: "32449", name: "邑南町" },
      { id: "32501", name: "津和野町" },
      { id: "32505", name: "吉賀町" },
      { id: "32525", name: "海士町" },
      { id: "32526", name: "西ノ島町" },
      { id: "32527", name: "知夫村" },
      { id: "32528", name: "隠岐の島町" },
    ],
  },
  {
    id: "33",
    name: "岡山県",
    short: "岡山",
    kana: "オカヤマケン",
    en: "okayama",
    cities: [
      {
        id: "33100",
        name: "岡山市",
        words: [
          { id: "33101", name: "北区" },
          { id: "33102", name: "中区" },
          { id: "33103", name: "東区" },
          { id: "33104", name: "南区" },
        ],
      },
      { id: "33202", name: "倉敷市" },
      { id: "33203", name: "津山市" },
      { id: "33204", name: "玉野市" },
      { id: "33205", name: "笠岡市" },
      { id: "33207", name: "井原市" },
      { id: "33208", name: "総社市" },
      { id: "33209", name: "高梁市" },
      { id: "33210", name: "新見市" },
      { id: "33211", name: "備前市" },
      { id: "33212", name: "瀬戸内市" },
      { id: "33213", name: "赤磐市" },
      { id: "33214", name: "真庭市" },
      { id: "33215", name: "美作市" },
      { id: "33216", name: "浅口市" },
      { id: "33346", name: "和気町" },
      { id: "33423", name: "早島町" },
      { id: "33445", name: "里庄町" },
      { id: "33461", name: "矢掛町" },
      { id: "33586", name: "新庄村" },
      { id: "33606", name: "鏡野町" },
      { id: "33622", name: "勝央町" },
      { id: "33623", name: "奈義町" },
      { id: "33643", name: "西粟倉村" },
      { id: "33663", name: "久米南町" },
      { id: "33666", name: "美咲町" },
      { id: "33681", name: "吉備中央町" },
    ],
  },
  {
    id: "34",
    name: "広島県",
    short: "広島",
    kana: "ヒロシマケン",
    en: "hiroshima",
    cities: [
      {
        id: "34100",
        name: "広島市",
        words: [
          { id: "34101", name: "中区" },
          { id: "34102", name: "東区" },
          { id: "34103", name: "南区" },
          { id: "34104", name: "西区" },
          { id: "34105", name: "安佐南区" },
          { id: "34106", name: "安佐北区" },
          { id: "34107", name: "安芸区" },
          { id: "34108", name: "佐伯区" },
        ],
      },
      { id: "34202", name: "呉市" },
      { id: "34203", name: "竹原市" },
      { id: "34204", name: "三原市" },
      { id: "34205", name: "尾道市" },
      { id: "34207", name: "福山市" },
      { id: "34208", name: "府中市" },
      { id: "34209", name: "三次市" },
      { id: "34210", name: "庄原市" },
      { id: "34211", name: "大竹市" },
      { id: "34212", name: "東広島市" },
      { id: "34213", name: "廿日市市" },
      { id: "34214", name: "安芸高田市" },
      { id: "34215", name: "江田島市" },
      { id: "34302", name: "府中町" },
      { id: "34304", name: "海田町" },
      { id: "34307", name: "熊野町" },
      { id: "34309", name: "坂町" },
      { id: "34368", name: "安芸太田町" },
      { id: "34369", name: "北広島町" },
      { id: "34431", name: "大崎上島町" },
      { id: "34462", name: "世羅町" },
      { id: "34545", name: "神石高原町" },
    ],
  },
  {
    id: "35",
    name: "山口県",
    short: "山口",
    kana: "ヤマグチケン",
    en: "yamaguchi",
    cities: [
      { id: "35201", name: "下関市" },
      { id: "35202", name: "宇部市" },
      { id: "35203", name: "山口市" },
      { id: "35204", name: "萩市" },
      { id: "35206", name: "防府市" },
      { id: "35207", name: "下松市" },
      { id: "35208", name: "岩国市" },
      { id: "35210", name: "光市" },
      { id: "35211", name: "長門市" },
      { id: "35212", name: "柳井市" },
      { id: "35213", name: "美祢市" },
      { id: "35215", name: "周南市" },
      { id: "35216", name: "山陽小野田市" },
      { id: "35305", name: "周防大島町" },
      { id: "35321", name: "和木町" },
      { id: "35341", name: "上関町" },
      { id: "35343", name: "田布施町" },
      { id: "35344", name: "平生町" },
      { id: "35502", name: "阿武町" },
    ],
  },
  {
    id: "36",
    name: "徳島県",
    short: "徳島",
    kana: "トクシマケン",
    en: "tokushima",
    cities: [
      { id: "36201", name: "徳島市" },
      { id: "36202", name: "鳴門市" },
      { id: "36203", name: "小松島市" },
      { id: "36204", name: "阿南市" },
      { id: "36205", name: "吉野川市" },
      { id: "36206", name: "阿波市" },
      { id: "36207", name: "美馬市" },
      { id: "36208", name: "三好市" },
      { id: "36301", name: "勝浦町" },
      { id: "36302", name: "上勝町" },
      { id: "36321", name: "佐那河内村" },
      { id: "36341", name: "石井町" },
      { id: "36342", name: "神山町" },
      { id: "36368", name: "那賀町" },
      { id: "36383", name: "牟岐町" },
      { id: "36387", name: "美波町" },
      { id: "36388", name: "海陽町" },
      { id: "36401", name: "松茂町" },
      { id: "36402", name: "北島町" },
      { id: "36403", name: "藍住町" },
      { id: "36404", name: "板野町" },
      { id: "36405", name: "上板町" },
      { id: "36468", name: "つるぎ町" },
      { id: "36489", name: "東みよし町" },
    ],
  },
  {
    id: "37",
    name: "香川県",
    short: "香川",
    kana: "カガワケン",
    en: "kagawa",
    cities: [
      { id: "37201", name: "高松市" },
      { id: "37202", name: "丸亀市" },
      { id: "37203", name: "坂出市" },
      { id: "37204", name: "善通寺市" },
      { id: "37205", name: "観音寺市" },
      { id: "37206", name: "さぬき市" },
      { id: "37207", name: "東かがわ市" },
      { id: "37208", name: "三豊市" },
      { id: "37322", name: "土庄町" },
      { id: "37324", name: "小豆島町" },
      { id: "37341", name: "三木町" },
      { id: "37364", name: "直島町" },
      { id: "37386", name: "宇多津町" },
      { id: "37387", name: "綾川町" },
      { id: "37403", name: "琴平町" },
      { id: "37404", name: "多度津町" },
      { id: "37406", name: "まんのう町" },
    ],
  },
  {
    id: "38",
    name: "愛媛県",
    short: "愛媛",
    kana: "エヒメケン",
    en: "ehime",
    cities: [
      { id: "38201", name: "松山市" },
      { id: "38202", name: "今治市" },
      { id: "38203", name: "宇和島市" },
      { id: "38204", name: "八幡浜市" },
      { id: "38205", name: "新居浜市" },
      { id: "38206", name: "西条市" },
      { id: "38207", name: "大洲市" },
      { id: "38210", name: "伊予市" },
      { id: "38213", name: "四国中央市" },
      { id: "38214", name: "西予市" },
      { id: "38215", name: "東温市" },
      { id: "38356", name: "上島町" },
      { id: "38386", name: "久万高原町" },
      { id: "38401", name: "松前町" },
      { id: "38402", name: "砥部町" },
      { id: "38422", name: "内子町" },
      { id: "38442", name: "伊方町" },
      { id: "38484", name: "松野町" },
      { id: "38488", name: "鬼北町" },
      { id: "38506", name: "愛南町" },
    ],
  },
  {
    id: "39",
    name: "高知県",
    short: "高知",
    kana: "コウチケン",
    en: "kochi",
    cities: [
      { id: "39201", name: "高知市" },
      { id: "39202", name: "室戸市" },
      { id: "39203", name: "安芸市" },
      { id: "39204", name: "南国市" },
      { id: "39205", name: "土佐市" },
      { id: "39206", name: "須崎市" },
      { id: "39208", name: "宿毛市" },
      { id: "39209", name: "土佐清水市" },
      { id: "39210", name: "四万十市" },
      { id: "39211", name: "香南市" },
      { id: "39212", name: "香美市" },
      { id: "39301", name: "東洋町" },
      { id: "39302", name: "奈半利町" },
      { id: "39303", name: "田野町" },
      { id: "39304", name: "安田町" },
      { id: "39305", name: "北川村" },
      { id: "39306", name: "馬路村" },
      { id: "39307", name: "芸西村" },
      { id: "39341", name: "本山町" },
      { id: "39344", name: "大豊町" },
      { id: "39363", name: "土佐町" },
      { id: "39364", name: "大川村" },
      { id: "39386", name: "いの町" },
      { id: "39387", name: "仁淀川町" },
      { id: "39401", name: "中土佐町" },
      { id: "39402", name: "佐川町" },
      { id: "39403", name: "越知町" },
      { id: "39405", name: "檮原町" },
      { id: "39410", name: "日高村" },
      { id: "39411", name: "津野町" },
      { id: "39412", name: "四万十町" },
      { id: "39424", name: "大月町" },
      { id: "39427", name: "三原村" },
      { id: "39428", name: "黒潮町" },
    ],
  },
  {
    id: "40",
    name: "福岡県",
    short: "福岡",
    kana: "フクオカケン",
    en: "fukuoka",
    cities: [
      {
        id: "40100",
        name: "北九州市",
        words: [
          { id: "40101", name: "門司区" },
          { id: "40103", name: "若松区" },
          { id: "40105", name: "戸畑区" },
          { id: "40106", name: "小倉北区" },
          { id: "40107", name: "小倉南区" },
          { id: "40108", name: "八幡東区" },
          { id: "40109", name: "八幡西区" },
        ],
      },
      {
        id: "40130",
        name: "福岡市",
        words: [
          { id: "40131", name: "東区" },
          { id: "40132", name: "博多区" },
          { id: "40133", name: "中央区" },
          { id: "40134", name: "南区" },
          { id: "40135", name: "西区" },
          { id: "40136", name: "城南区" },
          { id: "40137", name: "早良区" },
        ],
      },
      { id: "40202", name: "大牟田市" },
      { id: "40203", name: "久留米市" },
      { id: "40204", name: "直方市" },
      { id: "40205", name: "飯塚市" },
      { id: "40206", name: "田川市" },
      { id: "40207", name: "柳川市" },
      { id: "40210", name: "八女市" },
      { id: "40211", name: "筑後市" },
      { id: "40212", name: "大川市" },
      { id: "40213", name: "行橋市" },
      { id: "40214", name: "豊前市" },
      { id: "40215", name: "中間市" },
      { id: "40216", name: "小郡市" },
      { id: "40217", name: "筑紫野市" },
      { id: "40218", name: "春日市" },
      { id: "40219", name: "大野城市" },
      { id: "40220", name: "宗像市" },
      { id: "40221", name: "太宰府市" },
      { id: "40223", name: "古賀市" },
      { id: "40224", name: "福津市" },
      { id: "40225", name: "うきは市" },
      { id: "40226", name: "宮若市" },
      { id: "40227", name: "嘉麻市" },
      { id: "40228", name: "朝倉市" },
      { id: "40229", name: "みやま市" },
      { id: "40230", name: "糸島市" },
      { id: "40231", name: "那珂川市" },
      { id: "40341", name: "宇美町" },
      { id: "40342", name: "篠栗町" },
      { id: "40343", name: "志免町" },
      { id: "40344", name: "須恵町" },
      { id: "40345", name: "新宮町" },
      { id: "40348", name: "久山町" },
      { id: "40349", name: "粕屋町" },
      { id: "40381", name: "芦屋町" },
      { id: "40382", name: "水巻町" },
      { id: "40383", name: "岡垣町" },
      { id: "40384", name: "遠賀町" },
      { id: "40401", name: "小竹町" },
      { id: "40402", name: "鞍手町" },
      { id: "40421", name: "桂川町" },
      { id: "40447", name: "筑前町" },
      { id: "40448", name: "東峰村" },
      { id: "40503", name: "大刀洗町" },
      { id: "40522", name: "大木町" },
      { id: "40544", name: "広川町" },
      { id: "40601", name: "香春町" },
      { id: "40602", name: "添田町" },
      { id: "40604", name: "糸田町" },
      { id: "40605", name: "川崎町" },
      { id: "40608", name: "大任町" },
      { id: "40609", name: "赤村" },
      { id: "40610", name: "福智町" },
      { id: "40621", name: "苅田町" },
      { id: "40625", name: "みやこ町" },
      { id: "40642", name: "吉富町" },
      { id: "40646", name: "上毛町" },
      { id: "40647", name: "築上町" },
    ],
  },
  {
    id: "41",
    name: "佐賀県",
    short: "佐賀",
    kana: "サガケン",
    en: "saga",
    cities: [
      { id: "41201", name: "佐賀市" },
      { id: "41202", name: "唐津市" },
      { id: "41203", name: "鳥栖市" },
      { id: "41204", name: "多久市" },
      { id: "41205", name: "伊万里市" },
      { id: "41206", name: "武雄市" },
      { id: "41207", name: "鹿島市" },
      { id: "41208", name: "小城市" },
      { id: "41209", name: "嬉野市" },
      { id: "41210", name: "神埼市" },
      { id: "41327", name: "吉野ヶ里町" },
      { id: "41341", name: "基山町" },
      { id: "41345", name: "上峰町" },
      { id: "41346", name: "みやき町" },
      { id: "41387", name: "玄海町" },
      { id: "41401", name: "有田町" },
      { id: "41423", name: "大町町" },
      { id: "41424", name: "江北町" },
      { id: "41425", name: "白石町" },
      { id: "41441", name: "太良町" },
    ],
  },
  {
    id: "42",
    name: "長崎県",
    short: "長崎",
    kana: "ナガサキケン",
    en: "nagasaki",
    cities: [
      { id: "42201", name: "長崎市" },
      { id: "42202", name: "佐世保市" },
      { id: "42203", name: "島原市" },
      { id: "42204", name: "諫早市" },
      { id: "42205", name: "大村市" },
      { id: "42207", name: "平戸市" },
      { id: "42208", name: "松浦市" },
      { id: "42209", name: "対馬市" },
      { id: "42210", name: "壱岐市" },
      { id: "42211", name: "五島市" },
      { id: "42212", name: "西海市" },
      { id: "42213", name: "雲仙市" },
      { id: "42214", name: "南島原市" },
      { id: "42307", name: "長与町" },
      { id: "42308", name: "時津町" },
      { id: "42321", name: "東彼杵町" },
      { id: "42322", name: "川棚町" },
      { id: "42323", name: "波佐見町" },
      { id: "42383", name: "小値賀町" },
      { id: "42391", name: "佐々町" },
      { id: "42411", name: "新上五島町" },
    ],
  },
  {
    id: "43",
    name: "熊本県",
    short: "熊本",
    kana: "クマモトケン",
    en: "kumamoto",
    cities: [
      {
        id: "43100",
        name: "熊本市",
        words: [
          { id: "43101", name: "中央区" },
          { id: "43102", name: "東区" },
          { id: "43103", name: "西区" },
          { id: "43104", name: "南区" },
          { id: "43105", name: "北区" },
        ],
      },
      { id: "43202", name: "八代市" },
      { id: "43203", name: "人吉市" },
      { id: "43204", name: "荒尾市" },
      { id: "43205", name: "水俣市" },
      { id: "43206", name: "玉名市" },
      { id: "43208", name: "山鹿市" },
      { id: "43210", name: "菊池市" },
      { id: "43211", name: "宇土市" },
      { id: "43212", name: "上天草市" },
      { id: "43213", name: "宇城市" },
      { id: "43214", name: "阿蘇市" },
      { id: "43215", name: "天草市" },
      { id: "43216", name: "合志市" },
      { id: "43348", name: "美里町" },
      { id: "43364", name: "玉東町" },
      { id: "43367", name: "南関町" },
      { id: "43368", name: "長洲町" },
      { id: "43369", name: "和水町" },
      { id: "43403", name: "大津町" },
      { id: "43404", name: "菊陽町" },
      { id: "43423", name: "南小国町" },
      { id: "43424", name: "小国町" },
      { id: "43425", name: "産山村" },
      { id: "43428", name: "高森町" },
      { id: "43432", name: "西原村" },
      { id: "43433", name: "南阿蘇村" },
      { id: "43441", name: "御船町" },
      { id: "43442", name: "嘉島町" },
      { id: "43443", name: "益城町" },
      { id: "43444", name: "甲佐町" },
      { id: "43447", name: "山都町" },
      { id: "43468", name: "氷川町" },
      { id: "43482", name: "芦北町" },
      { id: "43484", name: "津奈木町" },
      { id: "43501", name: "錦町" },
      { id: "43505", name: "多良木町" },
      { id: "43506", name: "湯前町" },
      { id: "43507", name: "水上村" },
      { id: "43510", name: "相良村" },
      { id: "43511", name: "五木村" },
      { id: "43512", name: "山江村" },
      { id: "43513", name: "球磨村" },
      { id: "43514", name: "あさぎり町" },
      { id: "43531", name: "苓北町" },
    ],
  },
  {
    id: "44",
    name: "大分県",
    short: "大分",
    kana: "オオイタケン",
    en: "oita",
    cities: [
      { id: "44201", name: "大分市" },
      { id: "44202", name: "別府市" },
      { id: "44203", name: "中津市" },
      { id: "44204", name: "日田市" },
      { id: "44205", name: "佐伯市" },
      { id: "44206", name: "臼杵市" },
      { id: "44207", name: "津久見市" },
      { id: "44208", name: "竹田市" },
      { id: "44209", name: "豊後高田市" },
      { id: "44210", name: "杵築市" },
      { id: "44211", name: "宇佐市" },
      { id: "44212", name: "豊後大野市" },
      { id: "44213", name: "由布市" },
      { id: "44214", name: "国東市" },
      { id: "44322", name: "姫島村" },
      { id: "44341", name: "日出町" },
      { id: "44461", name: "九重町" },
      { id: "44462", name: "玖珠町" },
    ],
  },
  {
    id: "45",
    name: "宮崎県",
    short: "宮崎",
    kana: "ミヤザキケン",
    en: "miyazaki",
    cities: [
      { id: "45201", name: "宮崎市" },
      { id: "45202", name: "都城市" },
      { id: "45203", name: "延岡市" },
      { id: "45204", name: "日南市" },
      { id: "45205", name: "小林市" },
      { id: "45206", name: "日向市" },
      { id: "45207", name: "串間市" },
      { id: "45208", name: "西都市" },
      { id: "45209", name: "えびの市" },
      { id: "45341", name: "三股町" },
      { id: "45361", name: "高原町" },
      { id: "45382", name: "国富町" },
      { id: "45383", name: "綾町" },
      { id: "45401", name: "高鍋町" },
      { id: "45402", name: "新富町" },
      { id: "45403", name: "西米良村" },
      { id: "45404", name: "木城町" },
      { id: "45405", name: "川南町" },
      { id: "45406", name: "都農町" },
      { id: "45421", name: "門川町" },
      { id: "45429", name: "諸塚村" },
      { id: "45430", name: "椎葉村" },
      { id: "45431", name: "美郷町" },
      { id: "45441", name: "高千穂町" },
      { id: "45442", name: "日之影町" },
      { id: "45443", name: "五ヶ瀬町" },
    ],
  },
  {
    id: "46",
    name: "鹿児島県",
    short: "鹿児島",
    kana: "カゴシマケン",
    en: "kagoshima",
    cities: [
      { id: "46201", name: "鹿児島市" },
      { id: "46203", name: "鹿屋市" },
      { id: "46204", name: "枕崎市" },
      { id: "46206", name: "阿久根市" },
      { id: "46208", name: "出水市" },
      { id: "46210", name: "指宿市" },
      { id: "46213", name: "西之表市" },
      { id: "46214", name: "垂水市" },
      { id: "46215", name: "薩摩川内市" },
      { id: "46216", name: "日置市" },
      { id: "46217", name: "曽於市" },
      { id: "46218", name: "霧島市" },
      { id: "46219", name: "いちき串木野市" },
      { id: "46220", name: "南さつま市" },
      { id: "46221", name: "志布志市" },
      { id: "46222", name: "奄美市" },
      { id: "46223", name: "南九州市" },
      { id: "46224", name: "伊佐市" },
      { id: "46225", name: "姶良市" },
      { id: "46303", name: "三島村" },
      { id: "46304", name: "十島村" },
      { id: "46392", name: "さつま町" },
      { id: "46404", name: "長島町" },
      { id: "46452", name: "湧水町" },
      { id: "46468", name: "大崎町" },
      { id: "46482", name: "東串良町" },
      { id: "46490", name: "錦江町" },
      { id: "46491", name: "南大隅町" },
      { id: "46492", name: "肝付町" },
      { id: "46501", name: "中種子町" },
      { id: "46502", name: "南種子町" },
      { id: "46505", name: "屋久島町" },
      { id: "46523", name: "大和村" },
      { id: "46524", name: "宇検村" },
      { id: "46525", name: "瀬戸内町" },
      { id: "46527", name: "龍郷町" },
      { id: "46529", name: "喜界町" },
      { id: "46530", name: "徳之島町" },
      { id: "46531", name: "天城町" },
      { id: "46532", name: "伊仙町" },
      { id: "46533", name: "和泊町" },
      { id: "46534", name: "知名町" },
      { id: "46535", name: "与論町" },
    ],
  },
  {
    id: "47",
    name: "沖縄県",
    short: "沖縄",
    kana: "オキナワケン",
    en: "okinawa",
    cities: [
      { id: "47201", name: "那覇市" },
      { id: "47205", name: "宜野湾市" },
      { id: "47207", name: "石垣市" },
      { id: "47208", name: "浦添市" },
      { id: "47209", name: "名護市" },
      { id: "47210", name: "糸満市" },
      { id: "47211", name: "沖縄市" },
      { id: "47212", name: "豊見城市" },
      { id: "47213", name: "うるま市" },
      { id: "47214", name: "宮古島市" },
      { id: "47215", name: "南城市" },
      { id: "47301", name: "国頭村" },
      { id: "47302", name: "大宜味村" },
      { id: "47303", name: "東村" },
      { id: "47306", name: "今帰仁村" },
      { id: "47308", name: "本部町" },
      { id: "47311", name: "恩納村" },
      { id: "47313", name: "宜野座村" },
      { id: "47314", name: "金武町" },
      { id: "47315", name: "伊江村" },
      { id: "47324", name: "読谷村" },
      { id: "47325", name: "嘉手納町" },
      { id: "47326", name: "北谷町" },
      { id: "47327", name: "北中城村" },
      { id: "47328", name: "中城村" },
      { id: "47329", name: "西原町" },
      { id: "47348", name: "与那原町" },
      { id: "47350", name: "南風原町" },
      { id: "47353", name: "渡嘉敷村" },
      { id: "47354", name: "座間味村" },
      { id: "47355", name: "粟国村" },
      { id: "47356", name: "渡名喜村" },
      { id: "47357", name: "南大東村" },
      { id: "47358", name: "北大東村" },
      { id: "47359", name: "伊平屋村" },
      { id: "47360", name: "伊是名村" },
      { id: "47361", name: "久米島町" },
      { id: "47362", name: "八重瀬町" },
      { id: "47375", name: "多良間村" },
      { id: "47381", name: "竹富町" },
      { id: "47382", name: "与那国町" },
    ],
  },
];
