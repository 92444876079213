<template>
  <div>
    <FormatShow v-if="format" :format="format" />
    <div class="d-flex flex-wrap justify-content-center my-5">
      <BasicButton
        label="戻る"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto({ confirm: false })"
      />
      <BasicButton
        v-if="isRuleManager"
        label="編集"
        variant="secondary"
        outlined
        @click="goto({ name: 'UsersSystemAdminRulesEdit', params: { id: id } })"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouterUtil, useFormats, useUser } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Show as FormatShow } from "/@/vue/components/Molecules/Format";

const { id, backto, goto } = useRouterUtil();

const { isRuleManager } = useUser();

const { getFormat } = useFormats();
const { data: format } = getFormat(id);
</script>

<style scoped></style>
