<template>
  <div>
    <div class="mb-3">
      <FormLabel label="エリア選択(半透明のものは募集がありません)" />
      <SelectButton
        v-model="selectedAreas"
        :options="areasWithDisabled"
        option-label="label"
        option-value="value"
        option-disabled="disabled"
        multiple
        allow-empty
      />
    </div>

    <div class="d-flex align-items-center mb-3">
      <div class="me-2">
        <FormLabel label="開始日" />
        <DateForm
          v-model:value="startDate"
          :allowed-dates="existsDates"
          auto-apply
          is-valid
        />
      </div>
      <div class="me-2">
        <FormLabel label="終了日" />
        <DateForm
          v-model:value="finishDate"
          :allowed-dates="existsDates"
          :min-date="minDateForFinishDate"
          auto-apply
          is-valid
        />
      </div>
    </div>

    <template v-if="selectedAreas.length && startDate && finishDate">
      <div class="mb-3 d-flex align-items-center justify-content-end">
        <div class="d-flex flex-column justify-content-center align-items-end">
          <div class="d-flex align-items-center mb-1">
            <GenPdfButton
              :target-element-id="pdfElementId"
              :filename="filename + '.pdf'"
              :options="{
                unit: 'mm',
                format: 'a4',
                orientation: 'p',
                precision: 0,
              }"
              class="me-2"
            />
            <BasicButton label="CSV出力" @click="exportCSV" />
          </div>
          <span class="text-danger" style="font-size: 0.9rem"
            >※一日分のPDF/CSVが必要な場合は開始日と終了日を同日に設定することで取得可能です</span
          >
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-center w-100 pt-3">
        <div :style="pdfViewerStyles">
          <div
            :id="pdfElementId"
            :style="getPdfA4PortraitStyles(dayInfoUserPages)"
            style="padding: 10mm 5mm"
          >
            <div
              v-for="(users, page) in targetDayInfoUsersEachPage"
              :key="`day-info-${page}`"
              :style="{
                height: a4height,
                'min-height': a4height,
              }"
            >
              <h4 class="my-4" style="font-weight: bold">
                被災高齢者等把握事業訪問者予定リスト（{{
                  basicFormatter(users.at(0)?.targetDate, "withoutWdayYear")
                }}〜{{
                  basicFormatter(users.at(-1)?.targetDate, "withoutWdayYear")
                }}）
              </h4>
              <div class="d-flex align-items-center mb-5">
                <span class="p-2" style="border: 1px solid black"
                  >区分：　1. NSK　2. JCMA　3.JVOAD連合</span
                >
                <span
                  class="p-2"
                  style="border: 1px solid black; min-width: 300px"
                  >備考：</span
                >
              </div>

              <div class="mt-3">
                <span>※各日　10時～16時が現地の活動時間として想定</span>
              </div>

              <DayInfoUserTable
                :area="selectedAreas.join(',')"
                :users="users"
                class="w-100 mb-5"
                style="font-size: 0.9rem"
              />
              <div
                v-if="dayInfoUserPages > 1"
                class="d-flex justify-content-center w-100"
              >
                <span class="me-2">{{ page + 1 }}/{{ dayInfoUserPages }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <span v-else>エリアと開始日/終了日を選択してください</span>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { areas } from "/@/modules/constants";
import { getAreasWithDisabled } from "/@/modules/invest24Recruitment";
import {
  a4height,
  getPdfA4PortraitStyles,
  pdfViewerStyles,
} from "/@/modules/pdf";
import { useRecruitment } from "/@/vue/composables";
import { Label as FormLabel, DateForm } from "/@/vue/components/Atom";
import { BasicButton } from "/@/vue/components/Atom";
import { DayInfoUserTable } from "/@/vue/components/Organisms/Users/Invest24RecruitmentDayInfos";
import { GenPdfButton } from "/@/vue/components/Molecules/Button";
import { basicFormatter, fromISO } from "/@/modules/luxon";
import SelectButton from "primevue/selectbutton";

const selectedAreas = ref([areas[0]]);

const { getRecruitments } = useRecruitment();
const { data: recruitments } = getRecruitments();

const areasWithDisabled = computed(() => {
  return getAreasWithDisabled(recruitments.value || []);
});

const filteredRecruitments = computed(() => {
  if (!recruitments.value) return [];

  return recruitments.value.filter((recruitment) =>
    selectedAreas.value.includes(recruitment.area)
  );
});

const existsDates = computed(() => {
  return filteredRecruitments.value
    ?.map((recruitment) =>
      recruitment.invest24RecruitmentDayInfos.map((info) => info.targetDate)
    )
    .flat();
});

const startDate = ref();
const finishDate = ref();

const minDateForFinishDate = computed(() => {
  return startDate.value ? fromISO(startDate.value) : undefined;
});

const targetDayInfos = computed(() => {
  if (!startDate.value || !finishDate.value) return [];

  const startDateForComp = fromISO(startDate.value).startOf("day");
  const finishDateForComp = fromISO(finishDate.value).startOf("day");

  return filteredRecruitments.value
    .map((recruitment) => recruitment.invest24RecruitmentDayInfos)
    .flat()
    .filter((dayInfo) => {
      const targetDate = fromISO(dayInfo.targetDate).startOf("day");

      return (
        dayInfo.currentInvest24JoinUsersCount > 0 &&
        startDateForComp <= targetDate &&
        targetDate <= finishDateForComp
      );
    })
    .toSorted((a, b) => {
      return a.targetDate > b.targetDate ? 1 : -1;
    });
});

const allUsers = computed(() => {
  return targetDayInfos.value
    .map((info) => {
      if (!info.invest24JoinUserInfos) return [];

      return info.invest24JoinUserInfos.map((user) => ({
        ...user,
        targetDate: info.targetDate,
      }));
    })
    .flat();
});

const perPage = 20;

const dayInfoUserPages = computed(() => {
  return Math.ceil(allUsers.value.length / perPage);
});

const targetDayInfoUsersEachPage = computed(() => {
  return Array.from({ length: dayInfoUserPages.value }, (_, i) => {
    return allUsers.value.slice(i * perPage, (i + 1) * perPage);
  });
});

const dayInfoUserTable = ref();

// pdf

const pdfElementId = "pdf-contents";

// csv

const csvConfig = mkConfig({
  columnHeaders: [
    "勤務開始日",
    "勤務終了日",
    "氏名",
    "調査エリア",
    "勤務種別",
    "生年月日",
    "メールアドレス",
    "電話番号",
  ],
});

const usersForCsv = computed(() => {
  return allUsers.value.map((user) => {
    return {
      勤務開始日: basicFormatter(user.targetDate, "withoutWdayYear"),
      勤務終了日: basicFormatter(user.targetDate, "withoutWdayYear"),
      氏名: user.lastName + user.firstName,
      調査エリア: user.area,
      勤務種別: user.workType,
      生年月日: basicFormatter(user.birthday, "slashStyleOnlyDate"),
      メールアドレス: user.email,
      電話番号: user.phoneNumber,
    };
  });
});

const filename = computed(() => {
  return `被災高齢者等把握事業訪問者予定リスト_${selectedAreas.value.join(
    "_"
  )}_${basicFormatter(startDate.value, "slashStyleOnlyDate")}_${basicFormatter(
    finishDate.value,
    "slashStyleOnlyDate"
  )}`;
});

function exportCSV() {
  const csv = generateCsv(csvConfig)(usersForCsv.value);
  download({ ...csvConfig, filename: filename.value })(csv);
}
</script>

<style scoped></style>
