<template>
  <div style="padding: 0 7%">
    <div
      class="d-flex flex-column align-items-center justify-content-center w-100"
      style="
        background-color: #f2eee9;
        padding: 100px 0;
        border-radius: 40px 40px 0 0;
        background-image: url(/img/ishikawa_img_4.jpeg);
        background-position: center top;
      "
    >
      <transition name="slide" mode="out-in">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <span class="text-white">{{ subtitle }}</span>
          <h2 class="mt-2 text-white">{{ title }}</h2>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMqUtils } from "/@/vue/composables";

defineProps<{
  title: string;
  subtitle: string;
}>();

const { isMobile } = useMqUtils();
</script>

<style scoped></style>
