<template>
  <div>
    <DataTable
      striped-rows
      paginator
      :rows="selectedPerPage"
      :value="recruitments"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-click="$emit('select', $event.data.id)"
      @page="changePage"
    >
      <template #header>
        <TableHeader
          v-model="selectedPerPage"
          :target-length="recruitments.length"
          :current-page="currentPage"
        />
      </template>
      <Column
        field="startOn"
        header="期間"
        :style="
          isMobile
            ? { width: '170px', 'min-width': '170px' }
            : { width: '170px', 'min-width': '170px' }
        "
      >
        <template #body="{ data }">
          <span
            >{{ basicFormatter(data.startOn, "slashStyleOnlyDate") }}〜{{
              basicFormatter(data.finishOn, "slashStyleOnlyMonthDay")
            }}</span
          >
        </template>
      </Column>
      <Column
        field="area"
        :header="isMobile ? 'ｴﾘｱ' : 'エリア'"
        :style="isMobile ? { width: '100%' } : {}"
      >
        <template #body="{ data }">
          <span>{{ data.area }}</span>
        </template>
      </Column>
      <Column :style="{ width: '50px' }" header="詳細" body-class="text-center">
        <template #body="{ data }">
          <BasicButton
            icon="pi pi-bars"
            text
            variant="secondary"
            @click="$emit('select', data.id)"
          />
        </template>
      </Column>
      <Column :style="{ width: '50px' }" header="編集" body-class="text-center">
        <template #body="{ data }">
          <BasicButton
            icon="pi pi-pencil"
            variant="primary"
            text
            @click="$emit('edit', data.id)"
          />
        </template>
      </Column>
      <Column :style="{ width: '50px' }" header="削除">
        <template #body="{ data }">
          <BasicButton
            icon="pi pi-trash"
            variant="danger"
            text
            :disabled="data.hasParticipant"
            @click="$emit('delete', data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils, useTableHeader } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Header as TableHeader } from "/@/vue/components/Molecules/TableUtils";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { Invest24RecruitmentClient } from "/@/types";

defineProps<{
  recruitments: Invest24RecruitmentClient[];
  isVManager?: boolean;
}>();

defineEmits<{
  (e: "select", id: number): void;
  (e: "edit", id: number): void;
  (e: "delete", id: number): void;
}>();

const { isMobile } = useMqUtils();
const { selectedPerPage, currentPage, changePage } = useTableHeader();
</script>

<style scoped></style>
