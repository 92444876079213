<template>
  <div>
    <GenPdfButton
      :target-element-id="pdfElementId"
      :filename="filename"
      :options="{
        unit: 'mm',
        format: 'a4',
        orientation: 'p',
        precision: 0,
      }"
      class="mb-3"
    />

    <PublicationFormat
      v-if="format"
      :pdf-element-id="pdfElementId"
      :user="user"
      :data="format.data"
    />
  </div>
</template>

<script setup lang="ts">
import { luxonNow } from "/@/modules/luxon";
import { useUser, useFormats } from "/@/vue/composables";
import { GenPdfButton } from "/@/vue/components/Molecules/Button";
import { PublicationFormat } from "/@/vue/components/Organisms/Users/Formats";

// user
const { getUser } = useUser();
const { data: user } = getUser();

// pdf
const today = luxonNow();

const pdfElementId = "pdf-contents";
const filename = `証明書_${today.toFormat("yyyy_MM_dd")}.pdf`;

const { getFormatByType } = useFormats();
const { data: format, error } = getFormatByType("completation");
</script>

<style scoped>
.cell-header {
  width: 160px;
}

.cell-inner-header {
  width: 100px;
  max-width: 100px;
}
</style>
