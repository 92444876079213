<template>
  <Button
    :class="btnClass"
    :severity="severity"
    :disabled="disabledInComponent || disabled"
    :label="label"
    :name="name"
    :type="buttonType"
    :icon="icon"
    :icon-pos="iconPos"
    :size="size"
    :rounded="rounded"
    :text="text"
    :outlined="outlined"
    @click="onClick"
    style="transition: all 0.2s"
  >
    <slot></slot>
  </Button>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, ButtonHTMLAttributes } from "vue";
import Button from "primevue/button";
type ButtonTypes = ButtonHTMLAttributes["type"];

interface Props {
  name?: string;
  variant?: "green" | "primary" | "secondary" | "warning" | "gray" | "success";
  color?: "green" | "orange";
  label?: string;
  disabled?: boolean;
  circle?: boolean;
  large?: boolean;
  small?: boolean;
  middle?: boolean;
  slim?: boolean;
  auto?: boolean;
  icon?: string;
  iconPos?: "left" | "right";
  withIconPadding?: boolean;
  rounded?: boolean;
  withShadow?: boolean;
  buttonType?: ButtonTypes;
  outlined?: boolean;
  text?: boolean;
  w300?: boolean;
  stopDualClick?: boolean;
  isMobile?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  name: undefined,
  variant: undefined,
  buttonType: "button",
  withShadow: false,
  icon: undefined,
  iconPos: "left",
  label: undefined,
  color: "green",
  isMobile: false,
});

const emit = defineEmits<{
  (e: "click"): void;
}>();

const btnClass = computed(() => ({
  "with-opensite-shadow": true,
  "with-icon-padding-left":
    props.icon && props.withIconPadding && props.iconPos === "left",
  "with-icon-padding-right":
    props.icon && props.withIconPadding && props.iconPos === "right",
  "button-header": true,
  "drop-shadow": props.withShadow,
  "btn-circle": props.circle,
  "btn-slim": props.slim,
  "btn-auto": props.auto,
  "w-300": props.w300,
  "btn-green": props.color === "green",
  "btn-orange": props.color === "orange",
  "is-mobile": props.isMobile,
}));

const severity = computed(() => {
  return props.variant;
});

const size = computed(() => {
  if (props.small) {
    return "small";
  }

  if (props.large) {
    return "large";
  }

  return undefined;
});

const disabledInComponent = ref<boolean>(false);

function onClick(e: MouseEvent) {
  e.stopPropagation();
  emit("click");
  if (props.stopDualClick || props.buttonType === "submit") {
    const sleep = (msec: number) =>
      new Promise((resolve) => setTimeout(resolve, msec));

    (async () => {
      disabledInComponent.value = true;
      await sleep(3000);
      disabledInComponent.value = false;
    })();
  }
}
</script>

<style lang="scss">
.p-button.button-header {
  height: 60px;
  border-radius: 9999px;
  width: 200px;
  font-size: 16px;

  .p-button-icon {
    background-color: white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
  }

  &.is-mobile {
    height: 30px;
    width: 110px;
    font-size: 10px;

    .p-button-icon {
      height: 20px;
      width: 20px;
      line-height: 20px;
    }
  }

  &.with-icon-padding-left {
    padding-right: 40px;
  }

  &.with-icon-padding-right {
    padding-left: 40px;
  }

  &.btn-green {
    background-color: #69bb01 !important;

    .p-button-icon {
      color: #69bb01;
    }
  }

  &.btn-orange {
    background-color: #fda237 !important;

    .p-button-icon {
      color: #fda237;
    }
  }
}
</style>
