import Index from "./Index.vue";

export default [
  {
    path: "",
    name: "MagazinesIndex",
    component: Index,
    meta: {
      title: "広報誌『わ』",
      subtitle: "MAGAZINE",
      tree: [
        {
          title: "広報誌『わ』",
        },
      ],
    },
  },
];
