import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";

export function useCalendar() {
  // jpHoliday

  function getJpHolidays() {
    return useSWRV("/api/v1/calendars/get_jp_holidays", fetcher);
  }

  return {
    getJpHolidays,
  };
}
