<template>
  <div>
    <div class="mb-4">
      <FormLabel label="タイトル（必須） ※50文字以内" />
      <FormItem
        :value="title"
        :errors="errors"
        form-id="title"
        form-type="text"
        @update:value="$emit('update:title', $event as string | undefined)"
      />
    </div>

    <div v-if="formAttachedPdfs.length" class="mb-4">
      <FormLabel label="添付書類（必須）" />
      <div v-for="(pdf, idx) in formAttachedPdfs" :key="pdf.signedId">
        <div class="d-flex flex-wrap align-items-center">
          <a
            :href="pdf.url"
            target="_blank"
            class="me-2 d-flex align-items-center"
          >
            <span class="me-2">添付書類{{ idx + 1 }}</span>
            <i class="pi pi-external-link"></i>
          </a>

          <BasicButton
            icon="pi pi-trash"
            need-icon
            variant="danger"
            text
            @click="removeAttachedPdf(pdf)"
          />
        </div>
      </div>
    </div>

    <PdfForm
      v-if="formAttachedPdfs.length < 1"
      :model-value="newAttachedPdfs"
      :file-limit="1 - formAttachedPdfs.length"
      :current-count="formAttachedPdfs.length"
      class="mb-3"
      :is-invalid="!!errors?.newAttachedPdfs"
      @update:model-value="
        $emit('update:newAttachedPdfs', [
          ...newAttachedPdfs.filter((p) => !p.startsWith('data')),
          ...$event,
        ])
      "
    />

    <div class="mb-4">
      <FormLabel label="公開日時（未設定の場合は即座に公開されます）" />
      <div class="d-flex">
        <FormItem
          :value="publishedAt"
          :errors="errors"
          form-id="publishedAt"
          form-type="date"
          class="me-2"
          style="width: 210px; min-width: 210px"
          @update:value="
            $emit('update:publishedAt', $event as string | undefined)
          "
        />
        <FormItemTime
          :value="publishedAt"
          :env="publishedAtEnv"
          :errors="errors"
          form-id="publishedAt"
          label=""
          :minutes-increment="1"
          :style="
            isMobile
              ? {
                  width: '100%',
                }
              : {
                  width: '120px',
                }
          "
          @update:value="
            $emit('update:publishedAt', $event as string | undefined)
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useMqUtils } from "/@/vue/composables";
import { Label as FormLabel, BasicButton } from "/@/vue/components/Atom";
import {
  FormItem,
  FormItemTime,
  PdfForm,
} from "/@/vue/components/Molecules/Form";
import { PdfInfo, ZodFormattedErrors } from "/@/types";
import { fromISO } from "/@/modules/luxon";

const props = defineProps<{
  title?: string;
  publishedAt?: string;
  newAttachedPdfs: string[];
  attachedPdfs: PdfInfo[];
  errors: ZodFormattedErrors;
}>();

const emit = defineEmits<{
  (e: "update:title", title: string | undefined): void;
  (e: "update:publishedAt", publishedAt: string | undefined): void;
  (e: "update:newAttachedPdfs", newAttachedPdfs: string[]): void;
}>();

const { isMobile } = useMqUtils();

const publishedAtEnv = computed(() => {
  if (!props.publishedAt) return;

  return fromISO(props.publishedAt)?.toObject();
});

function removeAttachedPdf(pdf: { url: string; signedId: string }) {
  const rest = props.newAttachedPdfs.filter((p) => p !== pdf.signedId);
  emit("update:newAttachedPdfs", rest);
}

const formAttachedPdfs = computed(() => {
  if (!props.newAttachedPdfs) return [];

  return props.attachedPdfs.filter((pdf) => {
    return props.newAttachedPdfs.some((signedId) => pdf.signedId === signedId);
  });
});
</script>

<style scoped></style>
