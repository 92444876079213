import Index from "./Index.vue";
import Publication from "./Publication.vue";

export default [
  {
    path: "",
    name: "UsersBcpIndex",
    component: Index,
    meta: {
      title: "ＢＣＰ",
      subtitle: "Business Continuity Planning",
      tree: [
        {
          title: "会員ページ ＢＣＰ",
        },
      ],
    },
  },
];
