import { z } from "/@/modules/zodUtils";

export const userBaseObject = {
  firstName: z.string().trim().min(1).max(50),
  lastName: z.string().trim().min(1).max(50),
  firstNameKana: z.string().trim().min(1).max(50),
  lastNameKana: z.string().trim().min(1).max(50),
  birthday: z.string(),
  email: z.string().email(),
  phoneNumber: z.string().trim().min(10).max(11),
  beforeCheckManager: z.boolean().optional(),
};

export const invest24UserObject = {
  firstName: z.string().trim().min(1).max(50),
  lastName: z.string().trim().min(1).max(50),
  firstNameKana: z.string().trim().min(1).max(50),
  lastNameKana: z.string().trim().min(1).max(50),
  birthday: z.string(),
  email: z.string().email(),
  phoneNumber: z.string().trim().min(10).max(11),
  userId: z.number().optional(),
};

export const userObject = {
  ...userBaseObject,
  postcode: z.string().trim().min(7).max(7),
  address: z.string().trim().min(0).max(150),
  //area: z.string().trim().min(0).max(50).optional().or(z.literal("")),
  //city: z.string().trim().min(0).max(50).optional().or(z.literal("")),
  haveAffiliation: z.boolean().optional(),
  affiliation: z.string().trim().min(1).max(50).optional().or(z.literal("")),
  affiliationPhoneNumber: z
    .string()
    .trim()
    .min(10)
    .max(11)
    .optional()
    .or(z.literal("")),
  affiliationPostcode: z
    .string()
    .trim()
    .min(7)
    .max(7)
    .optional()
    .or(z.literal("")),
  affiliationAddress: z
    .string()
    .trim()
    .min(1)
    .max(150)
    .optional()
    .or(z.literal("")),
  registrationNumber: z
    .string()
    .trim()
    .min(1)
    .max(50)
    .optional()
    .or(z.literal("")),
  business: z.string().trim().min(0).max(50).optional().or(z.literal("")),
  special: z.boolean().optional(),
};
