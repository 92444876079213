import Login from "./Login.vue";
import Show from "./Show.vue";

export default [
  {
    path: "login",
    component: Login,
    name: "Invest24UsersLogin",
    meta: {
      title: "ボランティア申請確認",
      subtitle: "VOLUNTEER",
      tree: [
        {
          title: "ボランティア申請確認",
        },
      ],
    },
  },
  {
    path: "show",
    component: Show,
    name: "Invest24UsersShow",
    meta: {
      title: "ボランティア申請一覧",
      subtitle: "VOLUNTEER",
      tree: [
        {
          title: "ボランティア申請一覧",
        },
      ],
    },
  },
];
