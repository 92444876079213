<template>
  <div>
    <div v-if="!titleAsClass" class="mb-4">
      <FormLabel label="名前 ※30文字以内" />
      <FormItem
        :value="title"
        :errors="errors"
        form-id="title"
        form-type="text"
        @update:value="$emit('update:title', $event as string | undefined)"
      />
    </div>
    <div v-else class="mb-4">
      <FormLabel
        label="書式種別 ※30文字以内 すでに登録されている種別は入力できません（例：会則）変更したい場合は更新して編集ボタンより編集してください"
      />
      <FormItem
        :value="title"
        :errors="errors"
        form-id="title"
        form-type="text"
        @update:value="$emit('update:title', $event as string | undefined)"
      />
    </div>

    <div v-if="!hideSelectFormatType" class="mb-4">
      <FormLabel label="種別選択" />
      <Dropdown
        :model-value="formatClass"
        :options="formatClasses"
        class="w-100"
        @update:model-value="$emit('update:formatType', $event)"
      />
    </div>

    <ShowAttachedPdfs
      :attached-pdfs="formAttachedPdfs"
      show-delete-button
      @remove="removeAttachedPdf"
    />

    <PdfForm
      v-if="formAttachedPdfs.length < 1"
      :model-value="newAttachedPdfs"
      :file-limit="1"
      :file-min="1"
      :current-count="formAttachedPdfs.length"
      class="mb-3"
      :is-invalid="!!errors?.newAttachedPdfs"
      @update:model-value="
        $emit('update:newAttachedPdfs', [
          ...newAttachedPdfs.filter((p) => !p.startsWith('data')),
          ...$event,
        ])
      "
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { formatTypeTranslater } from "/@/modules/format";
import { Label as FormLabel } from "/@/vue/components/Atom";
import { FormItem, PdfForm } from "/@/vue/components/Molecules";
import { ShowAttachedPdfs } from "/@/vue/components/Molecules/PDF";
import Dropdown from "primevue/dropdown";
import {
  ZodFormattedErrors,
  FormatType,
  formatTypes as allFormatTypes,
  PdfInfo,
} from "/@/types";

const props = defineProps<{
  title?: string;
  formatType: FormatType;
  formatTypes?: FormatType[];
  formatClass?: string;
  formatClasses?: string[];
  newAttachedPdfs: string[];
  attachedPdfs: PdfInfo[];
  hideSelectFormatType?: boolean;
  titleAsClass?: boolean;
  errors: ZodFormattedErrors;
}>();

const emit = defineEmits<{
  (e: "update:title", title: string | undefined): void;
  (e: "update:formatType", formatType: FormatType): void;
  (e: "update:newAttachedPdfs", newAttachedPdfs: string[]): void;
}>();

const formAttachedPdfs = computed(() => {
  if (!props.newAttachedPdfs) return [];

  return props.attachedPdfs.filter((pdf) => {
    return props.newAttachedPdfs.some((signedId) => pdf.signedId === signedId);
  });
});

function removeAttachedPdf(pdf: { url: string; signedId: string }) {
  const rest = props.newAttachedPdfs.filter((p) => p !== pdf.signedId);
  emit("update:newAttachedPdfs", rest);
}
</script>

<style scoped></style>
