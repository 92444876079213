<template>
  <div>
    <div class="d-flex flex-column align-items-center">
      <div class="contents-960">
        <div
          class="d-flex flex-column align-items-center justify-content-center my-5"
        >
          <h3 class="text-green w-100 text-center">賛助会員様の紹介</h3>
        </div>
        <SponsorList v-if="sponsors" :sponsors="sponsors" />
      </div>
      <div class="my-5">
        <OpenSiteBackButton />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSponsors } from "/@/vue/composables";
import { OpenSiteBackButton } from "/@/vue/components/Atom";
import { List as SponsorList } from "/@/vue/components/Molecules/Sponsors";

const { getSponsors } = useSponsors();
const { data: sponsors } = getSponsors();
</script>

<style scoped></style>
