import Cancel from "./Cancel.vue";
import New from "./New.vue";
import Select from "./Select.vue";

export default [
  {
    path: "new",
    name: "Invest24JoinUsersNew",
    component: New,
    meta: {
      title: "ボランティア申請",
      subtitle: "APPLICATION",
      tree: [
        {
          title: "ボランティア募集",
        },
        {
          title: "ボランティア申請",
        },
      ],
    },
  },
  {
    path: "select",
    name: "Invest24JoinUsersSelect",
    component: Select,
    meta: {
      title: "申請",
      subtitle: "APPLICATION",
      tree: [],
    },
  },
  {
    path: "cancel/:id",
    name: "Invest24JoinUsersCancel",
    component: Cancel,
    meta: {
      title: "申請",
      subtitle: "CANCEL",
      tree: [],
    },
  },
];
