import Index from "./Index.vue";
import Show from "./Show.vue";

export default [
  {
    path: "",
    name: "UsersInquiriesIndex",
    component: Index,
    meta: {
      title: "問い合わせ一覧",
      tree: [{ title: "問い合わせ一覧" }],
    },
  },
  {
    path: ":id",
    name: "UsersInquiriesShow",
    component: Show,
    meta: {
      title: "問い合わせ詳細",
      tree: [{ title: "問い合わせ一覧", to: { name: "UsersInquiriesIndex" } }],
    },
  },
];
