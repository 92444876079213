import { isOverLapWithDateMap, isSameMonth } from "/@/modules/datemap";
import { areas } from "/@/modules/constants";
import {
  DateMap,
  Invest24RecruitmentClient,
  Invest24UserClient,
  Invest24UserAttachedDayInfo,
  Invest24RecruitmentType,
} from "/@/types";

export function recruitmentTypeTranslater(t: Invest24RecruitmentType) {
  switch (t) {
    case "invest24":
      return "実態調査ボランティア";
    case "shelter24":
      return "1.5次避難所ボランティア";
    default:
      return "";
  }
}

export function recruitmentFilter(
  r: Invest24RecruitmentClient,
  selectedArea?: string | string[],
  selectedDate?: DateMap
): boolean {
  const isNotContainSelectedDate =
    selectedDate &&
    selectedDate.year &&
    selectedDate.month &&
    !isOverLapWithDateMap(r.startOn, r.finishOn, selectedDate) &&
    !isSameMonth(r.startOn, selectedDate) &&
    !isSameMonth(r.finishOn, selectedDate);

  if (isNotContainSelectedDate) {
    return false;
  }

  if (!selectedArea) return true;
  if (
    Array.isArray(selectedArea)
      ? selectedArea.includes(r.area)
      : selectedArea === r.area
  )
    return true;
  if (
    Array.isArray(selectedArea)
      ? selectedArea.includes("その他") && !areas.includes(r.area)
      : selectedArea === "その他" && !areas.includes(r.area)
  )
    return true;

  return false;
}

export function getAreasWithDisabled(
  recruitments: Invest24RecruitmentClient[]
): {
  label: string;
  value: string;
  disabled: boolean;
}[] {
  return areas.map((area) => {
    return {
      label: area,
      value: area,
      disabled:
        !recruitments.some((r) => r.area === area) &&
        !(
          area === "その他" && recruitments.some((r) => !areas.includes(r.area))
        ),
    };
  });
}

export function getAreasWithDisabledByDayInfos(
  dayInfos: Invest24UserAttachedDayInfo[]
) {
  return areas.map((area) => {
    return {
      label: area,
      value: area,
      disabled:
        !dayInfos.some((d) => d.area === area) &&
        (area !== "その他" || !dayInfos.some((d) => !areas.includes(d.area))),
    };
  });
}

export function isJoined(
  user?: Invest24UserClient,
  recruitment?: Invest24RecruitmentClient,
  targetDate?: string
) {
  if (!user || !recruitment || !targetDate) return false;

  const targetDateInfo = recruitment.invest24RecruitmentDayInfos.find(
    (r) => r.targetDate === targetDate
  );

  if (!targetDateInfo) return false;

  return user.invest24JoinUsers.find(
    (joinUser) => joinUser.invest24RecruitmentDayInfoId === targetDateInfo.id
  );
}
