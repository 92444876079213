<template>
  <div
    style="
      min-height: 30vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="mb-4">
      <span>アカウントをお持ちの方は</span>
      <router-link :to="{ name: 'SignIn' }" class="">ログイン</router-link>
    </div>
    <div class="d-flex flex-column mb-4">
      <BasicButton
        label="石川県介護支援専門員協会の会員の方"
        class="mb-2"
        style="max-width: 300px"
        @click="
          goto({
            name: 'Invest24JoinUsersNew',
            query: { ...currentRouteQuery },
          })
        "
      />
      <BasicButton
        label="会員ではない方"
        class="mb-2"
        style="max-width: 300px"
        @click="
          goto({ name: 'Invest24JoinUsersNew', query: currentRouteQuery })
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";

const { goto, currentRouteQuery } = useRouterUtil();
</script>

<style scoped></style>
