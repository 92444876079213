<template>
  <div>
    <div class="d-flex align-items-center justify-content-center">
      <UserForm
        v-model:last-name="form.lastName"
        v-model:first-name="form.firstName"
        v-model:last-name-kana="form.lastNameKana"
        v-model:first-name-kana="form.firstNameKana"
        v-model:birthday="form.birthday"
        v-model:postcode="form.postcode"
        v-model:address="form.address"
        v-model:have-affiliation="form.haveAffiliation"
        v-model:affiliation="form.affiliation"
        v-model:affiliation-phone-number="form.affiliationPhoneNumber"
        v-model:affiliation-postcode="form.affiliationPostcode"
        v-model:affiliation-address="form.affiliationAddress"
        v-model:business="form.business"
        v-model:registration-number="form.registrationNumber"
        v-model:phone-number="form.phoneNumber"
        v-model:email="form.email"
        v-model:email-check="form.email"
        hide-email-check
        hide-special
        hide-registration-number
        is-sign-up
        class="contents-960 w-100"
        :errors="errors"
        :organizations="[]"
        :organization-members="[]"
      />
    </div>

    <div class="my-5 w-100 d-flex align-items-center justify-content-center">
      <OpenSiteBackButton
        class="me-3"
        :click-command="() => goto({ name: 'UsersShow' })"
      />
      <MoreReadButton
        class="ms-3"
        color="green"
        label="更新"
        button-type="submit"
        :disabled="loading"
        @click="submit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import {
  gPhoneNumber,
  gPostcode,
  gRegistrationNumber,
} from "/@/modules/groomer";
import { useUser, useRouterUtil, useZodScheme } from "/@/vue/composables";
import { OpenSiteBackButton, MoreReadButton } from "/@/vue/components/Atom";
import { Form as UserForm } from "/@/vue/components/Organisms/Users";
import {
  UserForm as UserFormType,
  UserCheckScheme,
  UserClient,
} from "/@/types";

const { getUser } = useUser();
const { data: user } = getUser();

const { goto } = useRouterUtil();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<UserFormType>(
  UserCheckScheme,
  user.value
);

startValidation.value = true;

watch(form, (f) => {
  // form を watch する場合は、まとめて変更した時の処理を書けるが、
  // 無駄な比較処理が増えるのと過去の値へのアクセスができない問題がある。
  // そのため個別のプロパティでの変更があった時のような処理が書けない

  if (f.firstName && f.firstName.length > 50) {
    form.firstName = f.firstName.slice(0, 50);
  }

  if (f.lastName && f.lastName.length > 50) {
    form.lastName = f.lastName.slice(0, 50);
  }

  if (f.firstName && f.firstName.length > 50) {
    form.firstName = f.firstName.slice(0, 50);
  }

  if (f.firstNameKana && f.firstNameKana.length > 50) {
    form.firstNameKana = f.firstNameKana.slice(0, 50);
  }

  if (f.lastNameKana && f.lastNameKana.length > 50) {
    form.lastNameKana = f.lastNameKana.slice(0, 50);
  }

  if (f.postcode) {
    form.postcode = gPostcode(f.postcode);
  }

  if (f.address && f.address.length > 150) {
    form.address = f.address.slice(0, 150);
  }

  if (f.phoneNumber) {
    form.phoneNumber = gPhoneNumber(f.phoneNumber);
  }

  if (f.affiliation && f.affiliation.length > 50) {
    form.affiliation = f.affiliation.slice(0, 50);
  }

  if (f.affiliationPostcode) {
    form.affiliationPostcode = gPostcode(f.affiliationPostcode);
  }

  if (f.affiliationAddress && f.affiliationAddress.length > 150) {
    form.affiliationAddress = f.affiliationAddress.slice(0, 150);
  }

  if (f.affiliationPhoneNumber) {
    form.affiliationPhoneNumber = gPhoneNumber(f.affiliationPhoneNumber);
  }

  if (f.registrationNumber) {
    form.registrationNumber = gRegistrationNumber(f.registrationNumber);
  }

  if (f.business && f.business.length > 50) {
    form.business = f.business.slice(0, 50);
  }
});

watch(user, (u) => {
  if (!u) {
    return;
  }

  if (form.id !== u.id) {
    resetForm(u);
  }
});

function resetForm(user?: UserClient) {
  if (user) {
    form.id = user.id;
    form.special = user.special;
    form.lastName = user.lastName;
    form.firstName = user.firstName;
    form.lastNameKana = user.lastNameKana;
    form.firstNameKana = user.firstNameKana;
    form.birthday = user.birthday;
    form.postcode = user.postcode;
    form.address = user.address;
    form.haveAffiliation = !!user.affiliation;
    form.affiliation = user.affiliation;
    form.affiliationPhoneNumber = user.affiliationPhoneNumber;
    form.affiliationPostcode = user.affiliationPostcode;
    form.affiliationAddress = user.affiliationAddress;
    form.business = user.business;
    form.registrationNumber = user.registrationNumber;
    form.phoneNumber = user.phoneNumber;
    form.email = user.email;
  } else {
    form.id = undefined;
    form.special = false;
    form.lastName = undefined;
    form.firstName = undefined;
    form.lastNameKana = undefined;
    form.firstNameKana = undefined;
    form.birthday = undefined;
    form.postcode = undefined;
    form.address = undefined;
    form.haveAffiliation = true;
    form.affiliation = undefined;
    form.affiliationPhoneNumber = undefined;
    form.affiliationPostcode = undefined;
    form.affiliationAddress = undefined;
    form.business = undefined;
    form.registrationNumber = undefined;
    form.phoneNumber = undefined;
    form.email = undefined;
  }
}

onMounted(() => {
  resetForm(user.value);
});

const { updateUser } = useUser();

const loading = ref(false);

async function submit() {
  try {
    loading.value = true;
    const args = UserCheckScheme.parse(form);

    if (await updateUser(args)) {
      alert("更新しました");
      goto({ name: "Index" });
    } else {
      alert(
        "予期せぬエラーで更新に失敗しました。お手数ですが開発会社までご連絡をお願いします。"
      );
      loading.value = false;
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
    loading.value = false;
  }
}
</script>

<style scoped></style>
