<template>
  <div>
    <MailConfirm ref="mailConfirm" mail-type="cancel" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRouterUtil, useInvest24JoinUser } from "/@/vue/composables";
import { MailConfirm } from "/@/vue/components/Layouts";

const mailConfirm = ref();

const { goto, currentRouteQuery, currentRouteParams } = useRouterUtil();

const id = computed<number>(() => {
  return Number(currentRouteParams.value.id);
});

const { destroyJoinUser } = useInvest24JoinUser();

onMounted(async () => {
  const uuid = currentRouteQuery.value.uuid as string | undefined;
  if (uuid) {
    if (
      !window.confirm(
        "ボランティアへの参加を中止します。よろしければ「OK」を押して下さい。"
      )
    ) {
      goto({ name: "Index" });
      return;
    }

    if (await destroyJoinUser(id.value, { uuid })) {
      mailConfirm.value.open(() => {
        goto({ name: "Index" });
      });
    } else {
      alert(
        "キャンセルに失敗しました。すでにキャンセルが行われている申請である可能性があります。"
      );
      goto({ name: "Index" });
    }
  }
});
</script>

<style scoped></style>
