import parsePhoneNumber from "libphonenumber-js";

export function formatNational(number: string) {
  const parsed = parsePhoneNumber(number, "JP");

  if (!parsed) {
    return number.toString();
  }

  return parsed.formatNational();
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const n = "09011112222";

  it("addHyphen", () => {
    expect(formatNational(n)).toBe("090-1111-2222");
  });
}
