<template>
  <div>
    <div class="d-flex justify-content-center w-100">
      <div class="contents-960 w-100">
        <span
          v-if="memberAnnounces && !memberAnnounces.length"
          class="text-danger"
        >
          お知らせがありません
        </span>
        <NewsList
          v-else
          :news="memberAnnounces"
          :organizations="joinOrganizations"
          reverse-color
          show-filter
          show-page-info
          show-pagenation
          hide-no-news-organization
          class="w-100"
          @select-news="handleSelectNews"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { getStorage, setStorage } from "/@/modules/localStorage";
import { List as NewsList } from "/@/vue/components/Molecules/News";
import { useRouterUtil, useUser, useNews } from "/@/vue/composables";

const { goto } = useRouterUtil();

// api

const { getUser } = useUser();
const { data: user } = getUser({
  withMemberAnnounces: true,
});

const memberAnnounces = computed(() => {
  if (!user.value) return [];

  return user.value.memberAnnounces || [];
});

const joinOrganizations = computed(() => {
  if (!user.value) return [];

  return user.value.joinOrganizations;
});

const managedOrganizations = computed(() => {
  if (!user.value) return [];

  return user.value.managedOrganizations;
});

const selectedOrganizationId = ref(
  getStorage("selectedOrganizationId") || undefined
);

watch(managedOrganizations, (os) => {
  if (!os) return;

  if (os.some((o) => o.id === selectedOrganizationId.value)) {
    return;
  }

  selectedOrganizationId.value = os[0]?.id;
});

watch(selectedOrganizationId, (id) => {
  if (!id) return;
  setStorage("selectedOrganizationId", id);
});

watch(user, (u) => {
  if (!u || !!selectedOrganizationId.value) return;

  selectedOrganizationId.value = u.managedOrganizations[0]?.id;
});

function handleSelectNews(id: number) {
  goto({ name: "UsersNewsUserNewsShow", params: { id: id } });
}
</script>

<style lang="scss" scoped></style>
