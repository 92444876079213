<template>
  <div>
    <div class="d-flex flex-column align-items-center w-100">
      <div class="contents-960 w-100">
        <FormatsList
          :formats="allFormats"
          show-filter
          show-page-info
          class="w-100"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useFormats } from "/@/vue/composables";
import { List as FormatsList } from "/@/vue/components/Molecules/Format";

const { getFormats } = useFormats();
const { data: formats } = getFormats({
  types: ["bcp"],
});

const allFormats = computed(() => {
  if (!formats.value) return [];

  return formats.value;
});
</script>

<style scoped></style>
