<template>
  <div>
    <div v-if="recruitment && targetDateInfo" class="mb-3">
      <UserTable
        v-if="targetDateInfo.invest24JoinUserInfos?.length"
        :recruitment="recruitment"
        :recruitment-day-info="targetDateInfo"
        @select="openModal"
        @detach="handleCancel"
      />
      <span v-else> まだ参加者はいません </span>
      <UserAllocater
        :recruitment="recruitment"
        :invest24-users="invest24Users || []"
        :target-date="targetDate"
        :users="users || []"
        @allocate-user="handleAllocateUser"
        @allocate-new-user="
          goto({
            name: 'UsersRecruitmentsNewUser',
            query: { id: recruitment.id, targetDate },
          })
        "
      />
    </div>

    <Divider />
    <UserPageActions>
      <div class="d-flex flex-row w-100 justify-content-end">
        <BasicButton
          label="編集"
          @click="gotoEditPage"
          class="me-2"
          outlined
          variant="primary"
        />
      </div>
    </UserPageActions>

    <DayInfoShow
      v-if="targetDateInfo"
      :target-date-info="targetDateInfo"
      is-admin
      with-date
    />

    <div class="d-flex flex-wrap justify-content-center my-5">
      <BasicButton
        label="戻る"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto({ confirm: false })"
      />
      <BasicButton
        label="編集"
        variant="primary"
        outlined
        @click="gotoEditPage"
      />
    </div>

    <UserModal
      v-model:visible="modalVisible"
      :user="selectedUser"
      @detach="handleCancel"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import {
  useRouterUtil,
  useRecruitment,
  useInvest24JoinUser,
  useUser,
} from "/@/vue/composables";
import { UserPageActions } from "/@/vue/components/Layouts";
import { BasicButton } from "/@/vue/components/Atom";
import { UserTable } from "/@/vue/components/Organisms/Users/Invest24RecruitmentDayInfos";
import {
  Show as DayInfoShow,
  UserModal,
} from "/@/vue/components/Molecules/Invest24RecruitmentDayInfos";
import { UserAllocater } from "/@/vue/components/Molecules/Invest24Recruitments";
import { Invest24UserAttachedInDayInfo, Invest24WorkType } from "/@/types";
import Divider from "primevue/divider";

const { id, currentRouteQuery, goto, backto } = useRouterUtil();

const targetDate = computed(() => currentRouteQuery.value.targetDate as string);

const { getRecruitment, allocateUserRecruitment } = useRecruitment();
const { data: recruitment, mutate: getRecruitmentMutate } = getRecruitment(id);

const { getInvest24Users, getUsers } = useUser();
const { data: invest24Users } = getInvest24Users();
const { data: users } = getUsers();

const targetDateInfo = computed(() => {
  if (!recruitment.value) return undefined;

  return recruitment.value.invest24RecruitmentDayInfos.find(
    (info) => info.targetDate === targetDate.value
  );
});

// modal

const modalVisible = ref(false);
const selectedUser = ref<Invest24UserAttachedInDayInfo>();

function openModal(user: Invest24UserAttachedInDayInfo) {
  selectedUser.value = user;
  modalVisible.value = true;
}

function closeModal() {
  selectedUser.value = undefined;
  modalVisible.value = false;
}

const { detachJoinUser } = useInvest24JoinUser();

function gotoEditPage() {
  goto({
    name: "UsersRecruitmentsEdit",
    params: { id: id.value },
    query: {
      targetDate: targetDate.value,
      recruitmentType: recruitment.value?.recruitmentType,
    },
  });
}

async function handleCancel(id: number) {
  if (!window.confirm("本当に取り消しますか？")) return;

  if (await detachJoinUser(id)) {
    alert("申請を取り消しました");
    getRecruitmentMutate();
    closeModal();
  } else {
    //alert("申請の取り消しに失敗しました");
  }
}

async function handleAllocateUser(prms: {
  invest24UserId: number;
  workType: Invest24WorkType;
}) {
  if (!recruitment.value) return;

  if (!window.confirm("ボランティアを追加しますか？")) return;

  if (
    await allocateUserRecruitment(recruitment.value.id, {
      ...prms,
      targetDate: targetDate.value as string,
    })
  ) {
    alert("追加しました");
    getRecruitmentMutate();
  } else {
    alert("追加に失敗しました");
  }
}
</script>

<style scoped></style>
