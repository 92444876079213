<template>
  <div>
    <UserPageActions>
      <div class="d-flex align-items-center justify-content-end w-100">
        <div class="d-flex align-items-center">
          <BasicButton
            label="新規作成"
            class="add-btn me-2"
            variant="secondary"
            outlined
            @click="
              goto({
                name: 'UsersSystemAdminRulesNew',
              })
            "
          />
        </div>
      </div>
    </UserPageActions>

    <div v-if="!applications">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <div v-else-if="applications.length">
      <FormatTable
        :formats="applications"
        is-rule-manager
        @select-format="gotoShow"
        @edit-format="gotoEdit"
        @destroy-format="handleDestroy"
      />
    </div>
    <div v-else class="d-flex align-items-center">
      <span>申請書が存在しません</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormats, useRouterUtil } from "/@/vue/composables";
import { BasicButton, Label as FormLabel } from "/@/vue/components/Atom";
import { Table as FormatTable } from "/@/vue/components/Organisms/Users/Formats";
import { UserPageActions } from "/@/vue/components/Layouts";
import { computed } from "vue";

const { goto } = useRouterUtil();

const { getFormats, destroyFormat } = useFormats();
const { data: formats, mutate: getFormatsMutate } = getFormats({
  types: ["bylaws", "application"],
});

const rules = computed(() => {
  if (!formats.value) return [];
  return formats.value.filter((f) => f.formatType === "bylaws");
});

const applications = computed(() => {
  if (!formats.value) return [];
  return formats.value.filter((f) => f.formatType === "application");
});

function gotoShow(id: number) {
  goto({
    name: "UsersSystemAdminRulesShow",
    params: {
      id,
    },
  });
}

function gotoEdit(id: number) {
  goto({ name: "UsersSystemAdminRulesEdit", params: { id: id } });
}

async function handleDestroy(id: number) {
  if (!window.confirm("本当に削除しますか？この操作は取り消せません。")) {
    return;
  }

  if (await destroyFormat(id)) {
    alert("削除しました");
    getFormatsMutate();
  } else {
    alert("削除に失敗しました");
  }
}
</script>

<style scoped></style>
