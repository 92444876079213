import { ref } from "vue";
import { DataTablePageEvent } from "primevue/datatable";

export function useTableHeader() {
  const selectedPerPage = ref(10);
  const currentPage = ref(1);

  function changePage(e: DataTablePageEvent) {
    currentPage.value = e.page + 1;
  }

  return {
    selectedPerPage,
    currentPage,

    changePage,
  };
}
