<template>
  <div>
    <UserPageActions v-if="recruitmentType === 'invest24'">
      <div class="d-flex flex-column w-100">
        <div class="d-flex justify-content-end mb-3">
          <div v-if="false" class="d-flex align-items-center">
            <SelectButton
              v-model="selectedType"
              :options="visibleTypeOptions"
              option-label="label"
              option-value="value"
              :allow-empty="false"
              class="me-2"
            />
          </div>

          <div class="d-flex align-items-center"></div>
        </div>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100"
        >
          <SelectButton
            v-if="recruitmentType === 'invest24' && !selectedRecruitment"
            v-model="selectedArea"
            :options="areasWithDisabled"
            option-label="label"
            option-value="value"
            option-disabled="disabled"
            class="mb-3"
            multiple
          />
          <div v-else></div>

          <BasicButton
            label="新規作成"
            @click="goto({ name: 'UsersRecruitmentsNew' })"
          />

          <BasicButton
            v-if="false"
            label="終了したものを表示"
            icon="pi pi-eye"
            :outlined="!showPast"
            class="mb-3"
            @click="showPast = !showPast"
          />
        </div>
      </div>
    </UserPageActions>

    <template v-if="selectedType === 'list'">
      <SelectDateDropdown
        v-model:year="selectedDate.year"
        v-model:month="selectedDate.month"
        :exists-dates="existsDates"
      />

      <RecruitmentTable
        v-if="!selectedRecruitment"
        :recruitments="filteredRecruitments"
        @select="handleSelect"
        @delete="handleDestroyRecruitment"
        @edit="goto({ name: 'UsersRecruitmentsEdit', params: { id: $event } })"
      />
      <RecruitmentDayInfoTable
        v-else
        :recruitment="selectedRecruitment"
        :show-past="showPast"
        :selected-date="selectedDate"
        @select="recruitmentDayInfoShow"
        @delete="handleDestroyRecruitment"
        @edit="recruitmentDayInfoEdit"
        @back="handleCancel"
      />

      <BasicButton
        v-if="selectedRecruitment"
        label="戻る"
        outlined
        variant="secondary"
        class="me-2"
        @click="handleCancel"
      />
    </template>
    <template v-if="selectedType === 'calendar'">
      <RecruitmentCalendar
        :recruitments="filteredRecruitments"
        :jp-holidays="jpHolidays || {}"
        :user="user"
        :invest24-users="invest24Users || []"
        :users="users || []"
        :loading="loading"
        hide-select-area
        is-admin
        :is-v-manager="isVManager"
        :show-past="showPast"
        @apply-recruitment="handleApplyRecruitment"
        @cancel-recruitment="handleCancelRecruitment"
        @destroy-recruitment="handleDestroyRecruitment"
        @allocate-user="handleAllocateUser"
        @allocate-new-user="
          goto({ name: 'UsersRecruitmentsNewUser', query: $event })
        "
        @detach-user="handleDetachUser"
        @update-selected-date="updateSelectedDate"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive, watch } from "vue";
import { areas } from "/@/modules/constants";
import {
  recruitmentFilter,
  getAreasWithDisabled,
} from "/@/modules/invest24Recruitment";
import { setStorage, getStorage } from "/@/modules/localStorage";
import {
  useCalendar,
  useRouterUtil,
  useRecruitment,
  useUser,
} from "/@/vue/composables";
import {
  SelectDate,
  BasicButton,
  SelectDateDropdown,
} from "/@/vue/components/Atom";
import { UserPageActions } from "/@/vue/components/Layouts";
import { List as RecruitmentCalendar } from "/@/vue/components/Molecules/Invest24Recruitments";
import { Table as RecruitmentTable } from "/@/vue/components/Organisms/Users/Invest24Recruitments";
import { Table as RecruitmentDayInfoTable } from "/@/vue/components/Organisms/Users/Invest24RecruitmentDayInfos";
import SelectButton from "primevue/selectbutton";
import { fromISO, luxonNow } from "/@/modules/luxon";
import {
  DateMap,
  Invest24RecruitmentClient,
  Invest24RecruitmentType,
  Invest24WorkType,
  invest24RecruitmentTypes,
} from "/@/types";
import { exists } from "i18next";

const { goto, currentRouteQuery } = useRouterUtil();

const recruitmentType = computed<Invest24RecruitmentType>(() => {
  return (
    (currentRouteQuery.value.recruitmentType as Invest24RecruitmentType) ||
    "invest24"
  );
});

// type

type VisibleType = "calendar" | "list";
const visibleTypes = ["calendar", "list"] as const;
const visibleTypeOptions = visibleTypes.map((type) => ({
  label: type === "calendar" ? "カレンダー" : "リスト",
  value: type,
}));
const selectedType = ref<VisibleType>("list");
watch(selectedType, (value) => setStorage("ManagementSelectedType", value));

// recruitmentTypes

// user

const { getUser, getInvest24Users, isVManager, getUsers } = useUser();
const { data: user, mutate: getUserMutate } = getUser();
const { data: users } = getUsers();
const { data: invest24Users } = getInvest24Users();

// recruitment

const {
  getRecruitments,
  cancelRecruitment,
  destroyRecruitment,
  allocateUserRecruitment,
  detachUserRecruitment,
} = useRecruitment();
const { data: recruitments, mutate: getRecruitmentsMutate } = getRecruitments({
  recruitmentType: recruitmentType,
});

// list

const today = luxonNow();

const selectedDate = reactive<DateMap>(
  getStorage("ManagementSelectedDate") || {
    year: undefined,
    month: undefined,
    day: undefined,
  }
);
watch(selectedDate, (value) => setStorage("ManagementSelectedDate", value));

function updateSelectedDate(dateMap: DateMap) {
  selectedDate.year = dateMap.year;
  selectedDate.month = dateMap.month;
  selectedDate.day = dateMap.day;
}

const selectedArea = ref(
  getStorage("ManagementSelectedArea") ||
    (recruitmentType.value === "invest24" ? areas : ["その他"])
);
watch(selectedArea, (value) => {
  setStorage("ManagementSelectedArea", value);
});

const areasWithDisabled = computed(() => {
  if (!recruitments.value) return [];

  return getAreasWithDisabled(recruitments.value);
});

const validAreas = computed(() => {
  return areasWithDisabled.value.filter((area) => !area.disabled);
});

const showPast = ref(true);

const areaFilteredRecruitments = computed(() => {
  if (!recruitments.value) return [];

  return recruitments.value.filter((r) =>
    recruitmentFilter(r, selectedArea.value)
  );
});

const existsDates = computed<string[]>(() => {
  if (!recruitments.value) return [];

  return areaFilteredRecruitments.value
    .filter((r) =>
      selectedRecruitment.value ? r.id === selectedRecruitment.value.id : true
    )
    .map((r) => {
      return r.invest24RecruitmentDayInfos.map((dayInfo) => dayInfo.targetDate);
    })
    .flat();
});

const filteredRecruitments = computed(() => {
  return areaFilteredRecruitments.value.filter((r) =>
    recruitmentFilter(r, selectedArea.value, selectedDate)
  );
});

const selectedRecruitment = computed(() => {
  if (!currentRouteQuery.value.id) return;

  return recruitments.value?.find(
    (r) => r.id === Number(currentRouteQuery.value.id)
  );
});

function handleSelect(id: number) {
  const targetRecruitment = recruitments.value?.find((r) => r.id === id);

  goto({
    name: "UsersRecruitmentsManagementsManagement",
    query: {
      id: id,
      recruitmentType: recruitmentType.value,
      area: targetRecruitment?.area,
    },
  });
}

function handleCancel() {
  goto({
    name: "UsersRecruitmentsManagementsManagement",
    query: { recruitmentType: recruitmentType.value },
  });
}

// calendar

const { getJpHolidays } = useCalendar();
const { data: jpHolidays } = getJpHolidays();

// actions

const loading = ref(false);

async function handleApplyRecruitment(id: number, targetDate: string) {
  goto({ name: "UsersRecruitmentsJoin", query: { id, targetDate } });
  return;
}

async function handleCancelRecruitment(id: number, targetDate: string) {
  if (!window.confirm("申請を取り消してよろしいですか？")) return;

  loading.value = true;

  if (await cancelRecruitment(id, { targetDate })) {
    alert("申請を取り消しました");
    getUserMutate();
    getRecruitmentsMutate();
  } else {
    alert("申請の取り消しに失敗しました");
  }

  loading.value = false;
}

async function handleDestroyRecruitment(id: number) {
  if (
    !window.confirm(
      "募集を削除しますか？この操作は取り消すことができません。また、すでに募集に対して参加者がいる場合は削除できません"
    )
  )
    return;

  loading.value = true;

  if (await destroyRecruitment(id)) {
    alert("削除に成功しました");
  } else {
    // alert("削除依頼の送信に失敗しました");
    getRecruitmentsMutate();
  }
}

async function handleDetachUser(
  id: number,
  invest24UserId: number,
  targetDate: string
) {
  if (
    !window.confirm("ボランティアへの参加をキャンセルします。よろしいですか？")
  )
    return;

  loading.value = true;

  if (await detachUserRecruitment(id, { invest24UserId, targetDate })) {
    alert("キャンセルしました");
    getUserMutate();
    getRecruitmentsMutate();
  } else {
    alert("キャンセルに失敗しました");
  }

  loading.value = false;
}

async function handleAllocateUser(
  id: number,
  prms: {
    invest24UserId: number;
    targetDate: string;
    workType: Invest24WorkType;
  }
) {
  if (!window.confirm("ボランティアを追加しますか？")) return;

  loading.value = true;

  if (await allocateUserRecruitment(id, prms)) {
    alert("追加しました");
    getRecruitmentsMutate();
  } else {
    alert("追加に失敗しました");
  }

  loading.value = false;
}

function recruitmentDayInfoShow(id: number, targetDate: string) {
  goto({
    name: "UsersRecruitmentsShow",
    params: { id },
    query: { targetDate, recruitmentType: recruitmentType.value },
  });
}

function recruitmentDayInfoEdit(id: number, targetDate: string) {
  goto({
    name: "UsersRecruitmentsEdit",
    params: { id },
    query: { targetDate, recruitmentType: recruitmentType.value },
  });
}
</script>

<style scoped></style>
