import Index from "./Index.vue";
import New from "./New.vue";
import Show from "./Show.vue";
import Edit from "./Edit.vue";

export default [
  {
    path: "",
    name: "UsersSystemAdminBcpIndex",
    component: Index,
    meta: {
      title: "BCP管理",
      tree: [
        {
          title: "BCP管理",
        },
      ],
    },
  },
  {
    path: "new",
    name: "UsersSystemAdminBcpNew",
    component: New,
    meta: {
      title: "新規作成",
      tree: [
        {
          name: "UsersSystemAdminBcpIndex",
          title: "BCP管理",
        },
      ],
    },
  },
  {
    path: ":id",
    name: "UsersSystemAdminBcpShow",
    component: Show,
    meta: {
      title: "詳細",
      tree: [
        {
          name: "UsersSystemAdminBcpIndex",
          title: "BCP管理",
        },
      ],
    },
  },
  {
    path: ":id/edit",
    name: "UsersSystemAdminBcpEdit",
    component: Edit,
    meta: {
      title: "編集",
      tree: [
        {
          name: "UsersSystemAdminBcpIndex",
          title: "BCP管理",
        },
      ],
    },
  },
];
