export function stripHtml(html: string) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent?.trim() || "";
}

export function zeroPadding(num: number | string, length: number) {
  return ("00" + num).slice(-length);
}

export function zen2han(str: string) {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
}

export function han2zen(str: string) {
  return str.replace(/[A-Za-z0-9]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
  });
}

export function onlyHan(str: string) {
  return str.replace(/[^A-Za-z0-9]/g, "");
}

export function getByteLength(str?: string) {
  if (!str) return 0;

  let count = 0;
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i);
    if (code <= 0x7f) {
      count += 1;
    } else if (code <= 0x7ff) {
      count += 2;
    } else if (code <= 0xffff) {
      count += 3;
    } else {
      count += 4;
    }
  }
  return count;
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("zeroPadding", () => {
    expect(zeroPadding("3", 3)).toBe("003");
  });

  it("zen2han", () => {
    expect(zen2han("ＡＢＣ")).toBe("ABC");
    expect(zen2han("０１２")).toBe("012");
  });

  it("han2zen", () => {
    expect(han2zen("ABC")).toBe("ＡＢＣ");
    expect(han2zen("012")).toBe("０１２");
  });

  it("onlyHan", () => {
    expect(onlyHan("あいうえお")).toBe("");
    expect(onlyHan("abcdefg")).toBe("abcdefg");
    expect(onlyHan("あいうえおabcde")).toBe("abcde");
  });

  it("getByteLength", () => {
    expect(getByteLength("あいうえお")).toBe(15);
    expect(getByteLength("abcde")).toBe(5);
    expect(getByteLength("あいうえおabcde")).toBe(20);
  });
}
