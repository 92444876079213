<template>
  <div>
    <UserPageActions v-if="canEditNews">
      <div class="d-flex w-100 justify-content-end">
        <BasicButton
          label="編集"
          variant="secondary"
          outlined
          @click="goto({ name: 'UsersNewsEdit', params: { id: id } })"
        />
      </div>
    </UserPageActions>

    <NewsShow v-if="news" :news="news" is-user-page />

    <div class="d-flex flex-wrap justify-content-center my-5">
      <BasicButton
        label="戻る"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto({ confirm: false })"
      />
      <BasicButton
        v-if="canEditNews"
        label="編集"
        variant="secondary"
        outlined
        @click="goto({ name: 'UsersNewsEdit', params: { id: id } })"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useNews, useRouterUtil, useUser } from "/@/vue/composables";
import { UserPageActions } from "/@/vue/components/Layouts";
import { Show as NewsShow } from "/@/vue/components/Molecules/News";
import { BasicButton } from "/@/vue/components/Atom";

const { getNewsItem } = useNews();
const { currentRouteParams, goto, backto } = useRouterUtil();

const id = computed(() => {
  return Number(currentRouteParams.value.id as string);
});

const { data: news } = getNewsItem(id, { admin: true });

// user

const { getUser, canEditNews } = useUser();
const { data: user } = getUser();
</script>

<style scoped></style>
