<template>
  <div>
    <DataTable
      v-if="formats.length"
      striped-rows
      paginator
      :rows="selectedPerPage"
      :value="formats"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-click="$emit('selectFormat', $event.data.id)"
    >
      <template #header>
        <TableHeader
          v-model="selectedPerPage"
          :target-length="formats.length"
          :current-page="currentPage"
        />
      </template>
      <Column sortable field="title" header="名前" />
      <Column
        sortable
        field="updatedAt"
        header="更新日時"
        style="width: 180px; max-width: 180px"
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.updatedAt, "slashStyle") }}</span>
        </template>
      </Column>
      <template v-if="isRuleManager">
        <Column
          header="編集"
          :style="{
            width: '60px',
          }"
        >
          <template #body="{ data }">
            <BasicButton
              icon="pi pi-pencil"
              text
              @click="$emit('editFormat', data.id)"
            />
          </template>
        </Column>

        <Column
          header="削除"
          :style="{
            width: '60px',
          }"
        >
          <template #body="{ data }">
            <div v-if="!data.title.includes('会則')">
              <BasicButton
                variant="danger"
                icon="pi pi-trash"
                text
                @click="$emit('destroyFormat', data.id)"
              />
            </div>
          </template>
        </Column>
      </template>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils, useTableHeader } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Header as TableHeader } from "/@/vue/components/Molecules/TableUtils";
import { Format } from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

defineProps<{
  formats: Format[];
  isRuleManager?: boolean;
}>();

defineEmits<{
  (e: "selectFormat", id: number): void;
  (e: "editFormat", id: number): void;
  (e: "destroyFormat", id: number): void;
}>();

//

const { isMobile } = useMqUtils();
const { selectedPerPage, currentPage, changePage } = useTableHeader();
</script>

<style scoped></style>
