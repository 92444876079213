import { fromISO, fromObject, isContain } from "/@/modules/luxon";
import { DateMap } from "/@/types/date.d";

export function isoToDateMap(iso: string): DateMap {
  const dt = fromISO(iso);

  if (!dt || dt.invalidReason) {
    throw new Error(
      `isoToDateMap received unprocessable value. ${
        dt ? "Invalid reason: " + dt.invalidReason : ""
      }`
    );
  }

  return { year: dt.year, month: dt.month };
}

export function createExistsDateMap(isos: string[]): DateMap[] {
  const sorted = isos.sort();
  return sorted.map((iso) => isoToDateMap(iso));
}

export function getStartEndFromMap(dateMap: DateMap) {
  const startDateTime = fromObject({
    year: dateMap.year,
    month: dateMap.month,
    day: 1,
  });

  if (!startDateTime) throw new Error("unexpected result fromObject");

  const start = startDateTime.toISO();
  const end = startDateTime.endOf("month").toISO();

  if (!start || !end) {
    throw new Error("Unexpected result in getStartEndFromMap");
  }

  return {
    start,
    end,
  };
}

export function isSameMonth(date: string, dateMap: DateMap) {
  const a = fromObject({ year: dateMap.year, month: dateMap.month, day: 1 });
  const b = fromISO(date);

  if (!a || !b) {
    throw new Error(`unexpected date ${date}`);
  }

  return a.hasSame(b, "month");
}

// 重複があるかどうかの関数

export function isOverlap(
  start1: string,
  end1: string,
  start2: string,
  end2: string
) {
  const startDT1 = fromISO(start1);
  const endDT1 = fromISO(end1);
  const startDT2 = fromISO(start2);
  const endDT2 = fromISO(end2);

  if (endDT1 < startDT2 || endDT2 < startDT1) {
    return false;
  }

  return true;
}

export function isContainInDateMap(target: string, dateMap: DateMap) {
  if (!dateMap.year && !dateMap.month) {
    return true;
  }

  const { start, end } = getStartEndFromMap(dateMap);
  return isContain(target, { start, end });
}

export function isOverLapWithDateMap(
  start: string,
  end: string,
  dateMap: DateMap
) {
  const start2 = fromObject({
    year: dateMap.year,
    month: dateMap.month,
    day: 1,
  });

  if (!start2) {
    throw new Error("unexpected result fromObject");
  }

  const end2 = start2.endOf("month");

  return isOverlap(start, end, start2.toISO(), end2.toISO());
}

if (import.meta.vitest) {
  const { describe, expect, it } = import.meta.vitest;

  describe("isOverlap", () => {
    it("returns true when overlaps", () => {
      const result = isOverlap(
        "2022-04-05T10:00:00Z",
        "2022-04-05T12:00:00Z",
        "2022-04-05T11:00:00Z",
        "2022-04-05T13:00:00Z"
      );
      expect(result).toBeTruthy();
    });

    it("returns false when does not overlap", () => {
      const result = isOverlap(
        "2022-04-05T10:00:00Z",
        "2022-04-05T11:00:00Z",
        "2022-04-05T12:00:00Z",
        "2022-04-05T13:00:00Z"
      );
      expect(result).toBeFalsy();
    });
  });

  describe("isOverlapWithDateMap", () => {
    it("returns true when overlaps", () => {
      const result = isOverLapWithDateMap(
        "2022-04-05T10:00:00Z",
        "2022-04-05T12:00:00Z",
        {
          year: 2022,
          month: 4,
        }
      );
      expect(result).toBeTruthy();
    });

    it("returns false when does not overlap", () => {
      const result = isOverLapWithDateMap(
        "2022-04-05T10:00:00Z",
        "2022-04-05T11:00:00Z",
        {
          year: 2023,
          month: 4,
        }
      );
      expect(result).toBeFalsy();
    });
  });
}
