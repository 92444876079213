<template>
  <div>
    <UserPageActions v-if="false">
      <div class="d-flex justify-content-between w-100">
        <BasicButton
          label="被災高齢者等把握事業訪問者予定リスト"
          icon="pi pi-print"
          @click="goto({ name: 'UsersInvest24DataPdf' })"
        />
      </div>
    </UserPageActions>

    <div class="mb-2">
      <span class="text-danger">
        氏名・生年月日・メールアドレスのいずれかが異なっている場合は別会員として表示されます
      </span>
    </div>

    <div class="mb-3" style="max-width: 350px">
      <FormLabel label="名前検索" />
      <InputForm
        v-model:value="searchName"
        class="w-100"
        is-valid
        placeholder="氏名・かな・メールアドレス・電話番号"
      />
    </div>

    <UsersTable :users="filteredUsers" @select="openModal" />
    <UserModal
      v-model:visible="modalVisible"
      :user="selectedUser"
      @destroy="handleDestroyUser"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useInvest24User, useRouterUtil } from "/@/vue/composables";
import { UserPageActions } from "/@/vue/components/Layouts";
import {
  BasicButton,
  Label as FormLabel,
  InputForm,
} from "/@/vue/components/Atom";
import {
  Table as UsersTable,
  Modal as UserModal,
} from "/@/vue/components/Organisms/Invest24/Invest24Users";
import { Invest24UserClient } from "/@/types";
import { computed } from "vue";

const { goto } = useRouterUtil();

const { getInvest24Users, destroyInvest24User } = useInvest24User();
const { data: users } = getInvest24Users();

// search

const searchName = ref("");

const filteredUsers = computed(() => {
  if (!users.value) {
    return [];
  }

  return users.value.filter((u: Invest24UserClient) => {
    return (
      !searchName.value ||
      (u.lastName + u.firstName).includes(searchName.value) ||
      (u.lastNameKana + u.firstNameKana).includes(searchName.value) ||
      u.email.includes(searchName.value) ||
      u.phoneNumber.includes(searchName.value)
    );
  });
});

// modal

const modalVisible = ref(false);
const selectedUser = ref<Invest24UserClient>();

function openModal(user: Invest24UserClient) {
  selectedUser.value = user;
  modalVisible.value = true;
}

async function handleDestroyUser(id: number) {
  if (!window.confirm("開発組織に削除依頼を送信します。よろしいですか？")) {
    return;
  }

  if (await destroyInvest24User(id)) {
    alert("削除依頼に成功しました。削除されるまで暫くお待ち下さい。");
    return;
  } else {
    alert("削除依頼に失敗しました。");
    return;
  }
}
</script>

<style scoped></style>
