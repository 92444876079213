<template>
  <div>
    <DataTable
      striped-rows
      paginator
      :rows="selectedPerPage"
      :value="users"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-select="$emit('select', $event.data)"
    >
      <template #header>
        <TableHeader
          v-model="selectedPerPage"
          :target-length="users.length"
          :current-page="currentPage"
        />
      </template>
      <Column
        sortable
        field="userId"
        header="会員"
        style="width: 100px"
        class="text-center"
      >
        <template #body="slotProps">
          <i
            v-if="!!slotProps.data.userId"
            class="pi pi-check text-success"
          ></i>
        </template>
      </Column>
      <Column
        v-if="!isMobile"
        field="createdAt"
        header="追加日"
        sortable
        :style="
          isMobile
            ? { width: '100px', 'min-width': '100px' }
            : { width: '100px', 'min-width': '100px' }
        "
      >
        <template #body="{ data }">
          <span>{{
            basicFormatter(data.createdAt, "slashStyleOnlyDate")
          }}</span>
        </template>
      </Column>
      <Column
        :field="(data) => data.lastName + data.firstName"
        header="氏名"
        :style="isMobile ? {} : { width: '250px' }"
      >
        <template #body="{ data }">
          <span
            >{{ data.lastName }}{{ data.firstName }}({{ data.lastNameKana
            }}{{ data.firstNameKana }})</span
          >
        </template>
      </Column>
      <Column
        field="birthday"
        header="生年月日"
        :style="isMobile ? { width: '110px' } : { width: '110px' }"
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.birthday, "slashStyleOnlyDate") }}</span>
        </template>
      </Column>
      <Column
        v-if="!isMobile"
        field="email"
        header="メールアドレス"
        :style="isMobile ? { width: '240px' } : { width: '240px' }"
      >
        <template #body="{ data }">
          <span>{{ data.email }}</span>
        </template>
      </Column>
      <Column v-if="!isMobile" field="phoneNumber" header="電話番号">
        <template #body="{ data }">
          <span>{{ formatNational(data.phoneNumber) }}</span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { basicFormatter } from "/@/modules/luxon";
import { formatNational } from "/@/modules/phonenumber";
import { useMqUtils, useTableHeader } from "/@/vue/composables";
import { Header as TableHeader } from "/@/vue/components/Molecules/TableUtils";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { Invest24UserClient } from "/@/types";

defineProps<{
  users: Invest24UserClient[];
}>();

defineEmits<{
  (e: "select", user: Invest24UserClient): void;
  (e: "destroy", invest24JoinUserId: number): void;
}>();

const { isMobile } = useMqUtils();

const { selectedPerPage, currentPage, changePage } = useTableHeader();
</script>

<style scoped></style>
