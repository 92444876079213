<template>
  <div>
    <DataTable
      striped-rows
      paginator
      :rows="10"
      :value="recruitmentDayInfo.invest24JoinUserInfos || []"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-select="$emit('select', $event.data)"
    >
      <Column
        v-if="false"
        field="targetDate"
        header="日付"
        :style="
          isMobile
            ? { width: '150px', 'min-width': '150px' }
            : { width: '150px', 'min-width': '150px' }
        "
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(recruitmentDayInfo.targetDate) }}</span>
        </template>
      </Column>
      <Column
        :field="(data) => data.lastName + data.firstName"
        header="氏名"
        sortable
        :style="isMobile ? { width: '120px' } : { width: '120px' }"
      >
        <template #body="{ data }">
          <span>{{ data.lastName }}{{ data.firstName }}</span>
        </template>
      </Column>
      <Column
        v-if="recruitment.recruitmentType === 'invest24'"
        field="workType"
        header="勤務種別"
        :style="isMobile ? { width: '80px' } : { width: '80px' }"
      >
        <template #body="{ data }">
          <span>{{ data.workType }}</span>
        </template>
      </Column>
      <Column
        v-if="!isMobile"
        field="birthday"
        header="生年月日"
        :style="isMobile ? { width: '100px' } : { width: '100px' }"
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.birthday, "slashStyleOnlyDate") }}</span>
        </template>
      </Column>
      <Column
        v-if="!isMobile"
        field="email"
        header="メールアドレス"
        :style="isMobile ? { width: '200px' } : { width: '200px' }"
      >
        <template #body="{ data }">
          <span>{{ data.email }}</span>
        </template>
      </Column>
      <Column field="phoneNumber" header="電話番号">
        <template #body="{ data }">
          <span>{{ data.phoneNumber }}</span>
        </template>
      </Column>
      <Column :style="isMobile ? { width: '60px' } : { width: '60px' }">
        <template #body="{ data }">
          <BasicButton
            icon="pi pi-trash"
            variant="danger"
            @click="$emit('detach', data.invest24JoinUserId)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {
  Invest24RecruitmentClient,
  Invest24RecruitmentDayInfoClient,
  Invest24UserAttachedInDayInfo,
} from "/@/types";

const { isMobile } = useMqUtils();

defineProps<{
  recruitment: Invest24RecruitmentClient;
  recruitmentDayInfo: Invest24RecruitmentDayInfoClient;
}>();

defineEmits<{
  (e: "select", user: Invest24UserAttachedInDayInfo): void;
  (e: "detach", invest24JoinUserId: number): void;
}>();
</script>

<style scoped></style>
