import Index from "./Index.vue";
import Management from "./Management.vue";

export default [
  {
    path: "",
    name: "UsersRecruitmentsManagementsIndex",
    component: Index,
    meta: {
      title: "ボランティア管理",
      subtitle: "VOLUNTEER",
    },
  },
  {
    path: "management",
    name: "UsersRecruitmentsManagementsManagement",
    component: Management,
    meta: {
      title: "ボランティア個別ページ",
      tree: [
        {
          title: "ボランティア管理",
          name: "UsersRecruitmentsManagementsIndex",
        },
      ],
    },
  },
];
