<template>
  <div>
    <DataTable
      striped-rows
      paginator
      :rows="selectedPerPage"
      :value="dayInfos"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-click="$emit('select', recruitment.id, $event.data.targetDate)"
      @page="changePage"
    >
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          <BasicButton
            label="戻る"
            variant="secondary"
            outlined
            @click="$emit('back')"
            class="text-nowrap"
          />
          <TableHeader
            v-model="selectedPerPage"
            :target-length="dayInfos.length"
            :current-page="currentPage"
          />
        </div>
      </template>
      <Column
        field="targetDate"
        header="日付"
        sortable
        :style="
          isMobile
            ? { width: '170px', 'min-width': '170px' }
            : { width: '170px', 'min-width': '170px' }
        "
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.targetDate) }}</span>
        </template>
      </Column>
      <Column
        v-if="false"
        field="area"
        header="エリア"
        :style="isMobile ? { width: '80px' } : { width: '80px' }"
      >
        <template #body="{ data }">
          <span>{{ recruitment.area }}</span>
        </template>
      </Column>
      <Column
        field="currentInvest24JoinUsersCount"
        header="申請人数"
        sortable
        :style="isMobile ? { with: '120px' } : { width: '120px' }"
      >
        <template #body="{ data }">
          <span>
            {{ data.currentInvest24JoinUsersCount }}/{{ data.needCount }}
          </span>
        </template>
      </Column>
      <Column
        v-if="!isMobile"
        :field="
          (data) =>
            data.invest24JoinUserInfos
              .map((info) => `${info.lastName}${info.firstName}`)
              .join(', ')
        "
        header="申請者"
        sortable
        :style="isMobile ? { with: '100%' } : {}"
      >
        <template #body="{ data }">
          <span>
            {{
              data.invest24JoinUserInfos
                .map((info) => `${info.lastName}${info.firstName}`)
                .join(", ")
            }}
          </span>
        </template>
      </Column>
      <Column :style="isMobile ? { width: '60px' } : { width: '60px' }">
        <template #body="{ data }">
          <BasicButton
            icon="pi pi-pencil"
            text
            @click="$emit('edit', recruitment.id, data.targetDate)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { basicFormatter, isGreater, luxonNow } from "/@/modules/luxon";
import { useMqUtils, useTableHeader } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { Invest24RecruitmentClient, DateMap } from "/@/types";
import { isContainInDateMap } from "/@/modules/datemap";
import { Header as TableHeader } from "/@/vue/components/Molecules/TableUtils";

const { isMobile } = useMqUtils();

const props = defineProps<{
  recruitment: Invest24RecruitmentClient;
  selectedDate: DateMap;
  showPast?: boolean;
}>();

defineEmits<{
  (e: "select", id: number, targetDate: string): void;
  (e: "edit", id: number, targetDate: string): void;
  (e: "back"): void;
}>();

const { selectedPerPage, currentPage, changePage } = useTableHeader();
selectedPerPage.value = 50;

const today = luxonNow().startOf("day");

const dayInfos = computed(() => {
  if (!props.recruitment || !props.recruitment.invest24RecruitmentDayInfos)
    return [];

  return props.recruitment.invest24RecruitmentDayInfos.filter((dayInfo) => {
    return (
      isContainInDateMap(dayInfo.targetDate, props.selectedDate) &&
      (props.showPast ||
        isGreater(dayInfo.targetDate, today.toISO(), { orEqual: true }))
    );
  });
});
</script>

<style scoped></style>
