import Index from "./Index.vue";
import Edit from "./Edit.vue";

export default [
  {
    path: "",
    name: "UsersSponsorsIndex",
    component: Index,
    meta: {
      title: "賛助会員一覧",
      tree: [],
    },
  },
  {
    path: "edit",
    name: "UsersSponsorsEdit",
    component: Edit,
    meta: {
      title: "賛助会員編集",
      tree: [{ title: "賛助会員一覧", name: "UsersSponsorsIndex" }],
    },
  },
];
