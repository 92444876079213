<template>
  <div>
    <div
      class="d-flex flex-wrap align-items-center justify-content-center"
      :style="{
        'max-width': isMobile ? '700px' : '900px',
      }"
    >
      <FilterButton
        v-for="(o, idx) in organizations"
        :label="o.name"
        :is-active="organizationId === o.id"
        :small="isMobile"
        :unread-conut="o.unreadCount"
        :key="o.id"
        @click="organizationId = o.id"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMqUtils } from "/@/vue/composables";
import { FilterButton } from "/@/vue/components/Atom/Buttons";
import { OrganizationClient } from "/@/types";

defineProps<{
  organizations: OrganizationClient[];
}>();

const organizationId = defineModel<number>();

const { isMobile } = useMqUtils();
</script>

<style scoped></style>
