<template>
  <div id="user-header" class="pt-3">
    <Toolbar>
      <template #start>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <BasicButton
              v-if="user"
              id="menu-button"
              icon="pi pi-bars"
              class="me-2 menu-button"
              need-icon
              variant="secondary"
              outlined
              large
              @click="showHeader = !showHeader"
            />
            <h5 class="d-flex align-items-center">
              <PageTree
                v-if="overwriteTree?.length || pageTree?.length"
                :tree="overwriteTree || pageTree"
              />
              <b
                id="user-page-header-title"
                style="border-bottom: 2px solid #999"
                >{{ overwriteTitle || pageTitle }}</b
              >
            </h5>
          </div>
        </div>
      </template>
      <template #end>
        <div class="px-2">
          <div v-if="user" class="d-flex align-items-center">
            <i class="pi pi-user me-2"></i>
            <span>{{ user.email }}</span>
          </div>
        </div>
      </template>
    </Toolbar>
    <Divider class="py-3 m-0" />
    <Sidebar v-if="user" v-model:visible="showHeader" id="user-header-sidebar">
      <PanelMenu
        id="user-panel-menu"
        :model="headerItems"
        v-model:expanded-keys="expandedKeys"
        multiple
        class="without-border w-100"
      />
    </Sidebar>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import { treeSearchComplete, treeSearch } from "/@/modules/json";
import { BasicButton } from "/@/vue/components/Atom";
import { PageTree } from "/@/vue/components/Layouts";
import { useRouterUtil, useAuthorize, useUser } from "/@/vue/composables";
import Sidebar from "primevue/sidebar";
import PanelMenu from "primevue/panelmenu";
import Toolbar from "primevue/toolbar";
import Divider from "primevue/divider";
import type { MenuItem } from "primevue/menuitem";
import { Invest24RecruitmentType, UserClient } from "/@/types";

interface Props {
  user?: UserClient;
  isAdminPage?: boolean;
  overwriteTitle?: string;
  overwriteTree?: {
    name: string;
    title: string;
    query?: Record<string, any>;
  }[];
}

const props = defineProps<Props>();
defineEmits(["logout", "hideHeader"]);

const showHeader = ref<boolean>(false);

const { goto, reload, currentRouteMeta, currentRouteQuery, currentRouteName } =
  useRouterUtil();
const {
  isAllManager,
  isAllPoster,
  isVManager,
  isOwner,
  isOffice,
  isOrganizationManager,
  isIManager,
  isABCManager,
  isABCPoster,
  isUserManager,
  isAllLManager,
  isBcpManager,
  isRuleManager,
} = useUser();

const recruitmentType = computed(() => {
  return (
    (currentRouteQuery.value.recruitmentType as Invest24RecruitmentType) ||
    "invest24"
  );
});

const pageTitle = computed(() => {
  return currentRouteMeta.value?.title;
});

const pageTree = computed(() => {
  return currentRouteMeta.value?.tree || [];
});

function commandHandler(command: () => void) {
  return () => {
    command();
    showHeader.value = false;
  };
}

// header items

const expandedKeys = ref<Record<string, any>>({});
const headerItems = computed<MenuItem[]>(() => {
  return [
    /*
    {
      label: "会員ページ",
      icon: "pi pi-th-large",
      visible: !!props.user && false,
      items: [
        {
          label: "ボランティア",
          icon: "pi pi-users",
          visible: true,
          items: [
            {
              label: "申請済ボランティア",
              icon: "pi pi-briefcase",
              visible: true,
              command: commandHandler(() =>
                goto({
                  name: "UsersJoinUsersIndex",
                })
              ),
            },
            {
              label: "ボランティア申請",
              icon: "pi pi-briefcase",
              visible: true,
              command: commandHandler(() =>
                goto({
                  name: "UsersRecruitmentsIndex",
                })
              ),
            },
          ],
        },
        {
          label: "会員メニュー",
          icon: "pi pi-th-large",
          items: [
            {
              label: "新着情報",
              icon: "pi pi-list",
              visible: !!props.user,
              command: commandHandler(() => {}),
            },
            {
              label: "研修記録",
              visible: !!props.user && false,
              command: commandHandler(() => {}),
            },
            {
              label: "研修動画",
              visible: !!props.user && false,
              command: commandHandler(() =>
                goto({ name: "UsersTrainingsIndex" })
              ),
            },
            {
              label: "ＢＣＰ",
              icon: "pi pi-book",
              visible: !!props.user,
              command: commandHandler(() => goto({ name: "UsersBcpIndex" })),
            },
          ],
        },
      ],
    },
    */
    {
      key: "Users",
      label: "管理者メニュー",
      visible:
        isOffice.value ||
        isAllManager.value ||
        isAllPoster.value ||
        isUserManager.value ||
        isAllLManager.value ||
        isOrganizationManager.value ||
        isABCManager.value ||
        isABCPoster.value ||
        isVManager.value ||
        isBcpManager.value ||
        isRuleManager.value,
      items: [
        {
          to: {
            name: "UsersRecruitmentsManagementsIndex",
          },
          key: "UsersRecruitmentsManagements",
          label: "ボランティア管理",
          visible: isVManager.value,
          items: [
            {
              key: "UsersRecruitmentsManagementsManagementInvest24",
              label: "奥能登実態調査管理",
              visible: isVManager.value,
              command: commandHandler(() =>
                goto({
                  name: "UsersRecruitmentsManagementsManagement",
                  query: { recruitmentType: "invest24" },
                })
              ),
              class: {
                "p-menuitem-active":
                  currentRouteName.value ===
                    "UsersRecruitmentsManagementsManagement" &&
                  recruitmentType.value === "invest24",
              },
            },
            {
              key: "UsersRecruitmentsManagementsManagementShelter24",
              label: "1.5次避難所管理",
              visible: isVManager.value,
              command: commandHandler(() =>
                goto({
                  name: "UsersRecruitmentsManagementsManagement",
                  query: { recruitmentType: "shelter24" },
                })
              ),
              class: {
                "p-menuitem-active":
                  currentRouteName.value ===
                    "UsersRecruitmentsManagementsManagement" &&
                  recruitmentType.value === "shelter24",
              },
            },
            {
              key: "UsersInvest24DataIndex",
              label: "ボランティア会員管理",
              visible: isVManager.value,
              command: commandHandler(() =>
                goto({ name: "UsersInvest24DataIndex" })
              ),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersInvest24DataIndex",
              },
            },
          ],
        },
        {
          key: "UsersNews",
          label: "投稿管理",
          visible: isAllPoster.value || isABCPoster.value,
          items: [
            {
              key: "UsersNewsIndex",
              label: "投稿一覧",
              visible: !!isAllPoster.value || isABCPoster.value,
              command: commandHandler(() => goto({ name: "UsersNewsIndex" })),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersNewsIndex",
              },
            },
          ],
        },
        {
          key: "UsersOrganizations",
          label: "組織・会員管理",
          visible:
            isOffice.value ||
            isAllPoster.value ||
            isABCManager.value ||
            isOrganizationManager.value ||
            isUserManager.value ||
            isAllLManager.value,
          items: [
            {
              key: "UsersOrganizationsIndex",
              label: "組織管理",
              visible: !!isABCManager.value || !!isOrganizationManager.value,
              command: commandHandler(() =>
                goto({ name: "UsersOrganizationsIndex" })
              ),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersOrganizationsIndex",
              },
            },
            {
              key: "UsersSystemAdminUsers",
              label: "会員管理",
              visible:
                !!isOffice.value ||
                !!isABCManager.value ||
                !!isUserManager.value ||
                !!isAllLManager.value,
              command: commandHandler(() =>
                goto({ name: "UsersSystemAdminUsers" })
              ),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersSystemAdminUsers",
              },
            },
          ],
        },
        {
          key: "UsersContents",
          label: "各種管理",
          visible:
            isIManager.value || isBcpManager.value || isRuleManager.value,
          items: [
            {
              key: "UsersMagazinesIndex",
              label: "広報誌『わ』管理",
              visible: !!isRuleManager.value,
              command: commandHandler(() =>
                goto({ name: "UsersMagazinesIndex" })
              ),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersMagazinesIndex",
              },
            },
            {
              key: "UsersSponsorsIndex",
              label: "賛助会員管理",
              visible: !!isRuleManager.value,
              command: commandHandler(() =>
                goto({ name: "UsersSponsorsIndex" })
              ),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersSponsorsIndex",
              },
            },
            {
              key: "UsersSystemAdminBcpIndex",
              label: "ＢＣＰ管理",
              visible: !!isBcpManager.value,
              command: commandHandler(() =>
                goto({ name: "UsersSystemAdminBcpIndex" })
              ),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersSystemAdminBcpIndex",
              },
            },
            {
              key: "UsersSystemAdminRulesIndex",
              label: "協会書類等管理",
              visible: !!isRuleManager.value,
              command: commandHandler(() =>
                goto({ name: "UsersSystemAdminRulesIndex" })
              ),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersSystemAdminRulesIndex",
              },
            },
            {
              key: "UsersInquiriesIndex",
              label: "お問い合わせ管理",
              visible: !!isIManager.value,
              command: commandHandler(() =>
                goto({ name: "UsersInquiriesIndex" })
              ),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersInquiriesIndex",
              },
            },
          ],
        },
        {
          key: "UsersTrainings",
          label: "研修管理",
          visible: !!isAllManager.value && false,
          items: [
            {
              key: "UsersTrainingsIndex",
              label: "研修投稿一覧",
              visible: !!isAllManager.value,
              command: commandHandler(() => {}),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersTrainingsIndex",
              },
            },
            {
              key: "UsersTrainingsRecordsIndex",
              label: "研修投稿",
              visible: !!isAllManager.value,
              command: commandHandler(() => {}),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersTrainingsRecordsIndex",
              },
            },
            {
              key: "UsersTrainingsRecordsConfirm",
              label: "研修記録確認",
              visible: !!isAllManager.value,
              command: commandHandler(() => {}),
              class: {
                "p-menuitem-active":
                  currentRouteName.value === "UsersTrainingsRecordsConfirm",
              },
            },
          ],
        },
      ],
    },
    {
      label: "サイトトップ",
      visible: !!props.user,
      command: commandHandler(() => goto({ name: "Index" })),
    },
    {
      label: "ログアウト",
      visible: !!props.user,
      command: commandHandler(() => handleLogout()),
    },
  ];
});

function expandNode(node: { key: string; items?: MenuItem[] }) {
  if (node && node.items && node.items.length) {
    expandedKeys.value[node.key] = true;

    for (let child of node.items as (MenuItem & { key: string })[]) {
      expandNode(child);
    }
  }
}

onMounted(() => {
  let key = currentRouteName.value;

  if (key === "UsersRecruitmentsManagementsManagement") {
    if (!recruitmentType.value) {
      key = "UsersRecruitmentsManagementsManagementInvest24";
    } else {
      key = `UsersRecruitmentsManagementsManagement${recruitmentType.value[0].toUpperCase()}${recruitmentType.value.slice(
        1
      )}`;
    }
  }

  if (!key) return;

  let targetTree = undefined;

  headerItems.value.forEach((item) => {
    if (!targetTree) {
      targetTree = treeSearch({ key: "key", value: key }, item);
    }
  });

  expandNode(targetTree);
});

// logout

const { logout } = useAuthorize();

async function handleLogout() {
  await logout();
  reload();
}
</script>

<style lang="scss" scoped>
#header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  color: $main-gray-color;
  background-color: white;

  .p-toolbar {
    padding: 0;
  }

  .p-button {
    border: none;
  }

  .menu-button {
    background-color: inherit;
    //border: 1px solid lightgray;
    .pi {
      font-size: 1.5rem;
    }
  }
}
</style>

<style lang="scss">
#user-header-sidebar {
  .p-menuitem-active {
    a {
      background-color: beige;
    }
  }
}

.p-sidebar-left .p-sidebar {
  width: 23rem;
}
</style>
