import html2pdf from "html2pdf.js";
import { PdfGenOptions } from "/@/types";

export function generatePDF(
  targetId: string,
  filename: string,
  jsPdfOption: PdfGenOptions = {
    unit: "in",
    format: "a4",
    orientation: "portrait",
    precision: 0,
  }
) {
  const element = document.getElementById(targetId);

  const opt = {
    filename: filename,
    jsPDF: jsPdfOption,
  };

  html2pdf().set(opt).from(element).save();
}

export const a4width = "210mm";
export const a4height = "297mm";

export const pdfA4PortraitStyles = {
  width: a4width,
  height: a4height,
  padding: "20mm",
  "font-family": "'MS Pゴシック', sans-serif",
};

export function getPdfA4PortraitStyles(pageNumber: number) {
  return {
    width: a4width,
    height: `calc(${a4height} * ${pageNumber})`,
    padding: "20mm",
    "font-family": "'MS Pゴシック', sans-serif",
  };
}

export const pdfViewerStyles = {
  width: a4width,
  "max-width": a4width,
  height: a4height,
  overflow: "scroll",
  border: "1px solid #000",
};
