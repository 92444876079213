export * from "./invest24JoinUser";
import axios from "axios";
import { errorHandle } from "/@/modules/error";
import { Invest24LoginPrms } from "/@/types/invest24User.d";
import { ComputedRef } from "vue";
import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";
import { Invest24UserClient } from "/@/types";

export function useInvest24User() {
  async function login(prms: Invest24LoginPrms) {
    // Cancel invest24Recruitment
    try {
      const res = await axios.post(`/api/v1/invest24_users/login`, {
        invest24User: prms,
      });

      return res.data.uuid;
    } catch (e) {
      alert(errorHandle(e));
      return false;
    }
  }

  function getUser(uuid: ComputedRef<string>) {
    return useSWRV<Invest24UserClient>(
      uuid.value ? `/api/v1/invest24_users/check?uuid=${uuid.value}` : null,
      fetcher
    );
  }

  function getInvest24Users() {
    return useSWRV<Invest24UserClient[]>("/api/v1/invest24_users", fetcher);
  }

  async function destroyInvest24User(id: number) {
    try {
      await axios.delete(`/api/v1/invest24_users/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    login,
    getUser,
    getInvest24Users,
    destroyInvest24User,
  };
}
