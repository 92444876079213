<template>
  <div>
    <UserPageActions>
      <div class="d-flex align-items-center justify-content-end w-100">
        <div class="d-flex align-items-center">
          <BasicButton
            label="新規作成"
            class="add-btn me-2"
            variant="secondary"
            outlined
            @click="
              goto({
                name: 'UsersMagazinesNew',
              })
            "
          />
        </div>
      </div>
    </UserPageActions>
    <div v-if="!magazines">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <MagazineTable
      v-else-if="magazines.length"
      :magazines="magazines"
      @select-magazine="gotoMagazine"
      @trash-magazine="handleTrashMagazine"
    />
    <div v-else class="d-flex align-items-center">
      <span>データが存在しません</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMagazines, useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Table as MagazineTable } from "/@/vue/components/Organisms/Users/Magazines";
import { UserPageActions } from "/@/vue/components/Layouts";

const { goto } = useRouterUtil();

const { getUsersMagazines, destroyMagazine } = useMagazines();
const { data: magazines, mutate: getMagazinesMutate } = getUsersMagazines();

function gotoMagazine(id: number) {
  goto({
    name: "UsersMagazinesShow",
    params: {
      id,
    },
  });
}

async function handleTrashMagazine(id: number | number[]) {
  if (!window.confirm("本当に削除しますか？この操作は取り消せません。")) {
    return;
  }

  if (await destroyMagazine(id)) {
    alert("削除しました");
    getMagazinesMutate();
    goto({ name: "UsersMagazinesIndex" });
  } else {
    alert("削除に失敗しました");
  }
}
</script>

<style scoped></style>
