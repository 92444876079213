import Index from "./Index.vue";

export default [
  {
    path: "",
    component: Index,
    name: "Shelter24Index",
    meta: {
      title: "1.5次避難所ボランティア",
      subtitle: "VOLUNTEER",
      tree: [
        {
          title: "1.5次避難所ボランティア",
        },
      ],
    },
  },
];
