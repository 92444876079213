<template>
  <div>
    <div class="d-flex flex-column align-items-center w-100">
      <div class="contents-960 w-100">
        <div class="w-100 mb-3 mt-5">
          <FormatsList :formats="applications" show-page-info class="w-100" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useFormats } from "/@/vue/composables";
import { Label as FormLabel } from "/@/vue/components/Atom";
import { List as FormatsList } from "/@/vue/components/Molecules/Format";

const { getFormats } = useFormats();
const { data: formats } = getFormats({
  types: ["application"],
});

const allFormats = computed(() => {
  if (!formats.value) return [];

  return formats.value;
});

const bylaws = computed(() => {
  if (!formats.value) return [];

  return allFormats.value.filter((format) => format.formatType === "bylaws");
});

const applications = computed(() => {
  if (!formats.value) return [];

  return allFormats.value.filter(
    (format) => format.formatType === "application"
  );
});
</script>

<style scoped></style>
