import Index from "./Index.vue";
import New from "./New.vue";

export default [
  {
    path: "",
    name: "UsersJoinUsersIndex",
    component: Index,
    meta: {
      title: "申請済ボランティア",
      subtitle: "APPLIED",
      tree: [
        {
          title: "申請済ボランティア",
          name: "UsersJoinUsersIndex",
        },
      ],
    },
  },
  {
    path: "new",
    name: "UsersJoinUsersNew",
    component: New,
    meta: {
      title: "ボランティア申請",
      tree: [],
    },
  },
];
