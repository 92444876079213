<template>
  <div>
    <UserPageActions>
      <div class="d-flex align-items-center justify-content-end w-100">
        <div class="d-flex align-items-center">
          <BasicButton
            label="新規作成"
            class="add-btn me-2"
            variant="secondary"
            outlined
            @click="
              goto({
                name: 'UsersSystemAdminBcpNew',
              })
            "
          />
        </div>
      </div>
    </UserPageActions>
    <div v-if="!bcps">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <FormatTable
      v-else-if="bcps.length"
      :formats="bcps"
      :is-rule-manager="isRuleManager"
      @select-format="gotoShow"
      @edit-format="gotoEdit"
      @destroy-format="handleDestroy"
    />
    <div v-else class="d-flex align-items-center">
      <span>データが存在しません</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUser, useFormats, useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Table as FormatTable } from "/@/vue/components/Organisms/Users/Formats";
import { UserPageActions } from "/@/vue/components/Layouts";
import { computed } from "vue";

const { goto } = useRouterUtil();

const { isRuleManager } = useUser();

const { getFormats, destroyFormat } = useFormats();
const { data: formats, mutate: getFormatsMutate } = getFormats({
  types: ["bcp"],
});

const bcps = computed(() => {
  if (!formats.value) return [];
  return formats.value;
});

function gotoShow(id: number) {
  goto({
    name: "UsersSystemAdminBcpShow",
    params: {
      id,
    },
  });
}

function gotoEdit(id: number) {
  goto({ name: "UsersSystemAdminBcpEdit", params: { id: id } });
}

async function handleDestroy(id: number | number[]) {
  if (!window.confirm("本当に削除しますか？この操作は取り消せません。")) {
    return;
  }

  if (await destroyFormat(id)) {
    alert("削除しました");
    getFormatsMutate();
  } else {
    alert("削除に失敗しました");
  }
}
</script>

<style scoped></style>
