import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { InquiryClient, InquiryForm, InquiryReplyForm } from "/@/types";
import { Ref, ComputedRef } from "vue";

export function useInquiries() {
  function getInquiry(id: Ref<number> | ComputedRef<number>) {
    return useSWRV<InquiryClient>(
      () => (id.value ? `/api/v1/inquiries/${id.value}` : null),
      fetcher
    );
  }

  function getInquiries() {
    return useSWRV<InquiryClient[]>(`/api/v1/inquiries`, fetcher);
  }

  async function submitInquiry(prms: InquiryForm) {
    try {
      await axios.post("/api/v1/inquiries", {
        inquiry: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function replyInquiry(id: number, prms: InquiryReplyForm) {
    try {
      await axios.post(`/api/v1/inquiries/${id}/reply`, {
        inquiry: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getInquiry,
    getInquiries,
    submitInquiry,
    replyInquiry,
  };
}
