<template>
  <div>
    <div class="d-flex align-items-center justify-content-center">
      <div class="contents-960">
        <div
          class="d-flex flex-column align-items-center justify-content-center py-5"
        >
          <h3 class="mb-5">入会をご検討の方へ</h3>
          <h4 class="text-green">メッセージ</h4>
        </div>

        <div class="mb-3">
          <p class="mb-2">
            　当協会は平成12年の5月、全国でもいち早く介護支援専門員のための組織として発足しました。
          </p>
          <p class="mb-2">
            　平成18年11月には、日本介護支援専門員協会が発足、全国的な活動へと広がり、今日では、富山・福井県と共に北陸ブロックとしての活動も始まっています。
          </p>
          <p class="mb-2">
            　私たち介護支援専門員は、介護が必要な方を支えるということだけでなく、その家族を含めた多くの方々の生活を支えています。そして、その役割は今後ますます重要になることが予測されることから、その期待に応えるべく常にケアマネジメントの確立と専門性の向上を目指していきたいと考えています。
          </p>
          <p class="mb-2">
            　当協会の特徴は、様々な職種が活動に参加しているという点であり、活動を通して、多くの刺激を受けることができます。今後も会員の皆さんのために、研修の充実はもちろん必要な情報の提供、会員同士や他職種との交流などを積極的に進めていきたいと考えています。
          </p>
          <p class="mb-2">　是非、皆さんの活動への参加をお願いいたします。</p>
        </div>

        <div
          class="w-100 my-5"
          style="
            height: 300px;
            background-image: url(/img/bg_top_catch.jpg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          "
        ></div>

        <template v-if="false">
          <h3 class="mb-3">入会のお問い合わせ</h3>
          <a class="mb-3" href="/pdf/ica-constitution.pdf" target="_blank">
            <RouterButton
              icon="pi pi-file-pdf"
              title="石川県介護支援専門員協会会則"
              reverse-color
            />
          </a>
        </template>

        <div class="w-100 mb-5" style="margin-top: 200px">
          <h4 class="w-100 text-center text-green">入会金及び会費</h4>
        </div>

        <table class="table-open-site table table-sm">
          <tbody>
            <tr>
              <th
                class="text-center align-middle"
                :style="{ width: isMobile ? '120px' : '200px' }"
              >
                会員
              </th>
              <td>
                <div>〈正会員〉</div>
                <div>
                  介護保険法（平成９年法律第１２３号）第７条第１項第５号に規定する介護支援専門員、又は第６９条の２第１項に規定する介護支援専門員の登録を受けている者のいずれかであって、本会の目的に賛同した者。
                </div>
                <div>入会金：2,000円 年会費：10,000円</div>
                <div>(内訳)</div>
                <div>
                  ・石川県介護支援専門員協会：1,000円（入会金）
                  5,000円（年会費）
                </div>
                <div>
                  ・日本介護支援専門員協会：1,000円（入会金） 5,000円（年会費）
                </div>
                <div class="mt-3">
                  正会員については、当協会の入会金及び年会費とあわせて、日本介護支援専門員協会の入会金及び会費も納めていただいております。日本介護支援専門員協会の入会金及び年会費は、当協会より日本介護支援専門員協会へ納めさせていただきます。
                </div>
                <div class="mt-5">〈特別会員〉</div>
                <div>
                  本会の目的に賛同し、介護支援専門員資格の有無に関わらず、それぞれの専門的な立場から本会の運営に参加できる学識経験者及び行政関係者等。
                </div>
                <div>入会金：1,000円 年会費：5,000円</div>
              </td>
            </tr>
            <tr v-if="false">
              <th class="text-center align-middle" style="width: 200px">
                特別会員
              </th>
              <td></td>
            </tr>
            <tr>
              <th class="text-center align-middle" style="width: 200px">
                賛助会員
              </th>
              <td>年会費：20,000円</td>
            </tr>
          </tbody>
        </table>

        <h5 v-if="false" class="mb-3">各種手続きについて</h5>
        <ul v-if="false" class="with-padding mb-5">
          <li v-for="appstyle in applicationStyles" class="mb-2">
            <div class="d-flex flex-wrap">
              <span style="width: 200px">{{ appstyle.title }}</span>
              <div class="d-flex flex-wrap">
                <a
                  class="me-2"
                  style="border-bottom: 1px solid black"
                  :href="appstyle.word"
                  target="_blank"
                  download
                >
                  <i class="pi pi-file-word" style="color: blue"></i>
                  {{ appstyle.subFileTitle }}（Word）
                </a>
                <a
                  class="me-2"
                  style="border-bottom: 1px solid black"
                  :href="appstyle.pdf"
                  target="_blank"
                  download
                >
                  <i class="pi pi-file-pdf" style="color: red"></i>
                  {{ appstyle.subFileTitle }}（PDF）
                </a>
              </div>
            </div>
          </li>
        </ul>

        <div class="d-flex justify-content-center align-items-center py-5">
          <MoreReadButton
            label="申し込みはこちら"
            color="green"
            @click="goto({ name: 'SignUp' })"
          />
        </div>

        <div v-if="false" class="w-100 mb-5" style="margin-top: 200px">
          <h4 class="w-100 text-center text-green mt-5 mb-4">お問い合わせ先</h4>
        </div>

        <div
          v-if="false"
          class="d-flex flex-column align-items-center card card-body"
          style="border-radius: 0; padding: 2rem; margin-bottom: 100px"
        >
          <h3><b>石川県介護支援専門員協会</b></h3>
          <div class="mb-3">
            <span>事務局：社会福祉法人石川県社会福祉協議会・施設振興課</span>
          </div>

          <span>〒920-8557　石川県金沢市本多町3-1-10</span>
          <div class="d-flex flex-wrap">
            <a href="tel:0762241211">TEL：076-224-1211</a>
            <div class="ms-3">
              <span>FAX：076-208-5760</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouterUtil, useMqUtils } from "/@/vue/composables";
import { RouterButton, MoreReadButton } from "/@/vue/components/Atom";

const { isMobile } = useMqUtils();
const { goto } = useRouterUtil();

const applicationStyles = computed(() => {
  return [
    {
      title: "入会申込書（別記様式１）",
      subFileTitle: "入会申込書",
      word: "/doc/style1.doc",
      pdf: "/pdf/style1.pdf",
    },
    {
      title: "退会届（別記様式３）",
      subFileTitle: "退会届",
      word: "/doc/style3.doc",
      pdf: "/pdf/style3.pdf",
    },
    {
      title: "変更届出書（別記様式４）",
      subFileTitle: "変更届出書",
      word: "/doc/style4.doc",
      pdf: "/pdf/style4.pdf",
    },
  ];
});
</script>

<style scoped></style>
