<template>
  <div
    id="header"
    style="
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      background-color: #fff;
      transition: all 0.2s;
    "
    :style="{
      'border-bottom': isScrolled ? '1px solid rgb(221, 221, 221)' : 'none',
      padding:
        user && !isMobile
          ? !isHovered && isScrolled
            ? '0 5% 0'
            : '1rem 5% 1rem'
          : isMobile
          ? '0 0 0 5%'
          : '0.5rem 5%',
    }"
    class="d-flex align-items-center"
    :class="{
      'is-scrolled': isScrolled,
      'is-mobile': isMobile,
    }"
    @mouseenter="isHovered = true"
  >
    <div class="d-flex w-100 justify-content-between align-items-center">
      <Sidebar v-if="false" v-model:visible="showHeader" position="full">
        <template #header>
          <LogoButton />
        </template>
        <PageHeaderMain
          class="h-100"
          :user="user"
          :menu-items="headerBottomItems"
          @click-menu="showHeader = false"
        />
      </Sidebar>
      <LogoButton :show-phone-number="!isScrolled" />
      <BarButton
        v-if="isMobile && false"
        @click="showHeader = true"
        button-id="menu-button"
      />
      <div
        class="d-flex flex-wrap"
        :class="{
          'justufy-content-between': isScrolled,
          'align-items-center': isScrolled,
          'justify-content-center': !isScrolled,
          'align-items-end': !isScrolled,
          'flex-column': !isScrolled,
        }"
      >
        <div
          class="d-flex align-items-center justify-content-end"
          :class="{ 'w-100': isMobile }"
        >
          <div
            v-if="!isMobile && false"
            class="d-flex align-items-center mb-1"
            style="font-size: 0.75rem"
          >
            <router-link
              :to="{ name: 'About' }"
              class="d-flex align-items-center me-2"
            >
              <i class="pi pi-caret-right me-2"></i>
              <span>石川県介護支援専門員協会とは</span>
            </router-link>
          </div>
          <router-link
            v-if="!user && isMobile"
            style="padding: 0.75rem 0.25rem"
            class="d-flex align-items-center justify-content-center"
            :to="{ name: 'SignIn' }"
          >
            <i class="pi pi-user"></i>
          </router-link>
          <Menubar
            :model="headerBottomItems"
            style="
              background: inherit;
              border: none;
              padding: 0;
              max-width: 750px;
              width: 100%;
            "
            :style="{
              hegiht: isMobile ? '48px' : 'auto',
              padding: isMobile ? '0.4rem 0.5rem' : '0',
            }"
            @mouseleave="leaveFromMenubar"
          >
            <template #item="{ item, props, hasSubmenu, root }">
              <router-link
                v-if="item.label === '会員専用ページ' && isMobile"
                :to="{ name: 'SignIn' }"
                class="w-100"
                style="padding: 0.25rem 0.75rem"
                ><HeaderButton
                  icon="pi pi-user"
                  label="会員専用ページ"
                  rounded
                  class="w-100 my-3 text-nowrap"
                  color="green"
                  with-icon-padding
              /></router-link>
              <router-link
                v-else-if="item.label === '入会案内' && isMobile"
                :to="{ name: 'Join' }"
                class="w-100"
                style="padding: 0.25rem 0.75rem"
              >
                <HeaderButton
                  icon="pi pi-sign-in"
                  label="入会案内"
                  color="orange"
                  class="w-100 text-nowrap"
                  rounded
                  with-icon-padding
                />
              </router-link>
              <router-link
                v-else-if="item.to"
                class="d-flex align-items-center h-100 w-100"
                :class="{ 'root-item': root }"
                :to="item.to"
                style="padding: 0.25rem 0.75rem"
              >
                <i
                  :class="hasSubmenu ? 'pi pi-caret-down' : 'pi pi-minus'"
                  class="me-2"
                  style="font-size: 0.8rem"
                ></i>
                <span class="me-2 text-nowrap">{{ item.label }}</span>
                <Badge
                  v-if="item.badge"
                  :class="{ 'ml-auto': !root, 'ml-2': root }"
                  :value="item.badge"
                />
              </router-link>
              <a
                v-else-if="item.href"
                class="d-flex align-items-center h-100 w-100 text-nowrap"
                :class="{ 'root-item': root }"
                :href="item.href"
                :target="item.target || '_blank'"
                style="padding: 0.25rem 0.75rem"
              >
                <i
                  :class="hasSubmenu ? 'pi pi-caret-down' : 'pi pi-minus'"
                  class="me-2"
                  style="font-size: 0.8rem"
                ></i>
                <span class="me-2 text-nowrap">{{ item.label }}</span>
                <Badge
                  v-if="item.badge"
                  :class="{ 'ml-auto': !root, 'ml-2': root }"
                  :value="item.badge"
                />
              </a>
              <div
                v-else
                class="d-flex align-items-center menu-item h-100 w-100"
                :class="{
                  'root-item': root,
                  active: item.activeName?.includes(currentRouteName),
                }"
                role="button"
                style="padding: 0.25rem 0.75rem"
                @mouseover="hasSubmenu ? hoverMenu($event) : () => {}"
              >
                <i
                  :class="hasSubmenu ? 'pi pi-caret-down' : 'pi pi-minus'"
                  class="me-2"
                  style="font-size: 0.8rem"
                ></i>
                <span class="me-2 text-nowrap label">{{ item.label }}</span>
                <Badge
                  v-if="item.badge"
                  :class="{ 'ml-auto': !root, 'ml-2': root }"
                  :value="item.badge"
                />
              </div>
            </template>
          </Menubar>
        </div>

        <div
          v-if="!user"
          class="d-flex align-items-center flex-wrap"
          style="transition: all 0.2s; position: absolute; z-index: 200"
          :style="{
            top: !isMobile
              ? isScrolled
                ? '80px'
                : '120px'
              : isScrolled
              ? '50px'
              : '80px',
            right: !isMobile
              ? isScrolled
                ? '30px'
                : '140px'
              : isScrolled
              ? '2%'
              : '9%',
          }"
        >
          <HeaderButton
            v-if="!isMobile"
            icon="pi pi-user"
            label="会員専用ページ"
            rounded
            class="me-2"
            color="green"
            :is-mobile="isMobile"
            @click="goto({ name: 'SignIn' })"
          />
          <HeaderButton
            icon="pi pi-sign-in"
            label="入会案内"
            color="orange"
            rounded
            :is-mobile="isMobile"
            @click="goto({ name: 'Join' })"
          />
        </div>
      </div>
    </div>
    <div
      v-if="pageTree && false"
      class="d-flex align-items-center justify-content-end"
    >
      <Breadcrumb
        :home="{
          title: 'Top',
          name: 'Index',
        }"
        :model="pageTree"
        class="py-2"
      >
        <template #item="{ item, props }">
          <router-link
            v-if="item.name"
            v-slot="{ href, navigate }"
            :to="{ name: item.name }"
            custom
          >
            <a :href="href" v-bind="props.action" @click="navigate">
              <i v-if="item.icon" :class="[item.icon, 'text-color']"></i>
              <span class="font-semibold">{{ item.title }}</span>
            </a>
          </router-link>
          <span v-else>{{ item.title }}</span>
        </template>
      </Breadcrumb>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import {
  useMqUtils,
  useRouterUtil,
  useScroll,
  useAuthorize,
  useUser,
} from "/@/vue/composables";
import { BarButton, HeaderButton } from "/@/vue/components/Atom";
import { PageHeaderMain, LogoButton } from "/@/vue/components/Layouts";
import { MagazineClient, OrganizationClient, UserClient } from "/@/types";
import Sidebar from "primevue/sidebar";
import Breadcrumb from "primevue/breadcrumb";
import Menubar from "primevue/menubar";
import Badge from "primevue/badge";

const props = defineProps<{
  pageTitle?: string;
  user?: UserClient;
  organizations: OrganizationClient[]; // association and branchs;
  latestMagazine?: MagazineClient;
}>();

//

const { isMobile } = useMqUtils();

// scroll

const { scrollPos } = useScroll();

const isScrolled = computed(() => {
  return scrollPos.value > 20;
});

const isHovered = ref(false);
function leaveFromMenubar(e) {
  isHovered.value = false;

  const children = Array(...e.target.children[1].children);
  const activeMenu = children.find((el) =>
    el.className.includes("p-menuitem-active")
  );

  if (activeMenu) {
    activeMenu.children[0].click();
  }
}

// tree

const { currentRouteMeta, goto, reload, currentRouteName } = useRouterUtil();

const pageTree = computed(() => {
  return currentRouteMeta.value?.tree;
});

// menu

const { logout } = useAuthorize();
const {
  isOffice,
  isAllLManager,
  isAllManager,
  isAllPoster,
  isIManager,
  isABCManager,
  isABCPoster,
  isUserManager,
  isOrganizationManager,
  isVManager,
  isBcpManager,
  isRuleManager,
} = useUser();

const showHeader = ref(false);

function hoverMenu(e: MouseEvent) {
  if (e.target?.className?.includes("label")) {
    return;
  }

  if (
    e.target?.parentElement?.parentElement?.attributes["aria-expanded"]
      ?.value == "false"
  ) {
    e.target.parentElement.click();
  }
}

const headerBottomItems = computed(() => {
  return [
    {
      to: {
        name: "Index",
      },
      icon: "pi pi-minus",
      label: "ホーム",
      visible: false,
      activeName: ["Index"],
    },
    {
      to: {
        name: "NewsIndex",
      },
      label: "活動のお知らせ",
      icon: "pi pi-caret-down",
      items: props.organizations.map((org) => ({
        to: {
          name: "NewsIndex",
          query: { organizationId: org.id },
        },
        label: org.name,
        icon: "pi pi-minus",
      })),
      activeName: ["NewsIndex"],
    },
    {
      icon: "pi pi-caret-down",
      label: "ボランティア申請",
      visible: false,
      items: [
        {
          to: {
            name: "Invest24Index",
            query: { recruitmentType: "invest24" },
          },
          icon: "pi pi-minus",
          label: "実態把握調査",
        },
        {
          to: {
            name: "Shelter24Index",
            query: { recruitmentType: "shelter24" },
          },
          icon: "pi pi-minus",
          label: "1.5次避難所",
        },
      ],
      activeName: ["Invest24Index", "Shelter24Index"],
    },
    {
      icon: "pi pi-minus",
      label: "災害対策",
      href: "https://portal.i-cma.jp",
      target: "_self",
      visible: true,
    },
    {
      to: {
        name: "MagazinesIndex",
      },
      icon: "pi pi-caret-down",
      label: "広報誌『わ』",
      items: [
        {
          href: props.latestMagazine?.attachedPdfs[0].url,
          visible: !!props.latestMagazine,
          icon: "pi pi-minus",
          label: "最新号",
        },
        {
          to: { name: "MagazinesIndex" },
          label: "バックナンバー",
          icon: "pi pi-minus",
        },
      ],
      activeName: ["MagazinesIndex"],
    },
    {
      to: { name: "Inquiry" },
      icon: "pi pi-minus",
      label: "お問い合わせ",
      activeName: ["Inquiry"],
    },
    {
      to: { name: "SignIn" },
      icon: "pi pi-minus",
      label: "会員専用ページ",
      visible: !props.user && isMobile.value,
      activeName: ["SignIn"],
    },
    {
      to: { name: "Join" },
      icon: "pi pi-minus",
      label: "入会案内",
      visible: !props.user && isMobile.value,
      activeName: ["Join"],
    },
    {
      icon: "pi pi-caret-down",
      label: "会員メニュー",
      visible: !!props.user && (!isScrolled.value || isHovered.value),
      items: [
        {
          to: {
            name: "UsersNewsUserNewsIndex",
          },
          icon: "pi pi-minus",
          label: "新着情報",
        },
        {
          label: "研修記録",
          icon: "pi pi-minus",
          visible: false,
          command: () => {},
        },
        {
          label: "研修動画",
          icon: "pi pi-minus",
          visible: false,
          command: () => {},
        },
        {
          to: {
            name: "UsersBcpIndex",
          },
          label: "ＢＣＰ",
          icon: "pi pi-minus",
        },
        {
          to: {
            name: "UsersRulesIndex",
          },
          label: "協会書類等",
          icon: "pi pi-minus",
          command: () => {},
        },
        {
          to: {
            name: "UsersShow",
          },
          label: "会員情報",
          icon: "pi pi-minus",
        },
        {
          label: "ログアウト",
          icon: "pi pi-minus",
          command: async () => {
            await logout();
            reload();
          },
        },
      ],
      activeName: [
        "UsersNewsUserNewsIndex",
        "UsersBcpIndex",
        "UsersRulesIndex",
        "UsersShow",
      ],
    },
    {
      icon: "pi pi-caret-down",
      label: "ボランティア",
      visible: false && !!props.user && (!isScrolled.value || isHovered.value),
      items: [
        {
          to: {
            name: "UsersJoinUsersIndex",
          },
          icon: "pi pi-minus",
          label: "申請済ボランティア",
        },
        /*
      {
        to: {
          name: "Invest24Index",
          query: { recruitmentType: "invest24" },
        },
        icon: "pi pi-minus",
        label: "ボランティアの申請",
      },
      */
      ],
      activeName: ["UsersJoinUsersIndex"],
    },
    {
      label: "管理者メニュー",
      icon: "pi pi-caret-down",
      visible:
        (isOffice.value ||
          isAllManager.value ||
          isAllPoster.value ||
          isUserManager.value ||
          isAllLManager.value ||
          isOrganizationManager.value ||
          isABCManager.value ||
          isABCPoster.value ||
          isVManager.value ||
          isBcpManager.value ||
          isRuleManager.value) &&
        (!isScrolled.value || isHovered.value),
      items: [
        {
          to: {
            name: "UsersRecruitmentsManagementsIndex",
          },
          label: "ボランティア管理",
          icon: "pi pi-caret-down",
          visible: isVManager.value,
          items: [
            {
              to: {
                name: "UsersRecruitmentsManagementsManagement",
                query: { recruitmentType: "invest24" },
              },
              label: "奥能登実態調査管理",
              icon: "pi pi-minus",
              visible: isVManager.value,
            },
            {
              to: {
                name: "UsersRecruitmentsManagementsManagement",
                query: { recruitmentType: "shelter24" },
              },
              label: "1.5次避難所管理",
              icon: "pi pi-minus",
              visible: isVManager.value,
            },
            {
              to: {
                name: "UsersInvest24DataIndex",
              },
              label: "ボランティア会員管理",
              icon: "pi pi-minus",
              visible: isVManager.value,
            },
          ],
        },
        {
          label: "投稿管理",
          icon: "pi pi-caret-down",
          visible: isAllPoster.value || isABCPoster.value,
          items: [
            {
              to: {
                name: "UsersNewsIndex",
              },
              label: "投稿一覧",
              icon: "pi pi-minus",
              visible: !!isAllPoster.value || isABCPoster.value,
            },
          ],
        },
        {
          label: "組織・管理管理",
          icon: "pi pi-caret-down",
          visible:
            isOffice.value ||
            isAllPoster.value ||
            isABCManager.value ||
            isOrganizationManager.value ||
            isUserManager.value ||
            isAllLManager.value,
          items: [
            {
              to: {
                name: "UsersOrganizationsIndex",
              },
              label: "組織管理",
              icon: "pi pi-minus",
              visible: !!isABCManager.value || !!isOrganizationManager.value,
            },
            {
              to: {
                name: "UsersSystemAdminUsers",
              },
              label: "会員管理",
              icon: "pi pi-minus",
              visible:
                !!isOffice.value ||
                !!isABCManager.value ||
                !!isUserManager.value ||
                !!isAllLManager.value,
            },
          ],
        },
        {
          label: "各種管理",
          icon: "pi pi-caret-down",
          visible:
            isIManager.value || isBcpManager.value || isRuleManager.value,
          items: [
            {
              to: {
                name: "UsersMagazinesIndex",
              },
              label: "広報誌『わ』管理",
              icon: "pi pi-minus",
              visible: !!isRuleManager.value,
            },
            {
              to: {
                name: "UsersSponsorsIndex",
              },
              label: "賛助会員管理",
              icon: "pi pi-minus",
              visible: !!isRuleManager.value,
            },
            {
              to: {
                name: "UsersSystemAdminBcpIndex",
              },
              label: "ＢＣＰ管理",
              icon: "pi pi-minus",
              visible: !!isBcpManager.value,
            },
            {
              to: {
                name: "UsersSystemAdminRulesIndex",
              },
              label: "協会書類等管理",
              icon: "pi pi-minus",
              visible: !!isRuleManager.value,
            },
            {
              to: {
                name: "UsersInquiriesIndex",
              },
              label: "お問い合わせ管理",
              icon: "pi pi-minus",
              visible: !!isIManager.value,
            },
          ],
        },
        {
          label: "研修管理",
          icon: "pi pi-caret-down",
          visible: !!isAllManager.value && false,
          items: [
            {
              label: "研修投稿一覧",
              icon: "pi pi-minus",
              visible: !!isAllManager.value,
              command: () => {},
            },
            {
              label: "研修投稿",
              icon: "pi pi-minus",
              visible: !!isAllManager.value,
              command: () => {},
            },
            {
              label: "研修記録確認",
              icon: "pi pi-minus",
              visible: !!isAllManager.value,
              command: () => {},
            },
          ],
        },
      ],
      activeName: [
        "UsersRecruitmentsManagementsManagement",
        "UsersInvest24DataIndex",
        "UsersNewsIndex",
        "UsersOrganizationsIndex",
        "UsersSystemAdminUsers",
        "UsersMagazinesIndex",
        "UsersSponsorsIndex",
        "UsersSystemAdminBcpIndex",
        "UsersSystemAdminRulesIndex",
        "UsersInquiriesIndex",
      ],
    },
  ];
});

function gotoSignUp() {
  showHeader.value = false;
  goto({ name: "SignIn" });
}

function gotoJoin() {
  goto({ name: "Join" });
  showHeader.value = false;
}
</script>

<style lang="scss">
#header {
  button.p-button {
    border: none;
  }

  .p-menuitem {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      height: 1px;
      width: 0px;

      transition: width 0.4s;
      background-color: #fda237;
    }

    &:hover::after {
      width: 100%;
    }

    &:has(.active) {
      &::after {
        width: 100%;
      }
    }

    &:hover {
      .root-item {
        color: #fda237;
      }
    }

    .root-item.active {
      color: #fda237;
    }
  }

  .p-submenu-list {
    text-wrap: nowrap;
    padding: 0;
    display: block !important;
    opacity: 0;
    transition: opacity 0.5s;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    border: none;

    .p-menuitem {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 45px;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0.30625rem 0.39375rem 0 0 rgba(46, 79, 82, 0.1);
      position: relative;
      border: 1px solid #e1dbc7;
      width: 215px;

      &::after {
        display: none;
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &:hover {
        background-color: #fda237;
        color: white;

        .p-menuitem-content {
          color: white;
        }

        .p-submenu-list > .p-menuitem {
          a {
            color: #334155;
          }

          &:hover {
            a {
              color: white;
            }
          }
        }
      }

      .p-menuitem-content {
        width: 100%;
        height: 100%;
        min-height: 45px;

        > * {
          height: 100%;
          min-height: 45px;
        }
      }
    }
  }

  .p-menuitem[aria-expanded="true"] > .p-submenu-list {
    opacity: 1;
    visibility: visible;
    z-index: 1100;
  }

  .p-menubar .p-menuitem {
    margin: 0;
  }

  .p-menubar-root-list {
    justify-content: end;
    min-width: 320px;
    left: -290px;
  }

  .p-menubar .p-submenu-list > .p-menuitem-active > .p-submenu-list {
    left: 15%;
    top: 100%;
  }

  .p-menubar
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    background: inherit;
  }

  .p-menubar .p-menuitem > .p-menuitem-content {
    color: inherit;
  }

  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
    background: inherit;
  }

  ul[aria-labelledby="main-menu_7_label"] {
    left: -70%;
  }

  &.is-mobile {
    .p-menubar-mobile {
      transition: all 0.5s;
      z-index: 1001;
      overflow: scroll;
      height: 100%;

      .p-menubar-root-list {
        transition: all 0.5s;
        padding: 0;
        font-size: 0.75rem;
        background-color: #f7f7f7;
        display: block;
        position: fixed;
        width: 0;
        height: 0;
        top: 47px;
        right: 0;
        left: 100vw;
        align-items: center;
        justify-content: start;
        overflow-y: scroll;
        border: none;

        // visibility: hidden;

        .p-menuitem {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 50px;
          border: none;

          &::after {
            width: 0;
          }

          &:not(:last-child):not([aria-label="会員専用ページ"]) {
            border-bottom: 2px dashed #e1dbc7;
          }

          .p-menuitem-content {
            width: 100%;
            height: 100%;
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .p-menuitem[aria-expanded="true"] > .p-submenu-list {
          display: block !important;
        }

        .p-submenu-list {
          display: none !important;
          margin-top: 0;
          padding: 0.5rem;

          .p-menuitem {
            // background-color: inherit;
            box-shadow: none;
          }
        }
      }

      &.p-menubar-mobile-active {
        .p-menubar-root-list {
          //visibility: visible;
          padding: 2rem;
          width: 100vw;
          height: calc(100vh - 47px);
          border: 1px solid #e2e8f0;
          left: 0;
        }
      }
    }
  }
}

.p-sidebar .p-sidebar-header {
  padding: 1rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
  color: $main-brawn;
}
</style>
