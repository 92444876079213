<template>
  <div>
    <ShowFormItemWrapper v-if="!withoutTerm" title="期間">
      <span>{{ basicFormatter(recruitment.startOn) }}</span
      >~<span>{{ basicFormatter(recruitment.finishOn) }}</span>
    </ShowFormItemWrapper>
    <ShowFormItemWrapper v-if="isInvest24" title="エリア">
      <span>{{ recruitment.area }}</span>
    </ShowFormItemWrapper>
    <template v-if="targetDateInfo">
      <ShowFormItemWrapper v-if="isVManager" title="現在人数">
        <span>{{ targetDateInfo.currentInvest24JoinUsersCount }}</span>
      </ShowFormItemWrapper>
      <ShowFormItemWrapper v-else title="募集空き人数">
        <span>{{
          targetDateInfo.needCount -
            targetDateInfo.currentInvest24JoinUsersCount ===
          0
            ? "満員"
            : targetDateInfo.needCount -
              targetDateInfo.currentInvest24JoinUsersCount
        }}</span>
      </ShowFormItemWrapper>
      <ShowFormItemWrapper v-if="targetDateInfo.workPlace" title="場所">
        <span>{{ targetDateInfo.workPlace }} </span>
      </ShowFormItemWrapper>
      <ShowFormItemWrapper title="作業時間">
        <span
          >{{ basicFormatter(targetDateInfo.meetingAt, "onlyTime") }}集合{{
            targetDateInfo.finishAt
              ? `〜${basicFormatter(targetDateInfo.finishAt, "onlyTime")}`
              : ""
          }}</span
        >
      </ShowFormItemWrapper>
      <ShowFormItemWrapper v-if="targetDateInfo.meetingPlace" title="集合場所">
        <span>{{ targetDateInfo.meetingPlace }} </span>
        <Image
          v-if="
            targetDateInfo.meetingPlace ===
            'いしかわ総合スポーツセンター サブアリーナの倉庫'
          "
          src="/img/ishikawa_sports_center_meeting_place.png"
          preview
          width="400"
          height="300"
        />
      </ShowFormItemWrapper>
      <ShowFormItemWrapper
        v-if="targetDateInfo.belongings || targetDateInfo.details"
        title="持ち物"
      >
        <span>{{ targetDateInfo.belongings || targetDateInfo.details }} </span>
      </ShowFormItemWrapper>
      <ShowFormItemWrapper v-if="targetDateInfo.rewards" title="報酬">
        <span>{{ targetDateInfo.rewards }}</span>
      </ShowFormItemWrapper>
      <ShowFormItemWrapper v-if="false" title="備考">
        <span>{{ targetDateInfo.details || "なし" }}</span>
      </ShowFormItemWrapper>

      <ShowFormItemWrapper v-if="isVManager" title="参加予定者" without-divider>
        <ul class="list-group w-100">
          <li
            v-for="user in targetDateInfo.invest24JoinUserInfos"
            :key="user.id"
            class="list-group-item"
          >
            <div
              class="d-flex justify-content-between align-items-center flex-column"
              role="button"
              @click="openToggleMap[user.id] = !openToggleMap[user.id]"
            >
              <div
                class="d-flex justify-content-between align-items-center w-100"
              >
                <span>{{ user.lastName }}{{ user.firstName }}</span>
                <div class="d-flex align-items-center">
                  <i
                    v-if="!openToggleMap[user.id]"
                    class="pi pi-angle-down me-2"
                  ></i>
                  <i v-else class="pi pi-angle-up me-2"></i>
                  <BasicButton
                    v-if="showRemover"
                    icon="pi pi-trash"
                    variant="danger"
                    @click="$emit('detachUser', user.id)"
                  />
                </div>
              </div>
              <div
                v-if="openToggleMap[user.id]"
                class="d-flex flex-column w-100 p-2"
                style="font-size: 0.9rem"
              >
                <div class="d-flex">
                  <div style="width: 4.5rem">メール</div>
                  <span>{{ user.email }}</span>
                </div>
                <div class="d-flex">
                  <div style="width: 4.5rem">電話番号</div>
                  <span>{{ user.phoneNumber }}</span>
                </div>
                <div class="d-flex">
                  <div style="width: 4.5rem">生年月日</div>
                  <span>{{
                    basicFormatter(user.birthday, "withoutWday")
                  }}</span>
                </div>
                <div class="d-flex">
                  <div style="width: 4.5rem">業務種別</div>
                  <span>{{ user.workType }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </ShowFormItemWrapper>
    </template>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from "vue";
import { basicFormatter, isAfterToday } from "/@/modules/luxon";
import { BasicButton } from "/@/vue/components/Atom";
import { ShowFormItemWrapper } from "/@/vue/components/Molecules/Form";
import {
  Invest24RecruitmentClient,
  Invest24UserAttachedInDayInfo,
} from "/@/types";
import Image from "primevue/image";

const props = defineProps<{
  recruitment: Invest24RecruitmentClient;
  targetDate: string;
  isVManager?: boolean;
  withoutTerm?: boolean;
  invest24Users: Invest24UserAttachedInDayInfo[];
}>();

defineEmits<{
  (e: "detachUser", id: number): void;
}>();

const targetDateInfo = computed(() => {
  return props.recruitment.invest24RecruitmentDayInfos.find(
    (info) => info.targetDate === props.targetDate
  );
});

const isInvest24 = computed(() => {
  return props.recruitment.recruitmentType === "invest24";
});

const isShelter24 = computed(() => {
  return props.recruitment.recruitmentType === "shelter24";
});

const showRemover = computed(() => {
  return (
    props.isVManager &&
    targetDateInfo.value &&
    targetDateInfo.value.invest24JoinUserInfos &&
    targetDateInfo.value.invest24JoinUserInfos.length > 0 &&
    props.targetDate &&
    isAfterToday(props.targetDate)
  );
});

const openToggleMap = reactive<Record<number, boolean>>({});
</script>

<style scoped></style>
