<template>
  <div>
    <DataTable
      striped-rows
      paginator
      :rows="selectedPerPage"
      :value="inquiries"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      @row-click="$emit('selectInquiry', $event.data.id)"
    >
      <template #header>
        <TableHeader
          v-model="selectedPerPage"
          :target-length="inquiries.length"
          :current-page="currentPage"
        />
      </template>
      <Column sortable field="title" header="お名前" style="width: 200px">
        <template #body="{ data }">
          <span>{{ data.name }}</span>
        </template>
      </Column>
      <Column
        sortable
        field="createdAt"
        header="問い合わせ日時"
        :header-style="
          isMobile
            ? { width: '170px', 'min-width': '170px' }
            : { width: '170px', 'min-width': '170px' }
        "
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.createdAt, "slashStyle") }}</span>
        </template>
      </Column>
      <Column field="contents" header="内容" 1 />
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils, useTableHeader } from "/@/vue/composables";
import { Header as TableHeader } from "/@/vue/components/Molecules/TableUtils";
import { InquiryClient } from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

const props = defineProps<{
  inquiries: InquiryClient[];
}>();

const emit = defineEmits<{
  (e: "selectInquiry", id: number): void;
}>();

const { isMobile } = useMqUtils();
const { selectedPerPage, currentPage, changePage } = useTableHeader();
</script>

<style lang="scss">
// in Organizations/Tabel.vue
</style>
