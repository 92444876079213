<template>
  <div id="invest24-root">
    <h3 v-if="false" id="page-header-title" class="mb-3">{{ pageTitle }}</h3>
    <router-view />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouterUtil } from "/@/vue/composables";

const { currentRouteMeta } = useRouterUtil();
const pageTitle = computed(() => {
  return currentRouteMeta.value?.title;
});
</script>

<style lang="scss" scoped></style>
