import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { PdfInfoScheme } from "/@/types/pdf.d";

// お知らせ, 公開講座, 研修会, 緊急情報
export const newsLabels = [
  "announce", // お知らせ
  // "member_announce", // 会員向けお知らせ
  "open_lecture", // 公開講座
  "training", // 研修会
] as const;
export const NewsLabelScheme = z.enum(newsLabels);
export type NewsLabel = zod.infer<typeof NewsLabelScheme>;

export const NewsScheme = z.object({
  id: z.number().optional(),
  forMembers: z.boolean(),
  organizationId: z.number().optional(),
  label: NewsLabelScheme,
  title: z.string().trim().min(0).max(50),
  publishedAt: z.string().optional(),
  heldAt: z.string().optional(),
  place: z.string().trim().min(0).max(256).optional().or(z.literal("")),
  content: z.string().trim().min(1).max(16777215),
  lecturer: z.string().trim().min(1).max(256).optional().or(z.literal("")),
  cost: z.string().trim().min(0).max(256).optional().or(z.literal("")),
  applicationMethod: z
    .string()
    .trim()
    .min(0)
    .max(256)
    .optional()
    .or(z.literal("")),
  applicationFormLink: z
    .string()
    .trim()
    .min(0)
    .max(256)
    .optional()
    .or(z.literal("")),
  showTop: z.boolean(),
  pinnedTo: z.string().optional(),
  newAttachedPdfs: z.string().array().max(4),
});

export type News = zod.infer<typeof NewsScheme>;

export const NewsCheckScheme = NewsScheme;

export const NewsFormScheme = NewsScheme.partial({
  id: true,
  title: true,
  publishedAt: true,
  heldAt: true,
  place: true,
  content: true,
  lecturer: true,
  cost: true,
  applicationMethod: true,
  applicationFormLink: true,
});
export type NewsForm = zod.infer<typeof NewsFormScheme>;

export const NewsClientScheme = NewsScheme.extend({
  id: z.number(),
  organizationName: z.string(),
  organizationColor: z.string(),
  publishedAt: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  attachedPdfs: PdfInfoScheme.array(),
  trashed: z.boolean(),
  unread: z.boolean(),
  nowPinned: z.boolean(),
});
export type NewsClient = zod.infer<typeof NewsClientScheme>;
