<template>
  <div>
    <div class="input-group">
      <div v-if="$slots.frontSlot" class="input-group-text">
        <slot name="frontSlot"></slot>
      </div>
      <slot name="frontButtonSlot"></slot>
      <input
        :id="formId"
        :name="formName"
        :value="value"
        :class="formClass"
        :type="formType"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="disabled"
        @input="update"
        @compositionstart="compositionStart"
        @compositionend="compositionEnd"
      />
      <div v-if="$slots.backSlot" class="input-group-text">
        <slot name="backSlot"></slot>
      </div>
      <slot name="backButtonSlot"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

interface Props {
  value?: string;
  isValid?: boolean;
  formId?: string;
  placeholder?: string;
  autocomplete?: string;
  formType?: string;
  formName?: string;
  icon?: string;
  disabled?: boolean;
  readonly?: boolean;
  step?: number;
  textEnd?: boolean;
  withoutBorder?: boolean;
  whenChange?: boolean;
  max?: number;
  min?: number;
  pattern?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:value", value: string | null): void;
}>();

const formClass = computed(() => {
  return {
    "form-control": true,
    "is-invalid": !props.isValid,
  };
});

const compositioning = ref(false);

function update(e: { target: HTMLInputElement }) {
  if (compositioning.value) return;

  if (e.target instanceof HTMLInputElement) {
    emit("update:value", e.target.value);
  } else {
    throw new Error("予期せぬエラーが発生しました");
  }
}

function compositionStart() {
  compositioning.value = true;
}

function compositionEnd(e: { target: HTMLInputElement }) {
  compositioning.value = false;
  emit("update:value", e.target.value);
}
</script>

<style lang="scss" scoped></style>
