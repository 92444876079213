import { useRouter } from "vue-router";
import { useStore } from "/@/vue/store";
import { User, SingUpResponse, Login } from "/@/types";

export function useAuthorize() {
  const store = useStore();
  const router = useRouter();

  async function login(user: Login) {
    return await store.dispatch("user/LOGIN", user);
  }

  async function logout() {
    await store.dispatch("user/LOGOUT");
    router.push({ name: "Index" });
  }

  async function signup(
    user: User,
    { substitute, fromQr }: { substitute?: boolean; fromQr?: boolean } = {}
  ): Promise<SingUpResponse> {
    if (substitute) {
      return await store.dispatch("user/SIGN_UP_SUBSTITUTE", user);
    } else if (fromQr) {
      return await store.dispatch("user/SIGN_UP_QR", user);
    } else {
      return await store.dispatch("user/SIGN_UP", user);
    }
  }

  return {
    login,
    logout,
    signup,
  };
}
