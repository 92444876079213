<template>
  <div>
    <ConfirmDialog :style="{ width: '90vw', 'max-width': '35rem' }">
      <template #message="slotProps">
        <div class="d-flex flex-row align-items-center">
          <i
            :class="slotProps.message.icon"
            style="font-size: 1.5rem"
            class="me-4"
          ></i>
          <div class="d-flex flex-column flex-grow">
            <p v-for="m in slotProps.message.message" :key="m">
              {{ m }}
            </p>
          </div>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import { applyMailAlert, cancelMailAlert } from "/@/modules/alert";

const props = defineProps<{
  mailType: "submit" | "cancel";
}>();
const confirm = useConfirm();

function open(acceptCallback: () => void) {
  switch (props.mailType) {
    case "submit":
      confirm.require({
        ...applyMailAlert,
        accept: acceptCallback,
        onHide: acceptCallback,
      });
      return;
    case "cancel":
      confirm.require({
        ...cancelMailAlert,
        accept: acceptCallback,
        onHide: acceptCallback,
      });
      return;
  }
}

defineExpose({
  open,
});
</script>

<style scoped></style>
