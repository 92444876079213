import { ComputedRef, Ref } from "vue";
import useSWRV from "swrv";
import { errorHandle } from "/@/modules/error";
import { axios, deepUndefineKeyToNull } from "/@/modules/axios";
import { fetcher } from "/@/modules/axios";
import { OrganizationClient, Organization } from "/@/types";

export function useOrganizations() {
  function getOrganizationKey(
    id?: Ref<number> | ComputedRef<number>,
    options: { admin?: boolean } = {}
  ) {
    const { admin } = options;

    if (!id || !id.value) {
      return null;
    }

    let uri = `/api/v1/organizations/${id.value}?`;

    if (admin) {
      uri += "admin=true&";
    }

    return uri;
  }

  function getOrganization(
    id?: Ref<number> | ComputedRef<number>,
    options: { admin?: boolean } = {}
  ) {
    return useSWRV<OrganizationClient>(
      () => getOrganizationKey(id, options),
      fetcher
    );
  }

  async function updateOrganization(id: number, organization: Organization) {
    try {
      await axios.put(`/api/v1/organizations/${id}`, {
        organizations: deepUndefineKeyToNull(organization),
      });
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  function getOrganizations(
    options: {
      branch?: boolean;
      withLatestNews?: boolean;
      association?: boolean;
    } = {}
  ) {
    const { branch, withLatestNews, association } = options;

    let uri = "/api/v1/organizations?";

    if (association) {
      uri += "association=true&";
    }

    if (branch) {
      uri += "branch=true&";
    }

    if (withLatestNews) {
      uri += "with_latest_news=true&";
    }

    return useSWRV<OrganizationClient[]>(uri, fetcher);
  }

  return {
    getOrganization,
    getOrganizations,
    updateOrganization,
  };
}
