<template>
  <div>
    <div class="mb-4">
      <FormLabel
        label="公開範囲 ※会員向けを選択した場合はログインしているユーザーのみ閲覧可能です"
      />
      <SelectButton
        :model-value="forMembers"
        :options="[
          { label: '一般公開', value: false },
          { label: '会員向け', value: true },
        ]"
        option-label="label"
        option-value="value"
        :allow-empty="false"
        @update:modelValue="$emit('update:forMembers', $event)"
      />
    </div>

    <div class="mb-4">
      <FormLabel label="組織" />
      <Dropdown
        :model-value="organizationId"
        :options="organizations"
        option-label="name"
        option-value="id"
        @update:model-value="$emit('update:organizationId', $event)"
      />
    </div>

    <div class="mb-4">
      <FormLabel label="タイトル（必須） ※50文字以内" />
      <FormItem
        :value="title"
        :errors="errors"
        form-id="title"
        form-type="text"
        @update:value="$emit('update:title', $event as string | undefined)"
      />
    </div>

    <div class="mb-4">
      <FormLabel label="記事種別選択" />
      <Dropdown
        :model-value="label"
        :options="newsLabels"
        :option-label="labelTranslater"
        class="w-100"
        @update:model-value="updateLabel"
      />
    </div>

    <div v-if="label !== 'announce'" class="mb-4">
      <FormLabel label="開催日" />
      <div class="d-flex">
        <FormItem
          :value="heldAt"
          :is-valid="!heldAt"
          :errors="errors"
          form-id="heldAt"
          form-type="date"
          class="me-2"
          style="width: 210px; min-width: 210px"
          @update:value="updateHeldAt"
        />
        <FormItemTime
          :value="heldAt"
          :env="heldAtEnv"
          :errors="errors"
          form-id="heldAt"
          :minutes-increment="1"
          :style="
            isMobile
              ? {
                  width: '100%',
                }
              : {
                  width: '120px',
                }
          "
          @update:value="$emit('update:heldAt', $event as string | undefined)"
        />
      </div>
    </div>

    <div v-if="label !== 'announce'" class="mb-4">
      <FormLabel
        :label="`開催場所 ※256文字以内${
          place?.length ? ` 現在：${place.length}文字` : ''
        }`"
      />
      <FormItem
        :value="place"
        :errors="errors"
        form-id="place"
        form-type="textarea"
        @update:value="$emit('update:place', $event as string | undefined)"
      />
    </div>

    <div v-if="label !== 'announce'" class="mb-4">
      <FormLabel
        :label="`講師情報 ※256文字以内${
          lecturer?.length ? ` 現在：${lecturer.length}文字` : ''
        }`"
      />
      <FormItem
        :value="lecturer"
        :errors="errors"
        form-id="lecturer"
        form-type="textarea"
        @update:value="$emit('update:lecturer', $event as string | undefined)"
      />
    </div>

    <div v-if="label !== 'announce'" class="mb-4">
      <FormLabel
        :label="`費用 ※256文字以内${
          cost?.length ? ` 現在：${cost.length}文字` : ''
        }`"
      />
      <FormItem
        :value="cost"
        :errors="errors"
        form-id="cost"
        form-type="textarea"
        @update:value="$emit('update:cost', $event as string | undefined)"
      />
    </div>

    <div v-if="label !== 'announce'" class="mb-4">
      <FormLabel
        :label="`申込方法 ※256文字以内${
          applicationMethod?.length
            ? ` 現在：${applicationMethod.length}文字`
            : ''
        }`"
      />
      <FormItem
        :value="applicationMethod"
        :errors="errors"
        form-id="applicationMethod"
        form-type="textarea"
        @update:value="
          $emit('update:applicationMethod', $event as string | undefined)
        "
      />
    </div>

    <div v-if="label !== 'announce'" class="mb-4">
      <FormLabel label="申し込み Google Form URL ※256文字以内" />
      <FormItem
        :value="applicationFormLink"
        :errors="errors"
        form-id="applicationFormLink"
        form-type="text"
        @update:value="
          $emit('update:applicationFormLink', $event as string | undefined)
        "
      />
    </div>

    <div class="mb-4">
      <FormLabel :label="`本文（必須） ※1MB以内`" />
      <FormItemEditor
        form-id="content"
        :errors="errors"
        :model-value="content"
        @update:model-value="
          $emit('update:content', $event as string | undefined)
        "
      />
    </div>

    <div v-if="formAttachedPdfs.length" class="mb-4">
      <FormLabel label="添付書類" />
      <div v-for="(pdf, idx) in formAttachedPdfs" :key="pdf.signedId">
        <div class="d-flex flex-wrap align-items-center">
          <a
            :href="pdf.url"
            target="_blank"
            class="me-4 d-flex align-items-center"
          >
            <span class="me-2">添付書類{{ idx + 1 }}</span>
            <i class="pi pi-external-link"></i>
          </a>

          <BasicButton
            icon="pi pi-trash"
            need-icon
            variant="danger"
            text
            @click="removeAttachedPdf(pdf)"
          />
        </div>
      </div>
    </div>

    <PdfForm
      v-if="formAttachedPdfs.length < 4"
      :model-value="newAttachedPdfs"
      :file-limit="4 - formAttachedPdfs.length"
      :current-count="formAttachedPdfs.length"
      class="mb-3"
      :is-invalid="!!errors?.newAttachedPdfs"
      @update:model-value="
        $emit('update:newAttachedPdfs', [
          ...newAttachedPdfs.filter((p) => !p.startsWith('data')),
          ...$event,
        ])
      "
    />

    <div class="mb-4">
      <FormLabel label="公開日時 ※未設定の場合は即座に公開されます" />
      <div class="d-flex">
        <FormItem
          :value="publishedAt"
          :errors="errors"
          form-id="publishedAt"
          form-type="date"
          class="me-2"
          style="width: 210px; min-width: 210px"
          @update:value="
            $emit('update:publishedAt', $event as string | undefined)
          "
        />
        <FormItemTime
          :value="publishedAt"
          :env="publishedAtEnv"
          :errors="errors"
          form-id="publishedAt"
          label=""
          :minutes-increment="1"
          :style="
            isMobile
              ? {
                  width: '100%',
                }
              : {
                  width: '120px',
                }
          "
          @update:value="
            $emit('update:publishedAt', $event as string | undefined)
          "
        />
      </div>
    </div>

    <div class="mb-3">
      <div class="d-flex align-items-center">
        <Checkbox
          v-model="pinned"
          input-id="pinned"
          name="pinned"
          binary
          class="me-2"
          @update:model-value="
            $event ? () => {} : $emit('update:pinnedTo', undefined)
          "
        />
        <FormLabel
          label="ホームの新着にピン止めする ※チェック後下に表示されるフォームからピン止め終了の日にちを選択して下さい。選択しない場合はピン止めされません"
          item-id="pinned"
          without-padding
        />
      </div>
    </div>

    <div v-if="pinned" class="mb-4">
      <div class="d-flex">
        <FormItem
          :value="pinnedTo"
          :errors="errors"
          form-id="pinnedTo"
          form-type="date"
          class="me-2"
          style="width: 210px; min-width: 210px"
          @update:value="$emit('update:pinnedTo', $event as string | undefined)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, watch } from "vue";
import { fromISO, luxonNow } from "/@/modules/luxon";
import { labelTranslater } from "/@/modules/news";
import { useMqUtils } from "/@/vue/composables";
import { Label as FormLabel, BasicButton } from "/@/vue/components/Atom";
import {
  FormItem,
  FormItemEditor,
  FormItemTime,
  PdfForm,
} from "/@/vue/components/Molecules/Form";
import { ZodFormattedErrors, OrganizationClient, PdfInfo } from "/@/types";
//import Editor from "primevue/editor";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import SelectButton from "primevue/selectbutton";
import { NewsLabel, newsLabels } from "/@/types/news.d";

const props = defineProps<{
  forMembers?: boolean;
  organizationId?: number;
  label: NewsLabel;
  title?: string;
  content?: string;
  heldAt?: string;
  place?: string;
  lecturer?: string;
  cost?: string;
  applicationMethod?: string;
  applicationFormLink?: string;
  publishedAt?: string;
  showTop?: boolean;
  pinnedTo?: string;
  newAttachedPdfs: string[];
  attachedPdfs: PdfInfo[];
  organizations: OrganizationClient[];
  errors: ZodFormattedErrors;
}>();

const emit = defineEmits<{
  (e: "update:forMembers", value?: boolean): void;
  (e: "update:organizationId", value?: number): void;
  (e: "update:label", value?: NewsLabel): void;
  (e: "update:title", value?: string): void;
  (e: "update:content", value?: string): void;
  (e: "update:heldAt", value?: string): void;
  (e: "update:place", value?: string): void;
  (e: "update:lecturer", value?: string): void;
  (e: "update:cost", value?: string): void;
  // (e: "update:areas", value?: string[]): void;
  (e: "update:applicationMethod", value?: string): void;
  (e: "update:applicationFormLink", value?: string): void;
  (e: "update:publishedAt", value?: string): void;
  (e: "update:showTop", value?: boolean): void;
  (e: "update:pinnedTo", value?: string): void;
  (e: "update:newAttachedPdfs", value?: string[]): void;
}>();

const { isMobile } = useMqUtils();

function updateLabel(e: NewsLabel) {
  emit("update:label", e);
}

/*
const addedNewsAreas = computed(() => {
  return newsAreas.concat("(指定なし)");
});
*/

function updateHeldAt(e: string) {
  emit("update:heldAt", e as string | undefined);
}

const heldAtEnv = computed(() => {
  if (!props.heldAt) return;

  return fromISO(props.heldAt)?.toObject();
});

const publishedAtEnv = computed(() => {
  if (!props.publishedAt) return;

  return fromISO(props.publishedAt)?.toObject();
});

const now = computed(() => {
  return luxonNow().startOf("day");
});

function removeAttachedPdf(pdf: { url: string; signedId: string }) {
  const rest = props.newAttachedPdfs.filter((p) => p !== pdf.signedId);
  emit("update:newAttachedPdfs", rest);
}

const formAttachedPdfs = computed(() => {
  if (!props.newAttachedPdfs) return [];

  return props.attachedPdfs.filter((pdf) => {
    return props.newAttachedPdfs.some((signedId) => pdf.signedId === signedId);
  });
});

// pinned

const pinned = ref<boolean>(false);

onMounted(() => {
  if (props.pinnedTo) {
    pinned.value = true;
  }
});

watch(
  () => props.pinnedTo,
  (pinnedTo) => {
    if (pinnedTo) {
      pinned.value = true;
    }
  }
);
</script>

<style scoped>
img {
  max-width: 300px;
  max-height: 400px;
}
</style>
