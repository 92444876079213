<template>
  <div>
    <i class="pi pi-caret-up"></i>
    <i class="pi pi-caret-up"></i>
    <i class="pi pi-caret-up"></i>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
