import { computed } from "vue";
import { RouteLocationRaw, useRouter, useRoute } from "vue-router";
import { dontNeedLoginList } from "/@/vue/pages/routes";

export function useRouterUtil() {
  const router = useRouter();
  const route = useRoute();

  const currentRoute = computed(() => {
    return router.currentRoute.value;
  });

  const currentRouteParams = computed(() => {
    return router.currentRoute.value.params;
  });

  const id = computed<number>(() => {
    const idstr = currentRouteParams.value?.id;

    if (!idstr) undefined;

    return Number(idstr);
  });

  const currentRouteQuery = computed(() => {
    return router.currentRoute.value.query;
  });

  const currentRouteName = computed(() => {
    return router.currentRoute.value.name?.toString();
  });

  type FRouteMeta = {
    title?: string;
    tree?: { title?: string; ownerTitle?: string; name: string }[];
  };

  const currentRouteMeta = computed<FRouteMeta>(() => {
    return router.currentRoute.value.meta;
  });

  // goto

  function goto(to: RouteLocationRaw) {
    router.push(to);
  }

  function updateHash(hash: string) {
    histroy.pushState(null, "", hash);
  }

  function updateQuery(query: Record<string, string>) {
    router.push({ query });
  }

  function backto(options = { confirm: true }) {
    const { confirm } = options;

    if (confirm) {
      if (
        !window.confirm(
          "前のページに戻る場合、この操作により現在入力した内容が失なわれます。前のページに戻る場合は「OK」を押して下さい。"
        )
      ) {
        return;
      }
    }

    if (window.history.length > 1) {
      router.go(-1);
    } else {
      router.push("/");
    }
  }

  function reload() {
    router.go(0);
  }

  // query

  function dropQuery() {
    router.push({ query: {} });
  }

  // page

  const isOpenSite = computed(() => {
    if (!currentRouteName.value) return false;

    return (
      (dontNeedLoginList.includes(currentRouteName.value) &&
        !["UsersNew"].includes(currentRouteName.value)) ||
      [
        "UsersIndex",
        "UsersEdit",
        "UsersShow",
        "UsersNewsUserNewsIndex",
        "UsersNewsUserNewsShow",
        "UsersJoinUsersIndex",
        "UsersRecruitmentsIndex",
        "UsersBcpIndex",
        "UsersRulesIndex",
        "UsersChangePassword",
      ].includes(currentRouteName.value)
    );
  });

  return {
    goto,
    backto,
    dropQuery,
    reload,
    updateHash,
    updateQuery,

    currentRoute,
    currentRouteParams,
    currentRouteQuery,
    currentRouteName,
    currentRouteMeta,
    id,

    isOpenSite,
  };
}
