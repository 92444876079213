import useSWRV from "swrv";
import { computed } from "vue";
import {
  PasswordChange,
  User,
  UserClient,
  Invest24UserClient,
  UserLabelType,
} from "/@/types";
import { useStore } from "/@/vue/store";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

//

export function useUser() {
  const store = useStore();

  const user = computed<UserClient | undefined>(() => {
    return store.state.user.user;
  });

  async function setUser(user: UserClient | undefined) {
    await store.dispatch("user/SET_USER", user);
  }

  async function getLoginUser() {
    await store.dispatch("user/GET_USER");
  }

  function getUser(
    options: { withMemberAnnounces?: boolean; withInvest24?: boolean } = {
      withMemberAnnounces: false,
      withInvest24: false,
    }
  ) {
    const { withMemberAnnounces, withInvest24 } = options;

    let uri = "/api/v1/current_user?";

    if (withMemberAnnounces) {
      uri += "with_member_announces=true&";
    }

    if (withInvest24) {
      uri += "with_invest24=true&";
    }

    return useSWRV<UserClient>(uri, fetcher);
  }

  function getUsers() {
    return useSWRV<UserClient[]>("/api/v1/users", fetcher);
  }

  function getInvest24Users() {
    return useSWRV<Invest24UserClient[]>("/api/v1/invest24_users", fetcher);
  }

  async function changePassword(form: PasswordChange) {
    try {
      await axios.patch("/api/v1/users/change_password", {
        user: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateUser(user: User) {
    try {
      await axios.patch("/api/v1/users", {
        user,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function sendResetPasswordEmail(email: string) {
    try {
      await axios.post("/api/v1/users/send_reset_password_email", {
        user: {
          email,
        },
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function resetPassword(
    form: PasswordChange,
    { token }: { token: string }
  ) {
    try {
      await axios.patch(`/api/v1/users/reset_password?token=${token}`, {
        user: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  //

  function hasLabel(user: UserClient, labelType: UserLabelType): boolean {
    return user.userLabels.map((ul) => ul.labelType).includes(labelType);
  }

  const isSystemAdmin = computed(() => {
    if (!user.value) return false;

    return hasLabel(user.value, "system_admin");
  });

  const isAllManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value) return true;

    return hasLabel(user.value, "all_manager");
  });

  const isAllPoster = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "all_poster");
  });

  const isAllLManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "all_l_manager");
  });

  const isAdmin = computed(() => {
    if (isSystemAdmin.value) return true;

    return user.value?.admin;
  });

  const isOwner = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value) return true;

    return hasLabel(user.value, "owner");
  });

  const isOffice = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "office");
  });

  const isUserManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "u_manager");
  });

  const isVManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "v_manager");
  });

  const isAManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "a_manager");
  });

  const isBManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "b_manager");
  });

  const isCManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "c_manager");
  });

  const isABCManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return isAManager.value || isBManager.value || isCManager.value;
  });

  const isAPoster = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "a_poster");
  });

  const isBPoster = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "b_poster");
  });

  const isCPoster = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "c_poster");
  });

  const isABCPoster = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return isAPoster.value || isBPoster.value || isCPoster.value;
  });

  const isIManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value || isAManager.value)
      return true;

    return hasLabel(user.value, "i_manager");
  });

  const isOrganizationManager = computed(() => {
    return isCManager.value;
  });

  const isBcpManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "bcp_manager");
  });

  const isRuleManager = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return hasLabel(user.value, "rule_manager");
  });

  const canEditNews = computed(() => {
    if (!user.value) return false;
    if (isSystemAdmin.value || isAllManager.value) return true;

    return isAllPoster.value || isABCManager.value || isABCPoster.value;
  });

  return {
    user,
    setUser,
    getLoginUser,
    getUser,
    getUsers,
    getInvest24Users,
    updateUser,
    changePassword,
    resetPassword,
    sendResetPasswordEmail,

    isAllManager,
    isAllPoster,
    isAllLManager,
    isOffice,
    isUserManager,
    isAdmin,
    isVManager,
    isOwner,
    isSystemAdmin,
    isIManager,
    isAManager,
    isBManager,
    isCManager,
    isABCManager,
    isOrganizationManager,
    isAPoster,
    isBPoster,
    isCPoster,
    isABCPoster,
    isBcpManager,
    isRuleManager,

    canEditNews,
  };
}
