<template>
  <div>
    <Chip
      class="justify-content-center text-nowrap w-100"
      :style="{
        'background-color': backgroundColor,
        color: color,
        'border-radius': '9999px',
      }"
      :label="label"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Chip from "primevue/chip";

const props = withDefaults(
  defineProps<{
    label: string;
    active?: boolean;
    reverseColor?: boolean;
    baseColor?: string;
  }>(),
  {
    baseColor: "#E2F1CF",
  }
);

const backgroundColor = computed(() => {
  if (props.active) {
    return "#000";
  }

  return props.reverseColor ? props.baseColor : "#fff";
});

const color = computed(() => {
  if (props.active) {
    return "#504434";
  } else {
    return "#504434";
  }
});
</script>

<style scoped></style>
