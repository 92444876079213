<template>
  <div class="d-flex flex-column justify-conent-center align-items-center">
    <div class="contents-960 w-100">
      <template v-if="error">
        <span>ページがみつかりません</span>
      </template>
      <template v-else>
        <OrganizationForm
          :key="formKey"
          v-model:name="form.name"
          v-model:postcode="form.postcode"
          v-model:address="form.address"
          v-model:phone-number="form.phoneNumber"
          v-model:constituentMembers="form.constituentMembers"
          :errors="errors"
          :show-remarks="showRemarks"
          class="mb-3"
          @update:constituent-members="forceUpdateForm"
        />

        <div class="d-flex justify-content-center my-4 py-4">
          <BasicButton
            label="キャンセル"
            variant="secondary"
            class="me-5"
            outlined
            @click="backto"
          />
          <BasicButton label="更新" button-type="submit" @click="submit" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import {
  useZodScheme,
  useRouterUtil,
  useOrganizations,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as OrganizationForm } from "/@/vue/components/Organisms/Users/Organizations";
import {
  OrganizationForm as OrganizationFormType,
  OrganizationCheckScheme,
  OrganizationClient,
} from "/@/types";
import { gPostcode, gPhoneNumber } from "/@/modules/groomer";
import { getAddress } from "/@/modules/address";

const { currentRouteParams, backto } = useRouterUtil();

const id = computed<number>(() => {
  const idstr = currentRouteParams.value?.id;

  if (!idstr) undefined;

  return Number(idstr);
});

const { getOrganization, updateOrganization } = useOrganizations();
const { data: organization, error } = getOrganization(id);

const showRemarks = computed(() => {
  if (!organization.value) return false;

  // branch == false であれば、協会になるので備考を表示する
  return !organization.value.branch;
});

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<OrganizationFormType>(
    OrganizationCheckScheme,
    organization.value || {
      name: undefined,
      postcode: undefined,
      address: undefined,
      phoneNumber: undefined,
      constituentMembers: [],
    }
  );

startValidation.value = true;

function resetForm(organization?: OrganizationClient) {
  // TODO: key が増えた時に変更を極力無くすために、 organizationClientKeys を実装し loop で書きなおす

  if (organization) {
    form.id = organization.id;
    form.name = organization.name;
    form.postcode = organization.postcode || undefined;
    form.address = organization.address || undefined;
    form.phoneNumber = organization.phoneNumber || undefined;
    form.constituentMembers = organization.constituentMembers;
  } else {
    form.id = undefined;
    form.name = undefined;
    form.postcode = undefined;
    form.address = undefined;
    form.phoneNumber = undefined;
    form.constituentMembers = [];
  }
}

onMounted(() => {
  resetForm(organization.value);
});

watch(organization, (o) => {
  if (!form.id) {
    resetForm(o);
  }
});

watch(form, (f) => {
  if (f.name && f.name.length > 30) {
    form.name = f.name.slice(0, 30);
  }

  if (f.postcode) {
    form.postcode = gPostcode(f.postcode);
  }

  if (f.phoneNumber) {
    form.phoneNumber = gPhoneNumber(f.phoneNumber);
  }
});

watch(
  () => form.postcode,
  async (p, old) => {
    if (p !== old) {
      form.address = await getAddress(p);
    }
  }
);

async function submit() {
  try {
    startValidation.value = true;

    const args = OrganizationCheckScheme.parse(form);

    if (await updateOrganization(id.value, args)) {
      alert("更新しました");
      backto({ confirm: false });
    } else {
      alert(
        "予期せぬエラーで更新に失敗しました。お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}

const formKeyNumber = ref(0);
const formKey = computed(() => {
  return `form-${formKeyNumber.value}`;
});

function forceUpdateForm() {
  formKeyNumber.value++;
}
</script>

<style scoped></style>
