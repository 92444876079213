<template>
  <div class="d-flex flex-column align-items-center">
    <div class="contents-960 w-100 pt-5">
      <FormItem
        v-model:value="email"
        label="メールアドレス"
        :errors="{}"
        form-id="email"
        form-type="email"
        class="mb-3"
        autocomplete="email"
      />

      <div class="w-100 d-flex justify-content-center mt-5">
        <OpenSiteBackButton class="me-3" label="戻る" />
        <MoreReadButton
          class="ms-3"
          color="green"
          label="送信"
          button-type="submit"
          :disabled="loading"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useUser } from "/@/vue/composables";
import { FormItem } from "/@/vue/components/Molecules";
import { MoreReadButton, OpenSiteBackButton } from "/@/vue/components/Atom";

const email = ref<string>("");

const loading = ref<boolean>(false);

const { sendResetPasswordEmail } = useUser();

async function submit() {
  if (!email.value) return;

  loading.value = true;

  if (await sendResetPasswordEmail(email.value)) {
    alert("パスワード再設定メールを送信しました。");
  } else {
    alert("パスワード再設定メールの送信に失敗しました。");
  }

  loading.value = false;
}
</script>

<style scoped></style>
