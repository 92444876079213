<template>
  <div>
    <UserPageActions>
      <div class="d-flex justify-content-between w-100">
        <BasicButton
          label="終了したものを表示"
          icon="pi pi-eye"
          :outlined="!showPast"
          @click="showPast = !showPast"
        />
      </div>
    </UserPageActions>

    <JoinUserTable
      :join-users="joinUsers || []"
      @select="handleSelectJoinUser"
    />

    <RecruitmentModal
      v-model:visible="visibleRecruitmentModal"
      v-model:target-date="targetDate"
      :loading="loading"
      :recruitment="selectedRecruitment"
      :user="user"
      :users="[]"
      :selected-type="'calendar'"
      @cancel-recruitment="handleCancelRecruitment"
      @after-hide="clear"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { isAfterToday } from "/@/modules/luxon";
import { useUser, useRecruitment } from "/@/vue/composables";
import { UserPageActions } from "/@/vue/components/Layouts";
import { BasicButton } from "/@/vue/components/Atom";
import { Table as JoinUserTable } from "/@/vue/components/Organisms/Users/JoinUsers";
import { Modal as RecruitmentModal } from "/@/vue/components/Molecules/Invest24Recruitments";
import { Invest24RecruitmentClient, Invest24JoinUserClient } from "/@/types";

const { getUser } = useUser();
const { data: user, mutate: getUserMutate } = getUser();

const { getRecruitments, cancelRecruitment } = useRecruitment();
const { data: recruitments, mutate: getRecruitmentsMutate } = getRecruitments();

const showPast = ref(false);

const joinUsers = computed<
  (Invest24JoinUserClient & { recruitment: Invest24RecruitmentClient })[]
>(() => {
  const u = user.value;
  const rs = recruitments.value;
  if (!u || !rs) return [];

  return u.invest24JoinUsers
    .filter((joinUser) => showPast.value || isAfterToday(joinUser.targetDate))
    .map((joinUser) => {
      const recruitment = rs.find((r) => r.id === joinUser.recruitmentId);
      if (!recruitment) return null;
      return {
        ...joinUser,
        recruitment,
      };
    });
});

// actions

const visibleRecruitmentModal = ref(false);
const targetDate = ref();
const loading = ref(false);
const selectedRecruitment = ref<Invest24RecruitmentClient>();

function handleSelectJoinUser(
  joinUser: Invest24JoinUserClient & { recruitment: Invest24RecruitmentClient }
) {
  selectedRecruitment.value = joinUser.recruitment;
  targetDate.value = joinUser.targetDate;
  visibleRecruitmentModal.value = true;
}

function clear() {
  selectedRecruitment.value = undefined;
  targetDate.value = undefined;
}

async function handleCancelRecruitment() {
  const recruitment = selectedRecruitment.value;
  const td = targetDate.value;
  if (!recruitment || !td) return;

  if (!window.confirm("申請を取り消してよろしいですか？")) return;

  loading.value = true;

  if (await cancelRecruitment(recruitment.id, { targetDate: td })) {
    alert("申請を取り消しました");
    getUserMutate();
    getRecruitmentsMutate();
    clear();
    visibleRecruitmentModal.value = false;
  } else {
    alert("申請の取り消しに失敗しました");
  }

  loading.value = false;
}
</script>

<style scoped></style>
