<template>
  <div>
    <ShowFormItemWrapper title="選択日にち">
      <span>{{ basicFormatter(targetDateInfo.targetDate) }}</span>
    </ShowFormItemWrapper>
    <ShowFormItemWrapper
      v-if="targetDateInfo.recruitmentType === 'invest24'"
      title="エリア"
    >
      <span>{{ targetDateInfo.area }}</span>
    </ShowFormItemWrapper>
    <ShowFormItemWrapper v-if="targetDateInfo.workPlace" title="場所">
      <span>{{ targetDateInfo.workPlace }}</span>
    </ShowFormItemWrapper>
    <ShowFormItemWrapper title="時間">
      <span>{{ basicFormatter(targetDateInfo.meetingAt, "onlyTime") }}</span>
      <span v-if="targetDateInfo.finishAt">
        〜{{ basicFormatter(targetDateInfo.finishAt, "onlyTime") }}
      </span>
    </ShowFormItemWrapper>
    <ShowFormItemWrapper title="集合場所">
      <span>{{ targetDateInfo.meetingPlace }} </span>
      <Image
        v-if="
          targetDateInfo.meetingPlace ===
          'いしかわ総合スポーツセンター サブアリーナの倉庫'
        "
        src="/img/ishikawa_sports_center_meeting_place.png"
        preview
        :width="isMobile ? '300' : '400'"
        :height="isMobile ? '200' : '300'"
      />
    </ShowFormItemWrapper>
    <ShowFormItemWrapper v-if="targetDateInfo.belongings" title="持ち物">
      <span>{{ targetDateInfo.belongings }}</span>
    </ShowFormItemWrapper>
    <ShowFormItemWrapper v-if="targetDateInfo.rewards" title="報酬">
      <span>{{ targetDateInfo.rewards }}</span>
    </ShowFormItemWrapper>

    <ShowFormItemWrapper v-if="false" title="備考">
      <span>{{ targetDateInfo.details || "なし" }}</span>
    </ShowFormItemWrapper>
  </div>
</template>

<script setup lang="ts">
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils } from "/@/vue/composables";
import { ShowFormItemWrapper } from "/@/vue/components/Molecules/Form";
import { Invest24UserAttachedDayInfo } from "/@/types";
import Image from "primevue/image";

defineProps<{
  targetDateInfo: Invest24UserAttachedDayInfo;
}>();

const { isMobile } = useMqUtils();
</script>

<style scoped></style>
