import { UserLabelType, UserPositionType } from "/@/types/user.d";

export function userLabelTranslater(userLabel: UserLabelType) {
  switch (userLabel) {
    case "system_admin":
      return "システム管理者";
    case "all_manager":
      return "全管理者";
    case "all_poster":
      return "全投稿者";
    case "office":
      return "事務局(社協)";
    case "v_manager":
      return "ボランティア管理者";
    case "u_manager":
      return "会員管理者";
    case "c_manager":
      return "支部長";
    case "c_poster":
      return "支部投稿者";
    case "b_manager":
      return "委員会管理者";
    case "b_poster":
      return "委員会投稿者";
    case "a_manager":
      return "県協管理者";
    case "a_poster":
      return "県協投稿者";
    case "all_l_manager":
      return "ラベル・組織管理者";
    case "bcp_manager":
      return "BCP管理者";
    case "rule_manager":
      return "事務管理者";
    case "i_manager":
      return "問い合わせ管理者";
  }
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("userLabelTranslater", () => {
    expect(userLabelTranslater("system_admin")).toBe("システム管理者");
    expect(userLabelTranslater("all_manager")).toBe("全管理者");
  });
}

export function userPositionTranslater(userLabel: UserPositionType) {
  switch (userLabel) {
    case "senior_advisor":
      return "相談役";
    case "chairman":
      return "会長";
    case "vice_chairman":
      return "副会長";
    case "director":
      return "理事";
    case "advisor":
      return "顧問";
    case "committee_chairman":
      return "委員長";
    case "committee_vice_chairman":
      return "副委員長";
    case "branch_manager":
      return "支部長";
    case "branch_submanager":
      return "副支部長";
    case "secretary_general":
      return "事務局長";
    case "secretariat":
      return "事務局";
    case "accountant":
      return "会計";
  }
}
