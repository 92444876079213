<template>
  <Button
    :class="btnClass"
    :severity="severity"
    :disabled="disabledInComponent || disabled"
    :label="label"
    :name="name"
    :type="buttonType"
    icon="pi pi-angle-right"
    icon-pos="right"
    :size="size"
    :rounded="rounded"
    :text="text"
    :outlined="outlined"
    @click="onClick"
  >
    <slot></slot>
  </Button>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, ButtonHTMLAttributes } from "vue";
import Button from "primevue/button";
type ButtonTypes = ButtonHTMLAttributes["type"];

interface Props {
  name?: string;
  variant?: "green" | "primary" | "secondary" | "warning" | "gray" | "success";
  color?: "green" | "orange";
  colorStr?: string;
  label?: string;
  disabled?: boolean;
  circle?: boolean;
  large?: boolean;
  small?: boolean;
  middle?: boolean;
  slim?: boolean;
  auto?: boolean;
  icon?: string;
  iconPos?: "left" | "right";
  rounded?: boolean;
  withShadow?: boolean;
  buttonType?: ButtonTypes;
  outlined?: boolean;
  text?: boolean;
  w300?: boolean;
  stopDualClick?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  name: undefined,
  variant: undefined,
  buttonType: "button",
  withShadow: false,
  icon: undefined,
  iconPos: "left",
  label: undefined,
});

const emit = defineEmits<{
  (e: "click"): void;
}>();

const btnClass = computed(() => ({
  "with-opensite-shadow": true,
  "button-more-read": true,
  "drop-shadow": props.withShadow,
  "btn-circle": props.circle,
  "btn-slim": props.slim,
  "btn-auto": props.auto,
  "w-300": props.w300,
  "btn-green": props.color === "green",
  "btn-orange": props.color === "orange",
  "text-nowrap": true,
}));

const severity = computed(() => {
  return props.variant;
});

const size = computed(() => {
  if (props.small) {
    return "small";
  }

  if (props.large) {
    return "large";
  }

  return undefined;
});

const disabledInComponent = ref<boolean>(false);

function onClick(e: MouseEvent) {
  e.stopPropagation();
  emit("click");
  if (props.stopDualClick || props.buttonType === "submit") {
    const sleep = (msec: number) =>
      new Promise((resolve) => setTimeout(resolve, msec));

    (async () => {
      disabledInComponent.value = true;
      await sleep(3000);
      disabledInComponent.value = false;
    })();
  }
}
</script>

<style lang="scss">
.p-button.button-more-read {
  border-radius: 9999px;
  font-size: 12px;
  background-color: white;
  border-width: 2px;
  color: $main-brawn;
  width: 150px;
  padding: 0.375rem 0.675rem;

  .p-button-icon {
    text-align: center;
    color: v-bind(colorStr);
  }

  border-color: v-bind(colorStr);

  &:hover {
    background-color: v-bind(colorStr);
    color: white;

    .p-button-icon {
      color: white;
    }
  }

  &.btn-green {
    border-color: #69bb01 !important;

    .p-button-icon {
      color: #69bb01;
    }

    &:hover {
      background-color: #69bb01;
      color: white;

      .p-button-icon {
        color: white;
      }
    }
  }

  &.btn-orange {
    border-color: #fda237 !important;

    .p-button-icon {
      color: #fda237;
    }

    &:hover {
      background-color: #fda237;
      color: white;

      .p-button-icon {
        color: white;
      }
    }
  }
}
</style>
