import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const PdfInfoScheme = z.object({
  signedId: z.string(),
  filename: z.string(),
  url: z.string(),
  contentType: z.string(),
});
export type PdfInfo = zod.infer<typeof PdfInfoScheme>;

export const PdfGenOptionsScheme = z.object({
  unit: z.enum(["in", "mm", "cm", "pt"]),
  format: z.enum([
    "a0",
    "a1",
    "a2",
    "a3",
    "a4",
    "a5",
    "a6",
    "a7",
    "a8",
    "a9",
    "a10",
    "b0",
    "b1",
    "b2",
    "b3",
    "b4",
    "b5",
    "b6",
    "b7",
    "b8",
    "b9",
    "b10",
    "c0",
    "c1",
    "c2",
    "c3",
    "c4",
    "c5",
    "c6",
    "c7",
    "c8",
    "c9",
    "c10",
  ]),

  orientation: z.enum(["p", "l"]),
  precision: z.number(),
});

export type PdfGenOptions = zod.infer<typeof PdfGenOptionsScheme>;
