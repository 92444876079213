<template>
  <div>
    <div class="d-flex justify-content-end align-items-center">
      <Dropdown
        v-if="isMobile && optionFiscalYears.length > 0"
        v-model="selectedYear"
        :options="optionFiscalYears"
        option-label="label"
        option-value="value"
        input-id="year-dropdown"
        class="ms-3"
      />
    </div>

    <h3 class="mb-3">{{ selectedYear }}年度活動報告</h3>
    <div class="d-flex" style="height: 100%">
      <NewsList
        :news="filteredNews || []"
        with-content
        class="w-100"
        reverse-color
        @select-news="handleSelectNews"
      />
      <PageLinks v-if="!isMobile" class="ms-3">
        <ul class="list-group">
          <li
            v-for="y in optionFiscalYears"
            :key="y.value"
            class="list-group-item"
            :class="{ active: y.value === selectedYear }"
            role="button"
            @click="selectedYear = y.value"
          >
            <span>{{ y.label }}活動報告</span>
          </li>
        </ul>
      </PageLinks>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useNews, useRouterUtil, useMqUtils } from "/@/vue/composables";
import { PageLinks } from "/@/vue/components/Layouts";
import { List as NewsList } from "/@/vue/components/Molecules/News";
import { fromISO, luxonNow } from "/@/modules/luxon";
import Dropdown from "primevue/dropdown";

const { goto } = useRouterUtil();
const { isMobile } = useMqUtils();

const selectedOrganizationId = ref();

const { getNews } = useNews();
const { data: news } = getNews(selectedOrganizationId, {
  top: true,
  association: true,
});

function handleSelectNews(id: number) {
  goto({ name: "NewsShow", params: { id: id } });
}

function getTodayFiscalYear() {
  const today = luxonNow();
  return today.month < 4 ? today.year - 1 : today.year;
}

const selectedYear = ref(getTodayFiscalYear());

const optionFiscalYears = computed(() => {
  if (!news.value || !news.value.length) return [];

  return Array.from(
    new Set(
      news.value.map((n) => {
        const pub = fromISO(n.publishedAt);
        return pub.month < 4 ? pub.year - 1 : pub.year;
      })
    )
  )
    .sort((a, b) => b - a)
    .map((y) => ({
      label: `${y}年度`,
      value: y,
    }));
});

const filteredNews = computed(() => {
  if (!news.value) return [];

  return news.value?.filter((n) => {
    const pub = fromISO(n.publishedAt);
    return pub.month < 4
      ? pub.year - 1 === selectedYear.value
      : pub.year === selectedYear.value;
  });
});
</script>

<style scoped></style>
