import Index from "./Index.vue";
import SignIn from "./SignIn.vue";
import SignUp from "./SignUp.vue";
import About from "./About.vue";
import Activity from "./Activity.vue";
import BranchActivity from "./BranchActivity.vue";
import Inquiry from "./Inquiry.vue";
import Join from "./Join.vue";
import Sponsor from "./Sponsor.vue";
import PrivacyPolicy from "./PrivacyPolicy.vue";
import PasswordReset from "./PasswordReset.vue";
import NotFound from "./NotFound.vue";
import { Router as Invest24Router, Root as Invest24Root } from "./Invest24";
import { Router as MagazineRouter, Root as MagazineRoot } from "./Magazines";
import { Router as NewsRouter, Root as NewsRoot } from "./News";
import { Router as Shelter24Router, Root as Shelter24Root } from "./Shelter24";
import { Router as UserRouter, Root as UserRoot } from "./Users";
import { UserClient } from "/@/types";
import { subtract } from "lodash";

export const routes = [
  {
    path: "/",
    name: "Index",
    title: "Top",
    component: Index,
  },
  {
    path: "/sign_in",
    name: "SignIn",
    title: "ログイン",
    component: SignIn,
    meta: {
      title: "ログイン",
      subtitle: "SIGNIN",
      tree: [
        {
          title: "ログイン",
        },
      ],
    },
  },
  {
    path: "/about",
    name: "About",
    title: "石川県介護支援専門員協会",
    component: About,
    meta: {
      title: "石川県介護支援専門員協会",
      subtitle: "ISHIKAWA CARE MANAGER ASSOCIATION",
      tree: [
        {
          title: "石川県介護支援専門員協会",
          subtitle: "about",
        },
      ],
    },
  },
  {
    path: "/activity",
    name: "Activity",
    title: "県協会活動",
    component: Activity,
    meta: {
      title: "県協会活動",
      tree: [
        {
          title: "県協会活動",
          subtitle: "ACTIVITY",
        },
      ],
    },
  },
  {
    path: "/branch_activity",
    name: "BranchActivity",
    title: "支部概要",
    component: BranchActivity,
    meta: {
      title: "支部概要",
      tree: [
        {
          title: "支部概要",
          subtitle: "BRANCH ACTIVITY",
        },
      ],
    },
  },
  {
    path: "/inquiry",
    name: "Inquiry",
    title: "お問い合わせ",
    component: Inquiry,
    meta: {
      title: "お問い合わせ",
      subtitle: "INQUIRY",
      tree: [
        {
          title: "お問い合わせ",
          subtitle: "inquiry",
        },
      ],
    },
  },
  {
    path: "/join",
    name: "Join",
    title: "入会案内",
    component: Join,
    meta: {
      title: "入会案内",
      subtitle: "JOIN",
      tree: [
        {
          title: "入会案内",
        },
      ],
    },
  },
  {
    path: "/sponsor",
    name: "Sponsor",
    title: "賛助会員様",
    component: Sponsor,
    meta: {
      title: "賛助会員様",
      subtitle: "sponsors",
      tree: [
        {
          title: "賛助会員様",
        },
      ],
    },
  },
  {
    path: "/sign_up",
    name: "SignUp",
    title: "新規登録",
    component: SignUp,
    meta: {
      title: "会員登録",
      subtitle: "SIGNUP",
      tree: [
        {
          title: "会員登録",
        },
      ],
    },
  },

  {
    path: "/privacy_policy",
    name: "PrivacyPolicy",
    title: "プライバシーポリシー",
    meta: {
      title: "プライバシーポリシー",
      subtitle: "PRIVACY POLICY",
      tree: [
        {
          title: "プライバシーポリシー",
        },
      ],
    },
    component: PrivacyPolicy,
  },
  {
    path: "/password_reset",
    name: "PasswordReset",
    title: "パスワードリセット",
    component: PasswordReset,
    meta: {
      title: "パスワードリセット",
      subtitle: "PASSWORD RESET",
      tree: [
        {
          title: "パスワードリセット",
        },
      ],
    },
  },
  {
    path: "/invest24",
    title: "実態調査ボランティア",
    component: Invest24Root,
    children: [...Invest24Router],
  },
  {
    path: "/magazines",
    title: "広報誌",
    component: MagazineRoot,
    children: [...MagazineRouter],
  },
  {
    path: "/news",
    title: "記事情報",
    component: NewsRoot,
    children: [...NewsRouter],
  },
  {
    path: "/shelter24",
    title: "1.5次避難所ボランティア",
    component: Shelter24Root,
    children: [...Shelter24Router],
  },
  {
    path: "/user",
    component: UserRoot,
    children: [...UserRouter],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    title: "Not Found",
    component: NotFound,
    meta: {
      title: "ページが見つかりません",
      subtitle: "NOT FOUND",
      tree: [
        {
          title: "ページが見つかりません",
        },
      ],
    },
  },
];

export const dontNeedLoginList = [
  "SignUp",
  "SignIn",
  "Index",
  "PasswordReset",
  "About",
  "Activity",
  "PrivacyPolicy",
  "BranchActivity",
  "Inquiry",
  "Join",
  "Sponsor",
  "NewsShow",
  "NewsIndex",
  "MagazinesIndex",
  "NotFound",
  "UsersNew",
  "UsersPasswordEdit",
  // invest24
  "Invest24Index",
  "Invest24JoinUsersNew",
  "Invest24JoinUsersSelect",
  "Invest24JoinUsersCancel",
  "Invest24UsersLogin",
  "Invest24UsersShow",
  // shelter24
  "Shelter24Index",
];

export function isNeedLogin(name: string): boolean {
  return !dontNeedLoginList.includes(name);
}

function needSystemAdminList(name: string) {
  return false; // name.startsWith("UsersSystemAdmin");
}

export function isNeedLabel(name: string, needLabel: string): boolean {
  if (needLabel === "system_admin" && needSystemAdminList(name)) {
    return true;
  }

  if (needLabel === "owner" && needSystemAdminList(name)) {
    return true;
  }

  return false;
}
