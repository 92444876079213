import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { userObject } from "/@/types/common.d";
import { OrganizationMemberScheme } from "/@/types/user.d";

const signUpRegistrationNumberObject = {
  special: z.boolean().optional(),
  registrationNumber: z.string().optional(),
};

export const SingUpRegistrationNumberCheckScheme = z
  .object(signUpRegistrationNumberObject)
  .refine(
    (data) => {
      return data.special || data.registrationNumber;
    },
    {
      message: "ケアマネージャーの方は登録番号を入力して下さい",
      path: ["registrationNumber"],
    }
  );

const signUpAffiliationObject = {
  haveAffiliation: z.boolean().optional(),
  affiliation: z.string().trim().min(1).max(50).optional().or(z.literal("")),
  affiliationPhoneNumber: z
    .string()
    .trim()
    .min(10)
    .max(11)
    .optional()
    .or(z.literal("")),
  affiliationPostcode: z
    .string()
    .trim()
    .min(7)
    .max(7)
    .optional()
    .or(z.literal("")),
  affiliationAddress: z
    .string()
    .trim()
    .min(1)
    .max(150)
    .optional()
    .or(z.literal("")),
};
export const SingUpAffiliationCheckScheme = z
  .object(signUpAffiliationObject)
  .refine(
    (data) => {
      return !data.haveAffiliation || data.affiliation;
    },
    {
      message: "所属情報を入力してください",
      path: ["affiliation"],
    }
  )
  .refine(
    (data) => {
      return !data.haveAffiliation || data.affiliationPhoneNumber;
    },
    {
      message: "所属情報を入力してください",
      path: ["affiliationPhoneNumber"],
    }
  )
  .refine(
    (data) => {
      return !data.haveAffiliation || data.affiliationPostcode;
    },
    {
      message: "所属情報を入力してください",
      path: ["affiliationPostcode"],
    }
  )
  .refine(
    (data) => {
      return !data.haveAffiliation || data.affiliationAddress;
    },
    {
      message: "所属情報を入力してください",
      path: ["affiliationAddress"],
    }
  );

export const UserSignUpScheme = z.object({
  ...userObject,
  password: z
    .string()
    .min(6, { message: "6文字以上である必要があります" })
    .max(255),
  passwordConfirmation: z.string(),
  organizationMembers: OrganizationMemberScheme.array(),
});

export const UserSignUpSubstituteScheme = z.object({
  ...userObject,
  organizationMembers: OrganizationMemberScheme.array(),
});

export const UserSignUpFormScheme = UserSignUpScheme.partial();
export type UserSignUpForm = zod.infer<typeof UserSignUpFormScheme>;

/*
export const TemporaryUserSignUpFormScheme =
  TemporaryUserSignUpScheme.partial();
export type TemporaryUserSignUpForm = zod.infer<
  typeof TemporaryUserSignUpFormScheme
>;
*/

export const UserSignUpCheckScheme = z.intersection(
  z.intersection(
    SingUpRegistrationNumberCheckScheme,
    SingUpAffiliationCheckScheme
  ),
  UserSignUpScheme.refine(
    (data) => data.password === data.passwordConfirmation,
    {
      message: "パスワードが一致していません",
      path: ["passwordConfirmation"], // path of error
    }
  )
);

export const UserSignUpCheckSubstituteScheme = z.intersection(
  z.intersection(
    SingUpRegistrationNumberCheckScheme,
    SingUpAffiliationCheckScheme
  ),
  UserSignUpSubstituteScheme
);

/*
export const TemporaryUserSignUpCheckScheme = z.intersection(
  SingUpRegistrationNumberCheckScheme,
  TemporaryUserSignUpScheme
);
*/
