<template>
  <div>
    <div class="d-flex flex-column align-items-center w-100">
      <div
        :style="{
          border: withoutBorder ? 'none' : '1px solid black',
          width: a4width,
        }"
      >
        <div :id="pdfElementId">
          <div :style="pdfA4PortraitStyles">
            <div class="d-flex justify-content-center">
              <Inplace v-if="editable" :closable="true">
                <template #display>
                  <h1>{{ data.title || "クリックして編集" }}</h1>
                </template>
                <template #content>
                  <InputText
                    :model-value="data.title"
                    autofocus
                    class="w-100"
                    @update:model-value="
                      $emit('update:data', { ...data, title: $event })
                    "
                  />
                </template>
              </Inplace>
              <h1 v-else>{{ data.title }}</h1>
            </div>

            <Inplace v-if="editable" :closable="true">
              <template #display>
                <p class="my-3">{{ data.to || "クリックして編集" }} 宛</p>
              </template>
              <template #content>
                <InputText
                  :model-value="data.to"
                  autofocus
                  class="w-100"
                  @update:model-value="
                    $emit('update:data', { ...data, to: $event })
                  "
                />
              </template>
            </Inplace>
            <p v-else class="my-3">{{ data.to }} 宛</p>

            <table class="table table-bordered w-100">
              <thead>
                <tr>
                  <th
                    class="text-center align-middle cell-header"
                    style="font-size: 0.8rem"
                  >
                    <Inplace v-if="editable" :closable="true">
                      <template #display>
                        <p class="my-3">
                          {{ data.numberTitle || "クリックして編集" }}
                        </p>
                      </template>
                      <template #content>
                        <InputText
                          :model-value="data.numberTitle"
                          autofocus
                          class="w-100"
                          @update:model-value="
                            $emit('update:data', {
                              ...data,
                              numberTitle: $event,
                            })
                          "
                        />
                      </template>
                    </Inplace>
                  </th>
                  <td colspan="2">123456789</td>
                </tr>
                <tr>
                  <th class="text-center align-middle cell-header">ふりがな</th>
                  <td colspan="2">
                    {{ userInfo.lastNameKana }}{{ userInfo.firstNameKana }}
                  </td>
                </tr>
                <tr>
                  <th class="text-center align-middle cell-header">氏名</th>
                  <td colspan="2">
                    {{ userInfo.lastName }}{{ userInfo.firstName }}
                  </td>
                </tr>
                <tr>
                  <th class="text-center align-middle cell-header">住所</th>
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <th rowspan="3" class="align-middle cell-header">
                    <div class="d-flex flex-column">
                      <span class="text-center mb-3">電話番号</span>
                      <span style="font-weight: normal; font-size: 0.8rem"
                        >※日中に連絡の取れる番号を記入して下さい</span
                      >
                    </div>
                  </th>
                  <td class="text-center cell-inner-header">自宅または携帯</td>
                  <td>TEL: {{ userInfo.phoneNumber }}</td>
                </tr>
                <tr>
                  <td
                    rowspan="2"
                    class="align-middle text-center cell-inner-header"
                  >
                    勤務先
                  </td>
                  <td>TEL:</td>
                </tr>
                <tr>
                  <td>
                    <span style="font-size: 0.8rem">勤務先名称：</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <td colspan="3">
                  <div class="d-flex flex-column">
                    <span>
                      上記の者が、当団体主催による下記研修を修了したことを証明します。
                    </span>
                    <span>研修名：</span>
                    <span>テーマ：</span>
                    <span>講師：</span>
                    <span>研修期日：</span>
                    <span>総時間数：</span>
                    <span class="mt-5 d-flex align-items-center mb-3">
                      <span class="ms-5">年</span>
                      <span class="ms-4">月</span>
                      <span class="ms-4">日</span>
                    </span>
                    <div class="d-flex flex-column align-items-end me-4 mb-3">
                      <span style="width: 300px" class="mb-3">団体名</span>
                      <div
                        class="d-flex justify-content-between align-items-center"
                        style="width: 300px"
                      >
                        <span>代表者氏名</span>
                        <span>印</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tbody>
            </table>
            <p>発行日: {{ today.toFormat("yyyy年MM月dd日") }}</p>
          </div>
          <hr data-html2canvas-ignore="true" />
          <div :style="pdfA4PortraitStyles">
            <div class="d-flex align-items-start">
              <span>【提出方法】</span>
              <div class="d-flex flex-column">
                <span>①石川県電子申請システム上で提出 </span>
                <span>②郵送(宛先)〒920-8580 石川県金沢市鞍月1丁目1番地</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { luxonNow } from "/@/modules/luxon";
import { a4width, pdfA4PortraitStyles } from "/@/modules/pdf";
import { UserClient, CompletationFormatData } from "/@/types";
import Inplace from "primevue/inplace";
import InputText from "primevue/inputtext";

const props = defineProps<{
  pdfElementId: string;
  user?: UserClient;
  data: Partial<CompletationFormatData>;
  withoutBorder?: boolean;
  editable?: boolean;
}>();

defineEmits<{
  (e: "update:data", data: CompletationFormatData): void;
}>();

const today = luxonNow();

const userInfo = computed(() => {
  // Template 内で、 `user?.name || ""` と書くのは冗長なので、
  // computed を使って、一度に処理する。
  // TODO:
  // ただし user に attribute が追加された場合、ここにも追加する必要があり管理コストがかかるため、
  // なんらかの方法で自動生成できるようにしたい。

  return {
    lastName: props.user?.lastName || "",
    firstName: props.user?.firstName || "",
    lastNameKana: props.user?.lastNameKana || "",
    firstNameKana: props.user?.firstNameKana || "",
    phoneNumber: props.user?.phoneNumber || "",
  };
});
</script>

<style scoped></style>
