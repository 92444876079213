<template>
  <div>
    <div class="d-flex flex-column align-items-center w-100">
      <InquiryTable
        :inquiries="allInquiries"
        class="w-100"
        @select-inquiry="selectInquiry"
      />
      <InquiryModal
        v-model:visible="visible"
        :inquiry="selectedInquiry"
        @after-hide="closeModal"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useInquiries, useRouterUtil } from "/@/vue/composables";
import { Modal as InquiryModal } from "/@/vue/components/Molecules/Inquiries";
import { Table as InquiryTable } from "/@/vue/components/Organisms/Users/Inquiries";
import { InquiryClient } from "/@/types";

const { goto } = useRouterUtil();

const { getInquiries } = useInquiries();
const { data: inquiries } = getInquiries();

const allInquiries = computed(() => {
  if (!inquiries.value) return [];

  return inquiries.value;
});

const visible = ref<boolean>(false);
const selectedInquiry = ref<InquiryClient>();

function selectInquiry(id: number) {
  goto({ name: "UsersInquiriesShow", params: { id: id } });

  selectedInquiry.value = allInquiries.value.find(
    (inquiry) => inquiry.id === id
  );
  visible.value = true;
}

function closeModal() {
  visible.value = false;
  selectedInquiry.value = undefined;
}
</script>

<style scoped></style>
