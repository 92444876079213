import Index from "./Index.vue";
import Publication from "./Publication.vue";

export default [
  {
    path: "",
    name: "UsersTrainingsIndex",
    component: Index,
    meta: {
      title: "研修",
      subtitle: "TRAINING",
      tree: [],
    },
  },
  {
    path: "publication",
    name: "UsersTrainingsPublication",
    component: Publication,
    meta: {
      title: "証明書の発行",
      tree: [
        {
          name: "UsersTrainingsIndex",
          title: "研修",
        },
      ],
    },
  },
];
