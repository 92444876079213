<template>
  <div>
    <div
      class="w-100 d-flex back-button-wrapper"
      :class="{
        'justify-content-center': center,
      }"
    >
      <BasicButton
        :label="label"
        :icon="icon"
        variant="secondary"
        outlined
        @click="backto({ confirm: false })"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";

withDefaults(
  defineProps<{
    label?: string;
    icon?: string;
    center?: boolean;
  }>(),
  {
    label: "戻る",
    icon: undefined,
    center: true,
  }
);

const { backto } = useRouterUtil();
</script>

<style lang="scss"></style>
