<template>
  <div>
    <div v-if="false" class="mb-4">
      <FormLabel label="種別" />
      <SelectButton
        :model-value="recruitmentType"
        :options="invest24RecruitmentTypes"
        :option-label="recruitmentTypeTranslater"
        :allow-empty="false"
        @update:model-value="
          $emit('update:recruitmentType', $event as Invest24RecruitmentType)
        "
      />
    </div>

    <div
      :class="{
        'mb-4': recruitmentType !== 'shelter24',
      }"
    >
      <FormLabel label="エリア(必須)" />
      <Dropdown
        v-if="recruitmentType !== 'shelter24'"
        v-model="selectedArea"
        :options="areas"
        class="w-100"
      />
    </div>

    <div v-if="selectedArea === 'その他'" class="mb-4">
      <FormItem
        :value="area"
        :errors="errors"
        form-id="area"
        form-type="text"
        :disabled="recruitmentType === 'shelter24'"
        @update:value="$emit('update:area', $event as string | undefined)"
      />
    </div>

    <div class="mb-4">
      <div class="d-flex flex-column mb-1">
        <span class="text-danger">
          作成後は日にちを変更することができなくなりますのでご注意下さい
        </span>
        <span class="text-danger">
          また同じエリアの同じ日にちに複数の募集を作成することはできません
        </span>
      </div>

      <div class="d-flex">
        <div class="me-2">
          <FormLabel label="開始日" />
          <FormItem
            :value="startOn"
            form-id="startOn"
            form-type="date"
            :disabled-dates="disabledDates"
            :errors="errors"
            @update:value="
              $emit('update:startOn', $event as string | undefined)
            "
          />
        </div>

        <div class="me-2">
          <FormLabel label="終了日" />
          <FormItem
            :value="finishOn"
            form-id="finishOn"
            form-type="date"
            :start-date="startOn ? fromISO(startOn) : undefined"
            :min-date="startOn ? fromISO(startOn) : undefined"
            :disabled-dates="disabledDates"
            :errors="errors"
            @update:value="
              $emit('update:finishOn', $event as string | undefined)
            "
          />
        </div>
      </div>
    </div>

    <div class="mb-4">
      <FormLabel label="募集人数" />
      <NumberForm
        :value="needCount"
        form-id="needCount"
        :items="optionNumbers"
        @update:value="$emit('update:needCount', $event)"
      />
    </div>

    <div class="mb-4">
      <FormLabel label="集合場所(必須)" />
      <FormItem
        :value="meetingPlace"
        :errors="errors"
        form-id="meetingPlace"
        form-type="text"
        :rows="10"
        :disabled="recruitmentType === 'shelter24'"
        @update:value="
          $emit('update:meetingPlace', $event as string | undefined)
        "
      />
    </div>

    <div class="d-flex">
      <div class="me-1">
        <FormLabel label="集合時間(必須)" />
        <FormItemTime
          :value="meetingAt"
          :start-time="{ hours: 9, minutes: 0 }"
          form-id="meetingAt"
          :errors="errors"
          class="mb-4"
          @update:value="
            $emit('update:meetingAt', $event as string | undefined)
          "
        />
      </div>
      <div class="ms-1">
        <FormLabel label="終了時間(必須)" />
        <FormItemTime
          :value="finishAt"
          :start-time="{ hours: 17, minutes: 0 }"
          form-id="finishAt"
          :errors="errors"
          class="mb-4"
          @update:value="$emit('update:finishAt', $event as string | undefined)"
        />
      </div>
    </div>

    <div class="mb-4">
      <FormLabel label="作業場所" />
      <FormItem
        :value="workPlace"
        :errors="errors"
        form-id="workPlace"
        form-type="text"
        :rows="10"
        :disabled="recruitmentType === 'shelter24'"
        @update:value="$emit('update:workPlace', $event as string | undefined)"
      />
    </div>

    <div class="mb-4">
      <FormLabel
        :label="`持ち物 ※100文字以内${
          belongings?.length ? ` 現在：${belongings.length}文字` : ''
        }`"
      />
      <FormItem
        :value="belongings"
        :errors="errors"
        form-id="belongings"
        form-type="textarea"
        :rows="3"
        @update:value="$emit('update:belongings', $event as string | undefined)"
      />
    </div>

    <div class="mb-4">
      <FormLabel
        :label="`報酬 ※100文字以内${
          rewards?.length ? ` 現在：${rewards.length}文字` : ''
        }`"
      />
      <FormItem
        :value="rewards"
        :errors="errors"
        form-id="rewards"
        form-type="textarea"
        :rows="3"
        @update:value="$emit('update:rewards', $event as string | undefined)"
      />
    </div>

    <div class="mb-4">
      <FormLabel
        :label="`備考 ※1000文字以内${
          details?.length ? ` 現在：${details.length}文字` : ''
        }`"
      />
      <FormItem
        :value="details"
        :errors="errors"
        form-id="details"
        form-type="textarea"
        placeholder="例）注意事項など"
        :rows="8"
        @update:value="$emit('update:details', $event as string | undefined)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { areas } from "/@/modules/constants";
import { fromISO } from "/@/modules/luxon";
import { Label as FormLabel, NumberForm } from "/@/vue/components/Atom";
import { FormItem, FormItemTime } from "/@/vue/components/Molecules";
import {
  Invest24RecruitmentType,
  Invest24RecruitmentClient,
  ZodFormattedErrors,
  invest24RecruitmentTypes,
} from "/@/types";
import { ref, watch } from "vue";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import { recruitmentTypeTranslater } from "/@/modules/invest24Recruitment";
import { computed } from "vue";

const props = defineProps<{
  recruitmentType: Invest24RecruitmentType;
  title?: string;
  startOn?: string;
  finishOn?: string;
  area?: string;
  meetingPlace?: string;
  meetingAt?: string;
  finishAt?: string;
  workPlace?: string;
  belongings?: string;
  rewards?: string;
  details?: string;
  needCount?: number;
  invest24Recruitments: Invest24RecruitmentClient[];
  errors: ZodFormattedErrors;
}>();

const emit = defineEmits<{
  (e: "update:recruitmentType", recruitmentType: Invest24RecruitmentType): void;
  (e: "update:title", title: string | undefined): void;
  (e: "update:startOn", startOn: string | undefined): void;
  (e: "update:finishOn", finishOn: string | undefined): void;
  (e: "update:meetingPlace", meetingPlace: string | undefined): void;
  (e: "update:meetingAt", meetingAt: string | undefined): void;
  (e: "update:finishAt", finishAt: string | undefined): void;
  (e: "update:workPlace", workPlace: string | undefined): void;
  (e: "update:needCount", needCount: number | undefined): void;
  (e: "update:belongings", belongings: string | undefined): void;
  (e: "update:rewards", rewards: string | undefined): void;
  (e: "update:details", details: string | undefined): void;
  (e: "update:area", area: string | undefined): void;
}>();

const optionNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const selectedArea = ref(areas[0]);

watch(selectedArea, (sarea) => {
  if (sarea !== "その他") {
    if (selectedArea.value !== sarea) {
      selectedArea.value = sarea;
    }
    emit("update:area", sarea);
  } else {
    if (props.area !== "いしかわ総合スポーツセンター") {
      emit("update:area", undefined);
    }
  }
});

watch(
  () => props.area,
  (area) => {
    if (area === "いしかわ総合スポーツセンター") {
      selectedArea.value = "その他";
    } else if (area && areas.includes(area)) {
      selectedArea.value = area;
    }
  }
);

const disabledDates = computed(() => {
  const rs = props.invest24Recruitments.filter((r) => {
    return r.area === selectedArea.value;
  });

  return rs
    .map((r) => r.invest24RecruitmentDayInfos.map((d) => d.targetDate))
    .flat();
});
</script>

<style scoped></style>
