<template>
  <div>
    <RecruitmentForm
      v-model:recruitment-type="form.recruitmentType"
      v-model:start-on="form.startOn"
      v-model:finish-on="form.finishOn"
      v-model:need-count="form.needCount"
      v-model:area="form.area"
      v-model:meeting-place="form.meetingPlace"
      v-model:meeting-at="form.meetingAt"
      v-model:finish-at="form.finishAt"
      v-model:work-place="form.workPlace"
      v-model:belongings="form.belongings"
      v-model:rewards="form.rewards"
      v-model:details="form.details"
      :invest24-recruitments="recruitments || []"
      :errors="errors"
    />

    <div class="d-flex justify-content-center my-5">
      <BasicButton
        label="キャンセル"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto"
      />
      <BasicButton button-type="submit" label="作成" @click="submit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref } from "vue";
import {
  useRouterUtil,
  useZodScheme,
  useRecruitment,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as RecruitmentForm } from "/@/vue/components/Organisms/Users/Invest24Recruitments";
import {
  Invest24RecruitmentForm as RecruitmentFormType,
  Invest24RecruitmentCheckScheme,
  Invest24RecruitmentType,
} from "/@/types/invest24Recruitment.d";

const { backto, goto } = useRouterUtil();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<RecruitmentFormType>(
  Invest24RecruitmentCheckScheme,
  {
    needCount: 10,
    recruitmentType: "invest24",
    invest24RecruitmentDayInfos: [],
  }
);

watch(form, (f) => {
  if (f.recruitmentType === "shelter24") {
    f.area = "いしかわ総合スポーツセンター";
    f.meetingPlace = "いしかわ総合スポーツセンター サブアリーナの倉庫";
    f.needCount = 3;
    f.workPlace = "いしかわ総合スポーツセンター";
    f.rewards = "15,000円/⽇ ※変更の可能性あり";
  } else if (
    f.recruitmentType === "invest24" &&
    f.area === "いしかわ総合スポーツセンター" &&
    f.meetingPlace === "いしかわ総合スポーツセンター サブアリーナの倉庫"
  ) {
    f.area = "七尾";
    f.meetingPlace = undefined;
    f.needCount = 10;
    f.workPlace = undefined;
    f.rewards = undefined;
  }
});

// debug
/*
form.needCount = 10;
form.area = "七尾";
*/

startValidation.value = true;

const recruitmentType = ref<Invest24RecruitmentType>("invest24");

const { createRecruitment, getRecruitments } = useRecruitment();
const { data: recruitments } = getRecruitments({
  recruitmentType,
});

async function submit() {
  try {
    const prms = Invest24RecruitmentCheckScheme.parse(form);

    if (await createRecruitment(prms)) {
      window.alert("募集を作成しました");
      goto({
        name: "UsersRecruitmentsManagementsManagement",
        query: { recruitmentType: form.recruitmentType },
      });
    } else {
      window.alert("募集の作成に失敗しました");
    }
    return;
  } catch (e) {
    console.error(e);
    window.alert("入力に問題があります。赤枠の項目を確認して下さい。");
  }
}
</script>

<style scoped></style>
