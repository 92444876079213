<template>
  <div>
    <div class="d-flex flex-column align-items-center">
      <div class="contents-960 w-100">
        <NewsShow v-if="news" :news="news" />
        <span v-else-if="error" class="text-danger">
          ページが見つかりません
        </span>
      </div>

      <div class="my-5">
        <OpenSiteBackButton />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useNews, useRouterUtil } from "/@/vue/composables";
import { OpenSiteBackButton } from "/@/vue/components/Atom";
import { Show as NewsShow } from "/@/vue/components/Molecules/News";

const { currentRouteParams } = useRouterUtil();

const id = computed(() => {
  return Number(currentRouteParams.value.id as string);
});

const { getNewsItem } = useNews();
const { data: news, error } = getNewsItem(id);
</script>

<style scoped></style>
