<template>
  <div>
    <template v-if="recruitment">
      <template v-if="!dayInfoTargetDate">
        <div class="d-flex flex-row flex-wrap mb-3">
          <span>以下から変更したい項目を選び更新して下さい。</span>
          <span>複数グループを同時に変更することはできません。</span>
        </div>

        <div class="card card-body">
          <Accordion>
            <AccordionTab
              v-if="!dayInfoTargetDate"
              header="エリア/日にちの変更"
            >
              <div class="mb-4">
                <FormLabel
                  :label="`エリア${
                    recruitment.recruitmentType === 'shelter24'
                      ? '(変更不可)'
                      : ''
                  }`"
                />
                <Dropdown
                  :model-value="area"
                  :options="
                    recruitment.recruitmentType === 'invest24'
                      ? areas
                      : [...areas, 'いしかわ総合スポーツセンター']
                  "
                  :disabled="recruitment.recruitmentType === 'shelter24'"
                  class="w-100"
                  @update:model-value="
                    $emit('update:area', $event as string | undefined)
                  "
                />
                <div v-if="area === 'その他'" class="mb-4">
                  <FormItem
                    :value="area"
                    :errors="errors"
                    form-id="area"
                    form-type="text"
                    @update:value="
                      $emit('update:area', $event as string | undefined)
                    "
                  />
                </div>
              </div>
              <div class="d-flex mb-4">
                <div class="me-2">
                  <FormLabel label="開始日" />
                  <FormItem
                    :value="startOn"
                    form-id="startOn"
                    form-type="date"
                    :errors="errors"
                    :disabled="tommorow > fromISO(recruitment.startOn)"
                    style="max-width: 250px"
                    @update:value="
                      $emit('update:startOn', $event as string | undefined)
                    "
                  />
                </div>

                <div class="me-2">
                  <FormLabel
                    label="終了日 ※すでに計画が入っている日以降、または今日以降の日にちのみ選択できます"
                  />
                  <FormItem
                    :value="finishOn"
                    form-id="finishOn"
                    form-type="date"
                    :errors="errors"
                    :min-date="
                      minDateForFinishOn || fromISO(recruitment.startOn)
                    "
                    :disabled="finishedRecruitment"
                    style="max-width: 250px"
                    @update:value="
                      $emit('update:finishOn', $event as string | undefined)
                    "
                  />
                  <span v-if="finishedRecruitment" class="text-danger">
                    すでに終了している募集です。新規作成して下さい
                  </span>
                </div>
              </div>

              <div class="d-flex my-5">
                <BasicButton
                  button-type="submit"
                  label="更新"
                  :disabled="!startOn || !finishOn || !area"
                  @click="$emit('submitAreaTerm')"
                />
              </div>
            </AccordionTab>
            <AccordionTab header="人数">
              <div class="mb-4">
                <FormLabel
                  label="変更したい日にち ※日付選択後、決定ボタンを押して下さい"
                />
                <FormItem
                  :value="selectedTargetDates"
                  form-id="targetDates"
                  form-type="date"
                  :allowed-dates="targetDates"
                  multi-dates
                  :auto-apply="false"
                  :errors="selectedTargetDatesErrors"
                  @update:value="
                    $emit('update:selectedTargetDates', [...$event] as string[])
                  "
                />
              </div>

              <div class="mb-4">
                <FormLabel
                  label="募集人数 ※0またはすでに応募されている人数未満にすることでこの日の募集を終了できます"
                />
                <span class="text-danger"
                  >設定した人数が既に存在する参加者数よりも小さい場合、自動的に既存の人数に設定されます</span
                >
                <NumberForm
                  :value="needCount || 0"
                  form-id="needCount"
                  :items="optionNumbers"
                  style="max-width: 300px"
                  @update:value="
                    $emit('update:needCount', $event as number | undefined)
                  "
                />
              </div>

              <div class="d-flex my-5">
                <BasicButton
                  button-type="submit"
                  label="更新"
                  :disabled="!selectedTargetDates.length"
                  @click="$emit('submitNeedCount')"
                />
              </div>
            </AccordionTab>
            <AccordionTab header="情報">
              <div class="mb-4">
                <FormLabel
                  label="変更したい日にち ※日付選択後、決定ボタンを押して下さい"
                />
                <FormItem
                  :value="selectedTargetDates"
                  form-id="targetDates"
                  form-type="date"
                  :allowed-dates="targetDates"
                  multi-dates
                  :auto-apply="false"
                  :errors="selectedTargetDatesErrors"
                  @update:value="
                    $emit('update:selectedTargetDates', [...$event] as string[])
                  "
                />
              </div>

              <div class="mb-4">
                <FormLabel :label="`業務場所`" />
                <FormItem
                  :value="workPlace"
                  :errors="errors"
                  form-id="workPlace"
                  form-type="text"
                  :disabled="recruitment.recruitmentType === 'shelter24'"
                  @update:value="
                    $emit('update:workPlace', $event as string | undefined)
                  "
                />
              </div>

              <FormLabel label="時間" />
              <div class="mb-4 d-flex flex-wrap">
                <div class="me-2" style="max-width: 250px">
                  <FormLabel label="集合時間" />
                  <FormItemTime
                    :value="meetingAt"
                    :start-time="{ hours: 9, minutes: 0 }"
                    form-id="meetingAt"
                    :errors="errors"
                    @update:value="
                      $emit('update:meetingAt', $event as string | undefined)
                    "
                  />
                </div>

                <div class="me-2" style="max-width: 250px">
                  <FormLabel label="終了時間" />
                  <FormItemTime
                    :value="finishAt"
                    :start-time="{ hours: 17, minutes: 0 }"
                    form-id="finishAt"
                    :errors="errors"
                    :minutesIncrement="5"
                    @update:value="
                      $emit('update:finishAt', $event as string | undefined)
                    "
                  />
                </div>
              </div>

              <div class="mb-4">
                <FormLabel :label="`集合場所`" />
                <FormItem
                  :value="meetingPlace"
                  :errors="errors"
                  form-id="meetingPlace"
                  form-type="text"
                  :rows="10"
                  :disabled="recruitment.recruitmentType === 'shelter24'"
                  @update:value="
                    $emit('update:meetingPlace', $event as string | undefined)
                  "
                />
              </div>

              <div class="mb-4">
                <FormLabel label="持ち物" />
                <FormItem
                  :value="belongings"
                  :errors="errors"
                  form-id="belongings"
                  form-type="text"
                  @update:value="
                    $emit('update:belongings', $event as string | undefined)
                  "
                />
              </div>

              <div class="mb-4">
                <FormLabel label="報酬" />
                <FormItem
                  :value="rewards"
                  :errors="errors"
                  form-id="rewards"
                  form-type="text"
                  @update:value="
                    $emit('update:rewards', $event as string | undefined)
                  "
                />
              </div>

              <div class="mb-4">
                <FormLabel
                  :label="`備考 ※1000文字以内${
                    details?.length ? ` 現在：${details.length}文字` : ''
                  }`"
                />
                <FormItem
                  :value="details"
                  :errors="errors"
                  form-id="details"
                  form-type="textarea"
                  placeholder="例）持ち物、注意事項など"
                  :rows="8"
                  @update:value="
                    $emit('update:details', $event as string | undefined)
                  "
                />
              </div>

              <div class="d-flex my-5">
                <BasicButton
                  button-type="submit"
                  label="更新"
                  class="ms-2"
                  :disabled="!selectedTargetDates.length"
                  @click="$emit('submitInfo')"
                />
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </template>

      <div v-else class="mb-4">
        <FormLabel
          :label="`日にち${!!dayInfoTargetDate ? '(変更不可)' : ''}`"
        />
        <Dropdown
          :model-value="targetDate"
          :options="[targetDate]"
          :option-label="basicFormatter"
          :disabled="!!dayInfoTargetDate"
          class="w-100"
        />
      </div>

      <div v-if="targetDateInfo && targetDate" class="card card-body mb-4">
        <div class="mb-4">
          <FormLabel
            label="募集人数 ※0またはすでに応募されている人数未満にすることでこの日の募集を終了できます"
          />
          <NumberForm
            :value="targetDateInfo.needCount || 0"
            form-id="needCount"
            :items="optionNumbers"
            style="max-width: 300px"
            @update:value="
              updateRecruitmentDayInfo(targetDate, 'needCount', $event)
            "
          />
        </div>

        <div class="mb-4">
          <FormLabel
            :label="`業務場所(${
              recruitment.recruitmentType === 'shelter24' ? '変更不可' : '必須'
            })`"
          />
          <FormItem
            :value="targetDateInfo.workPlace"
            :errors="errors"
            form-id="workPlace"
            form-type="text"
            :disabled="recruitment.recruitmentType === 'shelter24'"
            @update:value="
              updateRecruitmentDayInfo(targetDate, 'workPlace', $event)
            "
          />
        </div>

        <FormLabel label="時間" />
        <div class="mb-4 d-flex flex-wrap">
          <div class="me-2" style="max-width: 250px">
            <FormLabel label="集合時間(必須)" />
            <FormItemTime
              :value="targetDateInfo.meetingAt"
              :start-time="{ hours: 9, minutes: 0 }"
              form-id="meetingAt"
              :errors="errors"
              @update:value="
                updateRecruitmentDayInfo(targetDate, 'meetingAt', $event)
              "
            />
          </div>

          <div class="me-2" style="max-width: 250px">
            <FormLabel label="終了時間(必須)" />
            <FormItemTime
              :value="targetDateInfo.finishAt"
              :start-time="{ hours: 17, minutes: 0 }"
              form-id="finishAt"
              :errors="errors"
              :minutesIncrement="5"
              @update:value="
                updateRecruitmentDayInfo(targetDate, 'finishAt', $event)
              "
            />
          </div>
        </div>

        <div class="mb-4">
          <FormLabel
            :label="`集合場所(${
              recruitment.recruitmentType === 'shelter24' ? '変更不可' : '必須'
            })`"
          />
          <FormItem
            :value="targetDateInfo.meetingPlace"
            :errors="errors"
            form-id="meetingPlace"
            form-type="text"
            :rows="10"
            :disabled="recruitment.recruitmentType === 'shelter24'"
            @update:value="
              updateRecruitmentDayInfo(targetDate, 'meetingPlace', $event)
            "
          />
        </div>

        <div class="mb-4">
          <FormLabel label="持ち物" />
          <FormItem
            :value="targetDateInfo.belongings"
            :errors="errors"
            form-id="belongings"
            form-type="text"
            @update:value="
              updateRecruitmentDayInfo(targetDate, 'belongings', $event)
            "
          />
        </div>

        <div class="mb-4">
          <FormLabel label="報酬" />
          <FormItem
            :value="targetDateInfo.rewards"
            :errors="errors"
            form-id="rewards"
            form-type="text"
            @update:value="
              updateRecruitmentDayInfo(targetDate, 'rewards', $event)
            "
          />
        </div>

        <div class="mb-4">
          <FormLabel
            :label="`備考 ※1000文字以内${
              targetDateInfo.details?.length
                ? ` 現在：${targetDateInfo.details.length}文字`
                : ''
            }`"
          />
          <FormItem
            :value="targetDateInfo.details"
            :errors="errors"
            form-id="details"
            form-type="textarea"
            placeholder="例）持ち物、注意事項など"
            :rows="8"
            @update:value="
              updateRecruitmentDayInfo(targetDate, 'details', $event)
            "
          />
        </div>

        <div class="d-flex my-5">
          <BasicButton
            label="キャンセル"
            variant="secondary"
            class="me-2"
            outlined
            @click="$emit('cancel')"
          />
          <BasicButton
            button-type="submit"
            label="更新"
            class="ms-2"
            @click="$emit('submit')"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useRouterUtil } from "/@/vue/composables";
import {
  Label as FormLabel,
  NumberForm,
  BasicButton,
} from "/@/vue/components/Atom";
import { FormItem, FormItemTime } from "/@/vue/components/Molecules";
import { basicFormatter, fromISO } from "/@/modules/luxon";
import {
  Invest24RecruitmentClient,
  Invest24RecruitmentDayInfoForm,
  ZodFormattedErrors,
} from "/@/types";
import { ref, computed, watch } from "vue";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { createIsoRange, luxonNow } from "/@/modules/luxon";
import { onMounted } from "vue";

const props = defineProps<{
  startOn?: string;
  finishOn?: string;
  area?: string;
  needCount?: number;
  workPlace?: string;
  meetingAt?: string;
  finishAt?: string;
  meetingPlace?: string;
  belongings?: string;
  rewards?: string;
  details?: string;
  invest24RecruitmentDayInfos: Invest24RecruitmentDayInfoForm[];
  selectedTargetDates: string[];
  recruitment?: Invest24RecruitmentClient;
  errors: ZodFormattedErrors;
}>();

const emit = defineEmits<{
  (e: "update:startOn", startOn: string | undefined): void;
  (e: "update:finishOn", finishOn: string | undefined): void;
  (e: "update:area", area: string | undefined): void;
  (e: "update:needCount", needCount: number | undefined): void;
  (e: "update:workPlace", workPlace: string | undefined): void;
  (e: "update:meetingAt", meetingAt: string | undefined): void;
  (e: "update:finishAt", finishAt: string | undefined): void;
  (e: "update:meetingPlace", meetingPlace: string | undefined): void;
  (e: "update:belongings", belongings: string | undefined): void;
  (e: "update:rewards", rewards: string | undefined): void;
  (e: "update:details", details: string | undefined): void;
  (e: "update:selectedTargetDates", selectedTargetDates: string[]): void;
  (
    e: "update:invest24RecruitmentDayInfos",
    dayInofs: Invest24RecruitmentDayInfoForm[]
  ): void;
  (e: "cancel"): void;
  (e: "submitAreaTerm"): void;
  (e: "submitNeedCount"): void;
  (e: "submitInfo"): void;
}>();

const today = luxonNow().startOf("day");
const tommorow = today.plus({ days: 1 });
const minDateForFinishOn = computed(() => {
  if (props.recruitment?.mostFutureParticipantDate) {
    const lMFPD = fromISO(props.recruitment.mostFutureParticipantDate);
    return lMFPD && lMFPD > tommorow ? lMFPD : tommorow;
  }

  if (props.startOn) {
    const lStartOn = fromISO(props.startOn);
    return lStartOn && lStartOn > tommorow ? lStartOn : tommorow;
  }

  return tommorow;
});
const finishedRecruitment = computed(() => {
  if (!props.finishOn) return false;

  return fromISO(props.finishOn) < today;
});

//

const { currentRouteQuery } = useRouterUtil();
//

const optionNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const areas = ["七尾", "穴水", "能登町", "輪島", "珠洲", "志賀", "その他"];

watch(
  () => props.area,
  (sarea) => {
    if (sarea !== "その他") {
      emit("update:area", sarea);
    } else {
      emit("update:area", undefined);
    }
  }
);

const dayInfoTargetDate = computed(() => {
  return currentRouteQuery.value.targetDate;
});

const targetDate = ref<string>();

onMounted(() => {
  targetDate.value =
    (currentRouteQuery.value.targetDate as string) || props.startOn;
});

const targetDates = computed(() => {
  const startOn = props.startOn;
  const finishOn = props.finishOn;

  if (!startOn || !finishOn) {
    return [];
  }

  const threshold = tommorow > fromISO(startOn) ? tommorow : fromISO(startOn);

  return createIsoRange(threshold.toISO(), props.finishOn, "day", {
    withoutTime: true,
  });
});

const selectedTargetDatesErrors = computed(() => {
  if (!props.selectedTargetDates.length) {
    return { targetDates: { _errors: ["日にちを選択して下さい"] } };
  }

  return {};
});

const targetDateInfo = computed(() => {
  const target = currentRouteQuery.value.targetDate;
  if (!target) {
    return;
  }

  return props.invest24RecruitmentDayInfos.find((r) => r.targetDate === target);
});

function updateRecruitmentDayInfo(
  targetDate: string,
  key: keyof Invest24RecruitmentDayInfoForm,
  value: string | number | undefined
) {
  const targetDateInfo = props.invest24RecruitmentDayInfos.find(
    (r) => r.targetDate === targetDate
  );

  emit("update:invest24RecruitmentDayInfos", [
    ...props.invest24RecruitmentDayInfos.filter(
      (r) => r.targetDate !== targetDate
    ),
    {
      ...targetDateInfo,
      [key]: value,
    },
  ]);
}
</script>

<style scoped></style>
