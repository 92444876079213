import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { OrganizationClientScheme } from "/@/types/organization.d";
import { userObject } from "/@/types/common.d";
import { NewsClientScheme } from "/@/types/news.d";
import { Invest24UserAttachedDayInfoScheme } from "/@/types/invest24User.d";

// user label

export const userLabelTypes = [
  // "system_admin",
  // "owner",
  "office",
  "all_manager",
  "all_poster",
  "all_l_manager",
  "u_manager",
  "c_manager",
  "c_poster",
  "v_manager",
  "a_manager",
  "a_poster",
  "rule_manager",
  "bcp_manager",
  "b_manager",
  "b_poster",
  "i_manager",
] as const;
export const UserLabelTypeScheme = z.enum([...userLabelTypes, "system_admin"]);
export type UserLabelType = zod.infer<typeof UserLabelTypeScheme>;

export const userLabelDependancyTypes = ["Organization"] as const;
export const UserLabelDependancyTypeScheme = z.enum(userLabelDependancyTypes);
export type UserLabelDependancyType = zod.infer<
  typeof UserLabelDependancyTypeScheme
>;

export const UserLabelScheme = z.object({
  id: z.number().optional(),
  labelType: UserLabelTypeScheme,
  dependancyName: z.string().optional(),
  dependancyType: UserLabelDependancyTypeScheme.optional(),
  dependancyId: z.number().optional(),
});

export type UserLabel = zod.infer<typeof UserLabelScheme>;

// user position

// 相談役	senior-advisor
// 会長	chairman
// 副会長	vice-chairman
// 理事	director
// 顧問	advisor

// 委員長	committee-chairman
// 副委員長	committee-vice-chairman

// 支部長	branch-manager
// 副支部長	branch-submanager
// 事務局長	secretary-general
// 事務局	secretariat
// 会計	accountant

export const userPositionTypes = [
  "senior_advisor",
  "chairman",
  "vice_chairman",
  "director",
  "advisor",

  "committee_chairman",
  "committee_vice_chairman",

  "branch_manager",
  "branch_submanager",
  "secretary_general",
  "secretariat",
  "accountant",
] as const;
export const UserPositionTypeScheme = z.enum(userPositionTypes);
export type UserPositionType = zod.infer<typeof UserPositionTypeScheme>;

export const UserPositionScheme = z.object({
  id: z.number().optional(),
  positionType: UserPositionTypeScheme,
});

// organization member

export const OrganizationMemberScheme = z.object({
  id: z.number().optional(),
  organizationId: z.number(),
});

export type OrganizationMember = zod.infer<typeof OrganizationMemberScheme>;

export const UserScheme = z.object(userObject).extend({
  userLabels: UserLabelScheme.array(),
  userPositions: UserPositionScheme.array(),
  organizationMembers: OrganizationMemberScheme.array(),
});

export type User = zod.infer<typeof UserScheme>;
export type UserAttributes = keyof User;

export const UserFormScheme = UserScheme.partial().extend({
  id: z.number().optional(),
});
export type UserForm = zod.infer<typeof UserFormScheme>;

export const UserCheckScheme = UserScheme;

export const UserClientScheme = UserScheme.extend({
  id: z.number(),
  organizations: OrganizationClientScheme.array(),
  joinOrganizations: OrganizationClientScheme.array(),
  managedOrganizations: OrganizationClientScheme.array(),
  memberAnnounces: NewsClientScheme.array(),
  changedPassword: z.boolean(),
  admin: z.boolean(),
  owner: z.boolean(),
  invest24RecruitmentDayInfos: Invest24UserAttachedDayInfoScheme.array(),
  confirmedAt: z.string().optional(),
  confirmedInWeek: z.boolean().optional(),
  updatedInWeek: z.boolean().optional(),
});

export type UserClient = zod.infer<typeof UserClientScheme>;

// session

type SingUpResponse = {
  status: "confirm" | "success" | "error" | "reconfirm";
  user?: User;
};

// login

export const LoginScheme = z.object({
  email: z.string().email(),
  password: z.string().min(1, { message: "パスワードが入力されていません" }),
});

export type Login = zod.infer<typeof LoginScheme>;

export const LoginFormScheme = LoginScheme.partial();
export type LoginForm = zod.infer<typeof LoginFormScheme>;

// change password

export const PasswordChangeScheme = z.object({
  password: z.string().min(1, { message: "パスワードが入力されていません" }),
  passwordConfirmation: z
    .string()
    .min(1, { message: "パスワードが入力されていません" }),
});

export type PasswordChange = zod.infer<typeof PasswordChangeScheme>;

export const PasswordChangeCheckScheme = PasswordChangeScheme.refine(
  (data) => data.password === data.passwordConfirmation,
  {
    message: "パスワードが一致しません",
    path: ["passwordConfirmation"],
  }
);

export const PasswordChangeFormScheme = PasswordChangeScheme.partial();
export type PasswordChangeForm = zod.infer<typeof PasswordChangeFormScheme>;

// sign up
