import useSWRV from "swrv";
import { MagazineClient, Magazine } from "/@/types/magazine";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { Ref } from "vue";

export function useMagazines() {
  function getMagazines(
    options: { onlyLatest: boolean } = { onlyLatest: false }
  ) {
    const { onlyLatest } = options;

    let url = "/api/v1/magazines?";

    if (onlyLatest) {
      url += "only_latest=true&";
    }

    return useSWRV<MagazineClient[]>(url, fetcher);
  }

  function getUsersMagazines() {
    return useSWRV<MagazineClient[]>(() => `/api/v1/users/magazines`, fetcher);
  }

  function getMagazineKey(id: Ref<number | undefined>) {
    if (!id.value) return null;

    return `/api/v1/users/magazines/${id.value}`;
  }

  function getUsersMagazine(id: Ref<number | undefined>) {
    return useSWRV<MagazineClient>(() => getMagazineKey(id), fetcher);
  }

  async function createMagazine(prms: Magazine) {
    try {
      await axios.post("/api/v1/magazines", {
        magazine: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateMagazine(id: number, prms: Magazine) {
    try {
      await axios.patch(`/api/v1/magazines/${id}`, {
        magazine: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyMagazine(id: number | number[]) {
    try {
      if (Array.isArray(id)) {
        await axios.post("/api/v1/magazines/bulk_destroy", {
          magazine: {
            ids: id,
          },
        });
        return true;
      } else {
        await axios.delete(`/api/v1/magazines/${id}`);
      }
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  return {
    getMagazines,
    getUsersMagazines,
    getUsersMagazine,
    createMagazine,
    updateMagazine,
    destroyMagazine,
  };
}
