<template>
  <div>
    <UserPageActions>
      <div
        v-if="magazine"
        class="d-flex align-items-center justify-content-end w-100"
      >
        <BasicButton
          label="編集"
          variant="secondary"
          outlined
          @click="
            goto({
              name: 'UsersMagazinesEdit',
              params: {
                id: magazine.id,
              },
            })
          "
        />
      </div>
    </UserPageActions>
    <MagazineShow v-if="magazine" :magazine="magazine" />
    <BackButton>
      <BasicButton
        v-if="magazine"
        label="編集"
        variant="secondary"
        outlined
        class="ms-5"
        @click="
          goto({
            name: 'UsersMagazinesEdit',
            params: {
              id: magazine.id,
            },
          })
        "
      />
    </BackButton>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useMagazines, useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { UserPageActions } from "/@/vue/components/Layouts";
import { BackButton } from "/@/vue/components/Molecules";
import { Show as MagazineShow } from "/@/vue/components/Molecules/Magazines";

const { currentRouteParams, goto } = useRouterUtil();
const id = computed(() => Number(currentRouteParams.value.id));

const { getUsersMagazine } = useMagazines();
const { data: magazine } = getUsersMagazine(id);
</script>

<style scoped></style>
