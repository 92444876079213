import Applied from "./Applied.vue";
import Index from "./Index.vue";
import New from "./New.vue";
import NewUser from "./NewUser.vue";
import Edit from "./Edit.vue";
import Join from "./Join.vue";
import Show from "./Show.vue";

import { Root as DataRoot, Router as DataRouter } from "./Data";
import { Root as JoinUsersRoot, Router as JoinUsersRouter } from "./JoinUsers";
import { Root as ManagementsRoot, Router as Managements } from "./Managements";

export default [
  {
    path: "",
    name: "UsersRecruitmentsIndex",
    component: Index,
    meta: {
      title: "ボランティア申請",
      subtitle: "VOLUNTEER",
      tree: [],
    },
  },
  {
    path: "applied",
    name: "UsersRecruitmentsApplied",
    component: Applied,
    meta: {
      title: "申請状況",
      subtitle: "APPLIED",
      tree: [],
    },
  },
  {
    path: "join",
    name: "UsersRecruitmentsJoin",
    component: Join,
    meta: {
      title: "申請",
      subtitle: "APPLICATION",
      tree: [{ title: "奥能登実態調査ボランティア", name: "UsersIndex" }],
    },
  },
  {
    path: "new",
    name: "UsersRecruitmentsNew",
    component: New,
    meta: {
      title: "新規作成",
      tree: [
        {
          title: "実態調査ボランティア管理",
          name: "UsersRecruitmentsManagementsManagement",
        },
      ],
    },
  },
  {
    path: "new_user",
    name: "UsersRecruitmentsNewUser",
    component: NewUser,
    meta: {
      title: "新規ボランティア登録",
      tree: [
        {
          title: "実態調査ボランティア管理",
          name: "UsersRecruitmentsManagementsManagement",
        },
      ],
    },
  },
  {
    path: "edit/:id",
    name: "UsersRecruitmentsEdit",
    component: Edit,
    meta: {
      title: "編集",
      tree: [
        {
          title: "実態調査ボランティア管理",
          name: "UsersRecruitmentsManagementsManagement",
        },
      ],
    },
  },
  {
    path: "show/:id",
    name: "UsersRecruitmentsShow",
    component: Show,
    meta: {
      title: "ボランティア詳細",
      tree: [
        // overwrite in root
      ],
    },
  },
  {
    path: "data",
    component: DataRoot,
    children: DataRouter,
  },
  {
    path: "join_users",
    component: JoinUsersRoot,
    children: JoinUsersRouter,
  },
  {
    path: "managements",
    component: ManagementsRoot,
    children: Managements,
  },
];
