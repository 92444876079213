<template>
  <div>
    <UserPageActions>
      <div class="d-flex align-items-center justify-content-between w-100">
        <Dropdown
          v-model="selectedOrganizationId"
          :options="organizations"
          option-value="id"
          option-label="name"
        />

        <div class="d-flex align-items-center">
          <BasicButton
            label="新規作成"
            class="add-btn me-2"
            variant="secondary"
            outlined
            @click="
              goto({
                name: 'UsersNewsNew',
                query: { organizationId: selectedOrganizationId },
              })
            "
          />

          <BasicButton
            label="ゴミ箱"
            variant="danger"
            outlined
            @click="
              goto({
                name: 'UsersNewsTrashIndex',
                query: { organizationId: selectedOrganizationId },
              })
            "
          />
        </div>
      </div>
    </UserPageActions>

    <span v-if="news && !news.length" class="text-danger"
      >お知らせがありません</span
    >
    <NewsTable
      v-else-if="news"
      :news="news"
      @select-news="selectNews"
      @trash-news="handleTrashNews"
    />
    <i v-else class="pi pi-spin pi-spinner"></i>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { getStorage, setStorage } from "/@/modules/localStorage";
import { useRouterUtil, useUser, useNews } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { UserPageActions } from "/@/vue/components/Layouts";
import { Table as NewsTable } from "/@/vue/components/Organisms/Users/News";
import Dropdown from "primevue/dropdown";

const { goto } = useRouterUtil();

function selectNews(id: number) {
  goto({ name: "UsersNewsShow", params: { id: id } });
}

// api

const { getUser } = useUser();
const { data: user } = getUser();

const organizations = computed(() => {
  if (!user.value) return [];

  return user.value.managedOrganizations;
});

const selectedOrganizationId = ref(
  getStorage("selectedOrganizationId") || undefined
);

watch(organizations, (os) => {
  if (!os) return;

  if (os.some((o) => o.id === selectedOrganizationId.value)) {
    return;
  }

  selectedOrganizationId.value = os[0]?.id;
});

watch(selectedOrganizationId, (id) => {
  if (!id) return;
  setStorage("selectedOrganizationId", id);
});

watch(user, (u) => {
  if (!u || !!selectedOrganizationId.value) return;

  selectedOrganizationId.value = u.managedOrganizations[0]?.id;
});

const { getUsersNews, trashNews } = useNews();
const { data: news, mutate: getNewsMutate } = getUsersNews(
  selectedOrganizationId
);

const goTrashMessage =
  "ゴミ箱に移動しますか？ ゴミ箱の記事は一定期間後に完全に削除されます。";

async function handleTrashNews(id: number | number[]) {
  if (!window.confirm(goTrashMessage)) {
    return;
  }

  if (await trashNews(id)) {
    alert("ゴミ箱に移動しました");
    getNewsMutate();
    goto({ name: "UsersNewsIndex" });
  } else {
    alert("移動に失敗しました");
  }
}
</script>

<style lang="scss" scoped></style>
