import axios from "axios";
import {
  Invest24Recruitment,
  Invest24RecruitmentType,
  Invest24RecruitmentClient,
  Invest24WorkType,
} from "/@/types";
import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";
import { ComputedRef, Ref } from "vue";
import { errorHandle } from "/@/modules/error";

export function useRecruitment() {
  function getInvest24RecruitmentKey(id?: Ref<number> | ComputedRef<number>) {
    if (!id || !id.value) return null;

    return `/api/v1/invest24_recruitments/${id.value}`;
  }

  function getRecruitment(id: Ref<number> | ComputedRef<number>) {
    return useSWRV<Invest24RecruitmentClient>(
      () => getInvest24RecruitmentKey(id),
      fetcher
    );
  }

  function getRecruitments(
    options: {
      afterNow?: boolean;
      recruitmentType?:
        | Ref<Invest24RecruitmentType>
        | ComputedRef<Invest24RecruitmentType>;
    } = {}
  ) {
    function getUri() {
      let uri = "/api/v1/invest24_recruitments";

      if (options.afterNow) {
        uri += "?after_now=true";
      }

      if (options.recruitmentType?.value) {
        uri += `?recruitment_type=${options.recruitmentType.value}`;
      }

      return uri;
    }

    return useSWRV<Invest24RecruitmentClient[]>(getUri, fetcher);
  }

  async function createRecruitment(prms: Invest24Recruitment) {
    // Create invest24Recruitment

    try {
      await axios.post("/api/v1/invest24_recruitments", {
        invest24Recruitment: prms,
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async function updateRecruitment(
    id: number,
    prms: Invest24Recruitment,
    targetDates?: string[]
  ) {
    // Update invest24Recruitment
    try {
      let ps: Invest24Recruitment & { targetDates?: string[] } = prms;

      if (targetDates) {
        ps = {
          ...prms,
          targetDates,
        };
      }

      await axios.patch(`/api/v1/invest24_recruitments/${id}`, {
        invest24Recruitment: ps,
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async function applyRecruitment(id: number, prms: { targetDate: string }) {
    try {
      await axios.post(`/api/v1/invest24_recruitments/${id}/apply`, {
        invest24Recruitment: prms,
      });
      return true;
    } catch (e) {
      alert(errorHandle(e));
      return false;
    }
  }

  async function applyRecruitmentWithNewUser(
    id: number,
    prms: { targetDate: string; area: string } & SignUp,
    {
      isVManager,
    }: {
      isVManager: boolean;
    } = {
      isVManager: false,
    }
  ) {
    try {
      let uri = `/api/v1/invest24_recruitments/${id}/apply_with_new_user`;

      if (isVManager) {
        uri += "?is_v_manager=true";
      }

      await axios.post(uri, {
        invest24Recruitment: prms,
      });
      return true;
    } catch (e) {
      //alert(errorHandle(e));
      return false;
    }
  }

  async function cancelRecruitment(id: number, prms: { targetDate: string }) {
    // Cancel invest24Recruitment
    try {
      await axios.post(`/api/v1/invest24_recruitments/${id}/cancel`, {
        invest24Recruitment: prms,
      });
      return true;
    } catch (e) {
      alert(errorHandle(e));
      return false;
    }
  }

  async function allocateUserRecruitment(
    id: number,
    prms: {
      targetDate: string;
      workType: Invest24WorkType;
      invest24UserId?: number;
      userId?: number;
    }
  ) {
    // allocate user invest24Recruitment
    try {
      await axios.post(`/api/v1/invest24_recruitments/${id}/allocate`, {
        invest24Recruitment: prms,
      });
      return true;
    } catch (e) {
      alert(errorHandle(e));
      return false;
    }
  }

  async function detachUserRecruitment(
    id: number,
    prms: { invest24UserId: number; targetDate: string }
  ) {
    // detach user invest24Recruitment
    try {
      await axios.post(`/api/v1/invest24_recruitments/${id}/detach`, {
        invest24Recruitment: prms,
      });
      return true;
    } catch (e) {
      alert(errorHandle(e));
      return false;
    }
  }

  async function destroyRecruitment(id: number) {
    // Destroy invest24Recruitment
    try {
      await axios.delete(`/api/v1/invest24_recruitments/${id}`);
      return true;
    } catch (e) {
      alert(errorHandle(e));
      return false;
    }
  }

  return {
    getRecruitment,
    getRecruitments,
    createRecruitment,
    updateRecruitment,
    applyRecruitment,
    applyRecruitmentWithNewUser,
    cancelRecruitment,
    destroyRecruitment,
    allocateUserRecruitment,
    detachUserRecruitment,
  };
}
