<template>
  <div>
    <MagazineForm
      v-model:title="form.title"
      v-model:published-at="form.publishedAt"
      v-model:new-attached-pdfs="form.newAttachedPdfs"
      :attached-pdfs="[]"
      :errors="errors"
    />

    <div class="d-flex justify-content-center my-5">
      <BasicButton
        label="キャンセル"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto"
      />
      <BasicButton button-type="submit" label="作成" @click="submit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch } from "vue";
import { useZodScheme, useRouterUtil, useMagazines } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as MagazineForm } from "/@/vue/components/Organisms/Users/Magazines";
import {
  MagazineCheckScheme,
  MagazineForm as MagazineFormType,
} from "/@/types";

const { goto, backto } = useRouterUtil();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<MagazineFormType>(
  MagazineCheckScheme,
  {
    newAttachedPdfs: [],
  }
);

watch(form, (f) => {
  if (f.title && f.title.length > 50) {
    form.title = f.title.slice(0, 50);
  }
});

onMounted(() => {
  startValidation.value = true;
});

const { createMagazine } = useMagazines();

async function submit() {
  try {
    startValidation.value = true;

    const args = MagazineCheckScheme.parse(form);

    if (await createMagazine(args)) {
      alert("作成に成功しました");
      goto({ name: "UsersMagazinesIndex" });
    } else {
      alert("作成に失敗しました。");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい");
    console.error(e);
  }
}
</script>

<style scoped></style>
