import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { invest24UserObject } from "/@/types/common.d";
import { Invest24WorkTypeScheme } from "/@/types/invest24WorkType.d";

export const invest24JoinUserScheme = z.object({
  invest24UserId: z.number(),
  invest24RecruitmentDayInfoId: z.number(),
});
export type Invest24JoinUser = zod.infer<typeof invest24JoinUserScheme>;

export const Invest24JoinUserClientScheme = invest24JoinUserScheme.extend({
  id: z.number(),
  targetDate: z.string(),
});
export type Invest24JoinUserClient = zod.infer<
  typeof Invest24JoinUserClientScheme
>;

// for join

export const Invest24JoinUserNewScheme = z.object({
  targetDate: z.string(),
  workType: Invest24WorkTypeScheme,
});

export const Invest24JoinUserNewFormScheme =
  Invest24JoinUserNewScheme.extend(invest24UserObject).partial();
export type Invest24JoinUserNewForm = zod.infer<
  typeof Invest24JoinUserNewFormScheme
>;

export const Invest24JoinUserNewCheckScheme = z.intersection(
  Invest24JoinUserNewScheme,
  z.object(invest24UserObject)
);
