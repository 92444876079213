<template>
  <div>
    <CoreForm
      v-model:title="form.title"
      v-model:format-type="form.formatType"
      v-model:new-attached-pdfs="form.newAttachedPdfs"
      :attached-pdfs="[]"
      hide-select-format-type
      title-as-class
      :errors="errors"
    />

    <div class="d-flex justify-content-center my-4">
      <BasicButton
        label="キャンセル"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto"
      />
      <BasicButton label="作成" button-type="submit" @click="submit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouterUtil, useFormats, useZodScheme } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as CoreForm } from "/@/vue/components/Organisms/Users/Formats";
import { FormatForm, FormatCheckScheme } from "/@/types";
import { watch } from "vue";
import { errorHandle } from "/@/modules/error";

const { backto, goto } = useRouterUtil();

function resetForm() {
  form.title = undefined;
  form.formatType = "application";
  form.data = {};
  form.newAttachedPdfs = [];
}

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<FormatForm>(
  FormatCheckScheme,
  {
    formatType: "application",
    newAttachedPdfs: [],
  }
);

watch(form, (f) => {
  if (f.title && f.title.length > 30) {
    form.title = f.title.slice(0, 30);
  }
});

const { createFormat } = useFormats();

startValidation.value = true;

async function submit() {
  try {
    const args = FormatCheckScheme.parse(form);

    if (await createFormat(args)) {
      alert("更新しました");
      resetForm();
      goto({ name: "UsersSystemAdminRulesIndex" });
    } else {
      // alert in createFormat
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    errorHandle(e);
  }
}
</script>

<style scoped></style>
