<template>
  <div class="d-flex align-items-center justify-content-end w-100">
    <span class="me-2">表示件数</span>
    <SelectButton
      v-model="selectedPerPage"
      :options="[10, 30, 50]"
      :allow-empty="false"
      class="me-2"
    />
    <span>{{ currentPage }}/{{ totalPage }}ページ</span>
  </div>
</template>

<script setup lang="ts">
import SelectButton from "primevue/selectbutton";
import { computed } from "vue";

const props = defineProps<{
  targetLength: number;
  currentPage: number;
}>();

const selectedPerPage = defineModel<number>({
  default: 10,
});

const totalPage = computed(() => {
  return Math.ceil(props.targetLength / selectedPerPage.value);
});
</script>

<style scoped></style>
