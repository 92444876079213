<template>
  <div>
    <UserPageActions>
      <div class="d-flex align-items-center justify-content-end w-100">
        <BasicButton
          label="編集"
          variant="secondary"
          outlined
          @click="
            goto({
              name: 'UsersSponsorsEdit',
            })
          "
        />
      </div>
    </UserPageActions>

    <i v-if="!sponsors" class="pi pi-spin pi-spinner"></i>
    <div v-else-if="Object.keys(sponsors).length">
      <SponsorList :sponsors="sponsors" />
    </div>
    <span v-else class="text-danger">スポンサーが作成されていません</span>
  </div>
</template>

<script setup lang="ts">
import { useRouterUtil, useSponsors } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { UserPageActions } from "/@/vue/components/Layouts";
import { List as SponsorList } from "/@/vue/components/Molecules/Sponsors";

const { goto } = useRouterUtil();

// api

const { getSponsors } = useSponsors();
const { data: sponsors, mutate: getNewsMutate } = getSponsors();
</script>

<style lang="scss" scoped></style>
