<template>
  <div>
    <BranchFilter
      v-if="showFilter"
      :organizations="
        hideNoNewsOrganization ? organizationsHasNews : organizations
      "
      v-model="selectedOrganizationId"
      class="d-flex align-items-center justify-content-center w-100"
    />
    <div
      v-if="showTitle && selectedOrganization"
      class="d-flex align-items-center justify-content-center py-3 w-100"
    >
      <h2>{{ selectedOrganization.name }}のお知らせ</h2>
    </div>

    <div class="d-flex flex-column align-items-center w-100">
      <div class="contents-960 w-100">
        <div
          v-if="showFilter"
          class="d-flex align-items-center justify-content-end mt-2 mb-3"
        >
          <div class="mt-3">
            <FormLabel label="絞り込み" />
            <div class="d-flex align-items-center">
              <Dropdown
                v-model="selectedLabelType"
                :option-label="
                  (l) => (l === 'all' ? 'すべて' : labelTranslater(l))
                "
                :options="newsNoramlLabels"
                style="min-width: 150px"
                class="me-3 radius"
              />
              <Dropdown
                v-model="selectedYear"
                :option-label="(y) => (y === 'all' ? 'すべて' : y + '年')"
                :options="newsYears"
                style="min-width: 150px"
                class="me-3 radius"
              />
            </div>
          </div>
        </div>

        <div v-if="filteredPinnedNews.length" class="mb-3">
          <div
            class="hover-animation-wrapper contents-960 w-100"
            v-for="(n, idx) in filteredPinnedNews"
            :key="n.id"
            role="button"
            style="max-width: 100%"
          >
            <NewsListItem
              :news="n"
              :news-length="filteredPinnedNews.length"
              :idx="idx"
              :with-content="withContent"
              :reverse-color="reverseColor"
              :index-mode="indexMode"
              @select="$emit('selectNews', n.id)"
            />
          </div>
        </div>

        <div v-if="showPageInfo" class="d-flex justify-content-end mb-3">
          <PageInfo
            :per-page="perPage"
            :total="filteredNews.length"
            :first="currentPage"
            style="font-size: 0.75rem"
            class="me-3"
          />
        </div>
        <template v-if="filteredNews.length">
          <div
            class="hover-animation-wrapper contents-960 w-100"
            v-for="(n, idx) in filteredNews"
            :key="n.id"
            role="button"
            style="max-width: 100%"
          >
            <NewsListItem
              :news="n"
              :news-length="news.length"
              :idx="idx"
              :with-content="withContent"
              :reverse-color="reverseColor"
              :index-mode="indexMode"
              @select="$emit('selectNews', n.id)"
            />
          </div>
        </template>
        <template v-else-if="!filteredPinnedNews.length"
          ><span>お知らせがありません</span></template
        >
      </div>

      <Paginator
        v-if="showPagenation && filteredNews.length > perPage"
        v-model:first="currentPage"
        :rows="perPage"
        :total-records="currentPageNews.length"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useMqUtils, useRouterUtil } from "/@/vue/composables";
import { basicFormatter, isoFormat, luxonNow } from "/@/modules/luxon";
import { labelTranslater } from "/@/modules/news";
import {
  NewsLabelChip,
  Line,
  Label as FormLabel,
} from "/@/vue/components/Atom";
import { PageInfo } from "/@/vue/components/Molecules/UI";
import { BranchFilter } from "/@/vue/components/Molecules/News";
import { ListItem as NewsListItem } from "/@/vue/components/Molecules/News";
import {
  NewsClient,
  NewsLabel,
  OrganizationClient,
  newsLabels,
} from "/@/types";
import Paginator from "primevue/paginator";
import Dropdown from "primevue/dropdown";
import { watch } from "vue";

const props = withDefaults(
  defineProps<{
    news: NewsClient[];
    organizations: OrganizationClient[];
    withContent?: boolean;
    reverseColor?: boolean;
    showTitle?: boolean;
    showFilter?: boolean;
    showPageInfo?: boolean;
    showPagenation?: boolean;
    hideNoNewsOrganization?: boolean;
    indexMode?: boolean;
    perPage?: number;
    isUserPage?: boolean;
  }>(),
  {
    perPage: 10,
  }
);

const emits = defineEmits<{
  (e: "selectNews", id: number): void;
}>();

const { isMobile } = useMqUtils();
const { currentRouteQuery, updateQuery } = useRouterUtil();

// organization

const selectedOrganizationId = ref<number>();
const selectedOrganization = computed(() => {
  return props.organizations.find((o) => o.id === selectedOrganizationId.value);
});

const association = computed(() => {
  return props.organizations.find((o) => !o.branch && !o.committee);
});

onMounted(() => {
  if (currentRouteQuery.value.organizationId) {
    selectedOrganizationId.value = parseInt(
      currentRouteQuery.value.organizationId
    );
  } else if (association.value) {
    selectedOrganizationId.value = association.value.id;
  }
});

watch(selectedOrganizationId, (id) => {
  updateQuery({ organizationId: id });
});

watch(
  () => currentRouteQuery.value.organizationId,
  (id) => {
    selectedOrganizationId.value = parseInt(id);
  }
);

// filter

const organizationsHasNews = computed(() => {
  return props.organizations.filter((o) => {
    return props.news.some((n) => n.organizationId === o.id);
  });
});

const selectedLabelType = ref<NewsLabel | "all">("all");
const newsNoramlLabels = [
  "all",
  ...newsLabels.filter((l) => l !== "member_announce"),
];

const today = luxonNow();

const selectedYear = ref<string | "all">("all");
const newsYears = computed(() => {
  return [
    "all",
    ...Array.from(new Set(props.news.map((n) => n.publishedAt.slice(0, 4)))),
  ];
});

// page

const currentPage = ref(0);

const organizationFilteredNews = computed(() => {
  return props.news.filter((n) => {
    if (!selectedOrganizationId.value) return true;
    return n.organizationId === selectedOrganizationId.value;
  });
});

const labelFilteredNews = computed(() => {
  return organizationFilteredNews.value.filter((n) => {
    if (selectedLabelType.value === "all") return true;

    return n.label === selectedLabelType.value;
  });
});

const filteredAllNews = computed(() => {
  return labelFilteredNews.value.filter((n) => {
    if (selectedYear.value === "all") return true;

    return n.publishedAt.slice(0, 4) === selectedYear.value;
  });
});

const filteredNews = computed(() => {
  return filteredAllNews.value.filter((n) => !n.nowPinned);
});

const filteredPinnedNews = computed(() => {
  return filteredAllNews.value.filter((n) => n.nowPinned);
});

const currentPageNews = computed(() => {
  return filteredNews.value.slice(
    currentPage.value,
    currentPage.value + props.perPage
  );
});
</script>

<style scoped></style>
