import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { Invest24JoinUserClientScheme } from "/@/types/invest24JoinUser.d";
import { invest24UserObject } from "/@/types/common.d";
import { Invest24WorkTypeScheme } from "/@/types/invest24WorkType.d";
import { Invest24RecruitmentType } from "/@/types/invest24Recruitment.d";

export const Invest24UserAttachedDayInfoScheme = z.object({
  id: z.number(),
  targetDate: z.string(),
  workPlace: z.string(),
  meetingPlace: z.string(),
  meetingAt: z.string(),
  finishAt: z.string(),
  belongings: z.string(),
  rewards: z.string(),
  details: z.string(),
  area: z.string(),
  invest24JoinUserId: z.number(),
  recruitmentType: z.enum(["invest24", "shelter24"]),
  workType: Invest24WorkTypeScheme,
});
export type Invest24UserAttachedDayInfo = zod.infer<
  typeof Invest24UserAttachedDayInfoScheme
>;

export const Invest24UserScheme = z.object(invest24UserObject);

export type Invest24User = zod.infer<typeof Invest24UserScheme>;
export type Invest24UserAttributes = keyof Invest24User;

export const Invest24UserFormScheme = Invest24UserScheme.partial().extend({
  id: z.number().optional(),
});
export type Invest24UserForm = zod.infer<typeof Invest24UserFormScheme>;

export const Invest24UserCheckScheme = Invest24UserScheme;

export const Invest24UserClientScheme = Invest24UserScheme.extend({
  id: z.number(),
  uuid: z.string(),
  userId: z.number().optional(),
  invest24JoinUsers: Invest24JoinUserClientScheme.array(),
  invest24RecruitmentDayInfos: Invest24UserAttachedDayInfoScheme.array(),
});

export type Invest24UserClient = zod.infer<typeof Invest24UserClientScheme>;

export const Invest24LoginScheme = Invest24UserScheme.pick({
  lastName: true,
  firstName: true,
  email: true,
  phoneNumber: true,
  birthday: true,
});
export type Invest24LoginPrms = zod.infer<typeof Invest24LoginScheme>;

export const Invest24LoginFormScheme = Invest24LoginScheme.partial();
export type Invest24LoginForm = zod.infer<typeof Invest24LoginFormScheme>;

export const Invest24LoginCheckScheme = Invest24LoginScheme;
