import Index from "./Index.vue";
import Pdf from "./Pdf.vue";

export default [
  {
    path: "",
    name: "UsersInvest24DataIndex",
    component: Index,
    meta: {
      title: "ボランティア会員管理",
      tree: [],
    },
  },
  {
    path: "pdf",
    name: "UsersInvest24DataPdf",
    component: Pdf,
    meta: {
      title: "被災高齢者等把握事業訪問者予定リスト",
      tree: [
        {
          name: "UsersInvest24DataIndex",
          title: "ボランティア会員管理",
        },
      ],
    },
  },
];
