<template>
  <div v-if="false" class="w-100 d-flex justify-content-center">
    <div style="max-width: 600px" class="w-100">
      <TopMenus class="w-100 p-3" />
    </div>
  </div>
  <IndexMenuItem :menu-items="menuItems" />
  <hr />
  <IndexMenuItem :menu-items="userMenuItems" />
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useToast } from "primevue/usetoast";
import { useRouterUtil, useUser, useAuthorize } from "/@/vue/composables";
import { TopMenus } from "/@/vue/components/Molecules/IndexPage";
import { MenuItems as IndexMenuItem } from "/@/vue/components/Organisms/Index";

const { currentRouteQuery, dropQuery, reload } = useRouterUtil();

const toast = useToast();

function showSuccessToast() {
  toast.add({
    severity: "success",
    summary: "認証成功",
    detail: "メールアドレスの認証に成功しました。",
    group: "br",
  });
}

onMounted(() => {
  if (currentRouteQuery.value.confirmation) {
    showSuccessToast();
  }

  dropQuery();
});

// user

const { user } = useUser();

const menuItems = [
  {
    label: "実態調査",
    icon: "pi pi-sun",
    to: { name: "Invest24Index" },
    tabKey: "top",
  },
  {
    label: "避難所",
    icon: "pi pi-shield",
    to: { name: "Shelter24Index" },
    tabKey: "top",
  },
  {
    label: "協会概要",
    icon: "pi pi-sitemap",
    to: { name: "About" },
    tabKey: "top",
  },
  {
    label: "県協会活動",
    icon: "pi pi-star",
    to: { name: "Activity" },
    tabKey: "top",
  },
  {
    label: "支部概要",
    icon: "pi pi-star-fill",
    to: { name: "BranchActivity" },
    tabKey: "top",
  },
  {
    label: "入会案内",
    icon: "pi pi-sign-in",
    to: { name: "Join" },
    tabKey: "top",
  },
  {
    label: "賛助会員",
    icon: "pi pi-users",
    to: { name: "Sponsor" },
    tabKey: "top",
  },
  {
    label: "新着お知らせ",
    icon: "pi pi-bell",
    to: { name: "NewsIndex" },
    tabKey: "top",
  },
  {
    label: "広報誌『わ』管理",
    icon: "pi pi-book",
    to: { name: "MagazinesIndex" },
    tabKey: "top",
  },
  {
    label: "お問い合わせ",
    icon: "pi pi-info-circle",
    to: { name: "Inquiry" },
    tabKey: "top",
  },
  //...createMenuItems(13, "top"),
  //...createMenuItems(18, "search"),
  //...createMenuItems(8, "application"),
  //...createMenuItems(4, "inquiry"),
];

const { logout } = useAuthorize();

const userMenuItems = computed(() => {
  if (user.value) {
    return [
      {
        label: "マイページ",
        icon: "pi pi-user",
        to: { name: "UsersIndex", params: { id: user.value.id } },
        tabKey: "top",
      },
      {
        label: "申請済ボランティア",
        icon: "pi pi-briefcase",
        visible: true,
        to: {
          name: "UsersJoinUsersIndex",
        },
        tabKey: "top",
      },
      {
        label: "ボランティア申請",
        icon: "pi pi-briefcase",
        visible: true,
        to: {
          name: "UsersRecruitmentsIndex",
        },
      },
      {
        label: "ログアウト",
        icon: "pi pi-sign-out",
        tabKey: "top",
        command: async () => {
          await logout();
          reload();
        },
      },
    ];
  } else {
    return [
      {
        label: "会員の方",
        icon: "pi pi-sign-in",
        to: { name: "SignIn" },
        tabKey: "top",
      },
    ];
  }
});
</script>

<style scoped lang="scss"></style>
