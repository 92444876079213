import Index from "./Index.vue";
import Publication from "./Publication.vue";

export default [
  {
    path: "",
    name: "UsersRulesIndex",
    component: Index,
    meta: {
      title: "協会書類等",
      subtitle: "DOCUMENTS",
      tree: [
        {
          title: "会員ページ 協会書類等",
        },
      ],
    },
  },
];
