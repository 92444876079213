<template>
  <div>
    <div class="my-5">
      <span>ページが見つかりません</span>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
