<template>
  <div>
    <UserPageActions>
      <div v-if="canReply" class="d-flex w-100 justify-content-end">
        <BasicButton
          v-if="!replyMode"
          label="返信"
          outlined
          @click="replyMode = true"
        />
        <BasicButton
          v-else
          label="キャンセル"
          variant="secondary"
          outlined
          @click="replyMode = false"
        />
      </div>
    </UserPageActions>

    <InquiryShow v-if="inquiry" :inquiry="inquiry" is-user-page />

    <div v-if="replyMode">
      <FormItem
        v-model:value="form.content"
        :errors="errors"
        label="返信内容"
        form-id="content"
        form-type="textarea"
        class="mb-3"
        :rows="5"
      />
    </div>

    <div class="d-flex flex-wrap justify-content-center my-5">
      <BasicButton
        v-if="!canReply || !replyMode"
        label="戻る"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto({ confirm: false })"
      />
      <BasicButton
        v-else
        label="キャンセル"
        variant="secondary"
        class="me-5"
        outlined
        @click="replyMode = false"
      />
      <template v-if="canReply">
        <BasicButton
          v-if="!replyMode"
          label="返信"
          outlined
          @click="replyMode = true"
        />
        <BasicButton v-else label="送信" outlined @click="submit" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, reactive } from "vue";
import { useZodScheme, useInquiries, useRouterUtil } from "/@/vue/composables";
import { FormItem } from "/@/vue/components/Molecules";
import { UserPageActions } from "/@/vue/components/Layouts";
import { Show as InquiryShow } from "/@/vue/components/Molecules/Inquiries";
import { BasicButton } from "/@/vue/components/Atom";
import { InquiryReplyForm, InquiryReplyCheckScheme } from "/@/types";

const { getInquiry, replyInquiry } = useInquiries();
const { currentRouteParams, goto, backto } = useRouterUtil();

const id = computed(() => {
  return Number(currentRouteParams.value.id as string);
});

const { data: inquiry } = getInquiry(id);

const canReply = computed(() => {
  if (!inquiry.value) return false;

  if (inquiry.value.inquiryReplies.length) {
    return false;
  }

  return true;
});

// reply

const replyMode = ref<boolean>(false);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<InquiryReplyForm>(
  InquiryReplyCheckScheme
);

async function submit() {
  try {
    startValidation.value = true;

    const args = InquiryReplyCheckScheme.parse(form);

    if (await replyInquiry(id.value, args)) {
      alert("送信しました");
      goto({ name: "UsersInquiriesIndex" });
    } else {
      alert(
        "予期せぬエラーで送信に失敗しました。時間をおいて再度お試しいただくか、運営会社にお問い合わせください。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
