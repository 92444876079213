import Index from "./Index.vue";
import New from "./New.vue";
import Show from "./Show.vue";
import Edit from "./Edit.vue";

export default [
  {
    path: "",
    name: "UsersSystemAdminRulesIndex",
    component: Index,
    meta: {
      title: "協会書類等管理",
      subtitle: "DOCUMENTS",
      tree: [],
    },
  },
  {
    path: "new",
    name: "UsersSystemAdminRulesNew",
    component: New,
    meta: {
      title: "新規作成",
      tree: [
        {
          name: "UsersSystemAdminRulesIndex",
          title: "協会書類等管理",
        },
      ],
    },
  },
  {
    path: ":id",
    name: "UsersSystemAdminRulesShow",
    component: Show,
    meta: {
      title: "詳細",
      tree: [
        {
          name: "UsersSystemAdminRulesIndex",
          title: "協会書類等管理",
        },
      ],
    },
  },
  {
    path: ":id/edit",
    name: "UsersSystemAdminRulesEdit",
    component: Edit,
    meta: {
      title: "編集",
      tree: [
        {
          name: "UsersSystemAdminRulesIndex",
          title: "Rules管理",
        },
      ],
    },
  },
];
