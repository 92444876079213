<template>
  <div id="session-sign-in">
    <LoginForm class="w-100" style="max-width: 1000px" @login="login">
      <MessageList id="sign-in-errors" :messages="errors" status="error" />
    </LoginForm>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useAuthorize, useRouterUtil } from "/@/vue/composables";
import { MessageList } from "/@/vue/components/Atom";
import { LoginForm } from "/@/vue/components/Organisms";
import { Login } from "/@/types";

// login

const { login: userLogin } = useAuthorize();
const { goto, reload } = useRouterUtil();

const loading = ref<boolean>(false);
const errors = ref<Array<string>>([]);

async function login(signinForm: Login) {
  loading.value = true;

  if (await userLogin(signinForm)) {
    reload();
  } else {
    errors.value = [
      "ログインに失敗しました。IDとパスワードを確認してください。",
    ];
  }
  loading.value = false;
}
</script>

<style scoped lang="scss">
#session-sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
}
</style>
