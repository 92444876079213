<template>
  <div id="user" class="p-0">
    <UserHeader
      v-if="!isOpenSite"
      :user="user"
      class="mb-2"
      :overwrite-title="overwriteTitle"
      :overwrite-tree="overwriteTree"
    />
    <div class="users-router-view">
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUser, useRouterUtil } from "/@/vue/composables";
import { UserHeader } from "/@/vue/components/Layouts";
import { computed } from "vue";

const { isOpenSite, currentRouteName, currentRouteQuery } = useRouterUtil();

const { getUser } = useUser();
const { data: user } = getUser();

// overwrite breadcrumb

const isInvest24 = computed(() => {
  return currentRouteQuery.value.recruitmentType === "invest24";
});

const overwriteTitle = computed(() => {
  let title;

  if (currentRouteName.value === "UsersRecruitmentsManagementsManagement") {
    if (isInvest24.value) {
      title = "奥能登実態調査ボランティア管理";
    } else {
      title = "1.5次避難所調査ボランティア管理";
    }

    if (currentRouteQuery.value.area) {
      title += `（${currentRouteQuery.value.area}）`;
    }
  }

  return title;
});

const overwriteTree = computed(() => {
  if (currentRouteName.value === "UsersRecruitmentsShow") {
    return [
      {
        title: "ボランティア管理",
        name: "UsersRecruitmentsManagementsIndex",
      },
      {
        title: isInvest24.value
          ? "奥能登実態把調査ボランティア管理"
          : "1.5次避難所ボランティア管理",
        name: "UsersRecruitmentsManagementsManagement",
        query: { recruitmentType: currentRouteQuery.value.recruitmentType },
      },
    ];
  } else if (currentRouteName.value === "UsersRecruitmentsEdit") {
    return [
      {
        title: "ボランティア管理",
        name: "UsersRecruitmentsManagementsIndex",
      },
      {
        title: isInvest24.value
          ? "奥能登実態把調査ボランティア管理"
          : "1.5次避難所ボランティア管理",
        name: "UsersRecruitmentsManagementsManagement",
        query: { recruitmentType: currentRouteQuery.value.recruitmentType },
      },
      {
        title: "ボランティア詳細",
        name: "UsersRecruitmentsShow",
        params: { id: currentRouteQuery.value.id },
        query: {
          targetDate: currentRouteQuery.value.targetDate,
          recruitmentType: currentRouteQuery.value.recruitmentType,
        },
      },
    ];
  }
});
</script>

<style lang="scss" scoped>
.user-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

#user {
  min-height: calc(100vh - 2rem - 100px);
}

.users-router-view {
  padding: 1rem;
}
</style>
