<template>
  <BasicButton
    v-if="showBtn"
    icon="pi pi-chevron-up"
    need-icon
    circle
    outlined
    class="scroll-top-btn p-card"
    @click="scrollToTop(targetEl)"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { scrollToTop } from "/@/modules/ui";
import { BasicButton } from "/@/vue/components/Atom";

defineProps<{
  targetEl?: HTMLElement;
}>();

const showBtn = ref(false);

function showScrollTop() {
  if (window.scrollY >= 560) {
    showBtn.value = true;
  } else {
    showBtn.value = false;
  }
}

onMounted(() => {
  window.addEventListener("scroll", showScrollTop, { passive: true });
});

defineExpose({
  showBtn,
});
</script>

<style scoped lang="scss">
.scroll-top-btn {
  height: 40px;
  width: 40px;
  border: none;
  color: #4b5563;
  transition: all 0.05s ease-in-out;

  &:hover {
    background: #ffffff;
    box-shadow: 1px 2px 2px 1px #e9ecef;
    transform: scale(1.01);
  }
}
</style>
