export function treeSearch(
  searchCriteria: { key: string; value: any },
  tree: any
) {
  function search(tree, key, value) {
    if (tree[key] === value) {
      return tree;
    }

    if (!tree.items) {
      return null;
    }

    for (let item of tree.items) {
      const result = search(item, key, value);
      if (result) {
        return {
          key: tree.key,
          items: [result],
        };
      }
    }

    return null;
  }

  return search(tree, searchCriteria.key, searchCriteria.value);
}
