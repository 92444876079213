<template>
  <div id="app">
    <Toast position="bottom-right" group="br" />
    <div class="d-flex justify-content-center">
      <div
        class="contents w-100"
        :class="{
          'is-open-site': isOpenSite,
        }"
        style="position: relative"
      >
        <transition name="slide" mode="out-in">
          <PageHeader
            v-if="isOpenSite"
            :page-title="pageTitle"
            :user="user"
            :organizations="[association, ...branchs]"
            :latest-magazine="latestMagazine"
          />
        </transition>
        <transition name="slide" mode="out-in">
          <OpenSiteHeader
            v-if="isOpenSite && currentRouteName != 'Index'"
            :title="pageTitle"
            :subtitle="pageSubtitle"
            class="mt-3"
            :key="currentRouteName || '' + currentRouteQuery"
          />
        </transition>
        <transition name="slide" mode="out-in">
          <OpenSitePageTree
            v-if="
              isOpenSite &&
              (pageTree || overwritePageTree) &&
              currentRouteName != 'Index'
            "
            :page-tree="overwritePageTree || pageTree"
            :key="currentRouteName || '' + currentRouteQuery"
          />
        </transition>

        <div class="d-flex w-100 flex-column align-items-center">
          <div
            class="router-view d-flex flex-column align-items-center"
            :class="{
              'is-mobile': isMobile,
            }"
            :style="{
              width: '100%',
            }"
          >
            <router-view v-slot="{ Component }" style="width: 100%">
              <transition name="slide" mode="out-in">
                <component :is="Component" :key="$route.path" />
              </transition>
            </router-view>

            <div
              v-if="isOpenSite"
              class="w-100"
              style="background-color: #f2eee9"
              id="footer"
            >
              <div
                :class="{
                  'w-100 d-flex justify-content-end mb-3':
                    scrollTopBtn?.showBtn,
                }"
              >
                <ScrollTopButton ref="scrollTopBtn" />
              </div>
              <PageFooter class="w-100" :organizations="branchs" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { dontNeedLoginList } from "/@/vue/pages/routes";
import {
  useRouterUtil,
  useMqUtils,
  useUser,
  useOrganizations,
  useMagazines,
} from "/@/vue/composables";
import { PageHeader, PageFooter } from "/@/vue/components/Layouts";
import {
  OpenSiteHeader,
  OpenSitePageTree,
  ScrollTopButton,
} from "/@/vue/components/Molecules";
import Toast from "primevue/toast";

const { currentRouteName, currentRouteMeta, currentRouteQuery, isOpenSite } =
  useRouterUtil();

const isIndexPage = computed(() => {
  return currentRouteName.value === "Index";
});

const pageTitle = computed(() => {
  return currentRouteMeta.value?.title;
});
const pageSubtitle = computed(() => {
  return currentRouteMeta.value?.subtitle;
});

const pageTree = computed(() => {
  return currentRouteMeta.value?.tree;
});

const recruitmentType = computed(() => {
  return currentRouteQuery.value?.recruitmentType;
});

const isInvest24 = computed(() => {
  return recruitmentType.value === "invest24" || !recruitmentType.value;
});

const isShelter24 = computed(() => {
  return recruitmentType.value === "shelter24";
});

const overwritePageTree = computed(() => {
  if (currentRouteName.value === "Invest24JoinUsersNew") {
    return [
      {
        title: isInvest24.value
          ? "奥能登実態把調査ボランティア"
          : "1.5次避難所ボランティア",
        name: isInvest24.value ? "Invest24Index" : "Shelter24Index",
      },
      {
        title: "ボランティア申請",
      },
    ];
  }
});

const { isMobile } = useMqUtils();

const { getUser } = useUser();
const { data: user } = getUser();

const { getOrganizations } = useOrganizations();
const { data: organizations } = getOrganizations({
  association: true,
  branch: true,
});

const association = computed(() => {
  if (!organizations.value) return undefined;

  return organizations.value.find((org) => !org.branch && !org.committee);
});

const branchs = computed(() => {
  if (!organizations.value) return [];

  return organizations.value.filter((org) => org.branch && !org.committee);
});

// magazines

const { getMagazines } = useMagazines();
const { data: magazines } = getMagazines({
  onlyLatest: true,
});
const latestMagazine = computed(() => {
  if (!magazines.value) return undefined;

  return magazines.value[0];
});

//

const scrollTopBtn = ref();

onMounted(() => {
  function callback(mutationsList: MutationRecord[], obs: MutationObserver) {
    // TODO:
    // primevue で追加される panel の filterinput に対して Enter キーで閉じててしまう問題への対処
    // 以下は Dropdown で開かれる panel 内の filterinput にのみ対応している
    //

    for (const mutation of mutationsList) {
      const panel = Array.from(mutation.addedNodes).find(
        (node) => node?.dataset?.pcSection === "panel"
      );

      if (panel) {
        const header = Array.from(panel.childNodes).find(
          (node) => node?.dataset?.pcSection === "header"
        );

        if (header) {
          const filtercontainer = Array.from(header.childNodes).find(
            (node) => node?.dataset?.pcSection === "filtercontainer"
          );

          if (filtercontainer) {
            const filterinput = Array.from(filtercontainer.childNodes).find(
              (node) => node?.dataset?.pcSection === "filterinput"
            );

            if (filterinput) {
              filterinput.addEventListener(
                "keydown",
                (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                },
                true
              );
            }
          }
        }
      }
    }
  }

  const target = document.body;
  const obs = new MutationObserver(callback);
  obs.observe(target, { childList: true });
});
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  // transform:translate(-30%)
}

#app {
  letter-spacing: 0.1rem;

  .is-open-site {
    color: $main-brawn;
  }

  .router-view {
    position: relative;

    > * {
      padding: 1rem 3rem;
      min-height: calc(100vh - 300px);
    }

    #footer {
      min-height: auto;
    }

    &.is-mobile {
      padding-top: 0;

      > * {
        padding: 1rem;
      }
    }
  }
}
</style>
