<template>
  <div>
    <div v-if="magazine">
      <MagazineForm
        v-model:title="form.title"
        v-model:published-at="form.publishedAt"
        v-model:new-attached-pdfs="form.newAttachedPdfs"
        :attached-pdfs="magazine.attachedPdfs"
        :errors="errors"
      />
    </div>

    <div class="d-flex justify-content-center my-5">
      <BasicButton
        label="キャンセル"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto"
      />
      <BasicButton button-type="submit" label="作成" @click="submit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch, computed } from "vue";
import { useZodScheme, useRouterUtil, useMagazines } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as MagazineForm } from "/@/vue/components/Organisms/Users/Magazines";
import {
  MagazineCheckScheme,
  MagazineForm as MagazineFormType,
  MagazineClient,
} from "/@/types";

const { currentRouteParams, goto, backto } = useRouterUtil();

const id = computed(() => Number(currentRouteParams.value.id));

const { updateMagazine, getUsersMagazine } = useMagazines();
const { data: magazine } = getUsersMagazine(id);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<MagazineFormType>(
  MagazineCheckScheme,
  magazine.value
);

function resetForm(magazine?: MagazineClient) {
  if (!magazine) {
    form.id = undefined;
    form.title = undefined;
    form.newAttachedPdfs = [];
  } else {
    form.id = magazine.id;
    form.title = magazine.title;
    form.publishedAt = magazine.publishedAt;
    form.newAttachedPdfs =
      magazine.attachedPdfs.map((pdf) => pdf.signedId) || [];
  }
}

watch(magazine, (m) => {
  if (!form.id && m) {
    resetForm(m);
  }
});

watch(form, (f) => {
  if (f.title && f.title.length > 50) {
    form.title = f.title.slice(0, 50);
  }
});

onMounted(() => {
  startValidation.value = true;
  if (magazine.value) {
    resetForm(magazine.value);
  }
});

async function submit() {
  try {
    startValidation.value = true;

    const args = MagazineCheckScheme.parse(form);

    if (await updateMagazine(id.value, args)) {
      alert("更新しました");
      goto({ name: "UsersMagazinesIndex" });
    } else {
      alert(
        "更新に失敗しました。添付されたファイルが大きすぎる可能性があります。問題が続く場合はお手数ですが管理者にお問い合わせください。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
