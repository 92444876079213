import { ActionContext, Module } from "vuex";
import { axios } from "/@/modules/axios";
import { RootState } from "./index";
import { SingUpResponse, User, UserClient, Login } from "/@/types";
import { errorHandle } from "/@/modules/error";
import { isAxiosError } from "axios";

export type UserStoreState = {
  user?: UserClient;
};

type UserActionContext = ActionContext<UserStoreState, RootState>;

async function signUpProc(user: User, url: string): Promise<SingUpResponse> {
  try {
    const result = await axios.post(url, {
      user,
    });

    if (result && result.data && result.data.state) {
      return {
        status: "success",
        user: result.data,
      };
    }

    return {
      status: "error",
    };
  } catch (e) {
    if (isAxiosError(e) && e.response?.status) {
      if (e.response.status == 401) {
        return {
          status: "confirm",
        };
      }

      if (e.response.status == 406) {
        return {
          status: "reconfirm",
        };
      }
      return {
        status: "error",
      };
    } else {
      errorHandle(e);
      return {
        status: "error",
      };
    }
  }
}

export const userState: Module<UserStoreState, RootState> = {
  namespaced: true,
  state: {
    user: undefined,
  },

  mutations: {
    SET_USER(state: UserStoreState, user: UserClient) {
      state.user = user;
    },

    LOGOUT(state: UserStoreState) {
      state.user = undefined;
    },
  },

  actions: {
    SET_USER({ commit }: UserActionContext, user: UserClient) {
      commit("SET_USER", user);
    },

    async GET_USER({ dispatch }: UserActionContext) {
      try {
        const result = await axios.get("/api/v1/current_user");

        if (!result || !result.data) {
          dispatch("SET_USER", undefined);
          return false;
        }

        const user: User = result.data;
        dispatch("SET_USER", user);

        return user;
      } catch (e) {
        errorHandle(e);
        return false;
      }
    },

    async LOGIN({ dispatch }: UserActionContext, user: Login) {
      try {
        const result = await axios.post<UserClient>("/api/v1/login", {
          user,
        });

        if (result.data) {
          dispatch("SET_USER", result.data);
          return true;
        } else {
          return false;
        }
      } catch (e) {
        errorHandle(e);
        return false;
      }
    },

    async LOGOUT({ commit }: UserActionContext) {
      try {
        await axios.post("/api/v1/logout");
        commit("LOGOUT");
        return true;
      } catch (e) {
        errorHandle(e);
        return false;
      }
    },

    async SIGN_UP_SUBSTITUTE(_, user: User): Promise<SingUpResponse> {
      return signUpProc(user, "/api/v1/sign_up?substitute=true");
    },

    async SIGN_UP_QR(_, user: User): Promise<SingUpResponse> {
      return signUpProc(user, "/api/v1/sign_up?from_qr=true");
    },

    async SIGN_UP(_, user: User): Promise<SingUpResponse> {
      return signUpProc(user, "/api/v1/sign_up");
    },
  },
};
