<template>
  <div>
    <div class="calendar-wrapper">
      <FullCalendar ref="fullcalendar" :options="options" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import jaLocale from "@fullcalendar/core/locales/ja";
import interactionPlugin from "@fullcalendar/interaction";
import type {
  CalendarOptions,
  DayCellContentArg,
  DayCellMountArg,
  EventClickArg,
  DateSelectArg,
  EventApi,
} from "@fullcalendar/core";
import type { CalendarEventArgs, DateMap, JpHolidays } from "/@/types";
import { fromISO } from "/@/modules/luxon";

const props = defineProps<{
  events: CalendarEventArgs[];
  selectedDate?: DateMap;
  existsDates?: string[];
  jpHolidays?: JpHolidays;
}>();

const emits = defineEmits<{
  (e: "dateClick", starStr: string, dateClickInfo: DateSelectArg): void;
  (e: "eventClick", event: EventApi, eventClickInfo: EventClickArg): void;
  (e: "update:selectedDate", selectedDate: DateMap): void;
}>();

const validRange = computed(() => {
  if (!props.existsDates) {
    return;
  }
  const startMonth = props.existsDates.at(0);
  const endMonth = props.existsDates.at(-1);

  if (!startMonth || !endMonth) {
    return;
  }

  return {
    start: fromISO(startMonth).startOf("month").toJSDate(),
    end: fromISO(endMonth).endOf("month").toJSDate(),
  };
});

function createCalendarOptions(options = {}) {
  return {
    plugins: [dayGridPlugin, interactionPlugin],
    headerToolbar: {
      start: "prev",
      center: "title",
      end: "next",
    },
    businessHours: true,
    navLinks: false,
    initialView: "dayGridMonth",
    locales: [jaLocale],
    locale: "ja",
    themeSystem: "bootstrap5",
    height: "auto",
    validRange: validRange.value,
    dayCellContent: (e: DayCellContentArg) => {
      return e.dayNumberText.replace("日", "");
    },
    dayCellDidMount: (e: DayCellMountArg) => {
      if (!props.jpHolidays) {
        return;
      }

      if (props.jpHolidays[e.date.toLocaleDateString("sv-SE")]) {
        // class を追加
        (e.el as HTMLElement).classList.add("fc-day-holiday");
      }
    },
    dateClick: (info: DateSelectArg) => {
      emits("dateClick", info.startStr, info);
    },
    eventClick(info: EventClickArg) {
      emits("eventClick", info.event, info);
    },
    //datesSet: updateCurrentYearAndMonth,
    ...options,
  };
}

const options = computed<CalendarOptions>(() => {
  return createCalendarOptions({
    events: props.events,
  });
});

// current selected date info

const fullcalendar = ref();

function updateCurrentYearAndMonth() {
  const calendarApi = fullcalendar.value.getApi();
  const currentDate = calendarApi.getDate();

  const newDateMap = {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 1,
  };

  emits("update:selectedDate", newDateMap);
}

function gotoSelectedYearAndMonth() {
  if (!props.selectedDate) {
    return;
  }

  const calendarApi = fullcalendar.value.getApi();
  const date = new Date(
    props.selectedDate.year,
    props.selectedDate.month - 1,
    1
  );
  calendarApi.gotoDate(date);
}

onMounted(() => {
  if (!props.selectedDate) {
    updateCurrentYearAndMonth();
  } else {
    gotoSelectedYearAndMonth();
  }
  fullcalendar.value.getApi().on("datesSet", updateCurrentYearAndMonth);
});
</script>

<style lang="scss">
// ここの css は fullcalendar component に適用するために、
// global になっているので注意する

.fc.fc-theme-standard {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
}

.fc h2.fc-toolbar-title {
  font-size: 1.5rem;
}

.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-prev-button
  .fc-icon-chevron-left {
  font-family: fcicons !important;
}

.fc .fc-button-primary {
  background-color: inherit;
  color: #8a8a8e;
  border-color: #8a8a8e;

  &:disabled,
  &:hover,
  &:not(:disabled):active {
    background-color: inherit;
    color: #8a8a8e;
    border-color: #8a8a8e;
  }
}

.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-next-button
  .fc-icon-chevron-right {
  font-family: fcicons !important;
}

.fc-event {
  max-width: 100px;
  width: 100%;
  padding: 0.5rem 0;
}

.fc-daygrid-event-harness {
  display: flex;
  justify-content: center;
}

.fc-daygrid-event {
  font-size: 1rem;
  text-align: center;
}

.calendar-wrapper {
  --fc-non-business-color: white;
  --fc-button-bg-color: #{$main-primary-color};
  --fc-button-border-color: #{$main-primary-color};
  --fc-button-hover-bg-color: #{$main-primary-color};
  --fc-button-hover-border-color: #{$main-primary-color};
  --fc-button-active-bg-color: #{$main-primary-color};
  --fc-button-active-border-color: #{$main-primary-color};
  --fc-today-bg-color: #{rgba(#fbbc05, 0.5)};
  --fc-bg-event-color: white;
  --fc-small-font-size: 10px;
}

.fc .fc-toolbar-title {
  font-size: 35px;
}

.fc {
  color: #8a8a8e;

  .fc-day {
    a {
      color: #8a8a8e;
      text-decoration: none;
    }

    &.fc-day-sun,
    &.fc-day-holiday {
      .fc-daygrid-day-top {
        a {
          color: #ea4335;
        }
      }
    }

    &.fc-day-sat {
      .fc-daygrid-day-top {
        a {
          color: #0080ff;
        }
      }
    }

    &.fc-day-other {
      .fc-daygrid-day-top {
        opacity: 1;

        a {
          opacity: 0.5;
        }
      }
    }
  }

  .fc-bg-event {
    .fc-event-title {
      display: flex;
      justify-content: flex-start;
      color: #ea4335;
    }
  }

  .fc-event.hidden-event {
    display: none;
  }
}
</style>
