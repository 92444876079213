<template>
  <div>
    <div class="form-wrapper">
      <JoinForm
        v-if="recruitment"
        v-model:target-date="form.targetDate"
        :area="recruitment.area"
        :recruitment="recruitment"
        :errors="errors"
      />

      <div class="d-flex justify-content-center my-5">
        <BasicButton
          label="キャンセル"
          variant="secondary"
          class="me-5"
          outlined
          @click="backto"
        />
        <BasicButton
          button-type="submit"
          label="送信"
          :disabled="loading"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import {
  useRouterUtil,
  useZodScheme,
  useRecruitment,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { JoinForm } from "/@/vue/components/Organisms/Users/Invest24Recruitments";
import { ApplyRecruitmentForm, ApplyRecruitmentCheckScheme } from "/@/types";

const { currentRouteQuery, backto, goto } = useRouterUtil();

const recruitmentId = computed<number>(() => {
  return Number(currentRouteQuery.value.id);
});

const targetDate = computed<string>(() => {
  return currentRouteQuery.value.targetDate as string;
});

// recruitment

const { getRecruitment, applyRecruitment } = useRecruitment();
const { data: recruitment } = getRecruitment(recruitmentId);

// form
const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<ApplyRecruitmentForm>(ApplyRecruitmentCheckScheme, {
    targetDate: targetDate.value,
  });

startValidation.value = true;
const loading = ref<boolean>(false);

async function submit() {
  try {
    loading.value = true;
    const prms = ApplyRecruitmentCheckScheme.parse(form);

    if (await applyRecruitment(recruitmentId.value, prms)) {
      alert("申請が完了しました");
      goto({ name: "UsersRecruitmentsIndex" });
    } else {
      alert("意図しないエラーで申請に失敗しました。");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }

  loading.value = false;
}
</script>

<style scoped></style>
