import Users from "./Users.vue";

import { Router as BcpRouter, Root as BcpRoot } from "./BCP";
import { Router as RulesRouter, Root as RulesRoot } from "./Rules";

export default [
  {
    path: "users",
    name: "UsersSystemAdminUsers",
    component: Users,
    meta: {
      title: "会員一覧",
      tree: [],
    },
  },
  {
    path: "bcp",
    component: BcpRoot,
    children: BcpRouter,
  },
  {
    path: "rules",
    component: RulesRoot,
    children: RulesRouter,
  },
];
