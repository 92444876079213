<template>
  <div>
    <div class="file-upload-component">
      <FileUpload
        :file-limit="fileLimit"
        accept="image/*"
        :max-file-size="500000"
        :show-upload-button="false"
        :show-cancel-button="false"
        invalid-file-size-message="ファイルのサイズが大きすぎます"
        :invalid-file-limit-message="`添付できるファイルは${fileLimit}件のみです`"
        invalid-file-type-message="ファイルの形式が正しくありません。添付可能なファイル形式は画像のみです。"
        choose-label="ファイル選択"
        :class="{
          'p-invalid': isInvalid,
        }"
        :style="{
          'border-color': isInvalid ? 'red' : '',
        }"
        @select="selectFile"
        @remove="removeFile"
      >
        <template #empty>
          <p class="text-danger" style="font-size: 0.75rem">
            最大サイズは500kBです。変更したい場合のみ添付
          </p>
        </template>
      </FileUpload>
      <span
        v-if="currentCount > fileLimit && isInvalid"
        class="text-danger mt-4"
      >
        添付可能な数を越えています
      </span>
      <span
        v-else-if="fileMin && fileMin > currentCount"
        class="text-danger mt-4"
      >
        ファイルを添付して下さい。
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import FileUpload from "primevue/fileupload";

withDefaults(
  defineProps<{
    modelValue?: string[];
    fileLimit?: number;
    fileMin?: number;
    currentCount: number;
    isInvalid?: boolean;
  }>(),
  {
    modelValue: undefined,
    fileLimit: 1,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", value?: string[]): void;
}>();

async function selectFile(e: any) {
  const files = e.files;

  const results: string[] = await Promise.all(
    files.map(async (file: any) => {
      const reader = new FileReader();

      let blob = new Blob([file], { type: file.type });

      await reader.readAsDataURL(blob);

      return new Promise(
        (resolve) => (reader.onload = () => resolve(reader.result))
      );
    })
  );

  emit("update:modelValue", results);
}

async function removeFile(e: any) {
  const files = e.files;

  const results: string[] = await Promise.all(
    files.map(async (file: any) => {
      const reader = new FileReader();

      let blob = new Blob([file], { type: file.type });

      await reader.readAsDataURL(blob);

      return new Promise(
        (resolve) => (reader.onload = () => resolve(reader.result))
      );
    })
  );
  emit("update:modelValue", results);
}
</script>

<style lang="scss">
.file-upload-component {
  .p-fileupload-file-thumbnail,
  .p-fileupload-file-badge {
    display: none;
  }
}

.file-upload-component:has(.p-fileupload-buttonbar .p-invalid) {
  border-color: red;
}
</style>
