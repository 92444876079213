function constructAlertText({
  title,
  mailType,
}: {
  title: string;
  mailType: "submit" | "cancel";
}) {
  const txts = [];

  switch (mailType) {
    case "submit":
      txts.push(`申請が完了しました。`);
      break;
    case "cancel":
      txts.push(`キャンセルが完了しました。`);
      break;
  }

  txts.push(`「${title}」というタイトルで確認メールを送信します。`);
  txts.push(
    `メールが届くのが遅い場合は迷惑フォルダに届いてしまっている場合もございますのでご確認をお願いします。`
  );

  return txts;
}

export const applyMailAlert = {
  message: constructAlertText({
    title: "ボランティアへの申請が完了しました",
    mailType: "submit",
  }),
  header: "申請完了",
  icon: "pi pi-check",
  acceptLabel: "OK",
  rejectClass: "d-none",
};

export const cancelMailAlert = {
  message: constructAlertText({
    title: "ボランティアへの申請をキャンセルしました",
    mailType: "cancel",
  }),
  header: "キャンセル完了",
  icon: "pi pi-check",
  acceptLabel: "OK",
  rejectClass: "d-none",
};
