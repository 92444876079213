<template>
  <div>
    <div
      class="mb-3 d-flex align-items-center justify-content-end"
      style="height: 50px"
    >
      <div v-if="news?.trashed">
        <Chip
          label="ゴミ箱の記事"
          class="ms-3"
          style="background-color: #ef4444; color: white"
        />
      </div>
    </div>

    <div v-if="news">
      <NewsForm
        v-model:for-members="form.forMembers"
        v-model:organization-id="form.organizationId"
        v-model:label="form.label"
        v-model:title="form.title"
        v-model:published-at="form.publishedAt"
        v-model:held-at="form.heldAt"
        v-model:content="form.content"
        v-model:place="form.place"
        v-model:lecturer="form.lecturer"
        v-model:cost="form.cost"
        v-model:application-method="form.applicationMethod"
        v-model:application-form-link="form.applicationFormLink"
        v-model:show-top="form.showTop"
        v-model:pinned-to="form.pinnedTo"
        v-model:new-attached-pdfs="form.newAttachedPdfs"
        :attached-pdfs="news.attachedPdfs"
        :organizations="managedOrganizations"
        class="pb-5"
        :user="user"
        :errors="errors"
      />

      <div class="d-flex justify-content-center my-4">
        <BasicButton
          label="キャンセル"
          variant="secondary"
          class="me-5"
          outlined
          @click="backto"
        />
        <BasicButton label="更新" button-type="submit" @click="submit" />
      </div>
    </div>
    <i v-else class="pi pi-spin pi-spinner"></i>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import { scrollToTop } from "/@/modules/ui";
import {
  useZodScheme,
  useNews,
  useRouterUtil,
  useUser,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as NewsForm } from "/@/vue/components/Organisms/Users/News";
import {
  NewsCheckScheme,
  NewsForm as NewsFormType,
  NewsClient,
} from "/@/types/news.d";
import Chip from "primevue/chip";

const { getUser } = useUser();
const { data: user } = getUser();

const managedOrganizations = computed(() => {
  if (!user.value) return [];

  return user.value.managedOrganizations;
});

const { getNewsItem, updateNews } = useNews();

const { currentRouteParams, backto, goto } = useRouterUtil();
const id = computed(() => Number(currentRouteParams.value.id));

const { data: news } = getNewsItem(id, { admin: true });

watch(news, (n) => {
  if (!form.id && n) {
    resetForm(n);
  }
});

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<NewsFormType>(
  NewsCheckScheme,
  news.value
);

watch(form, (f) => {
  if (f.title && f.title.length > 50) {
    form.title = f.title.slice(0, 50);
  }
});

function resetForm(news?: NewsClient) {
  form.id = news?.id || undefined;
  form.forMembers = news?.forMembers || false;
  form.organizationId = news?.organizationId || undefined;
  form.title = news?.title || undefined;
  form.content = news?.content || undefined;
  form.heldAt = news?.heldAt || undefined;
  form.place = news?.place || undefined;
  form.lecturer = news?.lecturer || undefined;
  form.cost = news?.cost || undefined;
  form.applicationMethod = news?.applicationMethod || undefined;
  form.applicationFormLink = news?.applicationFormLink || undefined;
  form.publishedAt = news?.publishedAt || undefined;
  form.label = news?.label || "announce";
  form.showTop = news?.showTop || false;
  form.pinnedTo = news?.pinnedTo || undefined;
  form.newAttachedPdfs = news?.attachedPdfs.map((pdf) => pdf.signedId) || [];
}

onMounted(() => {
  startValidation.value = true;
  if (news.value) {
    resetForm(news.value);
  }
  scrollToTop();
});

function changeShouldUndefinedParams(form: NewsFormType) {
  if (form.label === "announce") {
    form.heldAt = undefined;
    form.place = undefined;
    form.lecturer = undefined;
    form.cost = undefined;
    form.applicationMethod = undefined;
    form.applicationFormLink = undefined;
  }
}

async function submit() {
  try {
    startValidation.value = true;

    changeShouldUndefinedParams(form);

    const args = NewsCheckScheme.parse(form);

    if (await updateNews(id.value, args)) {
      alert("更新しました");
      goto({ name: "UsersNewsIndex" });
    } else {
      alert(
        "更新に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
