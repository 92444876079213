<template>
  <div class="d-flex flex-column align-items-center">
    <div class="contents-960 w-100 py-5">
      <div v-if="needChangePassword" class="mb-3">
        <span class="text-danger">
          初回ログインのためパスワードの変更が必要です
        </span>
      </div>

      <PasswordChangeForm @submit="handleSubmit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useUser, useRouterUtil } from "/@/vue/composables";
import { PasswordChangeForm } from "/@/vue/components/Organisms";
import { PasswordChange } from "/@/types";

const { goto, currentRouteQuery } = useRouterUtil();

const resetPasswordToken = computed(() => {
  return currentRouteQuery.value.reset_password_token;
});

const { getUser, resetPassword, setUser } = useUser();
const { data: user, mutate: getUserMutate } = getUser();

const needChangePassword = computed(() => {
  if (!user.value) return;

  return !user.value.changedPassword;
});

async function handleSubmit(form: PasswordChange) {
  if (await resetPassword(form, { token: resetPasswordToken.value })) {
    alert("パスワードを変更しました。");
    await getUserMutate();

    setUser(user.value);

    goto({ name: "UsersShow" });
  } else {
    alert("パスワードの変更に失敗しました。");
  }
}
</script>

<style scoped></style>
