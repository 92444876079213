<template>
  <div>
    <div class="file-upload-component">
      <FileUpload
        :multiple="true"
        :file-limit="fileLimit"
        accept=".pdf,.docx,image/*"
        :max-file-size="2000000"
        :show-upload-button="false"
        :show-cancel-button="false"
        invalid-file-size-message="ファイルのサイズが大きすぎます"
        :invalid-file-limit-message="`添付できるファイルは${fileLimit}件のみです`"
        invalid-file-type-message="ファイルの形式が正しくありません。添付可能なファイル形式はPDF,Word,画像のみです。"
        choose-label="ファイル選択"
        :class="{
          'p-invalid': isInvalid,
        }"
        :style="{
          'border-color': isInvalid ? 'red' : '',
        }"
        @select="selectFile"
        @remove="removeFile"
      >
        <template #empty>
          <p class="text-danger">
            ファイル1つの最大サイズは2MBです。添付可能なファイルの最大数はあと{{
              fileLimit
            }}件です。
          </p>
        </template>
      </FileUpload>
      <span
        v-if="newAttachedCount > fileLimit && isInvalid"
        class="text-danger mt-4"
      >
        添付可能な数を越えています
      </span>
      <span
        v-else-if="fileMin && fileMin > newAttachedCount"
        class="text-danger mt-4"
      >
        ファイルを添付して下さい。
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import FileUpload from "primevue/fileupload";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue?: string[];
    fileLimit?: number;
    fileMin?: number;
    currentCount: number;
    isInvalid?: boolean;
  }>(),
  {
    modelValue: undefined,
    fileLimit: 1,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", value?: string[]): void;
}>();

function embedFileNameToDataUrl(dataurl: string, filename: string) {
  return dataurl.replace(/;base64,/, `;name=${filename};base64,`);
}

async function selectFile(e: any) {
  const files = e.files;

  const results: string[] = await Promise.all(
    files.map(async (file: any) => {
      const reader = new FileReader();

      let blob = new Blob([file], { type: file.type });

      await reader.readAsDataURL(blob);

      return new Promise(
        (resolve) =>
          (reader.onload = () =>
            resolve(embedFileNameToDataUrl(reader.result as string, file.name)))
      );
    })
  );

  emit("update:modelValue", results);
}

async function removeFile(e: any) {
  const files = e.files;

  const results: string[] = await Promise.all(
    files.map(async (file: any) => {
      const reader = new FileReader();

      let blob = new Blob([file], { type: file.type });

      await reader.readAsDataURL(blob);

      return new Promise(
        (resolve) => (reader.onload = () => resolve(reader.result))
      );
    })
  );
  emit("update:modelValue", results);
}

const newAttachedCount = computed(() => {
  return props.modelValue?.length || 0;
});
</script>

<style lang="scss">
.file-upload-component {
  .p-fileupload-file-thumbnail,
  .p-fileupload-file-badge {
    display: none;
  }
}

.file-upload-component:has(.p-fileupload-buttonbar .p-invalid) {
  border-color: red;
}
</style>
