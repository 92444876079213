import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { NewsClientScheme } from "/@/types/news.d";

export const ConstituentMemberScheme = z.object({
  role: z.string().trim().min(1).max(20),
  members: z
    .object({
      name: z.string().trim().min(2).max(40),
      remarks: z.string().trim().min(0).max(50).optional(),
    })
    .array(),
});

export type ConstituentMember = zod.infer<typeof ConstituentMemberScheme>;

export const OrganizationScheme = z.object({
  id: z.number().optional(),
  name: z.string().trim().min(1).max(50),
  postcode: z.string().trim().min(7).max(7).optional().or(z.literal("")),
  address: z.string().trim().min(0).max(50).optional().or(z.literal("")),
  phoneNumber: z.string().trim().min(10).max(11).optional().or(z.literal("")),
  constituentMembers: ConstituentMemberScheme.array(),
  color: z.string().optional(),
});
export type Organization = zod.infer<typeof OrganizationScheme>;

export const organizationFormScheme = OrganizationScheme.partial().extend({
  constituentMembers: ConstituentMemberScheme.array(),
});
export type OrganizationForm = zod.infer<typeof organizationFormScheme>;

export const OrganizationCheckScheme = OrganizationScheme;

export const OrganizationClientScheme = OrganizationScheme.extend({
  id: z.number(),
  branch: z.boolean(),
  committee: z.boolean(),
  news: NewsClientScheme.array(),
  unreadCount: z.number().optional(),
});
export type OrganizationClient = zod.infer<typeof OrganizationClientScheme>;
