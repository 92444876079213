<template>
  <div>
    <Dialog
      :visible="visible"
      modal
      header="詳細情報"
      dismissable-mask
      block-scroll
      style="width: 90%; max-width: 500px"
      @after-hide="$emit('after-hide')"
      @update:visible="$emit('update:visible', $event)"
    >
      <div v-if="recruitment" :key="recruitment.updatedAt">
        <div
          v-if="isVManager"
          class="d-flex align-items-center justify-content-end w-100"
        >
          <BasicButton
            label="編集"
            variant="primary"
            @click="
              goto({
                name: 'UsersRecruitmentsEdit',
                params: { id: recruitment.id },
                query: { targetDate: targetDate },
              })
            "
          />
        </div>

        <ShowFormItemWrapper title="選択日">
          <span>{{ basicFormatter(targetDate) }}</span>
        </ShowFormItemWrapper>
        <RecruitmentShow
          v-if="targetDate"
          :recruitment="recruitment"
          :target-date="targetDate"
          :invest24-users="invest24Users"
          :is-v-manager="isVManager"
          without-term
          @detach-user="$emit('detachUser', $event)"
        />
        <UserAllocater
          v-if="showAllocater && targetDate"
          :recruitment="recruitment"
          :invest24-users="invest24Users || []"
          :target-date="targetDate"
          :users="users || []"
          @allocate-user="$emit('allocateUser', $event)"
          @allocate-new-user="
            $emit('allocateNewUser', { id: recruitment.id, targetDate })
          "
        />

        <div
          v-if="targetDate && !isVManager"
          class="d-flex justify-content-between w-100"
        >
          <!--
          <BasicButton
            v-if="isJoinedRecruitment"
            label="取り下げる"
            variant="danger"
            :disabled="loading"
            @click="$emit('cancelRecruitment')"
          >
          </BasicButton>
          -->
          <BasicButton
            v-if="targetDateInfo"
            label="申請する"
            :disabled="
              loading ||
              targetDateInfo.currentInvest24JoinUsersCount >=
                targetDateInfo.needCount
            "
            @click="$emit('applyRecruitment')"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouterUtil } from "/@/vue/composables";
import { basicFormatter, isAfterToday } from "/@/modules/luxon";
import { BasicButton } from "/@/vue/components/Atom";
import { ShowFormItemWrapper } from "/@/vue/components/Molecules/Form";
import {
  Show as RecruitmentShow,
  UserAllocater,
} from "/@/vue/components/Molecules/Invest24Recruitments";
import Dialog from "primevue/dialog";
import {
  Invest24RecruitmentClient,
  Invest24UserClient,
  Invest24WorkType,
  UserClient,
} from "/@/types";

const props = defineProps<{
  recruitment?: Invest24RecruitmentClient;
  visible?: boolean;
  targetDate?: string;
  invest24Users: Invest24UserClient[];
  users: UserClient[];
  loading?: boolean;
  isVManager?: boolean;
}>();

defineEmits<{
  (e: "update:targetDate", value: string): void;
  (e: "update:visible", value: boolean): void;
  (e: "after-hide"): void;
  (e: "applyRecruitment"): void;
  (e: "cancelRecruitment"): void;
  (e: "destroyRecruitment"): void;
  (
    e: "allocateUser",
    prms: { invest24UserId: number; workType: Invest24WorkType }
  ): void;
  (e: "allocateNewUser", prms: { id: number; targetDate: string }): void;
  (e: "detachUser", id: number): void;
}>();

//

const { goto } = useRouterUtil();

// recruitment

const targetDateInfo = computed(() => {
  const recruitment = props.recruitment;
  const td = props.targetDate;

  if (!recruitment || !td) return;

  return recruitment.invest24RecruitmentDayInfos.find(
    (r) => r.targetDate === td
  );
});

// allocater

const showAllocater = computed(() => {
  return (
    props.isVManager &&
    targetDateInfo.value &&
    targetDateInfo.value.currentInvest24JoinUsersCount <
      targetDateInfo.value.needCount &&
    props.targetDate &&
    isAfterToday(props.targetDate)
  );
});
</script>

<style scoped></style>
