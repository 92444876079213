import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { PdfInfoScheme } from "/@/types/pdf.d";

export const MagazineScheme = z.object({
  id: z.number().optional(),
  title: z.string().trim().min(1).max(50),
  publishedAt: z.string().optional(),
  newAttachedPdfs: z.string().array().min(1).max(1),
});
export type Magazine = zod.infer<typeof MagazineScheme>;

export const MagazineFormScheme = MagazineScheme.partial({
  title: true,
  publishedAt: true,
});
export type MagazineForm = zod.infer<typeof MagazineFormScheme>;

export const MagazineCheckScheme = MagazineScheme;

export const MagazineClientScheme = MagazineScheme.extend({
  id: z.number(),
  publishedAt: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  attachedPdfs: PdfInfoScheme.array(),
});
export type MagazineClient = zod.infer<typeof MagazineClientScheme>;
