import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const inquiryTypes = ["ご相談", "ご依頼", "ご意見", "その他"] as const;

export const InquiryReplyScheme = z.object({
  content: z.string().trim().min(1).max(1000),
});
export const InquiryReplyFormScheme = InquiryReplyScheme.partial();
export type InquiryReplyForm = zod.infer<typeof InquiryReplyFormScheme>;

export const InquiryReplyCheckScheme = InquiryReplyScheme;

export const InquiryReplyClientScheme = InquiryReplyScheme.extend({
  id: z.number(),
  userId: z.number(), // reply user id  userId: z.number(), // reply user id
  userName: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const InquiryScheme = z.object({
  id: z.number().optional(),
  name: z.string().trim().min(1).max(50),
  email: z.string().email(),
  phoneNumber: z.string().trim().min(10).max(11).optional().or(z.literal("")),
  inquiryType: z.string().trim().min(1).max(50),
  contents: z.string().trim().min(1).max(1000),
});
export type Inquiry = zod.infer<typeof InquiryScheme>;

export const InquiryFormScheme = InquiryScheme.partial();
export type InquiryForm = zod.infer<typeof InquiryFormScheme>;

export const InquiryFormCheckScheme = InquiryScheme;

export const InquiryClientScheme = InquiryScheme.extend({
  id: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  userId: z.number(),
  inquiryReplies: InquiryReplyClientScheme.array(),
});
export type InquiryClient = zod.infer<typeof InquiryClientScheme>;
