<template>
  <div class="d-flex flex-column align-items-center">
    <MailConfirm ref="mailConfirm" mail-type="cancel" />
    <div v-if="dayInfos.length" class="w-100">
      <div class="d-flex justify-content-between align-items-center w-100">
        <SelectButton
          v-model="selectedArea"
          :options="areasWithDisabled"
          option-label="label"
          option-value="value"
          option-disabled="disabled"
          :allow-empty="false"
        />
      </div>
      <div class="mb-3">
        <span class="text-danger" style="font-size: 0.75rem">
          1.5次避難所ボランティアの情報は「その他」を選択してください
        </span>
      </div>

      <div
        class="d-flex flex-wrap justify-content-between align-items-center mb-3"
      >
        <SelectDate
          v-model:year="selectedDate.year"
          v-model:month="selectedDate.month"
          only-year-month
          :exists-dates="existsDates"
          disabled-auto-select
        />

        <BasicButton
          label="終了したものを表示"
          icon="pi pi-eye"
          :outlined="!showPast"
          @click="showPast = !showPast"
        />
      </div>
      <Invest24RecruitmentDayInfos
        v-if="filteredDayInfos.length"
        :invest24-recruitment-day-infos="filteredDayInfos"
        @select="openModal"
      />
      <span v-else class="text-danger">申請済のものがありません</span>

      <RecruitmentDayInfoModal
        v-model:visible="visibleRecruitmentModal"
        :day-info="selectedDayInfo"
        @after-hide="closeModal"
        @cancel="handleCancel"
      />
    </div>
    <span v-else class="text-danger"> 申請したボランティアがありません </span>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, reactive } from "vue";
import { areas } from "/@/modules/constants";
import { isContainInDateMap } from "/@/modules/datemap";
import { useUser, useInvest24JoinUser } from "/@/vue/composables";
import { getAreasWithDisabledByDayInfos } from "/@/modules/invest24Recruitment";
import { MailConfirm } from "/@/vue/components/Layouts";
import { SelectDate, BasicButton } from "/@/vue/components/Atom";
import {
  Table as Invest24RecruitmentDayInfos,
  Modal as RecruitmentDayInfoModal,
} from "/@/vue/components/Molecules/Invest24RecruitmentDayInfos";
import SelectButton from "primevue/selectbutton";
import { luxonNow, isGreater } from "/@/modules/luxon";
import { DateMap } from "/@/types";

const mailConfirm = ref();

const { getUser } = useUser();
const { data: user, mutate: getUserMutate } = getUser({ withInvest24: true });

const selectedArea = ref();

const areasWithDisabled = computed(() => {
  if (!user.value)
    return areas.map((area) => ({ label: area, value: area, disabled: false }));

  return getAreasWithDisabledByDayInfos(user.value.invest24RecruitmentDayInfos);
});

watch(areasWithDisabled, (areas) => {
  if (areas.length === 0) return;
  if (selectedArea.value) return;
  selectedArea.value = areas[0].value;
});

const showPast = ref(false);

const dayInfos = computed(() => {
  if (!user.value) return [];
  return user.value.invest24RecruitmentDayInfos;
});

const areaFilteredDayInfos = computed(() => {
  if (!user.value) return [];
  return dayInfos.value.filter(
    (dayInfo) =>
      (dayInfo.area === selectedArea.value ||
        (selectedArea.value === "その他" && !areas.includes(dayInfo.area))) &&
      (showPast.value ||
        isGreater(dayInfo.targetDate, today.startOf("day").toISO(), {
          orEqual: true,
        }))
  );
});

const existsDates = computed<string[]>(() => {
  if (!user.value) return [];

  return areaFilteredDayInfos.value
    .map((dayInfo) => dayInfo.targetDate)
    .filter((b) => !!b);
});

const today = luxonNow();

const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
});

const filteredDayInfos = computed(() => {
  return areaFilteredDayInfos.value.filter((dayInfo) =>
    isContainInDateMap(dayInfo.targetDate, selectedDate)
  );
});

// modal

const visibleRecruitmentModal = ref(false);

const selectedDayInfoId = ref<number>();
const selectedDayInfo = computed(() => {
  if (!user.value) return;

  return user.value.invest24RecruitmentDayInfos.find(
    (dayInfo) => dayInfo.id === selectedDayInfoId.value
  );
});

function openModal(id: number) {
  selectedDayInfoId.value = id;
  visibleRecruitmentModal.value = true;
}

function closeModal() {
  selectedDayInfoId.value = undefined;
  visibleRecruitmentModal.value = false;
}

// actions

const { destroyJoinUser } = useInvest24JoinUser();

async function handleCancel(id: number) {
  if (
    !window.confirm("ボランティアへの参加をキャンセルします。よろしいですか？")
  ) {
    return;
  }

  if (await destroyJoinUser(id)) {
    mailConfirm.value.open(() => {
      getUserMutate();
      closeModal();
    });
  } else {
    //alert("申請の取り消しに失敗しました");
  }
}
</script>

<style scoped></style>
