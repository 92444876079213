<template>
  <Button
    :class="btnClass"
    :severity="severity"
    :disabled="disabledInComponent || disabled"
    :label="label"
    :name="name"
    :type="buttonType"
    :icon="icon"
    :icon-pos="iconPos"
    :size="size"
    :rounded="rounded"
    :text="text"
    :outlined="outlined"
    @click="onClick"
  >
    <slot></slot>
  </Button>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, ButtonHTMLAttributes } from "vue";
import Button from "primevue/button";
type ButtonTypes = ButtonHTMLAttributes["type"];

interface Props {
  name?: string;
  variant?: "danger" | "primary" | "secondary" | "warning" | "gray" | "success";
  label?: string;
  disabled?: boolean;
  circle?: boolean;
  large?: boolean;
  small?: boolean;
  middle?: boolean;
  slim?: boolean;
  auto?: boolean;
  icon?: string;
  iconPos?: "left" | "right";
  rounded?: boolean;
  withShadow?: boolean;
  buttonType?: ButtonTypes;
  outlined?: boolean;
  text?: boolean;
  w300?: boolean;
  stopDualClick?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  name: undefined,
  variant: undefined,
  buttonType: "button",
  withShadow: false,
  icon: undefined,
  iconPos: "left",
  label: undefined,
});

const emit = defineEmits<{
  (e: "click"): void;
}>();

const btnClass = computed(() => ({
  "drop-shadow": props.withShadow,
  "btn-circle": props.circle,
  "btn-slim": props.slim,
  "btn-auto": props.auto,
  "w-300": props.w300,
}));

const severity = computed(() => {
  return props.variant;
});

const size = computed(() => {
  if (props.small) {
    return "small";
  }

  if (props.large) {
    return "large";
  }

  return undefined;
});

const disabledInComponent = ref<boolean>(false);

function onClick(e: MouseEvent) {
  e.stopPropagation();
  emit("click");
  if (props.stopDualClick || props.buttonType === "submit") {
    const sleep = (msec: number) =>
      new Promise((resolve) => setTimeout(resolve, msec));

    (async () => {
      disabledInComponent.value = true;
      await sleep(3000);
      disabledInComponent.value = false;
    })();
  }
}
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;

  &.btn-danger {
    background-color: #ea4335;
    color: white;
  }

  &.btn-icon {
    border: none;
  }

  &.btn-warning {
    background-color: #fbbc05;
    color: white;
  }

  &.btn-circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    font-size: 15px;
  }

  &.btn-small {
    width: 22px;
    height: 22px;
    padding: 0;
  }

  &.btn-middle {
    height: 30px;
    width: 30px;
    padding: 0;
  }

  &.btn-large {
    width: 22px;
    height: 22px;
    padding: 0;
  }

  &.btn-slim {
    height: 40px;
  }

  &.btn-auto {
    height: auto;
    width: auto;
  }

  &:disabled {
    background-color: #c2c2c2;
    color: #ffffff;
    border-color: #00000029;
  }

  &.w-300 {
    max-width: 300px;
  }

  > * {
    text-align: center;
    vertical-align: middle;
  }
}
</style>
