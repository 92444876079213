<template>
  <div>
    <div v-if="selectedItems.length" class="table-action-menu-button-wrapper">
      <MenuButton :menu-items="menuItems" />
    </div>
    <DataTable
      v-model:selection="selectedItems"
      striped-rows
      paginator
      :rows="selectedPerPage"
      :value="magazines"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-click="$emit('selectMagazine', $event.data.id)"
    >
      <template #header>
        <TableHeader
          v-model="selectedPerPage"
          :target-length="magazines.length"
          :current-page="currentPage"
        />
      </template>
      <Column
        v-if="!isMobile"
        selection-mode="multiple"
        header-style="width: 2rem"
      >
      </Column>
      <Column sortable field="title" header="タイトル">
        <template #body="{ data }">
          <span>{{ data.title }}</span>
        </template>
      </Column>
      <Column
        sortable
        field="publishedAt"
        header="公開日時"
        :header-style="
          isMobile
            ? { width: '170px', 'min-width': '170px' }
            : { width: '170px', 'min-width': '170px' }
        "
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.publishedAt, "slashStyle") }}</span>
        </template>
      </Column>
      <Column :style="{ width: '50px', 'min-width': '50px' }">
        <template #body="{ data }">
          <div class="d-flex">
            <BasicButton
              variant="danger"
              icon="pi pi-trash"
              need-icon
              text
              button-type="submit"
              @click="$emit('trashMagazine', data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils, useTableHeader } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules";
import { Header as TableHeader } from "/@/vue/components/Molecules/TableUtils";
import { MagazineClient } from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import type { MenuItem } from "primevue/menuitem";

const props = defineProps<{
  magazines: MagazineClient[];
}>();

const emit = defineEmits<{
  (e: "selectMagazine", id: number): void;
  (e: "trashMagazine", id: number | number[]): void;
}>();

const { isMobile } = useMqUtils();
const { selectedPerPage, currentPage, changePage } = useTableHeader();

const selectedItems = ref<MagazineClient[]>([]);

const menuItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "完全に削除する",
      icon: "pi pi-trash",
      command: () => {
        emit(
          "trashMagazine",
          selectedItems.value.map((n) => n.id)
        );
        selectedItems.value = [];
      },
    },
  ];
});
</script>

<style lang="scss">
// in Organizations/Tabel.vue
</style>
