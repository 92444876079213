<template>
  <div>
    <DataTable
      striped-rows
      paginator
      :rows="10"
      :value="organizations"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-click="$emit('selectOrganization', $event.data.id)"
    >
      <Column
        sortable
        field="name"
        header="名前"
        :style="isMobile ? {} : { width: '200px', 'min-width': '200px' }"
      />
      <Column field="phoneNumber" header="電話番号" style="width: 150px" />
      <Column header="住所" style="min-width: 150px">
        <template #body="{ data }">
          <span>
            <span v-if="data.postcode" :class="{ 'me-2': !!data.address }">
              {{ formatPostcode(data.postcode) }}
            </span>
            <span v-if="data.address">
              {{ data.address }}
            </span>
          </span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { formatPostcode } from "/@/modules/address";
import { formatNational } from "/@/modules/phonenumber";
import { useMqUtils } from "/@/vue/composables";
import { OrganizationClient } from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

defineProps<{
  organizations: OrganizationClient[];
}>();

defineEmits<{
  (e: "selectOrganization", id: number): void;
}>();

const { isMobile } = useMqUtils();
</script>

<style lang="scss">
.table-action-menu-button-wrapper {
  position: relative;

  button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
}
</style>
