<template>
  <div>
    <div
      class="d-flex flex-wrap align-items-center justify-content-center"
      :style="{
        'max-width': isMobile ? '700px' : '900px',
      }"
    >
      <FilterButton
        v-for="a in areas"
        :label="a.label"
        :is-active="selectedArea === a.value"
        :small="isMobile"
        :key="a.value"
        :disabled="a.disabled"
        @click="a.disabled ? () => {} : (selectedArea = a.value)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMqUtils } from "/@/vue/composables";
import { FilterButton } from "/@/vue/components/Atom/Buttons";

defineProps<{
  areas: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
}>();

const selectedArea = defineModel<string>();

const { isMobile } = useMqUtils();
</script>

<style scoped></style>
