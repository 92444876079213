<template>
  <div
    class="mb-3 d-flex align-items-center"
    style="height: auto; min-height: 50px"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
