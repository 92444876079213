import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { Invest24UserClientScheme } from "/@/types/invest24User.d";
import { Invest24WorkTypeScheme } from "/@/types/invest24WorkType.d";

export const Invest24RecruitmentDayInfoScheme = z.object({
  id: z.number().optional(),
  targetDate: z.string(),
  meetingPlace: z.string().min(1).max(100),
  meetingAt: z.string(),
  finishAt: z.string().optional(),
  workPlace: z.string().min(1).max(100),
  belongings: z.string().min(0).max(255).optional().or(z.literal("")),
  rewards: z.string().min(0).max(1000).optional().or(z.literal("")),
  details: z.string().min(0).max(1000).optional().or(z.literal("")),
  needCount: z.number(),
});

export type Invest24RecruitmentDayInfo = zod.infer<
  typeof Invest24RecruitmentDayInfoScheme
>;

export const Invest24RecruitmentDayInfoFormScheme =
  Invest24RecruitmentDayInfoScheme.partial();
export type Invest24RecruitmentDayInfoForm = zod.infer<
  typeof Invest24RecruitmentDayInfoFormScheme
>;

export const Invest24UserAttachedInDayInfoScheme =
  Invest24UserClientScheme.pick({
    id: true,
    invest24UserId: true,
    lastName: true,
    firstName: true,
    lastNameKana: true,
    firstNameKana: true,
    birthday: true,
    email: true,
    phoneNumber: true,
    uuid: true,
    userId: true,
  }).extend({
    invest24JoinUserId: z.number(),
    workType: Invest24WorkTypeScheme,
  });

export type Invest24UserAttachedInDayInfo = zod.infer<
  typeof Invest24UserAttachedInDayInfoScheme
>;

export const Invest24RecruitmentDayInfoClientScheme =
  Invest24RecruitmentDayInfoScheme.extend({
    id: z.number(),
    invest24JoinUserInfos:
      Invest24UserAttachedInDayInfoScheme.array().optional(), // admin or manager のみ
    currentInvest24JoinUsersCount: z.number(),
  });
export type Invest24RecruitmentDayInfoClient = zod.infer<
  typeof Invest24RecruitmentDayInfoClientScheme
>;

export const invest24RecruitmentTypes = ["invest24", "shelter24"] as const;
export const Invest24RecruitmentTypeScheme = z.enum(invest24RecruitmentTypes);
export type Invest24RecruitmentType = zod.infer<
  typeof Invest24RecruitmentTypeScheme
>;

export const Invest24RecruitmentScheme = z.object({
  id: z.number().optional(),
  recruitmentType: Invest24RecruitmentTypeScheme,
  startOn: z.string(),
  finishOn: z.string(),
  details: z.string().min(0).max(1000).optional().or(z.literal("")),
  area: z.string().min(1).max(100),
  needCount: z.number(),
  meetingPlace: z.string().min(1).max(100),
  meetingAt: z.string(),
  finishAt: z.string(),
  workPlace: z.string().min(0).max(100).optional().or(z.literal("")),
  belongings: z.string().min(0).max(255).optional().or(z.literal("")),
  rewards: z.string().min(0).max(1000).optional().or(z.literal("")),
  invest24RecruitmentDayInfos: Invest24RecruitmentDayInfoScheme.array(),
});
export type Invest24Recruitment = zod.infer<typeof Invest24RecruitmentScheme>;

export const Invest24RecruitmentFormScheme =
  Invest24RecruitmentScheme.partial().extend({
    recruitmentType: Invest24RecruitmentTypeScheme,
    invest24RecruitmentDayInfos: Invest24RecruitmentDayInfoFormScheme.array(),
  });
export type Invest24RecruitmentForm = zod.infer<
  typeof Invest24RecruitmentFormScheme
>;

export const Invest24RecruitmentCheckScheme = Invest24RecruitmentScheme;
export const Invest24RecruitmentCheckUpdateScheme =
  Invest24RecruitmentScheme.pick({
    startOn: true,
    finishOn: true,
    details: true,
    area: true,
    needCount: true,
    meetingPlace: true,
    meetingAt: true,
    finishAt: true,
    workPlace: true,
    belongings: true,
    rewards: true,
  }).extend({
    invest24RecruitmentDayInfos: Invest24RecruitmentDayInfoFormScheme.array(),
  });

export const Invest24RecruitmentClientScheme = Invest24RecruitmentScheme.pick({
  id: true,
  recruitmentType: true,
  startOn: true,
  finishOn: true,
  details: true,
  area: true,
  createdAt: true,
  updatedAt: true,
}).extend({
  id: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  invest24RecruitmentDayInfos: Invest24RecruitmentDayInfoClientScheme.array(),
  hasParticipant: z.boolean(),
  mostFutureParticipantDate: z.string().optional(),
});
export type Invest24RecruitmentClient = zod.infer<
  typeof Invest24RecruitmentClientScheme
>;

export const ApplyInvest24RecruitmentScheme = z.object({
  targetDate: z.string(),
});
export type ApplyRecruitment = zod.infer<typeof ApplyInvest24RecruitmentScheme>;

export const ApplyInvest24RecruitmentFormScheme =
  ApplyInvest24RecruitmentScheme.partial();
export type ApplyRecruitmentForm = zod.infer<
  typeof ApplyInvest24RecruitmentFormScheme
>;

export const ApplyRecruitmentCheckScheme = ApplyInvest24RecruitmentScheme;
