<template>
  <div>
    <div :class="`d-flex flex-row flex-wrap align-items-center`">
      <BasicButton
        v-if="!hideMonthChangeBtn"
        icon="pi pi-angle-left"
        small
        class="month-change-btn mb-2 me-2"
        :disabled="disabledPrevBtn"
        @click="prevMonth"
      />

      <div class="d-flex flex-row me-3 year-selector mb-2">
        <select
          class="form-select me-2"
          :value="year"
          :disabled="disabled"
          @change="updateYear"
        >
          <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
        </select>
        <label class="col-form-label">年</label>
      </div>
      <div class="d-flex flex-row month-selector mb-2">
        <select
          class="form-select me-2"
          :value="month"
          :disabled="!year || disabled"
          @change="updateMonth"
        >
          <option v-for="m in months" :key="m" :value="m">
            {{ m }}
          </option>
        </select>
        <label class="col-form-label me-1">月</label>
      </div>
      <div class="mb-2 me-2">
        <BasicButton
          v-if="selected && withResetBtn"
          variant="secondary"
          icon="pi pi-times-circle"
          text
          rounded
          @click="reset"
        />
      </div>

      <BasicButton
        v-if="!hideMonthChangeBtn"
        icon="pi pi-angle-right"
        small
        class="month-change-btn mb-2"
        :disabled="disabledNextBtn"
        @click="nextMonth"
      />
    </div>

    <div v-if="!onlyYearMonth" class="mb-2">
      <DateForm
        :value="day"
        :allowed-dates="existsDates"
        :disabled="disabled"
        :max-date="maxDate"
        :min-date="minDate"
        auto-apply
        is-valid
        @update:value="updateDay"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed, onMounted } from "vue";
import { DateTime } from "luxon";
import { fromISO, luxonNow } from "/@/modules/luxon";
import { createExistsDateMap } from "/@/modules/datemap";
import { setStorage, getStorage } from "/@/modules/localStorage";
import { DateForm, BasicButton } from "/@/vue/components/Atom";
import type { DateMap } from "/@/types";

interface Props {
  day?: string;
  month?: number;
  year?: number;
  existsDates: string[];
  disabled?: boolean;
  onlyYearMonth?: boolean;
  today?: DateTime;
  maxDate?: DateTime;
  minDate?: DateTime;
  withResetBtn?: boolean;
  disabledAutoSelect?: boolean;
  hideMonthChangeBtn?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  day: undefined,
  month: undefined,
  year: undefined,
  today: () => luxonNow(),
  maxDate: undefined,
  minDate: undefined,
});

const emit = defineEmits<{
  (e: "update:day", day: string | undefined): void;
  (e: "update:month", month: number | undefined): void;
  (e: "update:year", year: number | undefined): void;
}>();

function reset() {
  emit("update:year", undefined);
  emit("update:month", undefined);
  emit("update:day", undefined);
}

const selected = computed(() => {
  return !!props.year || !!props.month || props.day;
});

function updateYear(e: Event) {
  if (e.target instanceof HTMLSelectElement) {
    const nYear = parseInt(e.target.value);

    if (nYear) {
      emit("update:month", months.value.at(-1));
      emit("update:day", undefined);
    }

    emit("update:year", nYear);
    setStorage("selectedYear", nYear);
  } else {
    throw new Error("予期せぬエラーが発生しました");
  }
}

function updateMonth(e: Event) {
  if (e.target instanceof HTMLSelectElement) {
    const nMonth = parseInt(e.target.value);

    emit("update:month", nMonth);

    if (!props.day) {
      return;
    }

    const selectedMonth: number | undefined = fromISO(props.day)?.month;

    if (nMonth && selectedMonth !== nMonth) {
      emit("update:day", undefined);
    }

    setStorage("selectedMonth", nMonth);
  } else {
    throw new Error("予期せぬエラーが発生しました");
  }
}

function updateDay(day: string) {
  // TODO: 複数の日付の選択だけでいいかもしれない
  // ただし、year と month でのフィルターの関係上ひとつの月の中で選択という制限が必要で、
  // その制限方法の実装が思い付かなかったため保留
  const nDay = day;

  emit("update:day", nDay);

  if (nDay) {
    const selectedDay = fromISO(nDay);
    emit("update:month", selectedDay?.month);
    emit("update:year", selectedDay?.year);
  }
}

// pdatepicker

const existsMonths = computed<DateMap[]>(() => {
  if (!props.existsDates) {
    return [];
  }

  return _.uniqBy(
    createExistsDateMap(props.existsDates),
    (item) => `${item.year}-${item.month}`
  ).toSorted((a, b) => {
    if (a.year === b.year) {
      return a.month - b.month;
    }

    return a.year - b.year;
  });
});

function getYears(existsMonths: DateMap[]) {
  const years = existsMonths.map((m) => m.year);
  //years.push(today.year);
  return _.uniq(years).sort((a, b) => a - b);
}

const years = computed(() => {
  if (!existsMonths.value) {
    return [];
  }

  return getYears(existsMonths.value);
});

function getMonths(existsMonths: DateMap[], year: number | undefined) {
  if (!year) {
    return [];
  }

  const monthArr = existsMonths
    .filter((m) => {
      return m.year == year;
    })
    .map((m) => {
      return m.month;
    });

  return _.uniq(monthArr).sort((a, b) => a - b);
}

const months = computed(() => {
  if (!existsMonths.value) {
    return [];
  }

  return getMonths(existsMonths.value, props.year);
});

onMounted(() => {
  if (!existsMonths.value.length || props.disabledAutoSelect) {
    return;
  }

  const storagedYear = getStorage("selectedYear");
  const storagedMonth = getStorage("selectedMonth");

  if (storagedYear) {
    emit("update:year", storagedYear);
  }

  if (storagedMonth) {
    emit("update:month", storagedMonth);
  }

  if (storagedYear && storagedMonth) {
    return;
  }

  if (!storagedYear) {
    const years = getYears(existsMonths.value);
    const selectedYear = years.at(-1);
    emit("update:year", selectedYear);

    if (!storagedMonth) {
      const months = getMonths(existsMonths.value, selectedYear);
      const selectedMonth = months.at(-1);
      emit("update:month", selectedMonth);
    }
  }
});

function getPrevMonthFromExistsMonths(year: number, month: number) {
  const currentIdx = existsMonths.value.findIndex(
    (m) => m.year === year && m.month === month
  );

  if (currentIdx === 0) return undefined;

  const prev = existsMonths.value.at(currentIdx - 1);

  if (prev) {
    return { year: prev.year, month: prev.month };
  } else {
    return undefined;
  }
}

function getNextMonthFromExistsMonths(year: number, month: number) {
  const currentIdx = existsMonths.value.findIndex(
    (m) => m.year === year && m.month === month
  );

  if (currentIdx === existsMonths.value.length - 1) return undefined;

  const next = existsMonths.value.at(currentIdx + 1);

  if (next) {
    return { year: next.year, month: next.month };
  } else {
    return undefined;
  }
}

const disabledPrevBtn = computed(() => {
  if (!props.month || !props.year) {
    return true;
  }

  const result = getPrevMonthFromExistsMonths(props.year, props.month);

  if (!result) {
    return true;
  }

  return false;
});

const disabledNextBtn = computed(() => {
  if (!props.month || !props.year) {
    return true;
  }

  const result = getNextMonthFromExistsMonths(props.year, props.month);

  if (!result) {
    return true;
  }

  return false;
});

function prevMonth() {
  if (disabledPrevBtn.value || !props.month || !props.year) {
    return;
  }

  const result = getPrevMonthFromExistsMonths(props.year, props.month);

  if (!result) {
    return;
  }

  emit("update:year", result.year);
  emit("update:month", result.month);
}

function nextMonth() {
  if (disabledNextBtn.value || !props.month || !props.year) {
    return;
  }

  const result = getNextMonthFromExistsMonths(props.year, props.month);

  if (!result) {
    return;
  }

  emit("update:year", result.year);
  emit("update:month", result.month);
}
</script>

<style lang="scss" scoped>
.year-selector {
  min-width: 100px;
}

.month-selector {
  min-width: 90px;
}

.month-change-btn {
  width: 30px !important;
  height: 30px !important;
}
</style>
